@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

.notfound {
  font-size: 35px;
  font-family: 'Nunito', sans-serif;
  padding-bottom: 41px;
  color: #00518b;
  text-align: center;
  line-height: 20vh;
  max-width: 75%;
  margin: auto;
}

/* CSS do Header */

/* .x-header__container {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 3.75rem;
  height: 3.875rem;
  background-color: #f8f8f8;
}

.x-header__container.is--mobile {
  display: none;
} */

/* @media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-header__container {
    padding: 0 2rem;
    height: 3.3125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-header__container {
    padding: 0 2.25rem;
    height: 3rem;
  }

  .x-header__container.is--desk {
    display: none;
  }

  .x-header__container.is--mobile {
    display: -webkit-box;
    display: flex;
  }
} */

.x-header__logo img {
  /* width: 6.125rem;
    height: 3.3125rem; */
}

/* @media only screen and (max-width: 766px) {
  .x-header__logo {
    margin-left: 1rem;
  }

  .x-header__logo img {
    height: 3.125rem;
  }
} */

.main-header-opacity {
  opacity: 0.2;
}

/* .x-header__items {
  margin-left: 3.75rem;

  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
} */

/* @media only screen and (max-width: 1023px) {
  .x-header__items {
    margin-left: 0.9375rem;
    margin-top: 1rem;
  }
} */
/* .x-header__item {
 display: flex;
} */

.x-header__item:not(:first-child) {
  /* margin-left: 20px; */
}

@media only screen and (max-width: 1023px) {
  .x-header__item:not(:first-child) {
    /* margin-left: 1rem; */
  }

  /* .x-header__link {
    font-size: 0.7rem;
  } */
}

/* .x-header__link {
  color: #424b4a;
  -webkit-transition: all .175s ease-in-out;
  transition: all .175s ease-in-out;
  font-size: 1rem;
  width: 100%;
  line-height: 3.875rem;
  padding: 0 20px;
  height: 3.875rem;
} */

/* .x-my-account__avatar {
    width: 89px;
    height: 89px;
    border-radius: 50%;
    object-fit: cover;
} */

/* .x-header__link:hover {
  color: black;
  background-color: #f0f0f0;;
}

.x-header__menu {
  margin-left: auto;
  position: relative;
}

.x-my-account__avatar {
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 2.375rem;
  object-fit: cover;
}
.x-my-account__avatar img:focus {
  outline: none !important;
}
.x-my-account__avatar_container {
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 2.375rem;
  object-fit: cover;
}

.x-header__menu-btn {
  background-color: #00518b;
  border-radius: 2.375rem;
  height: 2.375rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  z-index: 3;
  position: relative;
} */

@media only screen and (max-width: 1023px) {
  /* .x-header__menu-btn {
    width: 8.75rem;
    height: 2.125rem;
  }
  .x-my-account__avatar_container {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    object-fit: cover;
  } */
}

@media only screen and (max-width: 766px) {
  /* .x-header__menu-btn {
    width: 6.875rem;
    height: 2.125rem;
  } */
}

@media only screen and (max-width: 1023px) {
  /* .x-header__menu-btn img {
    width: 2.125rem;
    height: 2.125rem;
  } */
}

/* .x-header__menu-btn:after {
  content: '';
  display: block;
  position: relative;
  margin-left: 1rem;
  margin-right: 1.25rem;
  width: 10px;
  height: 10px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  will-change: transform;
  top: -2px;
} */

@media only screen and (max-width: 1023px) {
  /* .x-header__menu-btn:after {
    width: 8px;
    height: 8px;
    position: absolute;
    right: -8px;
    top: 46%;
    -webkit-transform: translateY(-50%) rotate(135deg);
    transform: translateY(-50%) rotate(135deg);
  } */
}

/* .x-header__menu-text {
  margin-left: 1.25rem;

  font-size: 0.75rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 2px;
} */

@media only screen and (max-width: 1023px) {
  /* .x-header__menu-text {
    margin-left: 0.5rem;
    font-size: 0.7rem;
    line-height: 0.2rem;
  } */
}

/* .x-header__menu-list {
  position: absolute;
  width: 100%;
  border-radius: 0.625rem;
  top: 1rem;
  max-width: 11.25rem;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.284);
  padding-top: 1.875rem;
  -webkit-transform: translateY(-7%);
  transform: translateY(-7%);
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  -webkit-transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.x-header__menu-list.is--active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
  opacity: 1;
}

.x-header__menu-list-item {
  padding: 0.5rem 0;
  text-align: center;
}

.x-header__menu-list-item:last-child {
  padding-bottom: 1.5rem;
}

.x-header__menu-list-link {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: #424b4a;
  -webkit-transition: color all 0.175s ease-in-out;
  transition: color all 0.175s ease-in-out;
  display: block;
}

.x-header__menu-list-link:hover {
  color: #1e2222;
}

.x-header__side-menu {
  position: absolute;
  width: 15.625rem;
  background-color: white;
  padding: 1.5rem 2.375rem 3.75rem;
  z-index: 5;
  top: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.235s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.235s cubic-bezier(0.165, 0.84, 0.44, 1);
  height: 100%;
}

.x-header__side-menu.is--active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
} */

.x-header__side-items:last-child {
  /* border-top: 1px solid #D6D6D6; */
}

/* .x-header__side-item {
  padding: 1.25rem 0;
}

.x-header__side-item:first-child {
  padding-top: 2.5rem;
}

.x-header__side-item:last-child {
  padding-bottom: 2.5rem;
}

.x-header__side-link {
  color: #424b4a;
}

.x-header__side-link.is--bold {
  color: #0066b3;
  font-weight: bold;
}

.x-header__background {
  background-color: black;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  -webkit-transition: all 0.235s ease-in-out;
  transition: all 0.235s ease-in-out;
} */

/* .x-header__background.is--active {
  opacity: 0.6;
  pointer-events: all;
} */

/* CSS do Footer */

.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed;
  /* Stay in place */
  z-index: 7;
  /* Sit on top */
  left: 0;
  top: 0;
  display: none;
  background-color: rgb(255, 255, 255);
  /* Black fallback color */
  background-color: rgba(255, 255, 255, 0.9);
  /* Black w/opacity */
  overflow-x: hidden;
  /* Disable horizontal scroll */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.x-footer {
  background-color: white;
}

.x-footer__brand-img {
  margin: 0 auto;
  padding: 1.25rem 0 0.9375rem;
  display: block;
  width: 100%;
  max-width: 45.25rem;
}

.x-footer__facebook-text {
  margin: 0;
}

@media only screen and (max-width: 1023px) {
  .x-footer__brand-img {
    max-width: 30.125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__links {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
}

.x-footer__link {
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-family: 'Montserrat', sans-serif;
  color: #000;
}

@media only screen and (max-width: 1023px) {
  .x-footer__link {
    font-size: 0.5625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__link {
    font-size: 0.75rem;
  }
}

.x-footer__facebook {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (max-width: 766px) {
  .x-footer__facebook {
    margin-top: 2.125rem;
    margin-bottom: 2.375rem;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
  }
}

.x-footer__facebook-text {
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 1023px) {
  .x-footer__facebook-text {
    font-size: 0.5625rem;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 0.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__facebook-text {
    font-size: 0.75rem;
  }
}

.x-footer__facebook-logo {
  margin-left: 1.125rem;
  margin-right: 0.625rem;
}

@media only screen and (max-width: 1023px) {
  .x-footer__facebook-logo {
    margin-left: 0.875rem;
    margin-right: 0.3125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__facebook-logo {
    margin-left: 0;
  }
}

.x-footer__facebook-link {
  color: #0071ff;
  font-weight: bold;
}

@media only screen and (max-width: 1023px) {
  .x-footer__facebook-link {
    font-size: 0.5625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__facebook-link {
    font-size: 0.75rem;
  }
}

.x-footer__bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 70px;
  padding: 0 3.75rem;
  border-top: 1px solid #d6d6d6;
}

@media only screen and (max-width: 1023px) {
  .x-footer__bottom {
    padding: 0 5rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__bottom {
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.x-footer__links {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (max-width: 766px) {
  .x-footer__links {
    padding-top: 1.75rem;
  }
}

.x-footer__circle {
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: #00518b;
  display: block;
  border-radius: 50%;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}

@media only screen and (max-width: 766px) {
  .x-footer__circle {
    width: 0.5rem;
    height: 0.5rem;
    margin: 1.25rem 0;
  }
}

.footer-links__right-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .footer-links__right-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }
}

.footer-links__right-image {
  width: auto;
  height: 3.875rem;
  padding: 0.25rem 0;
}

.footer-links__right-copyright {
  padding-right: 35px;
  font-size: 12px;
}

@media (max-width: 767px) {
  .footer-links__right-copyright {
    padding: 12px 0px 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer-links__right-copyright {
    padding-right: 22px;
  }
}

.x-footer__brands.is--mobile {
  display: none;
}

@media only screen and (max-width: 766px) {
  .x-footer__brand-img {
    max-width: 25.125rem;
  }

  .x-footer__brands.is--desk {
    display: none;
  }

  .x-footer__brands.is--mobile {
    display: block;
    padding-bottom: 1.25rem;
  }
}

.x-footer__brand-row {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 15.875rem;
  margin: 0 auto;
}

/* CSS Back */

.x-my-account__back {
  margin: 0;
  width: 270px;
}

.x-follow-order__back {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.x-follow-order__back-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  color: #424b4a;
  font-weight: bold;
  margin-left: 0.625rem;
  padding-left: 15px !important;
}

@media only screen and (max-width: 766px) {
  .x-follow-order__back-text {
    font-size: 0.75rem;
  }
}

/* CSS termos */

.x-overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.88);
  -webkit-transition: opacity 275ms ease-in-out;
  transition: opacity 275ms ease-in-out;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  cursor: pointer;
  z-index: 5001;
}

.x-overlay.is--active {
  opacity: 1;
  visibility: visible;
}

.signup-popup {
  position: relative;
}

.signup-popup__container {
  opacity: 1;
  pointer-events: all;
  background: #fff;
  z-index: 99999;
  position: fixed;
  width: 745px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 60px;
  max-height: 80%;
  border: 2px solid #f8f8f8;
  border-radius: 5px;
  -webkit-transition: 0.5s bottom, 0.5s opacity;
  transition: 0.5s bottom, 0.5s opacity;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-popup__container {
    border: 1px solid #e0e0e0;
    width: 80%;
  }
}

@media (max-width: 767px) {
  .signup-popup__container {
    width: 100%;
    min-height: 100%;
    padding: 0 30px;
  }
}

.signup-popup__container:after {
  content: '';
  width: 100%;
  background: white;
  opacity: 0.4;
}

.signup-popup__title {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #00409a;
  padding-bottom: 2.75rem;
}

@media only screen and (max-width: 766px) {
  .signup-popup__title {
    padding-bottom: 0;
    margin: 2.25rem auto;
    width: 16rem;
  }
}

.signup-popup__text {
  overflow-y: scroll;
  height: 80%;
  font-size: 14px;
  line-height: 23px;
  padding-right: 20px;
}

.signup-popup__text::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.signup-popup__text::-webkit-scrollbar-thumb {
  background: #b5b5b5;
}

.signup-popup__text::-webkit-scrollbar-track {
  background: #f8f8f8;
}

body .signup-popup__text {
  scrollbar-face-color: #b5b5b5;
  scrollbar-track-color: #f8f8f8;
}

@media only screen and (max-width: 766px) {
  .signup-popup__text {
    padding: 0;
  }
}

.signup-popup__close {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  cursor: pointer;
}

@media only screen and (max-width: 766px) {
  .signup-popup__close {
    position: absolute;
    top: 3.125rem;
  }
}

.signup-popup__close svg {
  pointer-events: none;
}

.signup-popup__close svg .a {
  fill: #b5b5b5 !important;
}

.signup-popup__close svg.close-mobile {
  display: none;
}

.signup-popup__close svg.close-mobile .a {
  fill: #00409a !important;
}

@media only screen and (max-width: 766px) {
  .signup-popup__close svg.close-mobile {
    display: block;
  }
}

@media only screen and (max-width: 766px) {
  .signup-popup__close svg.close-desk {
    display: none;
  }
}

.signup-popup__paragraph {
  height: 320px;
}

@media only screen and (max-width: 766px) {
  .signup-popup__paragraph {
    padding: 30px 0;
    height: 600px;
    max-height: 80%;
    text-align: center;
  }
}

.is--hide {
  display: none;
}

.hide-popup {
  opacity: 0;
  pointer-events: none;
}

.hide-message {
  opacity: 0;
  pointer-events: none;
  height: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}



/* Loading */
.overlay-content {
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.4);
  flex-direction: column;
}

.overlay-content .wrapper {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay-content .wrapper .content-loading {
  background-color: #fff;
  width: auto;
  padding: 1.25em;
  border: none;
  border-radius: 0.3125em;
}


.overlay-content .wrapper .content-loading .image {
  width: 100%;
  margin: 1.25em auto;
}

.overlay-content .wrapper .content-loading .message {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}


.x-header__logo img {
  max-height: 62px;
}

.marcas_footer img {
  filter: grayscale(100%);
  -webkit-transition: linear 0.4s;
  transition: linear 0.4s;
  opacity: 0.5;
}