.signup-main{

}

/* BEGIN LEFT */
.signup-left {
  padding: 0 60px 0 60px;
  width: 100%;
  background: #eff3f8;
  padding-bottom: 8.5625rem;
  height: 100%;
}

.signup-logo{
  padding: 27px 0 68px
}

.signup-logo img  {
    max-height: 67px
}

.signup-title {
  position: relative;
  padding-bottom: 41px;
  color: #00409A;
  line-height: 60px
}

.signup-title > h1 {
  font-weight: bold;
  font-size: 40px;
  font-family: Montserrat;
  font-weight: 700;
}

.signup-title:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #0394db;
  position: absolute;
  bottom: 0
}

.signup-paragraph {
  font-size: 16px;
  line-height: 26px;
  color: #424B4A;
  max-width: 419px;
  padding-top: 34px
}

/* END LEFT */

/* BEGIN RIGHT */
.signup-right {
  width: 100%;
  padding: 0 94px
}

.signup-cnpj {
  margin-top: 155px;
}

.signup-cnpj-title {
  color: #0066B3;
  font-family: Montserrat;
  font-size: 28px;
  line-height: 37px;
  font-weight: lighter;
}

.signup-right__next-step--login {
  color: #B5B5B5;
  font-size: .8125rem;
  line-height: 1.125rem;
  text-decoration: underline
}

.signup-right__next-account {
  padding: 0 3.5rem;
  margin-top: 5rem
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* BEGIN LEFT */
  .signup-left {
    text-align: center;
    padding: 0 6.6875rem;
  }
  
  .signup-logo {
    padding: 0 0 30px
  }
  
  .signup-title {
    line-height: 34px
  }
  
  .signup-title > h1 {
    font-size: 40px;
  }
  
  .signup-paragraph {
    width: 100%;
    max-width: none;
    text-align: center;
    padding-bottom: 60px
  }
  /* END LEFT */
  /* BEGIN RIGHT */

  .signup-cnpj {
    margin-top: 80px;
  }

  .signup-cnpj-title {
    font-size: 1.375rem;
    line-height: 2.0625rem;
    text-align: center;
  }

}

@media (max-width: 767px) {
  /* BEGIN LEFT */
  .signup-left {
    text-align: center;
    padding-bottom: 1.875rem
  }
  
  .signup-logo {
    padding: 0 0 30px;
    text-align: center
  }
  
  .signup-title {
    margin: 0 auto;
  }
  
  .signup-title > h1 {
    text-align: center;
    line-height: 49px;
    font-size: 2.5rem;
  }
  
  .signup-paragraph {
    font-size: 14px;
    line-height: 22px
  }
  /* END LEFT */

  /* BEGIN RIGHT */
  .signup-right {
    padding: 0 30px;
  }

  .signup-cnpj {
    margin-top: 20px;
  }

  .signup-cnpj-title {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    text-align: center;
  }
}

.signup.has--no-scroll {
  overflow: hidden
}

.signup-left__button-mobile {
  display: none
}

.signup-main-opacity {
  opacity: 0.2;
}

.signup-right__form-empty::before {
  content: "Preencher o campo. ex:. 00.000.000/0000-00";
  display: inline-block;
  width: 20%;
  border-radius: 10px;
  width: 30%;
  font-size: .75em;
  padding: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.842)
}

.signup-right__form-invalid {
  display: -webkit-box;
  display: flex;
  color: #0394db;
  max-width: 18.125rem;
  font-size: .75rem;
  line-height: 1.0625rem;
  padding-left: 1.5625rem;
  opacity: 1;
  height: auto;
  pointer-events: all;
  -webkit-transition: .2s bottom, .2s opacity;
  transition: .2s bottom, .2s opacity
}

.signup-right__form-invalid svg {
  margin-right: .4375rem;
  margin-top: .3125rem;
  width: 3.125rem
}

.signup-right__form-invalid svg .a {
  fill: #0394db
}

.signup-right__form-valid {
  display: -webkit-box;
  display: flex;
  color: #53b934;
  font-size: .75rem;
  line-height: 1.0625rem;
  padding-left: 1.5625rem;
  opacity: 1;
  pointer-events: all;
  -webkit-transition: opacity .175s ease-in-out, height .3s ease-in-out;
  transition: opacity .175s ease-in-out, height .3s ease-in-out
}

.signup-right__form-valid svg {
  margin-right: .4375rem;
  margin-top: .3125rem;
  width: 1.375rem
}

.signup-right__form-valid svg .a {
  fill: #53b934
}

.signup-right__form-input {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #0066B3;
  padding-left: 15px;
  margin-bottom: 20px;
  outline: none;
  font-size: 14px
}

.signup-right__form-input:-webkit-autofill,
.signup-right__form-input:-webkit-autofill:hover,
.signup-right__form-input:-webkit-autofill:focus,
.signup-right__form-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important
}

.signup-right__form-input::-webkit-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__form-input::-moz-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__form-input::-ms-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__form-input::placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__form-row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative
}

.signup-right__form-label {
  font-size: 14px;
  line-height: 19px;
  color: #0066B3;
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0 5px;
  background: #fff
}

.signup-right__next-step {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center
}

@media (max-width: 767px) {
  .signup-right__next-step {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-right__next-step {
      width: 319px;
      margin: 0 auto 50px;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse
  }
}

@media (max-width: 767px) {
  .signup-right__next-account {
      margin-top: 1.625rem;
      padding: 0
  }
}

.signup-right__next-account--title {
  color: #0066B3;
  font-family: Montserrat;
  font-size: 28px;
  line-height: 37px;
  font-weight: lighter;
  margin-bottom: 1.25rem
}

@media (max-width: 767px) {
  .signup-right__next-account--title {
      font-size: 1.125rem;
      line-height: 1.6875rem;
      text-align: center
  }
}

.signup-right__next-account--title span {
  text-transform: uppercase;
  font-weight: bold;
  display: block
}

.signup-right__next-account--warning {
  color: #424B4A;
  font-size: .875rem;
  line-height: 1.625rem;
  margin-bottom: 2.875rem
}

@media  only screen and  (max-width: 767px) {
  .signup-right__next-account--warning {
    margin-bottom: 1.5rem!important;
  }
}

.signup-right__next-account--password-button {
  fill: #0066b3;
  position: absolute;
  top: 1.125rem;
  right: 1.125rem;
  cursor: pointer
}

.signup-right__next-account--password-button svg {
  fill: #0066b3;
  pointer-events: none
}

.signup-right__next-account--input {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #424B4A;
  padding-left: 15px;
  margin-bottom: 1.6875rem;
  width: 100%;
  font-size: 14px;
  outline: none
}

.signup-right__next-account--input.js--input-email {
  max-width: 50%;
  -webkit-box-align: start;
  align-items: flex-start;
  width: 15.5rem;
}

@media (max-width: 767px) {
  .signup-right__next-account--input.js--input-email {
      max-width: 100%
  }
}

.signup-right__next-account--input:-webkit-autofill,
.signup-right__next-account--input:-webkit-autofill:hover,
.signup-right__next-account--input:-webkit-autofill:focus,
.signup-right__next-account--input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important
}

.signup-right__next-account--input-check {
  display: none
}

.signup-right__next-account--input-check:before {
  content: "\2714";
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: block;
  width: 1em;
  height: 1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: .2s;
  transition: .2s
}

.signup-right__next-account--input::-webkit-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__next-account--input::-moz-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__next-account--input::-ms-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__next-account--input::placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__next-account--reminder {
  font-size: .75em;
  color: #424B4A;
  font-family: "Open Sans";
  display: -webkit-box;
  display: flex;
  margin-top: 9%;
}


.signup-right__next-account--label {
  font-size: 14px;
  line-height: 19px;
  color: #424B4A;
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0 5px;
  background: #fff;
  opacity: 0;
  -webkit-transition: .2s bottom, .2s opacity;
  transition: .2s bottom, .2s opacity
}

.signup-right__next-account--label.label-name {
  bottom: 80%;
  color: #00409A;
  opacity: 1;
  padding: 4px
}

.signup-right__next-account--label-check {
  margin-bottom: .9375rem;
  color: #000;
  font-size: .875rem;
  line-height: 0.85rem;
}

.signup-right__next-account--row {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative
}

@media (max-width: 767px) {
  .signup-right__next-account--row.email {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column
  }
}

.signup-right__next-account--checkbox {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 20px;
}

.signup-right__next-account--wrapper {
  position: relative;
  width: 50%
}

.signup-right__next-account--wrapper:first-child {
  margin-right: 10px
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-right__next-account--wrapper {
      width: 100%
  }
}

.signup-right__next-account--button {
  border-radius: 25px;
  text-decoration: none;
  margin-top: 20px;
  color: white;
  padding: .8125rem 3.8125rem;
  /* width: 100%; */
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 3.125rem
}

.signup-right__next-account--input-check {
  display: none
}

.signup-right__next-account--input-check+.signup-right__next-account--label-check:before {
  content: "";
  border: .0625rem solid #0394db;
  border-radius: .1875rem;
  display: inline-block;
  width: .875rem;
  height: .875rem;
  margin-right: .9375rem;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: .2s;
  transition: .2s
}

.signup-right__next-account--input-check+.signup-right__next-account--label-check:active:before {
  -webkit-transform: scale(0);
  transform: scale(0)
}

.signup-right__next-account--input-check:checked+.signup-right__next-account--label-check:before {
  background-color: #0394db;
  border-color: #0394db;
  padding: 2px;
  box-shadow: inset 0px 0px 1px 2px #fff;
  color: #fff
}

.x-overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.88);
  -webkit-transition: opacity 275ms ease-in-out;
  transition: opacity 275ms ease-in-out;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  cursor: pointer;
  z-index: 5001
}

.x-overlay.is--active {
  opacity: 1;
  visibility: visible
}

.signup-popup {
  position: relative
}

.signup-popup__container {
  opacity: 1;
  pointer-events: all;
  background: #fff;
  z-index: 99999;
  position: fixed;
  width: 745px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 60px;
  max-height: 80%;
  border: 2px solid #F8F8F8;
  border-radius: 5px;
  -webkit-transition: .5s bottom, .5s opacity;
  transition: .5s bottom, .5s opacity
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-popup__container {
      border: 1px solid #E0E0E0;
      width: 80%
  }
}

@media (max-width: 767px) {
  .signup-popup__container {
      width: 100%;
      min-height: 100%;
      padding: 0 30px
  }
}

.signup-popup__container:after {
  content: '';
  width: 100%;
  background: white;
  opacity: 0.4
}

/* .signup-popup__title {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #00409A;
  padding-bottom: 2.75rem
}

@media only screen and (max-width: 766px) {
  .signup-popup__title {
      padding-bottom: 0;
      margin: 2.25rem 0
  }
} */

.signup-popup__text {
  overflow-y: scroll;
  height: 80%;
  font-size: 14px;
  line-height: 23px;
  padding-right: 20px
}

.signup-popup__text::-webkit-scrollbar {
  width: 4px;
  height: 4px
}

.signup-popup__text::-webkit-scrollbar-thumb {
  background: #B5B5B5
}

.signup-popup__text::-webkit-scrollbar-track {
  background: #F8F8F8
}

body .signup-popup__text {
  scrollbar-face-color: #B5B5B5;
  scrollbar-track-color: #F8F8F8
}

@media only screen and (max-width: 766px) {
  .signup-popup__text {
      padding: 0
  }
}

.signup-popup__close {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  cursor: pointer
}

@media only screen and (max-width: 766px) {
  .signup-popup__close {
      position: absolute;
      top: 3.125rem
  }
}

.signup-popup__close svg {
  pointer-events: none
}

.signup-popup__close svg .a {
  fill: #b5b5b5 !important
}

.signup-popup__close svg.close-mobile {
  display: none
}

.signup-popup__close svg.close-mobile .a {
  fill: #00409A !important
}

@media only screen and (max-width: 766px) {
  .signup-popup__close svg.close-mobile {
      display: block
  }
}

@media only screen and (max-width: 766px) {
  .signup-popup__close svg.close-desk {
      display: none
  }
}

.signup-popup__paragraph {
  height: 320px
}

@media only screen and (max-width: 766px) {
  .signup-popup__paragraph {
      padding: 30px 0;
      height: 600px;
      max-height: 80%;
      text-align: center
  }
}

.is--hide {
  display: none
}

.hide-popup {
  opacity: 0;
  pointer-events: none
}

.hide-message {
  opacity: 0;
  pointer-events: none;
  height: 0
}

.cadastro > .input-password > .input-password-label {
  transform: translate3d(10px, 1px, 0) !important;
}

.cadastro > .input-password > input {
  box-shadow: none!important;
  height: 50px;
}


.cadastro > .input-password > .eye-position {
  top: 22px;
}