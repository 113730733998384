.financeiro-overlay-content {
	display: flex;
	width: 100%;
	left: 0;
	top: 0;
	align-items: center;
	height: 100%;
	justify-content: center;
	position: fixed;
	z-index: 15;
	background: rgba(0, 0, 0, 0.4) !important;
	flex-direction: column;
}

.financeiro-div-nota-fiscal-download-xml-pdf-modal {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
    align-items: center;
    min-width: 130px;
}

.financeiro-div-nota-fiscal-download-xml-pdf-modal p{
	margin: 0!important;
}

.financeiro-overlay-content .financeiro-wrapper {
	display: inherit;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.financeiro-overlay-content .financeiro-wrapper .financeiro-details-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding: 20px;
	background-color: #fff;
	border: none;
	border-radius: 0.3125em;
	height: 90%;
}

.title span {
	font-size: 2em;
	color: #585858;
	font-family: 'Montserrat';
}

.content-center {
	direction: ltr;
	font-family: 'Montserrat';
	font-size: 14px !important;
	max-height: 70%;
    overflow: overlay;
	width: 100%;
}

.content-center table {
	position: relative;
}

.content-center thead tr th {
	position: sticky;
	top: 0;
	background-color: #fff;
	width: 100px;
	padding: 5px !important;
}

.content-center tbody tr td {
	padding: 5px !important;
}

.button button {
	background-color: #0394db;
	padding: 15px 40px;
	border-radius: 5px;
	color: #fff;
}
.finaceiro-modal-thead{
	position: sticky;
	border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
}

@media (max-width: 1400px) {
	.financeiro-overlay-content .financeiro-wrapper .financeiro-details-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 10px;
		gap: 10px;
		padding: 20px;
		background-color: #fff;
		border: none;
		border-radius: 0.3125em;
		width: 90%;
		height: 90%;
	}
}

@media (max-width: 890px) {

	.content-center {
		direction: ltr;
		position: relative;
		max-height: 70%;
		overflow: visible;
		width: 100%;
		font-family: inherit;
		font-size: 12px !important;
	}
	.financeiro-detalhes-modal-td{
		border-bottom: 1px solid #ddd;
		display: table-cell;
		font-size: 0.8em;
		text-align: initial;
	}
	
	::-webkit-scrollbar-thumb {
		background-color: #00518b;
		border-radius: 5px;
	  }
	
	  ::-webkit-scrollbar-track {
		background-color: #f2f2f2;
	  }
	
	  ::-webkit-scrollbar {
		width: 12px;
		height: 12px;
		background-color: #f2f2f2;
	  }
	  #financeiro-modal-table{
		scrollbar-color: #00518b;
    	scrollbar-width: thin;
		overflow-x: auto;
    	max-width: 100%;
		table-layout: fixed;
		
	  }
	  .financeiro-modal-table-div{
		width: 100%;
		overflow-x: auto;
	  }
	  .pedidos.table thead tr th, .table tbody tr td {
		padding: 20px;
		border: none;
		text-align: center;
	}

	
}
@media only screen and (max-width: 767px){
	.financeiro-detalhes-modal-tr {
		display: table-row !important;
	}
	.table {
		font-size: 16px;
	}
}