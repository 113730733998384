@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**!
 * Initialize.css (v1.3.2) is a complete and customizable collection of CSS best practices based on Normalize.css and HTML5 Boilerplate.
 * http://jeroenoomsnl.github.io/initialize-css
 *
 * HTML5 Boilerplate: https://github.com/h5bp/html5-boilerplate
 * Normalize.css: http://github.com/necolas/normalize.css
 */
/**
 * Predefined values
 */
/**
  * Options
  */
/**
  * Mixin for optional properties
  */
/* ==========================================================================
    General
    ========================================================================== */
/**
  * 1. Set default font family to $initialize-font-family (default: sans-serif)
  * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
  * 3. Configurable defaults
  */
  html {
    box-sizing: border-box;
    font-family: "Open Sans";
    /* 1 */
    line-height: 1.4;
    /* 3 */
    font-size: 1em;
    /* 3 */
    -ms-text-size-adjust: 100%;
    /* 2 */
    -webkit-text-size-adjust: 100%;
    /* 2 */ }

  /**
    * Remove the margin in all browsers
    */
  body {
    margin: 0; }

  /**
    * Inheriting box-sizing
    * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
    */
  *, *:before, *:after {
    box-sizing: inherit; }

  /* ==========================================================================
      HTML5 display defenitions
      ========================================================================== */
  /**
    * Add the correct display in IE 9-.
    * 1. Add the correct display in Edge, IE, and Firefox.
    * 2. Add the correct display in IE.
    */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block; }

  /**
        * Add the correct display in IE 9-.
        */
  audio,
  canvas,
  progress,
  video {
    display: inline-block; }

  /**
    * Add the correct display in iOS 4-7.
    */
  audio:not([controls]) {
    display: none;
    height: 0; }

  /**
    * Add the correct vertical alignment in Chrome, Firefox, and Opera.
    */
  progress {
    vertical-align: baseline; }

  /**
    * Add the correct display in IE 10-.
    * 1. Add the correct display in IE.
    */
  template,
  [hidden] {
    display: none; }

  /* ==========================================================================
      Links
      ========================================================================== */
  /**
    * 1. Remove the gray background on active links in IE 10.
    * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
    */
  a {
    background-color: transparent;
    text-decoration: none;
    /* 1 */
    -webkit-text-decoration-skip: objects;
    /* 2 */ }

  /**
    * Remove the outline on focused links when they are also active or hovered
    * in all browsers (opinionated).
    */
  a:active,
  a:hover {
    outline-width: 0; }

  /* ==========================================================================
      Text-level semantics
      ========================================================================== */
  /**
    * 1. Remove the bottom border in Firefox 39-.
    * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
    */
  abbr[title] {
    border-bottom: none;
    /* 1 */
    text-decoration: underline;
    /* 2 */
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    /* 2 */ }

  /**
    * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
    */
  b,
  strong {
    font-weight: inherit; }

  /**
    * Add the correct font weight in Chrome, Edge, and Safari.
    */
  b,
  strong {
    font-weight: bolder; }

  /**
    * Add the correct font style in Android 4.3-.
    */
  dfn {
    font-style: italic; }

  /**
    * Correct the font size and margin on `h1` elements within `section` and
    * `article` contexts in Chrome, Firefox, and Safari.
    */
  /**
       * Address styling not present in IE 8/9.
       */
  mark {
    background: #ff0;
    color: #000; }

  /**
    * Add the correct font size in all browsers.
    */
  small {
    font-size: 80%; }

  /**
    * Prevent `sub` and `sup` elements from affecting the line height in
    * all browsers.
    */
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }

  sub {
    bottom: -0.25em; }

  sup {
    top: -0.5em; }

  /* ==========================================================================
      Embedded content
      ========================================================================== */
  /**
        * Remove the border on images inside links in IE 10-.
        */
  img {
    border-style: none; }

  /**
    * Hide the overflow in IE.
    */
  svg:not(:root) {
    overflow: hidden; }

  /* ==========================================================================
      Grouping content
      ========================================================================== */
  /**
    * 1. Correct the inheritance and scaling of font size in all browsers.
    * 2. Correct the odd `em` font sizing in all browsers.
    */
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */ }

  /**
    * Add the correct margin in IE 8.
    */
  figure {
    margin: 1em 40px; }

  /**
    * 1. Add the correct box sizing in Firefox.
    * 2. Show the overflow in Edge and IE.
    */
  hr {
    box-sizing: content-box;
    /* 1 */
    height: 0;
    /* 1 */
    overflow: visible;
    /* 2 */ }

  /* ==========================================================================
      Forms
      ========================================================================== */
  /**
    * 1. Change font properties to `inherit` in all browsers (opinionated).
    * 2. Remove the margin in Firefox and Safari.
    */
  button,
  input,
  select,
  textarea {
    margin: 0;
    /* 2 */
    font-family: "Open Sans"; }

  /**
    * Restore the font weight unset by the previous rule.
    */
  optgroup {
    font-weight: bold; }

  /**
    * Show the overflow in IE.
    * 1. Show the overflow in Edge.
    */
  button,
  input {
    /* 1 */
    overflow: visible; }

  /**
    * Remove the inheritance of text transform in Edge, Firefox, and IE.
    * 1. Remove the inheritance of text transform in Firefox.
    */
  button,
  select {
    /* 1 */
    text-transform: none; }

  /**
    * Remove the inner border and padding in Firefox.
    */
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }

  /**
    * Restore the focus styles unset by the previous rule.
    */
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

  /**
        * Change the border, margin, and padding in all browsers (opinionated).
        */
  /**
    * 1. Correct the text wrapping in Edge and IE.
    * 2. Correct the color inheritance from `fieldset` elements in IE.
    * 3. Remove the padding so developers are not caught out when they zero out
    *    `fieldset` elements in all browsers.
    */
  legend {
    box-sizing: border-box;
    /* 1 */
    color: inherit;
    /* 2 */
    display: table;
    /* 1 */
    max-width: 100%;
    /* 1 */
    padding: 0;
    /* 3 */
    white-space: normal;
    /* 1 */ }

  /**
    * Remove the default vertical scrollbar in IE.
    */
  textarea {
    overflow: auto; }

  /**
        * 1. Add the correct box sizing in IE 10-.
        * 2. Remove the padding in IE 10-.
        */
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */ }

  /**
    * Correct the cursor style of increment and decrement buttons in Chrome.
    */
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto; }

  /**
    * 1. Correct the odd appearance in Chrome and Safari.
    * 2. Correct the outline style in Safari.
    */
  [type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    outline-offset: -2px;
    /* 2 */ }

  /**
    * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
    */
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

  /**
    * Correct the text style of placeholders in Chrome, Edge, and Safari.
    */
  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54; }

  /**
    * 1. Correct the inability to style clickable types in iOS and Safari.
    * 2. Change font properties to `inherit` in Safari.
    */
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */ }

  /* ==========================================================================
      Optional configurations
      ========================================================================== */
  /**
    * Headings
    */
  /**
    * Block elements
    */
  /* ==========================================================================
      Print styles
      ========================================================================== */
  /* http://meyerweb.com/eric/tools/css/reset/
     v2.0 | 20110126
     License: none (public domain)
  */
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
 }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section {
    display: block; }

  blockquote,
  q {
    quotes: none; }
    blockquote:before, blockquote:after,
    q:before,
    q:after {
      content: "";
      content: none; }

  table {
    border-collapse: collapse;
    border-spacing: 0; }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: ''; }

  /* Hand cursor on clickable input elements */
  a,
  input[type="button"],
  input[type="submit"],
  input[type="file"],
  input[type="image"],
  button {
    cursor: pointer; }

  a {
    text-decoration: none; }

  ul {
    list-style: none; 
    padding-left: 0 !important
  }

  button {
    -webkit-appearance: none;
    border: none;
    background: none;
    outline: 0;
    margin: 0;
    padding: 0; }

  /** settings */
  .is--blue {
    background: #00518b;
    background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
    background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%); }

  .is--orange {
    background: #0394db;
    background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
    background: linear-gradient(90deg, #0394db 0%, #0394db 100%); }

  /**
   * Screen Sizes
   */
  /**
   * Breakpoints - Media Queries
   */
  /**
   * Media Queries
   * Mobile first
   */
  /**
   * Font Weight
   */
  /**
   * Common Helpers
   */
  /**
   * Clearfix: contain floats
   *
   * For modern browsers
   * 1. The space content is one way to avoid an Opera bug when the
   *    `contenteditable` attribute is included anywhere else in the document.
   *    Otherwise it causes space to appear at the top and bottom of elements
   *    that receive the `clearfix` class.
   * 2. The use of `table` rather than `block` is only necessary if using
   *    `:before` to contain the top-margins of child elements.
   */
  /**
   * Custom text selection style
   */
  /**
   * Custom input placeholders
   */
  /**
   * Custom default browser scrollbar
   */
  /**
   * Generate arrow on a pseudo-element
   * To set color, use 'border-color' inside a class
   *
   * @param  {string} $position:     up              Arrow position (up, right, bottom, left)
   * @param  {string} $arrow-width:  20px             Arrow Width
   * @param  {string} $arrow-size:   $arrow-width/4   Arrow size
   *
   * @return A CSS Arrow
   */
  /**
   * @include triangle within a pseudo element and add positioning properties (ie. top, left)
   * $direction: up, down, left, right
   */

/* tira a borda preta do chrome dos botões */
button::-moz-selection{
    outline:none!important;
}
button:focus,button:hover,button::selection{
    outline:none!important;
}

/* CSS Headings */

.conecta-h1-container {
	position: relative;
    padding-bottom: 35px;
}

.conecta-h1-with-dot, .conecta-h1 {
	font-size: 2.5rem;
    font-weight: 800;
    color: #00409A;
    font-family: 'Montserrat', sans-serif;
}

.conecta-h1-with-dot:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #0394db;
    position: absolute;
    bottom: 0;
	left: 0;
}

@media screen and (max-width: 767px) {
	.conecta-h1-with-dot:after {
		left: 50%;
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.conecta-h1-with-dot:after {
		left: 50%;
	}
}

.conecta-h2 {
	font-family: 'Montserrat', sans-serif;
	color: #00409A;
	font-size: 1.5rem;
    font-weight: bold;
}

.conecta-h3 {
	font-family: 'Montserrat', sans-serif;
	color: #424b4a;
    font-size: 0.975rem;
    font-weight: bold;
}

.ancorThinDark {
  text-decoration: underline !important;
  color: #00518b;
  -webkit-text-stroke-width: thin;
}
.guia-atacado-img {
  width: 12%;
  margin: 0 1rem;
}
.labSelect-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.title-container {
  display: flex;
  flex-direction: column;
}
.main-title {
  font-size: 2.25rem;
  font-family: "Montserrat", sans-serif;
  color: #0066b3;
  margin: 2.375rem 0 0 0;
}
.title-shape {
  width: 0.625rem;
  height: 0.625rem;
  background: #0394db;
  margin: 0.625rem 0 1.5625rem 0;
}
.subtitle-container {
  width: 100%;
  display: flex;
}
.subtitle {
  font-size: 1em;
  color: #424b4a;
  margin: 0 0 2.3125rem 0;
}
.linkBold {
  font-size: small;
  text-decoration: underline !important;
  color: #0d6efd;
  -webkit-text-stroke-width: thin;
}
.bold {
  -webkit-text-stroke-width: thin;
  font-size: small;
  color: #4c5655;
}
.linkBoldDDark {
  text-decoration: underline;
  color: #717979;
  -webkit-text-stroke-width: thin;
}
.ul-dot {
  list-style-type: disc;
  margin-left: 15px;
}
/*mobile*/
@media only screen and (max-width: 905px) {
  img.guia-atacado-img {
    width: 133px;
  }
}

.login-main {
	font-family: Open Sans;
}

.login-main-opacity {
	opacity: 0.2;
}

.login.has--no-scroll {
	overflow: hidden;
}

.login-top {
	background: -webkit-gradient(linear, left top, right top, color-stop(50%, #EDF4FA), color-stop(50%, white));
	background: linear-gradient(90deg, #EDF4FA 50%, white 50%);
	height: 650px;
	display: flex;
	justify-content: space-between;
	padding: 0 0 0 60px;
}

.overlay_login{
	/* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    display: none;
    background-color: rgb(255,255,255); /* Black fallback color */
    background-color: rgba(255,255,255, 0.9); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.login-signin__wrapper {
	padding-right: 60px;
}

.login-signin__container {
	width: 349px;
}

.login-signin__logo-image {
	display: none;
}

.login-signin__back-button {
	display: none;
}

.login-signin__social-network--button {
	width: 165px;
	border-radius: 5px;
	border: 1px solid #B5B5B5;
	padding: 12px 20px;
	background: #fff;
	font-size: 14px;
	line-height: 19px;
	color: #B5B5B5;
	font-family: Open Sans;
	display: flex;
	justify-content: flex-start;
}

.login-signin__social-network--button svg {
	margin-right: 20px;
}

.login-signin__social-network--image {
	width: 20px;
	height: 20px;
}

.login-signin__social-network--row {
	display: flex;
	justify-content: space-between;
}

.login-signin__social-network--row:first-child {
	margin-bottom: 10px;
}

.login-signin__social-network--title {
	color: #B5B5B5;
	font-size: 12px;
	line-height: 17px;
	text-align: center;
	margin: 20px auto;
	z-index: 1;
	position: relative;
}

.login-signin__social-network--title:before {
	content: '';
	position: absolute;
	height: 1px;
	top: 9px;
	left: 0px;
	width: 30%;
	background: #B5B5B5;
	z-index: 0;
}

.login-signin__social-network--title:after {
	content: '';
	position: absolute;
	height: 1px;
	top: 9px;
	right: 0px;
	width: 30%;
	background: #B5B5B5;
	z-index: 0;
}

.login-signin__title {
	font-family: 'Montserrat';
	font-weight: 800;
	font-size: 28px;
	line-height: 34px;
	color: #00409A;
	text-align: center;
	padding: 80px 0 25px;
}

.login-signin__sign-up {
	margin-top: 25.5px;
	text-align: center;
	font-size: 14px;
	line-height: 20px;
}

.login-signin__sign-up--mobile {
	display: none;
}

.login-signin__sign-up--tablet {
	display: none;
}

.login-signin__sign-up--link {
	color: #0394db;
	font-weight: 800;
}

.login-signin__form-input {
    height: 3.125rem;
    border-radius: 0.25rem!important;
    border: 1px solid #0066B3!important;
    padding-left: 0.9375rem!important;
    margin-bottom: 1.25rem!important;
    font-size: 0.875rem!important;
	outline: none!important;
	margin-right: 0px!important;
}

.login-signin__form-input:-webkit-autofill,
.login-signin__form-input:-webkit-autofill:hover,
.login-signin__form-input:-webkit-autofill:focus,
.login-signin__form-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.login-signin__form-input::-webkit-input-placeholder {
    color: #424B4A;
    opacity: 0.4;
    font-family: "Open Sans";
}

.login-signin__form-input::-moz-placeholder {
    color: #424B4A;
    opacity: 0.4;
    font-family: "Open Sans";
}

.login-signin__form-input::-ms-input-placeholder {
    color: #424B4A;
    opacity: 0.4;
    font-family: "Open Sans";
}

.login-signin__form-input:-ms-input-placeholder {
    color: #424B4A;
    opacity: 0.4;
    font-family: "Open Sans";
}

.login-signin__form-input::placeholder {
    color: #424B4A;
    opacity: 0.4;
    font-family: "Open Sans";
}

.login-signin__form-row {
	display: flex;
	flex-direction: column;
	position: relative;
}

.login-signin__form-label {
	font-size: 14px;
	line-height: 19px;
	color: #0066B3;
	position: absolute;
	top: -10px;
	left: 20px;
	padding: 0 5px;
	background: #fff;
	z-index: 5;
}
.login-signin__form-div-button{
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 15px;
}
.login-signin__form-button {
	width: 150px;
	color: #fff;
	height: 50px;
	font-size: 16px;
	line-height: 22px;
	cursor: pointer;
	font-family: Open Sans;
	border: none;
	font-weight: 800;
	border-radius: 25px;
}

.login-signin__form-password--button {
	position: absolute;
	top: 0.85rem;
	right: 1.125rem;
	cursor: pointer;
}

.login-signin__form-password--button svg {
	pointer-events: none;
}

.login-signin__form-link {
	font-size:smaller;
	text-decoration: underline;
	color: #0066B3;
	padding: 0 0 23.5px;
	text-align: center;
}

.login-banner_1 {
	background-image: url(/static/media/personagem02.fd0e9fee.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 460px;
	width: 65%;
	height: 100%;
}

.login-banner_2 {
	background-image: url(/static/media/personagem02.fd0e9fee.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 460px;
	width: 65%;
	height: 100%;
	z-index: 99;
}

.login-banner_3 {
	background-image: url(/static/media/personagem03.5168ae91.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 460px;
	width: 65%;
	height: 100%;
	z-index: 99;
}

.login-banner_4 {
	background-image: url(/static/media/personagem04.521e6059.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 460px;
	width: 65%;
	height: 100%;
	z-index: 99;
}

.login-banner_5 {
	background-image: url(/static/media/personagem05.2f3d8d1c.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 460px;
	width: 65%;
	height: 100%;
	z-index: 99;
}

.login-banner_6 {
	background-image: url(/static/media/personagem06.3dc3dc4e.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 460px;
	width: 65%;
	height: 100%;
	z-index: 99;
}

.login-banner_7 {
	background-image: url(/static/media/personagem06.3dc3dc4e.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 460px;
	width: 65%;
	height: 100%;
	z-index: 99;
}

.login-banner__container {
	/* margin: 0 0 0 60px */
	width: 40%;
}

.login-banner__button-mobile {
	display: none
}

.login-banner__logo {
	padding: 40px 0;
	z-index: 3;
	/* text-align: center; */
}

.login-banner__logo-image {
	max-height: 80px;
}

.login-banner__title {
	font-size: 50px;
	font-family: Montserrat;
	font-weight: 800;
	position: relative;
	padding-bottom: 41px;
	color: #00409A;
	line-height: 60px
}

.login-banner__title:after {
	content: '';
	width: 10px;
	height: 10px;
	background: #0394db;
	position: absolute;
	bottom: 0;
}

.login-banner__title-text {
	/* width: 40%; */
	font-weight: bold;
}

.login-banner__paragraph {
	font-size: 16px;
	line-height: 26px;
	color: #424B4A;
	max-width: 317px;
	padding-top: 34px;
	width: 95%;
}

.banner-main{
	margin-bottom: 40px;
}

.login-information__title {
	padding-top: 57px;
	position: relative;
	font-family: Montserrat;
	font-size: 40px;
	font-weight: 800;
	line-height: 53px;
	text-align: center;
	color: #00409A;
	padding-bottom: 35px;
}

.login-information__title-accent {
	color: #0394db;
}

.login-information__title:after {
	content: '';
	width: 10px;
	height: 10px;
	background: #00409A;
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translate(-50%);
					transform: translate(-50%);
}

.login-information__card {
	display: flex;
	margin-top: 73px;
	padding: 0 60px;
}

.login-information__card-container {
	width: 376px;
	margin: 0 auto;
	text-align: center;
	position: relative;
}

.login-information__card-container:hover .login-information__card-text {
	color: #0066B3;
	-webkit-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
	-webkit-transform: translateY(-30%);
					transform: translateY(-30%);
}

.login-information__card-container:hover .login-information__card-icon {
	-webkit-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
	-webkit-transform: translateY(-10%);
			transform: translateY(-10%);
}

.login-information__card-container:hover .login-information__card-bottom {
	opacity: 1;
	-webkit-transition: opacity .5s linear;
	transition: opacity .5s linear;
	-webkit-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
	background: #EFF3F8;
	height: 250px;
}

.login-information__card-container:hover .login-information__card-paragraph {
	-webkit-transition: 0.2s all ease-in-out;
			transition: 0.2s all ease-in-out;
}

.login-information__card-container:hover .login-information__card-top:after {
	display: block;

}

.login-information__card-container:focus{
	outline:none !important;
}

.login-information__card-bottom {
	opacity: 0;
	background: #fff;
	pointer-events: none;
	color: #424B4A;
	padding: 0 20px 38.5px;
	font-size: 16px;
	line-height: 29px;
	height: 0;
}

.login-information__card-bottom--accent {
	font-weight: 800;
}

.login-information__card-top {
	height: 194.5px;
	padding: 53px 0 0;
	background: #EFF3F8;
	outline:none !important;
}

.login-information__card-top:after {
	content: '';
	background: #0066B3;
	position: absolute;
	height: 1px;
	opacity: 0.07;
	width: 300px;
	right: 32px;
	display: none;
	outline:none !important;
}

.login-information__card-icon {
	padding-bottom: 27px;
}

.login-information__card-icon svg {
	max-height: 66px;
	min-height: 66px;
}

.login-information__card-text {
	color: #424B4A;
	font-weight: 800;
	font-size: 18px;
	line-height: 21.5px;
	padding-bottom: 11.5px;
}

.login-slider__card {
	position: relative;
	padding: 78px 60px;
	overflow-x: hidden;
}

.login-slider__card-container {
	display: -webkit-box;
	/* display: flex; */
	display: inline-block;
	flex-direction: column;
	padding-top: 36px;
	/* padding: 36px 0 0 30px; */
	margin: 0 auto;
	/* background: #fff;
	background-repeat: no-repeat;
	background-position: center bottom; */
}

.login-slider__card-container.slick-promo {
	/* background-image: url("./img/slick-promo-background.png"); */
	width: 330px !important;
	/* height: 308px !important; */
	height: auto;
}

.login-slider__card-container.slick-glasses {
	/* background-image: url("./img/slick-glasses-background.png"); */
	width: 330px !important;
	/* height: 282px !important; */
	height: auto;
}

.login-slider__card-container:after {
	content: '';
	position: absolute;
	width: 239px;
	height: 216px;
	/* background: #EFF3F8; */
	top: -20px;
	right: -22px;
	z-index: -1;
}

.login-slider__card-title {
	font-size: 23px;
	line-height: 33px;
	font-family: Montserrat;
	color: #424B4A;
	font-weight: 800;
	position: relative;
	padding-bottom: 40px;
}

.login-slider__card-title:after {
	content: '';
	width: 10px;
	height: 10px;
	background: #0394db;
	position: absolute;
	left: 0;
	bottom: 18px;
}

.login-slider__card-paragraph {
	color: #424B4A;
	font-size: 14px;
	line-height: 21px;
	padding-bottom: 30px;
}

.login-slider__card-button {
	border-radius: 25px;
	background: #0394db;
	text-decoration: none;
	color: white;
	padding: 13px 28px;
	width: 137px;
}

.slick-list {
	overflow: hidden;
}

.slick-track {
	display: flex;
	padding: 0 20px;
}

.slick-dots {
	display: flex !important;
	justify-content: center;
	margin-bottom: 87px;
	margin-top: 59px;
}

.slick-dots li {
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	width: 15px;
	height: 15px;
	justify-content: space-between;
	margin: 0 12px;
}

.slick-dots li.slick-active button {
	background: #0066B3;
	opacity: 1;
	width: 15px;
	height: 15px;
}

.slick-dots li button {
	margin: auto;
	padding: 0;
	font-size: 0;
	width: 11px;
	height: 11px;
	background: #0066B3;
	opacity: 0.5;
	border: none;
}

.slick-arrow {
	background-color: #fff;
	top: 50%;
	-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
	height: 35px;
	width: 35px;
	border: none;
	font-size: 0;
}

.slick-next {
	content: "";
	/* margin: 0 auto; */
	display: block;
	border-right: 2px solid #0066B3;
	border-top: 2px solid #0066B3;
	width: 14px;
	height: 14px;
	-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
	top: 72%;
	position: absolute;
	right: 40%;
	background: transparent;
}

.slick-prev {
	content: "";
	/* margin: 0 auto; */
	display: block;
	border-right: 2px solid #0066B3;
	border-top: 2px solid #0066B3;
	width: 14px;
	height: 14px;
	-webkit-transform: rotate(-136deg);
					transform: rotate(-136deg);
	top: 72%;
	left: 40%;
	position: absolute;
	background: transparent;
}

.sweet-loading{
	z-index: 999999999;
	position: fixed;
	top:47%;
	left: 47%;
	opacity: 1 !important;
}

.block{
	display: flex;
	justify-content: center;
	align-items: center;
}

.banner{
	display: inline-block;
	margin: 50px 25px;
}

.banner img:hover{
	cursor: pointer;
}
.banner.banner_1 img{
	box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
}
.banner.banner_2 img{
	box-shadow: 2.5rem -2.5rem 0px -25px rgb(237,244,249);
}
.banner.banner_3 img{
	box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
}
.banner.banner_4 img{
	box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
}
.banner.banner_5 img{
	box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
}
.banner.banner_6 img{
	box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
}
.banner.banner_7 img{
	box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
}

/* CSS tablet */

@media (min-width: 768px) and (max-width: 1024px) {
	.banner.banner_1 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_2 img{
		box-shadow: 2.5rem -2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_3 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_4 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_5 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_6 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_7 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}

	.login-banner__container {
		width: 100%;
	}

	.login-top {
		height: auto;
		display: flex;
		flex-direction: column;
		padding-bottom: 50px;
		background: -webkit-gradient(linear, left top, right top, color-stop(72%, #EDF4FA), color-stop(50%, white));
		background: linear-gradient(90deg, #EDF4FA 72%, white 50%);
	}

	.login-signin {
		width: 100%;
		padding: 0 60px;
		z-index: 2;
		margin: 0px auto;
		background: #fff;
		padding-bottom: 40px;
		box-shadow: 1px 4px 6px -2px rgba(186, 186, 186, 0.56);
	}

	.login-signin__wrapper {
		/* padding: 0 60px; */
		z-index: 2;
	}

	.login-signin__container {
		margin: 0 auto;
	}

	.login-signin__sign-up--tablet {
		display: block;
	}

	.login-signin__sign-up--tablet-image_1 {
		width: 380px;
		position: absolute;
		top: 75px;
		right: 0;
		z-index: 99;
		background-image: url(/static/media/personagem02.fd0e9fee.png);
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 380px;
		height: 477px;
	}
	.login-signin__sign-up--tablet-image_2 {
		width: 380px;
		position: absolute;
		top: 75px;
		right: 0;
		z-index: 99;
		background-image: url(/static/media/personagem02.fd0e9fee.png);
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 380px;
		height: 477px;
	}

	.login-signin__sign-up--tablet-image_3 {
		width: 380px;
		position: absolute;
		top: 75px;
		right: 0;
		z-index: 99;
		background-image: url(/static/media/personagem03.5168ae91.png);
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 380px;
		height: 477px;
	}
	.login-signin__sign-up--tablet-image_4 {
		width: 380px;
		position: absolute;
		top: 75px;
		right: 0;
		z-index: 99;
		background-image: url(/static/media/personagem04.521e6059.png);
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 380px;
		height: 477px;
	}
	.login-signin__sign-up--tablet-image_5 {
		width: 380px;
		position: absolute;
		top: 75px;
		right: 0;
		z-index: 99;
		background-image: url(/static/media/personagem05.2f3d8d1c.png);
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 380px;
		height: 477px;
	}
	.login-signin__sign-up--tablet-image_6 {
		width: 380px;
		position: absolute;
		top: 75px;
		right: 0;
		z-index: 99;
		background-image: url(/static/media/personagem06.3dc3dc4e.png);
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 380px;
		height: 477px;
	}
	.login-signin__sign-up--tablet-image_7 {
		width: 380px;
		position: absolute;
		top: 75px;
		right: 0;
		z-index: 99;
		background-image: url(/static/media/personagem06.3dc3dc4e.png);
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 380px;
		height: 477px;
	}

	.login-banner_1 {
		background-image: none;
		padding-bottom: 45px;
		background-color: #EDF4FA;
		padding: 0 60px 0 0;
	}

	.login-banner_2 {
		background-image: none;
		padding-bottom: 45px;
		background-color: #EDF4FA;
		padding: 0 60px 0 0;
	}

	.login-banner_3 {
		background-image: none!important;
		padding-bottom: 45px;
		background-color: #EDF4FA;
		padding: 0 60px 0 0;
	}
	.login-banner_4 {
		background-image: none!important;
		padding-bottom: 45px;
		background-color: #EDF4FA;
		padding: 0 60px 0 0;
	}
	.login-banner_5 {
		background-image: none!important;
		padding-bottom: 45px;
		background-color: #EDF4FA;
		padding: 0 60px 0 0;
	}
	.login-banner_6 {
		background-image: none!important;
		padding-bottom: 45px;
		background-color: #EDF4FA;
		padding: 0 60px 0 0;
	}
	.login-banner_7 {
		background-image: none!important;
		padding-bottom: 45px;
		background-color: #EDF4FA;
		padding: 0 60px 0 0;
	}

	.login-banner__title-text {
		width: 85%;
	}

	.login-banner__paragraph{
		width: 85%;
	}

	.login-banner__paragraph-text {
		padding-bottom: 45px;
	}

	.login-information__title {
		font-size: 28px;
		line-height: 32px;
	}

	.login-information__card-top:after {
		width: 245px;
	}

	.login-slider__card {
		padding: 34px 60px;
	}

	.slick-next {
		right: 36%;
		top: 75%;
	}

	.slick-prev {
		left: 36%;
		top: 75%;
	}

	.banner{
		margin: 15px;
	}

	.banner img{
		height: auto;
		width: 200px;
	}
}

/* CSS mobile */

@media (max-width: 767px) {
	.banner.banner_1 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_2 img{
		box-shadow: -2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_3 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_4 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_5 img{
		box-shadow: -2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_6 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}
	.banner.banner_7 img{
		box-shadow: 2.5rem 2.5rem 0px -25px rgb(237,244,249);
	}

	.login-top {
		padding: 0 40px;
		background: #EDF4FA;
		height: auto;
	}

	.login-top.has--no-scroll .login-banner__container{
		display: none;
	}

	.login-top.has--no-scroll{
		overflow: hidden;
		position: fixed;
   		top: 0;
   		left: 0;
   		right: 0;
		bottom: 0;
		z-index: 4;
	}

	.login-signin {
		display: none;
		position: absolute;
		background: white;
		padding: 0 32px;
		left: 0;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	.login-signin.is--active {
		display: block;
		z-index: 2;
		overflow-y: scroll;
	}

	.login-signin__wrapper {
		padding-right: 0;
	}

	.login-signin__container {
		width: auto;
	}

	.login-signin__logo-image {
		max-height: 67px;
		display: block;
		margin: 0 auto;
	}

	.login-signin__back-button {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		top: 30px;
	}

	.login-signin__social-network--button svg {
		margin-right: 12px;
	}

	.login-signin__social-network--button {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		padding: 12px 14px;
	}

	.login-signin__social-network--button:first-child {
		margin-right: 10px;
	}

	.login-signin__title {
		padding: 28px 0 15px;
		color: #0066B3;
		font-size: 18px;
	}

	.login-signin__sign-up--mobile {
		margin-top: 40px;
		display: block;
		padding-bottom: 47px;
	}

	.login-signin__sign-up--tablet {
		display: none;
	}

	.login-signin__sign-up {
		display: none;
	}

	.login-signin__sign-up--link {
		text-decoration: underline;
	}

	.login-banner_1 {
		background-image: none;
		width: 100%;
		text-align: center;
		padding-left: 0;
	}

	.login-banner_2 {
		background-image: none;
		width: 100%;
		text-align: center;
		padding-left: 0;
	}

	.login-banner_3 {
		background-image: none;
		width: 100%;
		text-align: center;
		padding-left: 0;
	}

	.login-banner_4 {
		background-image: none;
		width: 100%;
		text-align: center;
		padding-left: 0;
	}

	.login-banner_5 {
		background-image: none;
		width: 100%;
		text-align: center;
		padding-left: 0;
	}

	.login-banner_6 {
		background-image: none;
		width: 100%;
		text-align: center;
		padding-left: 0;
	}

	.login-banner_7 {
		background-image: none;
		width: 100%;
		text-align: center;
		padding-left: 0;
	}

	.login-banner__container {
		margin: 0;
		align-items: center;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.login-banner__button-mobile {
		display: block;
		width: 100%;
		background: #0066b3;
		color: #fff;
		height: 50px;
		font-size: 16px;
		line-height: 22px;
		cursor: pointer;
		font-family: Open Sans;
		border: none;
		font-weight: 800;
		border-radius: 25px;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-top: 20px
	}

	.login-banner__logo {
		padding: 0 0 68px
	}

	.login-banner__title {
		font-size: 32px;
		line-height: 37px;
	}

	.login-banner__title-text {
		width: 100%;
	}

	.login-banner__paragraph {
		font-size: 14px;
		line-height: 22px;
	}

	.login-information {
		padding: 0 36px;
	}

	.login-information__title {
		font-size: 25px;
		line-height: 32px;
	}

	.login-information__card {
		padding: 0;
		flex-direction: column;
		overflow: hidden;
		position: relative;
	}

	.login-information__card .slick-next {
		top: 85.5%;
		right: 2%;
	}

	.login-information__card .slick-prev {
		top: 85.5%;
		left: 2%;
	}

	.login-information__card .slick-track {
		padding: 0px;
	}

	.login-information__card-container {
		overflow: hidden;
		width: 100% !important;
	}

	.login-information__card-bottom {
		opacity: 1;
		-webkit-transition: opacity .5s linear;
		transition: opacity .5s linear;
		-webkit-transition: 0.2s all ease-out;
		transition: 0.2s all ease-out;
		background: #EFF3F8;
		height: auto;
	}

	.login-information__card-text {
		color: #0066B3;
		-webkit-transition: 0.2s all ease-out;
		transition: 0.2s all ease-out;
		-webkit-transform: translateY(-30%);
		transform: translateY(-30%);
	}

	.login-slider__card {
		padding: 33px 0;
	}

	.login-slider__card-container {
		margin-right: 45px;
	}

	.login-slider__card-container .slick-prev {
		top: 75.5%;
	}

	.slick-dots li {
		width: 6px;
	}

	.slick-next {
		top: 75%;
		right: 2%;
	}

	.slick-prev {
		top: 75%;
		left: 2%;
	}

	.block{
		display: block;
		margin-top: 15px;
	}

	.banner{
		width: 100%;
		text-align: center;
		margin:15px 0;
	}
}

p.login-signin__sign-up--text {
    margin-bottom: 0;
}
.crm-input {
  display: inline-flex;
  position: relative;
}

.crm-input-label {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
}

.crm-input [type="text"],
.crm-input [type="number"],
.crm-input [type="email"],
.crm-input [type="password"],
.crm-input [type="tel"],
.crm-input [type="url"] {
  /* box-shadow: 0 0 0 1px #b5b5b5; */
  border: 1px solid #b5b5b5;
  padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 0;
  width: 100%;
}

.crm-input input:-moz-read-only {
  color: #a6abaa;
  border-color: #cbcbcb;
}

.crm-input input:read-only {
  color: #a6abaa;
  border-color: #cbcbcb;
}

.crm-input [type="text"]:-moz-read-only, .crm-input [type="number"]:-moz-read-only, .crm-input [type="email"]:-moz-read-only, .crm-input [type="password"]:-moz-read-only, .crm-input [type="tel"]:-moz-read-only, .crm-input [type="url"]:-moz-read-only, .crm-input [type="date"]:-moz-read-only, .crm-input [type="datetime-local"]:-moz-read-only {
  border: 1px solid #cbcbcb;
  box-shadow: unset;
}

.crm-input [type="text"]:read-only,
.crm-input [type="number"]:read-only,
.crm-input [type="email"]:read-only,
.crm-input [type="password"]:read-only,
.crm-input [type="tel"]:read-only,
.crm-input [type="url"]:read-only,
.crm-input [type="date"]:read-only,
.crm-input [type="datetime-local"]:read-only {
  border: 1px solid #cbcbcb;
  box-shadow: unset;
}

.crm-input [type="text"]:disabled,
.crm-input [type="number"]:disabled,
.crm-input [type="email"]:disabled,
.crm-input [type="password"]:disabled,
.crm-input [type="tel"]:disabled,
.crm-input [type="url"]:disabled,
.crm-input [type="date"]:disabled,
.crm-input [type="datetime-local"]:disabled {
  background-color: unset;
}

.crm-input [type="date"],
.crm-input [type="datetime-local"] {
  box-shadow: 0 0 0 1px #b5b5b5 !important;
  border: medium none currentColor;
  border: initial;
  padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 0;
  width: calc(100% - 1rem);
}
.crm-input [type="textarea"] {
  box-shadow: 0 0 0 1px #b5b5b5 !important;
  border: medium none currentColor;
  border: initial;
  padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 0;
  width: 100%;
}

.crm-input input {
  color: rgba(32, 34, 34, 0.9);
}

.tooltip-shape {
  position: absolute;
  width: 0.9375rem;
  height: 0.9375rem;
  right: 25px;
  top: 42px;
}

.tooltip-shape:before {
  content: "i";
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.625rem;
  color: #0394db;
  z-index: 15;
  border: 1px solid #0394db;
  border-radius: 50%;
  width: 0.9375rem;
  height: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  opacity: 1 !important;
}

.tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: relative;
  z-index: 5;
  left: 9.2rem;
  top: 4.5rem;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-shape:hover .tooltiptext {
  visibility: visible;
}

.font-order {
  color: #333333 !important;
  font-family: "Segoe UI";
  font-weight: 400;
}

@media only screen and (max-width: 766px) {
  .crm-input {
    width: 100% !important;
  }

  .crm-input [type="text"],
  .crm-input [type="number"],
  .crm-input [type="email"],
  .crm-input [type="password"],
  .crm-input [type="tel"],
  .crm-input [type="url"],
  .crm-input [type="date"],
  .crm-input [type="datetime-local"] {
    width: 100%;
    margin: 1.375rem 0 0 0;
  }
}

@media only screen and (max-width: 425px) {
  .tooltip-shape {
    top: 66px;
  }
}

.input-password {
	display: inline-flex;
	position: relative;
}

.input-password-label {
    font-size: 0.75rem;
    -webkit-transform: translate3d(10px, 15px, 0);
    transform: translate3d(10px, 15px, 0);
    color: #00518b;
    background: #fff;
    font-family: 'Open Sans';
    position: absolute;
    z-index: 2;
    padding: 0 0.3125rem;
}

.input-password [type='text'],
.input-password [type='password'] {
    /* box-shadow: 0 0 0 1px #b5b5b5; */
    border: 1px solid #b5b5b5;
    padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
    border-radius: 5px;
    margin: 1.375rem 1rem 0 0;
    position: relative;
    z-index: 0;
    width: 100%;
}

.input-password input:-moz-read-only {
	color:#A6ABAA;
	border-color:#CBCBCB;
}

.input-password input:read-only {
	color:#A6ABAA;
	border-color:#CBCBCB;
}

.input-password [type='text']:-moz-read-only, .input-password [type='password']:-moz-read-only {
	border: 1px solid #CBCBCB;
	box-shadow: unset;
}

.input-password [type='text']:read-only,
.input-password [type='password']:read-only {
	border: 1px solid #CBCBCB;
	box-shadow: unset;
}

.input-password [type='text']:disabled ,
.input-password [type='password']:disabled {
	background-color: unset;
}

.input-password input {
    color: rgba(66, 75, 74, 0.8);
}


.eye-position {
	position: absolute;
    width: 0.9375rem;
    height: 0.9375rem;
    right: 10%;
    top: 37px;
}

@media only screen and (max-width: 766px) {
	.input-password {
		width: 100%!important;
	}

    .input-password [type='text'],
	.input-password [type='password'] {
		width: 100%;
		margin: 1.375rem 0 0 0;
	}
}
.notfound {
  font-size: 35px;
  font-family: 'Nunito', sans-serif;
  padding-bottom: 41px;
  color: #00518b;
  text-align: center;
  line-height: 20vh;
  max-width: 75%;
  margin: auto;
}

/* CSS do Header */

/* .x-header__container {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 3.75rem;
  height: 3.875rem;
  background-color: #f8f8f8;
}

.x-header__container.is--mobile {
  display: none;
} */

/* @media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-header__container {
    padding: 0 2rem;
    height: 3.3125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-header__container {
    padding: 0 2.25rem;
    height: 3rem;
  }

  .x-header__container.is--desk {
    display: none;
  }

  .x-header__container.is--mobile {
    display: -webkit-box;
    display: flex;
  }
} */

.x-header__logo img {
  /* width: 6.125rem;
    height: 3.3125rem; */
}

/* @media only screen and (max-width: 766px) {
  .x-header__logo {
    margin-left: 1rem;
  }

  .x-header__logo img {
    height: 3.125rem;
  }
} */

.main-header-opacity {
  opacity: 0.2;
}

/* .x-header__items {
  margin-left: 3.75rem;

  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
} */

/* @media only screen and (max-width: 1023px) {
  .x-header__items {
    margin-left: 0.9375rem;
    margin-top: 1rem;
  }
} */
/* .x-header__item {
 display: flex;
} */

.x-header__item:not(:first-child) {
  /* margin-left: 20px; */
}

@media only screen and (max-width: 1023px) {
  .x-header__item:not(:first-child) {
    /* margin-left: 1rem; */
  }

  /* .x-header__link {
    font-size: 0.7rem;
  } */
}

/* .x-header__link {
  color: #424b4a;
  -webkit-transition: all .175s ease-in-out;
  transition: all .175s ease-in-out;
  font-size: 1rem;
  width: 100%;
  line-height: 3.875rem;
  padding: 0 20px;
  height: 3.875rem;
} */

/* .x-my-account__avatar {
    width: 89px;
    height: 89px;
    border-radius: 50%;
    object-fit: cover;
} */

/* .x-header__link:hover {
  color: black;
  background-color: #f0f0f0;;
}

.x-header__menu {
  margin-left: auto;
  position: relative;
}

.x-my-account__avatar {
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 2.375rem;
  object-fit: cover;
}
.x-my-account__avatar img:focus {
  outline: none !important;
}
.x-my-account__avatar_container {
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 2.375rem;
  object-fit: cover;
}

.x-header__menu-btn {
  background-color: #00518b;
  border-radius: 2.375rem;
  height: 2.375rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  z-index: 3;
  position: relative;
} */

@media only screen and (max-width: 1023px) {
  /* .x-header__menu-btn {
    width: 8.75rem;
    height: 2.125rem;
  }
  .x-my-account__avatar_container {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    object-fit: cover;
  } */
}

@media only screen and (max-width: 766px) {
  /* .x-header__menu-btn {
    width: 6.875rem;
    height: 2.125rem;
  } */
}

@media only screen and (max-width: 1023px) {
  /* .x-header__menu-btn img {
    width: 2.125rem;
    height: 2.125rem;
  } */
}

/* .x-header__menu-btn:after {
  content: '';
  display: block;
  position: relative;
  margin-left: 1rem;
  margin-right: 1.25rem;
  width: 10px;
  height: 10px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  will-change: transform;
  top: -2px;
} */

@media only screen and (max-width: 1023px) {
  /* .x-header__menu-btn:after {
    width: 8px;
    height: 8px;
    position: absolute;
    right: -8px;
    top: 46%;
    -webkit-transform: translateY(-50%) rotate(135deg);
    transform: translateY(-50%) rotate(135deg);
  } */
}

/* .x-header__menu-text {
  margin-left: 1.25rem;

  font-size: 0.75rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 2px;
} */

@media only screen and (max-width: 1023px) {
  /* .x-header__menu-text {
    margin-left: 0.5rem;
    font-size: 0.7rem;
    line-height: 0.2rem;
  } */
}

/* .x-header__menu-list {
  position: absolute;
  width: 100%;
  border-radius: 0.625rem;
  top: 1rem;
  max-width: 11.25rem;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.284);
  padding-top: 1.875rem;
  -webkit-transform: translateY(-7%);
  transform: translateY(-7%);
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  -webkit-transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.x-header__menu-list.is--active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
  opacity: 1;
}

.x-header__menu-list-item {
  padding: 0.5rem 0;
  text-align: center;
}

.x-header__menu-list-item:last-child {
  padding-bottom: 1.5rem;
}

.x-header__menu-list-link {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: #424b4a;
  -webkit-transition: color all 0.175s ease-in-out;
  transition: color all 0.175s ease-in-out;
  display: block;
}

.x-header__menu-list-link:hover {
  color: #1e2222;
}

.x-header__side-menu {
  position: absolute;
  width: 15.625rem;
  background-color: white;
  padding: 1.5rem 2.375rem 3.75rem;
  z-index: 5;
  top: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.235s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.235s cubic-bezier(0.165, 0.84, 0.44, 1);
  height: 100%;
}

.x-header__side-menu.is--active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
} */

.x-header__side-items:last-child {
  /* border-top: 1px solid #D6D6D6; */
}

/* .x-header__side-item {
  padding: 1.25rem 0;
}

.x-header__side-item:first-child {
  padding-top: 2.5rem;
}

.x-header__side-item:last-child {
  padding-bottom: 2.5rem;
}

.x-header__side-link {
  color: #424b4a;
}

.x-header__side-link.is--bold {
  color: #0066b3;
  font-weight: bold;
}

.x-header__background {
  background-color: black;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  -webkit-transition: all 0.235s ease-in-out;
  transition: all 0.235s ease-in-out;
} */

/* .x-header__background.is--active {
  opacity: 0.6;
  pointer-events: all;
} */

/* CSS do Footer */

.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed;
  /* Stay in place */
  z-index: 7;
  /* Sit on top */
  left: 0;
  top: 0;
  display: none;
  background-color: rgb(255, 255, 255);
  /* Black fallback color */
  background-color: rgba(255, 255, 255, 0.9);
  /* Black w/opacity */
  overflow-x: hidden;
  /* Disable horizontal scroll */
  -webkit-transition: 0.5s;
  transition: 0.5s;
  /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.x-footer {
  background-color: white;
}

.x-footer__brand-img {
  margin: 0 auto;
  padding: 1.25rem 0 0.9375rem;
  display: block;
  width: 100%;
  max-width: 45.25rem;
}

.x-footer__facebook-text {
  margin: 0;
}

@media only screen and (max-width: 1023px) {
  .x-footer__brand-img {
    max-width: 30.125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__links {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
}

.x-footer__link {
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-family: 'Montserrat', sans-serif;
  color: #000;
}

@media only screen and (max-width: 1023px) {
  .x-footer__link {
    font-size: 0.5625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__link {
    font-size: 0.75rem;
  }
}

.x-footer__facebook {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 766px) {
  .x-footer__facebook {
    margin-top: 2.125rem;
    margin-bottom: 2.375rem;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.x-footer__facebook-text {
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 1023px) {
  .x-footer__facebook-text {
    font-size: 0.5625rem;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 0.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__facebook-text {
    font-size: 0.75rem;
  }
}

.x-footer__facebook-logo {
  margin-left: 1.125rem;
  margin-right: 0.625rem;
}

@media only screen and (max-width: 1023px) {
  .x-footer__facebook-logo {
    margin-left: 0.875rem;
    margin-right: 0.3125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__facebook-logo {
    margin-left: 0;
  }
}

.x-footer__facebook-link {
  color: #0071ff;
  font-weight: bold;
}

@media only screen and (max-width: 1023px) {
  .x-footer__facebook-link {
    font-size: 0.5625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__facebook-link {
    font-size: 0.75rem;
  }
}

.x-footer__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 3.75rem;
  border-top: 1px solid #d6d6d6;
}

@media only screen and (max-width: 1023px) {
  .x-footer__bottom {
    padding: 0 5rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-footer__bottom {
    padding: 0;
    flex-direction: column;
  }
}

.x-footer__links {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 766px) {
  .x-footer__links {
    padding-top: 1.75rem;
  }
}

.x-footer__circle {
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: #00518b;
  display: block;
  border-radius: 50%;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}

@media only screen and (max-width: 766px) {
  .x-footer__circle {
    width: 0.5rem;
    height: 0.5rem;
    margin: 1.25rem 0;
  }
}

.footer-links__right-container {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .footer-links__right-container {
    flex-direction: column-reverse;
  }
}

.footer-links__right-image {
  width: auto;
  height: 3.875rem;
  padding: 0.25rem 0;
}

.footer-links__right-copyright {
  padding-right: 35px;
  font-size: 12px;
}

@media (max-width: 767px) {
  .footer-links__right-copyright {
    padding: 12px 0px 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer-links__right-copyright {
    padding-right: 22px;
  }
}

.x-footer__brands.is--mobile {
  display: none;
}

@media only screen and (max-width: 766px) {
  .x-footer__brand-img {
    max-width: 25.125rem;
  }

  .x-footer__brands.is--desk {
    display: none;
  }

  .x-footer__brands.is--mobile {
    display: block;
    padding-bottom: 1.25rem;
  }
}

.x-footer__brand-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 15.875rem;
  margin: 0 auto;
}

/* CSS Back */

.x-my-account__back {
  margin: 0;
  width: 270px;
}

.x-follow-order__back {
  display: flex;
  align-items: center;
}

.x-follow-order__back-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  color: #424b4a;
  font-weight: bold;
  margin-left: 0.625rem;
  padding-left: 15px !important;
}

@media only screen and (max-width: 766px) {
  .x-follow-order__back-text {
    font-size: 0.75rem;
  }
}

/* CSS termos */

.x-overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.88);
  -webkit-transition: opacity 275ms ease-in-out;
  transition: opacity 275ms ease-in-out;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  cursor: pointer;
  z-index: 5001;
}

.x-overlay.is--active {
  opacity: 1;
  visibility: visible;
}

.signup-popup {
  position: relative;
}

.signup-popup__container {
  opacity: 1;
  pointer-events: all;
  background: #fff;
  z-index: 99999;
  position: fixed;
  width: 745px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 60px;
  max-height: 80%;
  border: 2px solid #f8f8f8;
  border-radius: 5px;
  -webkit-transition: 0.5s bottom, 0.5s opacity;
  transition: 0.5s bottom, 0.5s opacity;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-popup__container {
    border: 1px solid #e0e0e0;
    width: 80%;
  }
}

@media (max-width: 767px) {
  .signup-popup__container {
    width: 100%;
    min-height: 100%;
    padding: 0 30px;
  }
}

.signup-popup__container:after {
  content: '';
  width: 100%;
  background: white;
  opacity: 0.4;
}

.signup-popup__title {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #00409a;
  padding-bottom: 2.75rem;
}

@media only screen and (max-width: 766px) {
  .signup-popup__title {
    padding-bottom: 0;
    margin: 2.25rem auto;
    width: 16rem;
  }
}

.signup-popup__text {
  overflow-y: scroll;
  height: 80%;
  font-size: 14px;
  line-height: 23px;
  padding-right: 20px;
}

.signup-popup__text::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.signup-popup__text::-webkit-scrollbar-thumb {
  background: #b5b5b5;
}

.signup-popup__text::-webkit-scrollbar-track {
  background: #f8f8f8;
}

body .signup-popup__text {
  scrollbar-face-color: #b5b5b5;
  scrollbar-track-color: #f8f8f8;
}

@media only screen and (max-width: 766px) {
  .signup-popup__text {
    padding: 0;
  }
}

.signup-popup__close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

@media only screen and (max-width: 766px) {
  .signup-popup__close {
    position: absolute;
    top: 3.125rem;
  }
}

.signup-popup__close svg {
  pointer-events: none;
}

.signup-popup__close svg .a {
  fill: #b5b5b5 !important;
}

.signup-popup__close svg.close-mobile {
  display: none;
}

.signup-popup__close svg.close-mobile .a {
  fill: #00409a !important;
}

@media only screen and (max-width: 766px) {
  .signup-popup__close svg.close-mobile {
    display: block;
  }
}

@media only screen and (max-width: 766px) {
  .signup-popup__close svg.close-desk {
    display: none;
  }
}

.signup-popup__paragraph {
  height: 320px;
}

@media only screen and (max-width: 766px) {
  .signup-popup__paragraph {
    padding: 30px 0;
    height: 600px;
    max-height: 80%;
    text-align: center;
  }
}

.is--hide {
  display: none;
}

.hide-popup {
  opacity: 0;
  pointer-events: none;
}

.hide-message {
  opacity: 0;
  pointer-events: none;
  height: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}



/* Loading */
.overlay-content {
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.4);
  flex-direction: column;
}

.overlay-content .wrapper {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay-content .wrapper .content-loading {
  background-color: #fff;
  width: auto;
  padding: 1.25em;
  border: none;
  border-radius: 0.3125em;
}


.overlay-content .wrapper .content-loading .image {
  width: 100%;
  margin: 1.25em auto;
}

.overlay-content .wrapper .content-loading .message {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}


.x-header__logo img {
  max-height: 62px;
}

.marcas_footer img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-transition: linear 0.4s;
  transition: linear 0.4s;
  opacity: 0.5;
}
.signup-main{

}

/* BEGIN LEFT */
.signup-left {
  padding: 0 60px 0 60px;
  width: 100%;
  background: #eff3f8;
  padding-bottom: 8.5625rem;
  height: 100%;
}

.signup-logo{
  padding: 27px 0 68px
}

.signup-logo img  {
    max-height: 67px
}

.signup-title {
  position: relative;
  padding-bottom: 41px;
  color: #00409A;
  line-height: 60px
}

.signup-title > h1 {
  font-weight: bold;
  font-size: 40px;
  font-family: Montserrat;
  font-weight: 700;
}

.signup-title:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #0394db;
  position: absolute;
  bottom: 0
}

.signup-paragraph {
  font-size: 16px;
  line-height: 26px;
  color: #424B4A;
  max-width: 419px;
  padding-top: 34px
}

/* END LEFT */

/* BEGIN RIGHT */
.signup-right {
  width: 100%;
  padding: 0 94px
}

.signup-cnpj {
  margin-top: 155px;
}

.signup-cnpj-title {
  color: #0066B3;
  font-family: Montserrat;
  font-size: 28px;
  line-height: 37px;
  font-weight: lighter;
}

.signup-right__next-step--login {
  color: #B5B5B5;
  font-size: .8125rem;
  line-height: 1.125rem;
  text-decoration: underline
}

.signup-right__next-account {
  padding: 0 3.5rem;
  margin-top: 5rem
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* BEGIN LEFT */
  .signup-left {
    text-align: center;
    padding: 0 6.6875rem;
  }
  
  .signup-logo {
    padding: 0 0 30px
  }
  
  .signup-title {
    line-height: 34px
  }
  
  .signup-title > h1 {
    font-size: 40px;
  }
  
  .signup-paragraph {
    width: 100%;
    max-width: none;
    text-align: center;
    padding-bottom: 60px
  }
  /* END LEFT */
  /* BEGIN RIGHT */

  .signup-cnpj {
    margin-top: 80px;
  }

  .signup-cnpj-title {
    font-size: 1.375rem;
    line-height: 2.0625rem;
    text-align: center;
  }

}

@media (max-width: 767px) {
  /* BEGIN LEFT */
  .signup-left {
    text-align: center;
    padding-bottom: 1.875rem
  }
  
  .signup-logo {
    padding: 0 0 30px;
    text-align: center
  }
  
  .signup-title {
    margin: 0 auto;
  }
  
  .signup-title > h1 {
    text-align: center;
    line-height: 49px;
    font-size: 2.5rem;
  }
  
  .signup-paragraph {
    font-size: 14px;
    line-height: 22px
  }
  /* END LEFT */

  /* BEGIN RIGHT */
  .signup-right {
    padding: 0 30px;
  }

  .signup-cnpj {
    margin-top: 20px;
  }

  .signup-cnpj-title {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    text-align: center;
  }
}

.signup.has--no-scroll {
  overflow: hidden
}

.signup-left__button-mobile {
  display: none
}

.signup-main-opacity {
  opacity: 0.2;
}

.signup-right__form-empty::before {
  content: "Preencher o campo. ex:. 00.000.000/0000-00";
  display: inline-block;
  width: 20%;
  border-radius: 10px;
  width: 30%;
  font-size: .75em;
  padding: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.842)
}

.signup-right__form-invalid {
  display: flex;
  color: #0394db;
  max-width: 18.125rem;
  font-size: .75rem;
  line-height: 1.0625rem;
  padding-left: 1.5625rem;
  opacity: 1;
  height: auto;
  pointer-events: all;
  -webkit-transition: .2s bottom, .2s opacity;
  transition: .2s bottom, .2s opacity
}

.signup-right__form-invalid svg {
  margin-right: .4375rem;
  margin-top: .3125rem;
  width: 3.125rem
}

.signup-right__form-invalid svg .a {
  fill: #0394db
}

.signup-right__form-valid {
  display: flex;
  color: #53b934;
  font-size: .75rem;
  line-height: 1.0625rem;
  padding-left: 1.5625rem;
  opacity: 1;
  pointer-events: all;
  -webkit-transition: opacity .175s ease-in-out, height .3s ease-in-out;
  transition: opacity .175s ease-in-out, height .3s ease-in-out
}

.signup-right__form-valid svg {
  margin-right: .4375rem;
  margin-top: .3125rem;
  width: 1.375rem
}

.signup-right__form-valid svg .a {
  fill: #53b934
}

.signup-right__form-input {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #0066B3;
  padding-left: 15px;
  margin-bottom: 20px;
  outline: none;
  font-size: 14px
}

.signup-right__form-input:-webkit-autofill,
.signup-right__form-input:-webkit-autofill:hover,
.signup-right__form-input:-webkit-autofill:focus,
.signup-right__form-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important
}

.signup-right__form-input::-webkit-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__form-input::-moz-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__form-input::-ms-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__form-input:-ms-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__form-input::placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__form-row {
  display: flex;
  flex-direction: column;
  position: relative
}

.signup-right__form-label {
  font-size: 14px;
  line-height: 19px;
  color: #0066B3;
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0 5px;
  background: #fff
}

.signup-right__next-step {
  display: flex;
  justify-content: space-between;
  align-items: center
}

@media (max-width: 767px) {
  .signup-right__next-step {
      display: flex;
      flex-direction: column-reverse
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-right__next-step {
      width: 319px;
      margin: 0 auto 50px;
      display: flex;
      flex-direction: column-reverse
  }
}

@media (max-width: 767px) {
  .signup-right__next-account {
      margin-top: 1.625rem;
      padding: 0
  }
}

.signup-right__next-account--title {
  color: #0066B3;
  font-family: Montserrat;
  font-size: 28px;
  line-height: 37px;
  font-weight: lighter;
  margin-bottom: 1.25rem
}

@media (max-width: 767px) {
  .signup-right__next-account--title {
      font-size: 1.125rem;
      line-height: 1.6875rem;
      text-align: center
  }
}

.signup-right__next-account--title span {
  text-transform: uppercase;
  font-weight: bold;
  display: block
}

.signup-right__next-account--warning {
  color: #424B4A;
  font-size: .875rem;
  line-height: 1.625rem;
  margin-bottom: 2.875rem
}

@media  only screen and  (max-width: 767px) {
  .signup-right__next-account--warning {
    margin-bottom: 1.5rem!important;
  }
}

.signup-right__next-account--password-button {
  fill: #0066b3;
  position: absolute;
  top: 1.125rem;
  right: 1.125rem;
  cursor: pointer
}

.signup-right__next-account--password-button svg {
  fill: #0066b3;
  pointer-events: none
}

.signup-right__next-account--input {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #424B4A;
  padding-left: 15px;
  margin-bottom: 1.6875rem;
  width: 100%;
  font-size: 14px;
  outline: none
}

.signup-right__next-account--input.js--input-email {
  max-width: 50%;
  align-items: flex-start;
  width: 15.5rem;
}

@media (max-width: 767px) {
  .signup-right__next-account--input.js--input-email {
      max-width: 100%
  }
}

.signup-right__next-account--input:-webkit-autofill,
.signup-right__next-account--input:-webkit-autofill:hover,
.signup-right__next-account--input:-webkit-autofill:focus,
.signup-right__next-account--input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important
}

.signup-right__next-account--input-check {
  display: none
}

.signup-right__next-account--input-check:before {
  content: "\2714";
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: block;
  width: 1em;
  height: 1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: .2s;
  transition: .2s
}

.signup-right__next-account--input::-webkit-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__next-account--input::-moz-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__next-account--input::-ms-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__next-account--input:-ms-input-placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__next-account--input::placeholder {
  color: #424B4A;
  opacity: 0.4;
  font-family: Open Sans
}

.signup-right__next-account--reminder {
  font-size: .75em;
  color: #424B4A;
  font-family: "Open Sans";
  display: flex;
  margin-top: 9%;
}


.signup-right__next-account--label {
  font-size: 14px;
  line-height: 19px;
  color: #424B4A;
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0 5px;
  background: #fff;
  opacity: 0;
  -webkit-transition: .2s bottom, .2s opacity;
  transition: .2s bottom, .2s opacity
}

.signup-right__next-account--label.label-name {
  bottom: 80%;
  color: #00409A;
  opacity: 1;
  padding: 4px
}

.signup-right__next-account--label-check {
  margin-bottom: .9375rem;
  color: #000;
  font-size: .875rem;
  line-height: 0.85rem;
}

.signup-right__next-account--row {
  display: flex;
  justify-content: space-between;
  position: relative
}

@media (max-width: 767px) {
  .signup-right__next-account--row.email {
      display: flex;
      flex-direction: column
  }
}

.signup-right__next-account--checkbox {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.signup-right__next-account--wrapper {
  position: relative;
  width: 50%
}

.signup-right__next-account--wrapper:first-child {
  margin-right: 10px
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-right__next-account--wrapper {
      width: 100%
  }
}

.signup-right__next-account--button {
  border-radius: 25px;
  text-decoration: none;
  margin-top: 20px;
  color: white;
  padding: .8125rem 3.8125rem;
  /* width: 100%; */
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 3.125rem
}

.signup-right__next-account--input-check {
  display: none
}

.signup-right__next-account--input-check+.signup-right__next-account--label-check:before {
  content: "";
  border: .0625rem solid #0394db;
  border-radius: .1875rem;
  display: inline-block;
  width: .875rem;
  height: .875rem;
  margin-right: .9375rem;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: .2s;
  transition: .2s
}

.signup-right__next-account--input-check+.signup-right__next-account--label-check:active:before {
  -webkit-transform: scale(0);
  transform: scale(0)
}

.signup-right__next-account--input-check:checked+.signup-right__next-account--label-check:before {
  background-color: #0394db;
  border-color: #0394db;
  padding: 2px;
  box-shadow: inset 0px 0px 1px 2px #fff;
  color: #fff
}

.x-overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.88);
  -webkit-transition: opacity 275ms ease-in-out;
  transition: opacity 275ms ease-in-out;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  cursor: pointer;
  z-index: 5001
}

.x-overlay.is--active {
  opacity: 1;
  visibility: visible
}

.signup-popup {
  position: relative
}

.signup-popup__container {
  opacity: 1;
  pointer-events: all;
  background: #fff;
  z-index: 99999;
  position: fixed;
  width: 745px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 60px;
  max-height: 80%;
  border: 2px solid #F8F8F8;
  border-radius: 5px;
  -webkit-transition: .5s bottom, .5s opacity;
  transition: .5s bottom, .5s opacity
}

@media (min-width: 768px) and (max-width: 1024px) {
  .signup-popup__container {
      border: 1px solid #E0E0E0;
      width: 80%
  }
}

@media (max-width: 767px) {
  .signup-popup__container {
      width: 100%;
      min-height: 100%;
      padding: 0 30px
  }
}

.signup-popup__container:after {
  content: '';
  width: 100%;
  background: white;
  opacity: 0.4
}

/* .signup-popup__title {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #00409A;
  padding-bottom: 2.75rem
}

@media only screen and (max-width: 766px) {
  .signup-popup__title {
      padding-bottom: 0;
      margin: 2.25rem 0
  }
} */

.signup-popup__text {
  overflow-y: scroll;
  height: 80%;
  font-size: 14px;
  line-height: 23px;
  padding-right: 20px
}

.signup-popup__text::-webkit-scrollbar {
  width: 4px;
  height: 4px
}

.signup-popup__text::-webkit-scrollbar-thumb {
  background: #B5B5B5
}

.signup-popup__text::-webkit-scrollbar-track {
  background: #F8F8F8
}

body .signup-popup__text {
  scrollbar-face-color: #B5B5B5;
  scrollbar-track-color: #F8F8F8
}

@media only screen and (max-width: 766px) {
  .signup-popup__text {
      padding: 0
  }
}

.signup-popup__close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer
}

@media only screen and (max-width: 766px) {
  .signup-popup__close {
      position: absolute;
      top: 3.125rem
  }
}

.signup-popup__close svg {
  pointer-events: none
}

.signup-popup__close svg .a {
  fill: #b5b5b5 !important
}

.signup-popup__close svg.close-mobile {
  display: none
}

.signup-popup__close svg.close-mobile .a {
  fill: #00409A !important
}

@media only screen and (max-width: 766px) {
  .signup-popup__close svg.close-mobile {
      display: block
  }
}

@media only screen and (max-width: 766px) {
  .signup-popup__close svg.close-desk {
      display: none
  }
}

.signup-popup__paragraph {
  height: 320px
}

@media only screen and (max-width: 766px) {
  .signup-popup__paragraph {
      padding: 30px 0;
      height: 600px;
      max-height: 80%;
      text-align: center
  }
}

.is--hide {
  display: none
}

.hide-popup {
  opacity: 0;
  pointer-events: none
}

.hide-message {
  opacity: 0;
  pointer-events: none;
  height: 0
}

.cadastro > .input-password > .input-password-label {
  -webkit-transform: translate3d(10px, 1px, 0) !important;
          transform: translate3d(10px, 1px, 0) !important;
}

.cadastro > .input-password > input {
  box-shadow: none!important;
  height: 50px;
}


.cadastro > .input-password > .eye-position {
  top: 22px;
}
.crm-button-label {
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.crm-button-blue {
  border-radius: 1.875rem;
  padding: 0 0.9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  margin: 0 auto;
  -webkit-transition: all 0.155s ease-in;
  transition: all 0.155s ease-in;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crm-button-orange {
  border-radius: 1.875rem;
  padding: 0 0.9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
  background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
  margin: 0 auto;
  margin-top: 1.75rem;
  -webkit-transition: all 0.155s ease-in;
  transition: all 0.155s ease-in;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crm-button:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.284);
}

.crm-button p {
  margin-bottom: 0;
}

@media only screen and (max-width: 766px) {
  .crm-button-blue,
  .crm-button-orange {
    width: 100% !important;
    margin-right: 35px;
  }
}

/* CSS desktop */

.header__container {
	/* display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center; */
	padding: 0 3.75rem;
	height: 3.875rem;
	background-color: #f8f8f8;
}

.header__container.is--mobile {
	display: none;
}

.header__logo {
    height: 3.875rem;
}

.header__logo img {
	width: auto;
	height: 5.2rem;
}

.header__items {
	/* margin-left: 3.75rem; */
	display: flex;
	align-items: center;
}

ul.header__items {
	margin-bottom: 0px;
}

.header__item {
	display: flex;
}

.header__link {
	color: #00518A;
	-webkit-transition: all .175s ease-in-out;
	transition: all .175s ease-in-out;
	font-size: 1rem;
	width: 100%;
	line-height: 3.875rem;
	padding: 0 20px;
	height: 3.875rem;
	font-weight: 600;
	text-decoration: none !important;
}

.header__link:hover {
	color: #ffffff;
	background-color: #0394db;
}

.header__menu {
	margin-left: auto;
	position: relative;
}

.header__menu-btn {
	background-color: #00518b;
	border-radius: 2.375rem;
	height: 2.375rem;
	display: flex;
	align-items: center;
	z-index: 3;
	position: relative;
}

.header__menu-btn:after {
	content: '';
	display: block;
	position: relative;
	margin-left: 1rem;
	margin-right: 1.25rem;
	width: 10px;
	height: 10px;
	border-top: 2px solid white;
	border-right: 2px solid white;
	-webkit-transform-origin: center;
	transform-origin: center;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	will-change: transform;
	top: -2px;
}

.header__menu-text {
	margin-left: 1.25rem;
	line-height: 2.375rem;
	font-size: 0.75rem;
	color: white;
	font-weight: bold;
	cursor: pointer;
}

label.header__menu-text {
	margin-bottom: 0px;
}

.header__menu-list {
	position: absolute;
	width: 100%;
	border-radius: 0.625rem;
	top: 1rem;
	max-width: 11.25rem;
	background-color: white;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.284);
	padding-top: 1.875rem;
	-webkit-transform: translateY(-7%);
	transform: translateY(-7%);
	opacity: 0;
	pointer-events: none;
	z-index: 2;
	-webkit-transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.header__menu-list-item {
	padding: 0.5rem 0;
	text-align: center;
}

.header__menu-list-item:last-child {
	padding-bottom: 1.5rem;
}

.div-width-logout {
	width: 100%;
}


.div-width-logout p{
	font-size: 0.75rem;
}

.header__menu-list-link {
	font-size: 0.75rem;
	line-height: 0.9375rem;
	color: #424b4a;
	-webkit-transition: color all 0.175s ease-in-out;
	transition: color all 0.175s ease-in-out;
	display: block;
	width: 100%;
	text-align: center;
}

.header__menu-list-link:hover {
	color: #1e2222;
}

.header__menu-list.is--active {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	pointer-events: all;
	opacity: 1;
}

.header__side-menu {
	position: absolute;
	width: 15.625rem;
	background-color: white;
	padding: 1.5rem 2.375rem 3.75rem;
	z-index: 5;
	top: 0;
	left: 0;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: all 0.235s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.235s cubic-bezier(0.165, 0.84, 0.44, 1);
	height: 100%;
}

.header__side-menu.is--active {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.header__side-item {
	padding: 1.25rem 0;
}

.header__side-item:first-child {
	padding-top: 2.5rem;
}

.header__side-item:last-child {
	padding-bottom: 2.5rem;
}

.header__side-link {
	color: #424b4a;
}

.header__side-link.is--bold {
	color: #0066b3;
	font-weight: bold;
}

.header__background {
	background-color: black;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	z-index: 4;
	width: 100vw;
	height: 100vh;
	top: 0;
	right: 0;
	-webkit-transition: all 0.235s ease-in-out;
	transition: all 0.235s ease-in-out;
}

.header__background.is--active {
	opacity: 0.6;
	pointer-events: all;
}

.my-account__avatar {
	width: 2.375rem;
	height: 2.375rem;
	border-radius: 2.375rem;
	object-fit: cover;
}

.my-account__avatar img:focus {
	outline: none !important;
}

.my-account__avatar_container {
	width: 2.375rem;
	height: 2.375rem;
	border-radius: 2.375rem;
	object-fit: cover;
}

/* CSS tablet */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
	.header__container {
		padding: 0 2rem;
		height: 3.3125rem;
	}
}

@media only screen and (max-width: 1023px) {
	.header__items {
		margin-left: 0.9375rem;
		margin-top: 1rem;
	}

	.header__link {
		font-size: 0.7rem;
	}

	.header__menu-btn {
		width: 8.75rem;
		height: 2.125rem;
	}

	.my-account__avatar_container {
		width: 2.125rem;
		height: 2.125rem;
		border-radius: 2.125rem;
		object-fit: cover;
	}

	.header__menu-btn img {
		width: 2.125rem;
		height: 2.125rem;
	}

	.header__menu-btn:after {
		width: 8px;
		height: 8px;
		position: absolute;
		right: -8px;
		top: 46%;
		-webkit-transform: translateY(-50%) rotate(135deg);
		transform: translateY(-50%) rotate(135deg);
	}

	.header__menu-text {
		margin-left: 0.5rem;
		font-size: 0.7rem;
		line-height: 0.2rem;
	}
}

/* CSS mobile */

@media only screen and (max-width: 766px) {
	.header__container {
		padding: 0 1rem;
		height: 3rem;
	}

	.header__container.is--desk {
		display: none;
	}

	.header__container.is--mobile {
		display: flex;
	}

	.header__logo {
		margin-left: 1rem;
		height: 3rem;
	}

	.header__logo img {
		height: 100%
	}

	.header__menu-btn {
		width: 6.875rem;
		height: 2.125rem;
	}
}

.header_menu-btn-mobile{
	display: flex;
	grid-gap: 30px;
	gap: 30px;
}

.header_menu-btn-mobile div.dropdown-menu {
	margin-left: -41px!important;
}
.button-my-profile p{
	font-size: 0.75rem;
}

.user-menu-main {
	padding: 10px 30px;
}

.user-menu-main ul {
    margin-top: 0;
    margin-bottom: 0;
}

.user-menu-btn {
    border-radius: 1.5rem;
    height: 44px;
    width: 44px;
    align-items: center;
    z-index: 3;
    position: relative;
    top: 10px;
    padding-top: 3px;
}

.user-menu-img {
	width: 89px;
    height: 89px;
    border-radius: 50%;
    object-fit: cover;
}

.user-menu .dropdown-menu {
	border-radius: 10px;
	box-shadow: 0px 0px 17px -1px rgb(0 0 0 / 45%);
	width: 300px;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
    width: 100%;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 0;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 50px;
    margin: 10px 0;
}

.dropdown-item > a{
    color: #000;
}

.user-menu-logout{
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 5px;
    margin: 10px auto;
    padding: 10px;
}

@media only screen and (max-width: 425px) {
	.user-menu-btn {
		top: 3px !important;
	}
}
.otica-menu-main {
	padding: 10px 10px;
}

.otica-menu-main ul {
	margin-top: 0;
	margin-bottom: 0;
}

.otica-menu .dropdown-menu {
	border-radius: 10px;
	box-shadow: 0px 0px 17px -1px rgb(0 0 0 / 45%);
	width: 400px;
	height: 400px;
}

.otica-menu-main .list-unstyled {
    padding: 15px 0;
    list-style: none;
    width: 100%;
}

.otica-menu-main .list-unstyled:hover {
	background-color: #0394db;
}

.otica-menu-main .list-unstyled:hover .otica-ul-text {
	color: #ffffff !important;
}

.otica-menu-img {
	width: 3.375rem;
	height: 3.375rem;
	border-radius: 3.375rem;
	object-fit: cover;
	margin: 5px;
	border: 2px solid #00518b;
}

.otica-menu-btn {
	height: 3.875rem;
	z-index: 3;
	line-height: 3.875rem;
}

.otica-menu-text {
	color: #00518b;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	line-height: 25px;
}

.otica-ul-text {
	color: rgb(151, 151, 151);
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	line-height: 25px;
}

.ativo .otica-ul-text {
	color: #00518b;
}

.line-h-50 {
	line-height: 50px !important;
}

.otica-menu-main .dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 0;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 1px solid rgb(151, 151, 151);
	border-radius: 50px;
	margin: 10px 0;
}

.otica-menu-nova{
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 5px;
    margin: 10px auto;
    padding: 10px;
}

.otica-menu-content{
    overflow-x: hidden;
    height: 300px;
}

.otica-menu-content-mobile{
	overflow-x: hidden;
    height: 100%;
}

.otica-menu-footer{
    bottom: 0;
    position: fixed;
    width: 100%;
    padding: 0 20px;
    left: 0;
    background-color: #fff;
    border-radius: 10px;
}

.otica-menu-footer-center button{
	margin-bottom: 0.7rem;
	font-size: 0.75rem;
}


@media only screen and (max-width: 425px) {
	.otica-menu-btn {
		height: 3.5rem !important;
		z-index: 3;
		line-height: unset !important;
	}
}

.offcanvas-backdrop.show {
    opacity: .5;
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.x-main .x-welcome {
  margin-top: 3.375rem;
  width: 100%;
  padding: 0 3.75rem;
}
.x-main__first-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .x-main .x-welcome {
    padding: 0 5rem;
    margin-top: 1.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-welcome {
    padding: 0 2.25rem;
  }
}

.x-main .x-welcome__title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #00518b;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 766px) {
  .x-main .x-welcome__title {
    font-size: 0.9375rem;
  }
}

.x-main .x-welcome__square {
  margin-top: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #0394db;
  display: block;
}

@media only screen and (max-width: 766px) {
  .x-main .x-welcome__square {
    font-size: 1rem;
  }
}

.x-main .x-welcome__desc {
  margin-top: 1.75rem;
  font-size: 1rem;
}

.x-main .x-welcome__desc.is--desk {
  display: block;
}

.x-main .x-welcome__desc.is--mobile {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-welcome__desc {
    max-width: 23.4375rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-welcome__desc.is--desk {
    display: none;
  }

  .x-main .x-welcome__desc.is--mobile {
    display: block;
  }
}

.x-main .x-welcome__desc-bold {
  font-weight: bold;
  color: #0394db;
}

.x-main .x-my-essilor {
  background-color: #f3f3f5;
  margin-top: 2.5rem;
  padding: 0 3.75rem;
  width: calc(100% - 7.5rem);
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor {
    width: calc(100% - 4.5rem);
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__title {
    text-align: center !important;
  }

  .x-main .x-my-essilor {
    padding: 0 2.25rem;
  }

  .x-main .x-my-essilor__title:before {
    width: 100% !important;
  }
}

.x-main .x-my-essilor__title {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  color: #0066b3;
  font-weight: bold;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__title {
    font-size: 1rem;
  }
}

.x-main .x-my-essilor__title:before {
  content: "";
  width: 10.625rem;
  height: 0.25rem;
  background-color: #0066b3;
  display: block;
  position: absolute;
  bottom: -1px;
  z-index: 1;
}

.x-main .x-my-essilor__title:after {
  content: "";
  width: 100%;
  height: 0.125rem;
  background-color: #e1e1e2;
  display: block;
  position: absolute;
  bottom: 0;
}

.x-main .x-my-essilor .slick-slide {
  margin: 0 8px;
}

.x-main .x-my-essilor .slick-list {
  margin: 0 -8px;
}

.x-main .x-my-essilor__list {
  overflow: hidden;
  margin-top: 1.625rem;
  padding-bottom: 1.125rem;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__list {
    padding-bottom: 1.0625rem;
  }
}

.x-main .x-my-essilor__list .slick-dots {
  bottom: 2rem;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__list .slick-dots {
    bottom: 1.75rem;
    position: relative;
  }
}

.x-main .x-my-essilor__list .slick-arrow {
  bottom: 2rem;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__list .slick-arrow {
    bottom: 1.75rem;
  }
}

.x-main .x-my-essilor__list .slick-arrow.slick-prev {
  left: calc(50% - 74px);
}

.x-main .x-my-essilor__list .slick-arrow.slick-next {
  right: calc(50% - 74px);
}

.x-main .x-my-essilor__item {
  height: 10.9rem;
  width: 10.9rem;
  padding: 1rem;
  margin: 0 8px 16px 8px;
  border-radius: 1.25rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__item {
    /* height: 11.875rem; */
    padding: 1rem 0.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__item {
    width: 97%;
    height: 16.4375rem;
    padding: 2.125rem 0.875rem;
  }

  .x-main .x-my-essilor__item:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.x-main .x-my-essilor__logo {
  display: -webkit-box;
  display: contents;
  flex-direction: column;
  align-items: center;
  padding: 0.25rem;
}

.x-main .x-my-essilor__logo-text,
.x-main .x-my-essilor__logo-img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__logo-text.is--tracking,
  .x-main .x-my-essilor__logo-text.is--pedidos,
  .x-main .x-my-essilor__logo-text.is--vantagens,
  .x-main .x-my-essilor__logo-img.is--tracking,
  .x-main .x-my-essilor__logo-img.is--pedidos,
  .x-main .x-my-essilor__logo-img.is--vantagens {
    width: 6.875rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__logo-text.is--varilux,
  .x-main .x-my-essilor__logo-img.is--varilux {
    width: 9.0625rem;
  }
}

.x-main .x-my-essilor__button {
  border-radius: 1.25rem;
  width: 9rem;
  height: 2.3125rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background: #00518b;
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-main .x-my-essilor__button {
    width: 9.8rem;
  }

  .x-main .x-welcome {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__button {
    height: 3rem;
  }
}

.x-main .x-my-essilor__button.is--blue {
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
}

.x-main .x-my-essilor__button.is--orange {
  background: #0394db;
  background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
  background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
}

.x-main .x-my-essilor__button.is--pink {
  background: #ec7b9b;
}

.x-main .x-my-essilor__button.is--light-green {
  background: #95cb4e;
}

.x-main .x-my-essilor__button.is--light-blue {
  background: #5ac4bc;
}

.x-main .x-my-essilor__button.is--gray {
  background: #6a757a;
}

@media screen and (max-width: 767px) {
  .x-main .x-my-essilor__button {
    justify-content: space-between;
    padding: 0 20px;
    width: 15.8rem;
  }
}

.x-main .x-my-essilor__button-arrow {
  position: absolute;
  right: 0.5625rem;
}

.x-main .x-my-essilor__button-arrow.btn-is--pink {
  fill: #ec7b9b;
}

.x-main .x-my-essilor__button-arrow.btn-is--light-green {
  fill: #95cb4e;
}

.x-main .x-my-essilor__button-arrow.btn-is--light-blue {
  fill: #5ac4bc;
}

.x-main .x-my-essilor__button-arrow.btn-is--gray {
  fill: #6a757a;
}

.x-main .x-my-essilor__button-text {
  font-weight: bold;
  color: white;
  font-size: 0.75rem;
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__button-text {
    font-size: 0.9375rem;
  }
}

.x-main .x-conecta {
  width: 100%;
  max-width: 23.6875rem;
  padding-left: 2.25rem;
  padding-right: 3.75rem;
  background-color: #ededed;
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-main .x-conecta {
    max-width: none;
    padding: 0 5rem;
    min-height: 500px;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-conecta {
    padding: 0 2.25rem;
    min-height: 500px;
    max-width: none;
  }
}

.x-main .x-conecta__title {
  padding-top: 3.375rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: #00518b;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-conecta__title {
    padding-top: 1.25rem;
  }
}

.x-main .x-conecta__square {
  margin-top: 1.5rem;
  margin-bottom: 1.75rem;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #0394db;
  display: block;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-conecta__square {
    margin-top: 0.875rem;
    margin-bottom: 1.25rem;
  }
}

.x-main .x-conecta__slider {
  width: 100%;
  max-width: 17.375rem;
  /* padding-bottom: 4.875rem; */
  /* overflow: hidden; */
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-conecta__slider {
    max-width: none;
    /* padding-bottom: 3.75rem; */
  }
}

.x-main .x-conecta__slider .slick-dots {
  position: relative;
  bottom: 2.25rem;
}

@media only screen and (max-width: 767px) and (max-width: 1023px) {
  .x-main .x-conecta__slider .slick-dots {
    bottom: 1.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-conecta__slider .slick-dots {
    bottom: 3.625rem;
  }
}

.x-main .x-conecta__slider .slick-arrow {
  bottom: 2.25rem;
  margin-top: 80px;
}

.x-main .x-conecta__slider .slick-arrow.slick-prev {
  left: calc(50% - 4.5rem);
}

.x-main .x-conecta__slider .slick-arrow.slick-next {
  right: calc(50% - 4.5rem);
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-main .x-conecta__slider .slick-arrow {
    bottom: 1.625rem;
    margin-top: 75px;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-conecta__slider .slick-arrow {
    margin-top: 53px;
  }
}

.x-main .x-conecta .slick-slide {
  margin: 0 8px;
}

.x-main .x-conecta .slick-list {
  margin: 0 -8px;
}

.x-main .x-conecta__img {
  display: inline-block;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-conecta__img {
    /* height: 12.0625rem; */
    object-fit: cover;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-conecta__img {
    max-width: none;
    width: 100%;
    height: auto;
  }
}

.x-body .slick-arrow {
  font-size: 0;
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid #00518b;
  border-right: 2px solid #00518b;
  bottom: 0;
}

.x-body .slick-arrow.slick-prev {
  left: 0;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  /* margin-top: 5rem; */
}

/* .x-body .x-my-essilor .slick-arrow.slick-prev {
    margin-top: -80px;
} */

.x-body .slick-arrow.slick-next {
  right: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  /* margin-top: 5rem; */
}

/* .x-body .x-my-essilor .slick-arrow.slick-next {
    margin-top: -80px;
} */

/* @media only screen and (max-width: 766px) {
    .x-body .x-conecta .slick-arrow.slick-prev {
        margin-top: 4.8rem;
    }
    .x-body .x-conecta .slick-arrow.slick-next {
        margin-top: 4.8rem;
    }
    .x-body .x-my-essilor .slick-arrow.slick-prev {
        margin-top: -50px;
    }
    .x-body .x-my-essilor .slick-arrow.slick-next {
        margin-top: -50px;
    }
} */

/* @media only screen and (max-width: 1023px) {
    .x-body .slick-arrow.slick-prev {
        margin-top: 75px;
    }
    .x-body .slick-arrow.slick-next {
        margin-top: 75px;
    }
} */

.x-body .slick-dots {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.x-body .slick-dots li {
  margin: 0 0.875rem;
}

.x-body .slick-dots li button {
  font-size: 0;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #00518b;
  opacity: 0.8;
}

.x-body .slick-dots li.slick-active button {
  width: 0.625rem;
  height: 0.625rem;
}

.homelog {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}

@media only screen and (max-width: 1023px) {
  .x-main {
    flex-direction: column;
  }
}

/* CSS Carousel */

.carousel-essilor {
  width: 280px;
  margin: auto;
}

.carousel-essilor .customarrows {
  width: 277px;
}

.carousel-essilor .customarrows button {
  border: solid #00518b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
}

.carousel-essilor .customarrows .prev {
  float: left;
  margin: 15px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carousel-essilor .customarrows .prev:focus {
  outline: none !important;
}

.carousel-essilor .customarrows .next {
  float: right;
  margin: 15px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel-essilor .customarrows .next:focus {
  outline: none !important;
}

.carousel-essilor .carousel.carousel-slider {
  height: 335px;
}

/* CSS Custom Modulo */

.x-main .module {
  height: 10.9rem;
  width: 10.9rem;
  padding: 1rem;
  margin: 0 8px 16px 8px;
  border-radius: 1.25rem;
  display: inline-flex;
  flex-direction: column;
  -webkit-box-align: start;
  /* align-items: flex-start; */
  justify-content: space-between;
  background-color: white;
}

@media only screen and (max-width: 1023px) {
  .x-main .module {
    /* height: 11.875rem; */
    padding: 1rem 0.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .module {
    width: 75%;
    height: 13.4375rem;
    padding: 1.125rem 0.875rem;
    margin: 0 40px;
  }

  .x-main .module:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.x-main .module_logo {
  display: -webkit-box;
  display: contents;
  flex-direction: column;
  align-items: center;
  padding: 0.25rem;
}

.x-main .module_logo-img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 766px) {
  .x-main .module_logo-img {
    max-height: 70%;
  }
}

.x-main .module_button {
  border-radius: 1.25rem;
  width: 100%;
  height: 2.3125rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background: #00518b;
  padding: 0 1rem;
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-main .module_button {
    width: 9.8rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .module_button {
    height: 3rem;
  }
}

.x-main .module_button-text {
  font-weight: bold;
  color: white;
  font-size: 0.75rem;
  float: left;
}

@media only screen and (max-width: 766px) {
  .x-main .module_button-text {
    font-size: 0.9375rem;
  }
}

.x-main .module_svg {
  float: right;
}

.banner-home {
  margin: 7px 0;
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .banner-home {
    margin: 7px;
  }
}

.swal2-html-container {
  max-height: 20em;
  /* To be adjusted as you like */
  overflow-y: auto;
  width: 100%;
}

.x-main .x-conecta-guides {
  /* display: flex; */
  text-align: center;
  margin-top: 2rem;
  align-content: center;
  max-width: 280px;
  align-items: center;
  padding-bottom: 2rem;
}

.x-main .x-qrcode-home-guides {
  max-width: 125px;
}

.x-main .x-qrcode-title {
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #00518b;
  font-family: "Montserrat", sans-serif;
}

.x-main .x-qrcode-title a {
  color: #0394db;
}
@media only screen and (max-width: 400px) {
  .x-main .module {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .x-main .module_button {
    width: 100%;
    margin: auto;
  }
  .x-main .module_logo-img {
    max-width: 85%;
    max-height: 100%;
    display: block;
    margin: auto;
    padding: 15px;
}
.x-main .module_button-text {
  font-size: smaller;
}
}

.profileInput > .input-password [type='password'],
.profileInput > .crm-input [type="text"],
.profileInput > .crm-input [type="number"],
.profileInput > .crm-input [type="email"],
.profileInput > .crm-input [type="password"],
.profileInput > .crm-input [type="tel"],
.profileInput > .crm-input [type="url"],
.profileInput > .crm-select > .crm-select-input
{
    margin: 1.375rem 0 0 0 !important;
}

.back{
    margin: 40px 0 0 60px;
}

.my-account__main {
    margin: 5% 1rem;
}

.my-account__container {
    display: flex;
    margin: 0 60px;
}

.my-account_button-left {
	width: 50%;
	display: flex;
	padding-top: 30px;
	align-items: center;
	justify-content: center;
}

.my-account_button-link {
	text-decoration: underline;
}

.my-account_button-link:hover {
	text-decoration: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__container {
        flex-direction: column;
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .my-account__container {
        flex-direction: column;
        margin: 0;
    }
}

.my-account__info {
    max-width: 325px;
    margin: 0 60px 0 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__info {
        max-width: 400px;
        align-self: center;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .my-account__info {
        max-width: none;
        max-width: initial;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0;
    }
}

.my-account__paragraph {
    font-size: 1em;
    max-width: 90%;
    line-height: 1.5;
    font-family: "Open Sans";
    margin: 1rem 0 0 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__paragraph {
        max-width: 100%;
        font-size: .875em
    }
}

.my-account__profile {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 65px 0
}

@media screen and (max-width: 767px) {
    .my-account__profile {
        flex-direction: column;
        align-items: initial
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .is-tablet {
        max-width: 767px;
        margin: 0 auto;
        padding: 10px
    }
}

.my-account__photo {
    display: flex;
    align-items: center
}

.my-account__photo-img ,
.my-account__photo-img-preview {
    width: 89px;
    height: 89px;
    border-radius: 50%;
    object-fit: cover;
}

.my-account__photo-change{
    margin-left: 20px;
}

.my-account__photo-change-link {
    text-decoration: underline;
    font-family: "Open Sans";
    font-size: .875em;
    font-weight: 600;
    margin: 0 0 0 20px;
    position: relative;
    cursor:pointer;
}

input[type='file'] {
    display: none
}

@media screen and (max-width: 767px) {
    .my-account__photo-change-link {
        margin: 0 0 0 30px
    }
}

.my-account__photo-detalhes {
    text-decoration: underline;
    color: #424B4A;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100px;
    visibility: visible;
    cursor: pointer;
    pointer-events: all;
    opacity: 1
}

.my-account__photo-detalhes.is-opacity {
    visibility: hidden;
    pointer-events: none;
    cursor: none;
    opacity: 0;
    -webkit-transition: opacity .175 ease-in-out, visibility .175 ease-in-out;
    transition: opacity .175 ease-in-out, visibility .175 ease-in-out
}

.my-account__photo-detalhes-2 {
    text-decoration: underline;
    position: absolute;
    left: -20px;
    bottom: 0;
    width: 100px;
    visibility: visible;
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
    -webkit-transition: opacity .175 ease-in-out, visibility .175 ease-in-out;
    transition: opacity .175 ease-in-out, visibility .175 ease-in-out
}

.my-account__photo-detalhes-2.is-opacity {
    visibility: hidden;
    pointer-events: none;
    cursor: none;
    opacity: 0;
    -webkit-transition: opacity .175 ease-in-out, visibility .175 ease-in-out;
    transition: opacity .175 ease-in-out, visibility .175 ease-in-out
}

.my-account__form {
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: column;
	width: 100%;
}

.my-account__form-item {
    /* padding: 0 0 1.375rem 0; */
	display: flex;
	vertical-align: center;
}

.my-account__form-session {
	padding: 1.975rem 0 0 0;
}

.my-account__form-genre {
	padding: 1.375rem 0 0 0;
	display: flex;
}

@media screen and (max-width: 767px) {
    .my-account__form-item {
        max-width: 320px;
        padding: 10px 0;
        flex-direction: column
    }
}

.my-account__form-cep {
    display: flex;
    align-items: center;
    width: 100%;
    /* margin: 0 0 20px 0; */
    position: relative;
}

@media screen and (max-width: 767px) {
    .my-account__form-cep {
        flex-direction: column
    }
}

.my-account__form-reminder {
    font-size: .75em;
    max-width: 300px;
    /* font-weight: bold; */
    font-family: "Open Sans"
}

.my-account__form-reminder.tp2 {
    font-size: .875em;
    display: block;
    color: #0066B3;
    text-decoration: underline;
	margin: 1.375rem 1rem 0 0;
}

@media screen and (max-width: 767px) {
    .my-account__form-reminder.tp2 {
        align-self: flex-start
    }
}

.my-account__register {
    max-width: 768px
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__register {
        margin: 0 auto
    }
}

@media screen and (max-width: 767px) {
    .my-account__register {
        max-width: 320px;
        margin: 30px auto auto;
        overflow: hidden
    }
}

.register-opacity {
    opacity: 0.2;
}

.my-account__form-access-checkbox {
    margin: 30px 0 20px 0;
    display: flex;
    -webkit-box-align: center;
    /* align-items: center; */
    align-self: flex-start;
    overflow: hidden
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__form-access-checkbox {
        align-self: center
    }
}

.my-account__menu-anchor {
	display: flex;
	flex-direction: column;
	width: 90%;

	margin-top: 20px;
	border: 1px solid #00409A;
	background-color: rgba(0,64,154,.07);
}

.my-account__menu-anchor a {
	text-decoration: none;
	color: #00409A !important;

	padding: 8px 20px;
	border-bottom: 1px solid #00409A;
	margin: 0;

	line-height: 1.5;
    font-family: "Open Sans";
	font-size: 1em;

	cursor: pointer;
}

.my-account__menu-anchor a:hover {
	background-color: #00409A;
    color: #fff !important;
    font-weight: 700;
}

.my-account__menu-anchor a:last-child {
	border: none;
}
.crm-select {
  display: inline-flex;
  position: relative;
}

.crm-select-label {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 0.3125rem;
}

.crm-select-input {
  /* font-size: 0.875rem; */
  color: rgba(66, 75, 74, 0.8);
  font-family: "Open Sans";
  padding: 0.9375rem 3rem 0.9375rem 1.25rem;
  box-shadow: 0 0 0 1px #b5b5b5;
  border: medium none currentColor;
  border: initial;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 1;
  background: transparent;
  width: 100%;
}

.crm-ou {
  display: block;
  margin-top: 13px;
}

.crm-select-input > div {
  border-color: #b5b5b5 !important;
  box-shadow: 0 0 0 1px #b5b5b5 !important;
}

.crm-select-arrow {
  position: absolute;
  z-index: 0;
  right: 45px;
  top: 43px;
}

.crm-select-arrow:before {
  content: "";
  display: block;
  width: 0.545rem;
  height: 0.545rem;
  position: absolute;
  border-bottom: 2px solid #00639d;
  border-right: 2px solid #00639d;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.font-order {
  color: #333333;
  font-family: "Segoe UI";
  font-weight: 400;
}

/* .crm-select-input select:active,
.crm-select-input select:hover {
  outline-color: red;
  color: rgba(66, 75, 74, 0.8);
  box-shadow: 0 0 10px 100px #000 inset;
}
.crm-select-input option:checked {
  outline: none;
  color: #fff;
  background-color: #2684ff !important;
} */

@media only screen and (max-width: 766px) {
  .crm-select {
    width: 100% !important;
  }

  .crm-select-input {
    margin: 1.375rem 0.04rem 0 0.04rem;
  }
}

.radio-button-container {
    display: flex;
    align-items: center;
    margin: 0 0 0 0;
    /* overflow: hidden; */
	height: 22px;
}

.radio-button-label {
    font-size: .75em;
    color: #00518B;
    font-family: "Open Sans";
    padding: 0px 10px;
	/* margin-top: 10px; */
}

.radio-button-container [type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    width: 14px;
    height: 22px;
}

.radio-button-container [type='radio']:checked:after,
.radio-button-container [type='radio']:before {
    content: '';
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: solid 1px #00518B;
}

.radio-button-container [type='radio']:checked:after {
    content: '';
    background-color: #00518B;
    display: inline-block;
    width: 10px;
    left: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 10px;
    border-radius: 50%;
    position: absolute
}
.check-box-container {
    display: flex;
    align-items: center;
    margin: 0 0 0 0;
    overflow: hidden;
	/* height: 22px; */
}

.check-box-label {
    font-size: .75em;
    color: #00518B;
    font-family: "Open Sans";
    padding: 0px 10px;
	/* margin-top: 10px; */
}

@media screen and (max-width: 767px) {
    .check-box-label {
        -webkit-transform: translate3d(23px, 2px, 0);
        transform: translate3d(23px, 2px, 0);
    }
}

.check-box-container [type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    width: 14px;
    height: 22px;
}

.check-box-container [type='checkbox']:checked:after,
.check-box-container [type='checkbox']:before {
    content: '';
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 20%;
    border: solid 1px #00518B;
}

.check-box-container [type='checkbox']:checked:after {
    content: '';
    background-color: #00518B;
    display: inline-block;
    width: 10px;
    left: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 10px;
    border-radius: 20%;
    position: absolute
}
.AccordionSummary {
	width: 100%;
}

.MuiAccordionDetails-root {
	display: block !important;
	padding: 8px 16px 16px;
}

.btnRemover {
	top: 29px;
	margin-top: 30px;
	font-size: 16px;
	color: #000;
}

.btnRemover > small {
	font-size: small;
}

.btnRemover:hover {
	text-decoration:none; 
}

.oticaUp .image-upload{
	position: unset;
    width: unset;
}
.upload-content {
  width: 100%;
  padding: 0 20px;
}

.upload-content > .fileContainer {
  background: transparent !important;
  box-shadow: none !important;
  position: relative;
  border: 1px dashed #ccc;
  border-radius: 10px;
  padding: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  width: 100%;
  height: 85px;
}

.upload-content-paragraph {
  font-size: 0.8rem;
  font-family: "Montserrat", sans-serif;
  /* font-weight: bold; */
  color: #424b4a;
  margin: 0 0 0.5rem 0;
  text-align: left;
}

.btn-upload {
  background: transparent !important;
  border-radius: 30px;
  color: #0066b3 !important;
  border-radius: 10px !important;
  width: 100%;
  height: 100px;
}

.picturesWrapper {
  width: 100%;
}

.pictureContent {
  flex-wrap: wrap;
  background: transparent !important;
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  width: 50%;
  height: 85px;
}

.pictureContainer {
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  position: relative;
}

.pictureFile {
  width: auto;
  height: 80%;
  border: solid 1px #ddd;
}

.removeFile {
  position: absolute;
  top: 0%;
  color: #fff;
  background: #0394db;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  width: 20px;
  height: 20px;
  z-index: 999;
  margin-left: 100%;
}

.main-otica .image-upload {
  position: absolute;
  width: 25%;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.tolltip {
  color: #0394db;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}

.oticaMedia .tolltip {
  color: #0394db;
  position: absolute;
  right: 0;
  top: 20%;
  z-index: 999;
}

.otica-menu-img {
  width: 3.375rem;
  height: 3.375rem;
  border-radius: 3.375rem;
  object-fit: cover;
  margin: 5px;
  border: 2px solid #00518b;
}

.main-otica .otica__photo-img,
.main-otica .otica__photo-img-preview,
#main-body .image-upload .otica__photo-img,
#main-body .image-upload .otica__photo-img-preview {
  width: 89px;
  height: 89px;
  border-radius: 50%;
  object-fit: cover;
  margin: 5px;
  border: 2px solid #00518b;
}

.main-otica .otica__photo-change-link,
#main-body .image-upload .otica__photo-change-link {
  text-decoration: underline;
  font-family: "Open Sans";
  font-size: 0.875em;
  font-weight: 600;
  margin: 0 0 0 20px;
  position: relative;
  cursor: pointer;
}

@media only screen and (min-width: 1025px) {
  .oticaCrm .image-upload {
    position: absolute;
    width: 18%;
  }
}

/* Basic CSS */


.main-tracking .crm-select-input {
	margin: 1.375rem 0 0 0 !important;
}
#main-body-tracking{
	width: 90%;
    min-height: 73vh;
    margin: 0 auto;
    margin-top: 1.75rem;
    position: relative;
    
}
#main-tabela{
	width: 80%;
	
	}
@media only screen and (max-width: 1220px) {
	
	#main-body-tracking {
			width: 100% !important;
		}
	}
.main-tracking .crm-input [type="text"],
.main-tracking .crm-input [type="number"],
.main-tracking .crm-input [type="email"],
.main-tracking .crm-input [type="password"],
.main-tracking .crm-input [type="tel"],
.main-tracking .crm-input [type="url"] {
	margin: 1.375rem 0rem 0 0 !important;
}

.main-tracking .crm-select-arrow {
	position: absolute;
	z-index: 0;
	right: 30px;
	top: 43px;
}



.tracking-filters {
	padding: 1.75rem 0;
	position: relative;
}

.tracking-filters-row {
	display: flex;
	align-items: flex-end;
}

.tracking-filters-row:not(:first-child) {
	margin-top: 1.75rem;
}

.tracking-filters-box {
	position: relative;
	display: flex;
	flex-direction: column;
}

.tracking-filters-box.lab {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.tracking-filters-box.cnpj {
	position: relative;
	display: flex;
	flex-direction: column;
}

.tracking-filters-label {
	margin-bottom: 1.25rem;
	font-size: 1rem;
	color: #424b4a;
	position: relative;
	text-align: center;
}

.tracking-filters-text {
	font-weight: bold;
	font-size: 1rem;
	color: #00518b;
	text-transform: uppercase;
	line-height: 65px;
}

.Opacity {
	opacity: 0.2;
}

.react-datepicker-wrapper {
	display: block !important;
}

.react-datepicker-popper {
	z-index: 100 !important;
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1210px) {

	.x-main .tracking-filters-row {
		justify-content: space-between;
	}

	.tracking-filters-text {
		width: unset;
		text-align: center;
		margin: 0;
	}
}

/* CSS for mobile small devices */

@media only screen and (max-width: 766px) {
	#main-tabela {
		width: 75%;
	}
	.tracking-filters-row {
		flex-direction: column;
		align-items: flex-start;
	}

	.tracking-filters-box {
		width: 100%;
		padding-top: 1.75rem;
	}

	.tracking-filters-box.cnpj {
		margin-left: 0px;
	}

	.tracking-filters-row:not(:first-child) {
		margin-top: unset;
	}

	.tracking-filters-text {
		margin: 0;
		margin-top: 1.75rem;
		width: 100%;
		line-height: 15px;
	}
}



.main-tracking .x-web-ordering-tracking__main {
	background: #f3f3f5;
	display: flex;
	flex-direction: column;
	position: relative;
}



@media only screen and (min-width: 1210px) {
	.main-tracking .x-web-ordering-tracking__main {
		width: 370px;
	}
}


@media only screen and (max-width: 766px) {
	
	.x-my-account__back {
		margin-top: 40px;
	}
}

@media only screen and (max-width: 1210px) and (min-width: 767px) {
	

	.x-web-ordering-tracking__main.active-arrow {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
		transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
		z-index: 500;
	}

	.x-my-account__back {
		margin-top: 40px;
	}
}


@media only screen and (max-width: 766px) {

	.x-web-ordering-tracking__main.active-arrow {
		width: 90vw;
		right: 0;
		position: absolute;
		height: 100%;
		z-index: 500;
		-webkit-transition: all 0.175s ease-in-out;
		transition: all 0.175s ease-in-out;
	}

	.x-web-ordering-tracking__main {
		width: 85px;
		overflow: hidden;
	}

}

@media only screen and (max-width: 1210px) and (min-width: 767px) {

	.x-web-ordering-tracking__main.active-arrow {
		width: 500px;
		right: 0;
		position: absolute;
		height: 100%;
		z-index: 500;
		-webkit-transition: all 0.175s ease-in-out;
		transition: all 0.175s ease-in-out;
	}

	.x-web-ordering-tracking__main {
		width: 40px;
		overflow: hidden;
		-webkit-transition: all 0.175s ease-in-out;
		transition: all 0.175s ease-in-out;
	}


}


.logolabTracking{
		/* background-color: rgb(255, 255, 255); */
		/* position: absolute; */
		/* width: 25%; */
		/* margin-left: -2rem; */
		text-align: center;
		right: 0;
		top: 200px;
		background: rgb(243, 243, 245);
		background: -webkit-gradient(linear, left top, right top, color-stop(5%, rgba(243, 243, 245, 1)), color-stop(15%, rgba(255, 255, 255, 1)), color-stop(85%, rgba(255, 255, 255, 1)), color-stop(95%, rgba(243, 243, 245, 1)));
		background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
	
}
@media only screen and (max-width: 440px) {
    #main-tabela {
        width: 85%;
        padding-left: 20px;
    }
}
.tracking-filters-input,
.tracking-filters-date,
.tracking-filters-option,
.tracking-filters-select {
  font-size: 1rem;
  color: #707070;
}

.tracking-filters-select {
  width: 100%;
  border-radius: 6px;
  padding: 1rem 1.375rem;
  position: relative;
  border: 1px solid #b5b5b5;
  background: transparent;
  z-index: 1;
  height: 58px;
}

.tracking-filters-select-cnpj {
  width: 100%;
  border-radius: 6px;
  padding: 1rem 1.375rem;
  position: relative;
  border: 1px solid #b5b5b5;
  background: transparent;
  z-index: 1;
  height: 58px;
  font-size: 1rem;
  color: #707070;
}

.tracking-filters-select-arrow {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid #00518b;
  border-right: 2px solid #00518b;
  bottom: 1.55rem;
  right: 1.25rem;
  z-index: 1;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.tracking-filters-select-option {
  padding: 0.625rem 0;
}

.tracking-filters-input {
  height: 3.125rem;
  border-radius: 0.375rem;
  border: 1px solid #b5b5b5;
  padding: 0.875rem 1rem;
  width: 100%;
}

.tracking-filters-input.cnpj {
  border-radius: 0.375rem;
  border: 1px solid #b5b5b5;
  width: 100%;
  padding: 1rem 1.375rem;
  height: 58px;
}

.tracking-filters-date-container {
  display: flex;
}

.tracking-filters-date {
  border: 1px solid #b5b5b5;
  padding: 0.875rem 1rem;
  width: 100%;
  text-align: center;
}

.tracking-filters-box.is--date {
  margin-left: auto;
}

.tracking-filters-date.start-date {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tracking-filters-date.end-date {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.cnpj .tooltip-shape:before {
  content: "i";
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.625rem;
  color: #0394db;
  z-index: 15;
  border: 1px solid #0394db;
  border-radius: 50%;
  width: 0.9375rem;
  height: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  right: 0;
  top: 4.2rem;
  opacity: 1 !important;
}

.tracking-search-button {
  border-radius: 1.875rem;
  width: 20.1875rem;
  height: 3.125rem;
  padding: 0 0.9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  margin: 0 auto;
  margin-top: 1.75rem;
  -webkit-transition: all 0.155s ease-in;
  transition: all 0.155s ease-in;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tracking-search-button:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.284);
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .tracking-filters-input,
  .tracking-filters-date {
    width: 9rem;
  }

  .tracking-filters-select .cnpj {
    width: 12rem;
  }

  .main-tracking .tooltiptext {
    left: unset;
    right: 92px;
  }
}

/* CSS for mobile small devices */
@media only screen and (max-width: 300px) {
  #label-qual-laboratorio {
    -webkit-transform: translate(10px, 2px);
            transform: translate(10px, 2px);
  }
}
@media only screen and (max-width: 766px) {
  .tracking-filters-input,
  .tracking-filters-input.cnpj,
  .tracking-filters-date {
    width: 100%;
  }

  .is__shape--position {
    position: absolute;
  }

  .tracking-filters-box.is--date {
    width: 100%;
    margin-left: unset;
  }

  .tracking-search-button {
    width: 100%;
  }

  .main-tracking .tooltip-shape:before {
    right: 0;
  }

  .main-tracking .tooltiptext {
    top: 99px;
    right: 156px;
    left: unset;
  }

  .main-tracking .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 82%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}

.main-tabela-opacity {
  opacity: 0.2;
}

.tracking-pedidos-thead-th {
  padding: 30px 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 90%;
}

.tracking-pedidos-thead-th:first-child {
  border-radius: 10px 0 0 0;
}

.tracking-pedidos-thead-th:last-child {
  border-radius: 0 10px 0 0;
}

.tracking-main-table-body:nth-of-type(odd) {
  background-color: #e5edf3;
}

.tracking-main-table-body:nth-of-type(even) {
  background-color: #fafaff;
}

.pedidos.table tbody + tbody {
  border-top: 0px;
}

.pedidos.table thead tr th,
.table tbody tr td {
  border: none;
}

.pedidos.table td,
.table th {
  padding: 1.5rem 0.75rem;
  vertical-align: middle;
}

.tracking-main-table-status {
  position: relative;
}

.tracking-main-table-status-button {
  display: block;
  margin: 0;
  width: calc(100% - 22px);
  padding: 0.625rem 0;
  border-radius: 6px;
  color: white;
  text-align: center;
  font-size: 0.875rem;
  font-weight: bold;
}

.tracking-main-table-status-button.is--green {
  background-color: #098c6c;
}

.tracking-main-table-status-button.is--red {
  background-color: #ff0000;
}

.tracking-main-table-status-button.is--blue {
  background-color: #00518b;
}

.tracking-main-table-status-arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  right: 5px;
  bottom: 15px;
  border-right: 2px solid #00518b;
  border-bottom: 2px solid #00518b;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}

.tracking-main-table-status-arrow.inverse {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}

.tracking-main-table-row-details {
  background-color: #fff;
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
  /* border-bottom: 1px solid #E5EDF3; */
}

.tracking-main-table-row-details-td {
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}

.tracking-order-detail {
  display: flex;
  flex-direction: row;
}

.tracking-order-detail-column.direita {
  width: calc(60% - 20px);
  margin-left: 20px;
}

.detalhes.table {
  width: 100%;
  color: #212529;
  border: 0px;
  text-align: left;
}

.detalhes.table thead tr th,
.detalhes.table tbody tr td {
  border: none;
}

.detalhes.table td,
.detalhes.table th {
  border-top: 0px;
}

.detalhes.table tbody + tbody {
  border: 0px;
}

.detalhes.table thead th {
  border-bottom: 0px;
  /* background: #EDF4FA; */
  text-transform: uppercase;
  color: #00518b;
  font-family: "Montserrat", sans-serif;
  font-size: 90%;
}

.detalhes.table td,
.table th {
  padding: 0.5rem 0.75rem;
  vertical-align: middle;
  color: #585858;
}

.tracking-order-detail-column.esquerda {
  width: 40%;
}

.tracking-order-detail-column.esquerda .card-pedido {
  border-radius: 12px;
  background-color: #edf4fa;
  padding: 20px;
}

.tracking-order-detail-column.esquerda h3 {
  text-transform: uppercase;
  color: #00518b;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

/* .tracking-order-detail-column.esquerda svg {
    margin-bottom: 15px;
} */

.tracking-order-detail-column.esquerda p {
  text-align: left;
}

.tracking-detail-card-title {
  margin-top: 7px;
  font-weight: bold;
  color: #585858;
}

.tracking-detail-card-p {
  color: #707070;
}

.pedidos .hide-table-row {
  display: none;
}

.tracking-detail-card-title.mobile,
.tracking-detail-card-p.mobile,
.tracking-detail-card-title.tablet,
.tracking-detail-card-p.tablet {
  display: none;
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .tracking-order-detail {
    display: flex;
    flex-direction: column;
  }

  .tracking-order-detail-column.esquerda {
    width: 100%;
  }

  .tracking-order-detail-column.direita {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .hide-table-column-tablet {
    display: none;
  }

  .tracking-detail-card-title.tablet,
  .tracking-detail-card-p.tablet {
    display: grid;
  }
}

/* CSS for mobile small devices */

@media only screen and (max-width: 766px) {
  .tracking-pedidos-thead-th {
    padding: 20px 10px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 90%;
  }

  .tracking-pedidos-thead-th:nth-child(3n) {
    border-radius: 0 10px 0 0;
  }

  .pedidos.table td,
  .table th {
    padding: 0.5rem;
    vertical-align: middle;
  }

  .tracking-main-table-status {
    display: inline;
  }

  .tracking-main-table-status-button {
    width: 100%;
  }

  .tracking-main-table-status-arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    right: 0px;
    bottom: 0px;
    top: 50px;
    border-right: 2px solid #00518b;
    border-bottom: 2px solid #00518b;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .tracking-order-detail {
    display: flex;
    flex-direction: column;
  }

  .tracking-order-detail-column.esquerda {
    width: 100%;
  }

  .tracking-order-detail-column.direita {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .pedidos .hide-table-column {
    display: none;
  }

  .pedidos .hide-table-row {
    display: table-row;
  }

  .pedidos .status-row {
    padding-bottom: 30px !important;
  }

  .detalhes.table {
    font-size: 80%;
  }

  .tracking-detail-card-title.mobile,
  .tracking-detail-card-p.mobile {
    display: grid;
  }
}

/* https://www.florin-pop.com/blog/2019/04/how-to-create-a-timeline-with-react/ */
.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px;
}

.timeline-container::after {
  background-color: #00518b;
  content: "";
  position: absolute;
  left: calc(10% - 12px);
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  padding-left: 102px;
  padding-right: 0;
  position: relative;
  margin: 10px 0;
  width: 100%;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 500px;
  max-width: 100%;
  text-align: right;
}

.timeline-item-content::after {
  content: " ";
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item .timeline-item-content {
  text-align: left;
  align-items: flex-start;
  min-height: 70px;
}

.timeline-item .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item .timeline-item-content .tag {
  left: auto;
  right: 5px;
  color: #00518b;
}

.timeline-item-content time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 13px;
  line-height: 24px;
}

.timeline-item-content a {
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: " ►";
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #00518b;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}

.timeline-item:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(10% - -20px);
  left: calc(10% - 11px);
  background-color: #fff;
  border: 3px solid #00518b;
  border-radius: 50%;
  z-index: 1;
}
@media only screen and (min-width: 1800px) {
  .timeline-item {
    display: flex;
    align-self: flex-start;
    justify-content: space-evenly !important;
    }
}

@media only screen and (max-width: 1024px) {
  .timeline-item-content {
    max-width: 100%;
    width: 360px;
  }
}

@media only screen and (min-width: 464px) and (max-width: 768px) {
  .timeline-item-content {
    width: 90%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 463px) {
  .timeline-item-content {
    width: 90%;
  }
}

@media only screen and (min-width: 150px) and (max-width: 424px) {
}

@media only screen and (min-width: 550px) and (max-width: 765px) {
  .timeline-container::after {
    left: calc(10% - 17px);
  }
}
@media only screen and (max-width: 767px) {
  .timeline-container::after {
    left: calc(10% - 2px);
  }
  .timeline-item:after {
    top: calc(10% - -28px);
    left: calc(10% - 11px);
  }

  .timeline-item-content,
  .timeline-item .timeline-item-content {
    padding: 15px 10px;
    text-align: center;
    align-items: center;
    width: 96%;
    min-height: 90px;
  }

  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }

  .timeline-item-content time {
    margin-top: 20px;
  }

  .timeline-item-content a {
    text-decoration: underline;
  }

  .timeline-item-content a::after {
    display: none;
  }
}

.pagination .previous.disabled {
  display: none;
}

.pagination .next.disabled {
  display: none;
}

.pagination-container {
  text-align: center;
}

.pagination {
  margin: 15px auto;
  display: inline-flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #0066b3;
  border-color: #0066b3;
  color: #fff;
}

.pagination > li > a {
  /* border: 1px solid #0066b3 ; */
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #0066b3;
  border-color: #0066b3;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #0066b3;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.pdf-order {
	font-family: "Montserrat", sans-serif;
}

.pdf-order-header {
	border-bottom: 1px solid #d8d8da;
}

.pdf-order-dados,
.pdf-order-prescricao,
.pdf-order-armacao {
	padding-bottom: 5px;
}

.pdf-order-footer{
    border-top: 1px solid #d8d8da;
    padding-top: 10px;
    margin-top: 20px;
    padding-bottom: 20px;
}

.pdf-order-footer > div > p {
	font-size: 0.775rem;
    margin-bottom: 2px;
}

.pdf-order-footer > div > p > small {
	color: #0066B3;
    font-weight: bold;
}

.pdf-title {
	color: #00518b !important;
	font-size: 0.875rem;
	font-weight: bold;
	font-family: "Montserrat", sans-serif;
	padding: 1rem 0.625rem 10px 0;
	display: block;
	cursor: default;
	text-decoration: none !important;
	border-bottom: 1px solid;
}

.pdf-subtitle {
	color: #000;
	font-size: 0.875rem;
}

.pdf-subtitle-bold {
	margin: 0.625rem 0 0.125rem 0;
	font-size: 0.875rem;
	font-weight: bold;
}

.pdf-span {
	font-size: 0.600rem;
	border-bottom: 1px solid #d8d8da;
	margin: 0 0.5px;
	padding: 3px 0;
}

.pdf-span p {
	margin-bottom: 0.5px !important;
	color: #000 !important;
}

.pdf-button{
    color: #0066B3;
    font-family: "Montserrat", sans-serif;
    font-size: 0.9rem;
    font-weight: bold;
}

.pdf-button:hover{
    text-decoration: underline;
}
.pdf-order {
  font-family: "Montserrat", sans-serif;
}

.pdf-order-header {
  border-bottom: 1px solid #d8d8da;
}

.pdf-order-dados,
.pdf-order-prescricao,
.pdf-order-armacao {
  padding-bottom: 5px;
}

.pdf-order-footer {
  border-top: 1px solid #d8d8da;
  padding-top: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.pdf-order-footer > div > p {
  font-size: 0.775rem;
  margin-bottom: 2px;
}

.pdf-order-footer > div > p > small {
  color: #0066b3;
  font-weight: bold;
}

.pdf-title {
  color: #00518b !important;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0.625rem 10px 0;
  display: block;
  cursor: default;
  text-decoration: none !important;
  border-bottom: 1px solid;
}

.pdf-subtitle {
  color: #000;
  font-size: 0.875rem;
}

.pdf-subtitle-bold {
  margin: 0.625rem 0 0.125rem 0;
  font-size: 0.875rem;
  font-weight: bold;
}

.pdf-span {
  font-size: 0.6rem;
  border-bottom: 1px solid #d8d8da;
  margin: 0 0.5px;
  padding: 3px 0;
}

.pdf-span p {
  margin-bottom: 0.5px !important;
  color: #000 !important;
}

.pdf-order-dados-adicionais {
  font-size: 0.6rem;
  /* border-bottom: 1px solid #d8d8da; */
  margin: 0 0.5px;
  padding: 3px 0;
}

.pdf-order-dados-adicionais p {
  margin-bottom: 0.5px !important;
  color: #000 !important;
}

.pdf-button {
  color: #0066b3;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
}

.pdf-button:hover {
  text-decoration: underline;
}

.resumo-lateral_summary {
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  padding: 38px 6px 6.75rem 2rem;
  height: 100%;
  overflow: hidden;
}

.resumo-lateral_summary.confirm {
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  padding: 2.9375rem 3.75rem 6.75rem 2.75rem;
  -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
  transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
}

.resumo-lateral_summary--intro {
  display: flex;
  flex-direction: column;
}

.resumo-lateral_summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.resumo-lateral_summary--paragraph {
  color: #424b4a;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
}

.resumo-lateral_summary--icon {
  align-self: center;
  margin: 0 0 2.25rem 0;
}

.resumo-lateral_main {
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 30%;
  max-width: 400px;
  margin-left: 15px;
}
.resumo-lateral_main .resumo-lateral_summary--arrow {
  display: inline-table;
  left: 10px;
  top: 30px;
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  border-bottom: 3px solid #0394db;
  border-left: 3px solid #0394db;
  -webkit-transform: translateX(0) rotate(45deg);
          transform: translateX(0) rotate(45deg);
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}


span.resumo-lateral_summary--arrow.active-arrow {
  -webkit-transform: translateX(0) rotate(223deg);
          transform: translateX(0) rotate(223deg);
}
@media only screen and (min-width: 1150px) {
  .span.resumo-lateral_summary--arrow {
    display: none;
  }
  .resumo-lateral_summary--arrowContainer {
    display: none;
  }
}

@media only screen and (max-width: 1150px) {
  .resumo-lateral_summary--container {
    display: none;
  }
  .resumo-lateral_summary--container.active-arrow {
    display: inline-block;
  }
  .resumo-lateral_main.active-arrow {
    width: 450px;
    right: 0;
    position: absolute;
    height: 100vh;
    z-index: 500;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin-right: 0px !important;
  }

  .resumo-lateral_main {

    width: 31px;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
  .resumo-lateral_summary--arrowContainer {
    height: 100%;
    width: 34px;
    position: absolute;
    -webkit-transform: translate(-29px, -37px);
            transform: translate(-29px, -37px);
  }
}


@media only screen and (max-width: 800px) {
  .resumo-lateral_main {
    margin: 0px 0px 0 20px;
    width: 31px;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    height: 100vh;
  }
}

@media only screen and (max-width: 500px) {
  .resumo-lateral_main.active-arrow {
    width: 100%;
    right: 0;
    position: absolute;
    height: 100vh;
    z-index: 500;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin-right: 0px !important;
  }
  .resumo-lateral_summary--container.active-arrow {
    display: inline-block;
}
}

#reset-padlock-success{
  position: relative;
}
#reset-padlock{
  padding-bottom:40px ;
}
.p-check-new-password{
  font-size: 14px;
  line-height: 26px;
  color: #424B4A;
}
.passwordRoles{
  background: #f3f3f394;
  padding: 10px;
  margin: 10px 22px 10px 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
#btn_enviarNewPassaword{
  margin-top: 30px;
}
.newPassword__title {
    font-family: "Montserrat";
    font-weight: 800;
    color: #00409a;
    text-align: center;
    padding: 20px 0 0 0px;
    margin: 0px;
}
.newPassword-signin__text{
  text-align: center;
  max-width: 100%;
  color: #0066b3;
  font-family: "Open Sans";
  font-size: 1em;
  margin-bottom: 37px;
}
.newPassword-text {
 
  max-width: 95%;
  font-family: "Open Sans", sans-serif;
  font-size: 16px; 
  margin-bottom: 0px; 
  color: #333; 
  background-color: #f9f9f9; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  padding: 15px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.error-message {
  background-color: #f8d7da;
  color: #721c24; 
  padding: 10px;
  border: 1px solid #f5c6cb; 
  border-radius: 4px;
  margin-top: 10px;
}
.reset-banner {
  width: 70%;
  display: flex;
}

.reset-banner__wallpapper_1 {
  background-image: url(/static/media/personagem02.fd0e9fee.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 460px;
  width: 100%;
  height: 100%;
}

.reset-banner__wallpapper_2 {
  background-image: url(/static/media/personagem02.fd0e9fee.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 460px;
  width: 100%;
  height: 100%;
}

.reset-banner__wallpapper_3 {
  background-image: url(/static/media/personagem03.5168ae91.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 460px;
  width: 100%;
  height: 100%;
}

.reset-pass__alert-modal {
  width: 40rem;
  height: 50rem;
}

.swal2-html-container {
  max-height: 30rem;
  text-align: center;
}

.reset-pass__alert-modal-img {
  border: ghostwhite;
  border-style: groove;
  border-radius: 10px;
}

.reset-pass__alert-modal-link {
  color: #0066b3;
  text-decoration: underline !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__wallpapper {
    height: auto;
    height: initial;
    background-size: 392px;
    position: relative;
    -webkit-transform: translate3d(30%, 20%, 0);
    transform: translate3d(30%, 20%, 0);
    z-index: 0;
  }
}

.reset-banner__color {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__color {
    width: 80%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .reset-banner {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.reset-banner__button-mobile {
  display: none;
}

@media (max-width: 767px) {
  .reset-banner__button-mobile {
    display: block;
    width: 100%;
    background: #0066b3;
    color: #fff;
    height: 50px;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    font-family: Open Sans;
    border: none;
    font-weight: 800;
    border-radius: 25px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

.reset-banner__logo {
  padding: 27px 0 68px;
}

@media (max-width: 767px) {
  .reset-banner__logo {
    padding: 0 0 68px;
    position: relative;
    z-index: 1;
    margin: 0 60%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__logo {
    padding: 0 0 68px;
    margin: auto 20%;
    width: 0;
    display: flex;
    align-self: flex-end;
  }
}

.reset-banner__logo-image {
  max-height: 67px;
}

.reset-banner__title {
  font-size: 50px;
  font-family: Montserrat;
  font-weight: 800 !important;
  position: relative;
  padding-bottom: 41px;
  color: #00409a;
  line-height: 60px;
  max-width: 100%;
}

@media (max-width: 767px) {
  .reset-banner__title {
    font-size: 32px;
    line-height: 37px;
  }
}

.reset-banner__title:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #0394db;
  position: absolute;
  bottom: 0;
}

@media (max-width: 767px) {
  .reset-banner__title-text {
    width: 100%;
  }
}

.reset-banner__paragraph {
  font-size: 16px;
  line-height: 26px;
  color: #424b4a;
  max-width: 100%;
  padding-top: 34px;
}

@media (max-width: 767px) {
  .reset-banner__paragraph {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__paragraph {
    max-width: 100%;
  }
}

.reset-signin__text {
  text-align: center;
  max-width: 100%;
  color: #0066b3;
  font-family: "Open Sans";
  font-size: 1em;
  margin-bottom: 37px;
  margin-top: 27px;
}

.reset-signin__text.tp-2 {
  color: #424b4a;
}

.reset-signin__text.tp-2 > a {
  color: #0066b3;
}

.reset-signin__text.tp-3 > a {
  color: #0066b3;
  font-size: 0.8185em;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto 45px auto;
    background: #fff;
    padding-bottom: 40px;
    margin-left: 0;
    width: 90%;
    box-shadow: 1px 4px 6px -2px rgba(186, 186, 186, 0.56);
  }
}

@media (max-width: 767px) {
  .reset-signin {
    position: fixed;
    background: white;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    left: 0;
    /* overflow: hidden; */
    width: 100%;
    height: 105%;
  }

  .reset-signin.is--active {
    display: block;
    z-index: 2;
    overflow-y: scroll;
  }
}

.reset-signin__container {
  width: 349px;
}

.reset-signin__container.js--padlock {
  position: absolute;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.reset-signin__container.js--email {
  position: relative;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.reset-signin__container.is--disable {
  pointer-events: none;
  cursor: none;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.175s ease-in-out;
  transition: opacity 0.175s ease-in-out;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-signin__container {
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .reset-signin__container {
    width: auto;
  }
}

.reset-signin__padlock {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFkAAABZCAMAAABi1XidAAAA/FBMVEUAAAAAAAAAAP8AAIAAgIAAgP8AVaoAQIAAQL8AM5kAZpkAZswAVaoASbYAbbYAYL8AVaoAVcYATbMAZrMAXaIAXbkAVaoAar8AYrEAYsQAW7YAVaoAVbsAZrsAYK8AYL8AWrQAY7gAYLcAaLcAZLIAZ7gAZbUAY7UAZbYAZLMAY7MAZbUAYrQAZrMAZLEAZ7QAZbQAZLMAZLQAZrQAZrMAZbMAZbIAZ7QAZbMAZbMAZbMAZbQAZbIAZLIAZbIAZrMAZrQAZbMAZbIAZbIAZbIAZLIAZbIAZbMAZbMAZrMAZrIAZbMAZrMAZrMAZbIAZbIAZrIAZrIAZrMAZrO9iy1CAAAAU3RSTlMAAQECAgIDBAQFBQUGBwcICQkKCgsLDAwNDQ4PDw8QEBESICAhLzA+P0BDTE5QXFxqa3p6f4OIiImQl5ifpaantLW2wMPExM/S09/g4eLv8PDx8SkqoZoAAAO8SURBVFjDtdlte9o2FAZgBZJARwA3zKnrBqOnYZRtbKmbbGHtCk26aWSDZeH5//9lHwwN+PXIhfPR4rovcfTiY0mp4jhsNNunrucHCHzPPW03G4dqB3HU9pEMv330lazzClnxyimNH+ewa/y4jOtqFId2be2GB2l4DRu4rSEP3Ra79XPYhV+XwY6GbWhH4FY8lAnvoHBKdFEuugWT5FlPKvXfTsycNOb9MHrQe5YHN6UpHkz4FPObKNknObC0v2t3sbYvAQDNLPhE2OPhjORyGmmDq3uSnACAzlg0dSn8QC7H/acHI0PS9AHo1Ild7cnh+8H2syuStwDQq6bIvjDH81X/tmK0JMcA4CfhU+HoTcn7fvJxSHIEAKeJ0RPCQ3I5SGsYkwYAEBvFSiCUzepfJ7O0WHU6qGzJHekKIRf99KaQnAIAOlu7hXTtheSHrKElH6K1uLmDnEl3iztymNM2AgCclegy5mRm25i8infaFe+V5CKzbfRlcN0vNZC2kE2evBoDfWjd5SJ53bieHsHO5SCCa9i5jFr0rt6DHL3Lz/cgnyul1BH2IKOqlGrvRW4qpby9yJ5SB3ovsj5QdexFRl38MrGVT1RLir69NcaQDyYrZlHjqhprSdfJcEZxvI/Wimw76s9pERMArnDSTcnlZV/05z6QvAQ8YQFDciAdkDF5B/iyLXRE3tlMIAKB0kJ5LJcNCWhxn23lQJbnErIvmxsl5DPZfC4hd2RrsITsyL56SshN1diF/O7X13G5IasJCuQJ+S4u12TvlHx5QvLnmKwPZO/BXHlCMozn2RN+uObJCRiGRFMpVf06OQnDkLoirJFi8i0/5sAwpC+t62LyI9d0GgxDOtJaNCaHXNGpMAxZk9bP8TyHJD+9zoBhSHHNnxjBkOTsYzq8IR9p+7kRRm/pELly8edgyqwLH7PgTblWZj6/ecyA8eeTXJjp1JXy3ZuMX/+xIVf1Dvdn/XlDLlotVnJ7S1bdncldtS3nH3xZyLoek/PzYSE7Ki7nljRy2VdJOe+8bpR9FLMdvapS6u+YnJfqITkTJlmpC/If+bnogrwRwN8opYK/yN/ih3atvJMpzm6K4pfr6+vf/yX/u7A4f57afKf8ZHVQfLWUup8HtkfFo9z48Yfvo7jIvELwUSZ8yS1TpwTcEV5a2V7V9MRXV1XX6t7q26rNHZ48257tPV79THY/WC9x91h1ikqcwKmUvTCtNF9k9Vy/aJZm18vSTW6vgdtQO4nKcaP13H3Z1cFL93mrcSzq7P8lpK49+Rb3ewAAAABJRU5ErkJggg==) no-repeat center center;
  height: 100px;
  position: relative;
  margin-top: 20%;
}

@media (max-width: 767px) {
  .reset-signin__padlock {
    margin-top: 30%;
  }
}

.reset-signin__email {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFkAAABZCAMAAABi1XidAAAA7VBMVEUAAAAAAAAAAP8AAIAAgIAAgP8AVaoAQIAAQL8AM5kAZswAVaoAVdUASbYAbbYAYL8AVaoAVcYATbMAZrMAXaIAXbkAVaoAar8AYrEAYsQAW7YAVaoAVbsAZrsAYK8AYL8AWrQAY7gAaLcAZLIAZbUAY7UAZbYAYrQAZbMAZ7QAZbIAZbQAZLMAZbMAZrQAZrMAZLMAZLQAZbMAZLMAZbQAZ7QAZbMAZbMAZbQAZbIAZrMAZbIAZbIAZrMAZbMAZbIAZbMAZbIAZrMAZbIAZbMAZrMAZbMAZrMAZbMAZrMAZrMAZbIAZrIAZrMAZrPT7B+NAAAATnRSTlMAAQECAgIDBAQFBQYGBwcICQkKCgsLDAwNDQ4PDw8QEBESICEwPj9OW1xgamt5eoCChIaHiIiJl5ifoKKmp7W2v8DDxM/Q0tPg4eLw8PH3myRHAAADlUlEQVRYw7XZ/1+aQBgHcJJSo6Fko4gS7wkGc7P2Nbc1bTZbbpn8/3/O7gCVE4Xn+PL5qex6v0547g7uJCk7+4ra7uiGaYFlGnqnrSr7Ugk5aJuQjNk+KMhqF7ArF1puvJ7CLvF6HlcnkB2ii9qKAdgYigjcJoAPaaPd5iWIxWziYI2AaIiGcGsG5Imxl1kSXciXbkaRHPYgb3qHabBKIH/IUQoMxaLugo9IQZnsGDTNojCltxa23IPi6clbZBPKiJmEO1BOOom7B2Vl4y7WrNJkq8bJJ1BeTrjZgpQok/gMcgpl5rSiLnOd1qHc6KtnIFKyTPYr6vK6PKzSZSuEG1B+GuFaXYEcruWXFciXwfMmVBE2T7crkdmSaFQiG5K0RyqRyZ7UhGrSLHEx4XMktXAN3fe3+Lx1AFq4ceJMfbGMbA01HdlzXzRPOqroxrTpjYcPa/8Z9QDDOjH30DdvEHQaM4V6vr+gbQdI+BttO/N9iaDk4Q2aHtF+9IdUxvV5GHzFEeJm31PYBSabSBm8BYK2n3z/2YVANrAyuJR+stPHE4VnrAmTdbQMziyDdmndT4MGTNbwMtiUnrs72/UpPA5/ZLIqIIM9TqHZPf4Oa1kRkcPhuL36ruN/YXJDTGb/s5UecZ8zGbGmcPL2wrZHQRlzMqLseBn6ycJmZRyHQ1kVlYPCnsarz1mWMS/LwjK4z1xhszLmYfjK5OxnpIQcTJP/3Bjsz/tciy+BrInKDJ6uCjv6jS+YT4HcEJQHAcPGzCD67Sb6bJ2PgZw5kfIyndgXXlTYo+v7iORp60Mo6yLyunAH4Vq68GLfZPnMH8kHBC/HR4TLLu8vh7tI0XtKJGe9DsbkjaEGbuL6h+9WS7mBlTfhjemZjqDb8H1wKWdc6ZWcDof0KHgdXMkyQchs8Zw56asVo4kck9NHSyjbiclhB/1TistSN0tGwSH9g5PTNr6YjIQB3rwwOianXQ+HLpxYGDTnL6Uf/RfU3tczG2s4mO59WX9Y8zvUfl2fNZ1i8vthMpk8suZXuD3GoeiD+cs77L6oN54JuA93Vxubdsi3IfG93KL7z6/StrYLbBSnbWwX2iruZZ581PPt6ZqYU6Y8O5knyEMr0SvSQx9dybrQudVrWeQMD3+1DdFzvOYp7nywmePsUdayHnEsrZb3wLSmnu3qOTlTc7PLYaknp1dLV6RSUqsrrWP9vEusc/24pdRRnf0P762himejFNcAAAAASUVORK5CYII=) no-repeat center center;
  height: 100px;
  position: relative;
  margin-top: 20%;
}

@media (max-width: 767px) {
  .reset-signin__email {
    margin-top: 50%;
  }
}

.reset-signin__logo-image {
  display: none;
}

@media (max-width: 767px) {
  .reset-signin__logo-image {
    max-height: 67px;
    display: block;
    margin: 0 auto;
  }
}

.reset-signin__back-button {
  display: none;
}

@media (max-width: 767px) {
  .reset-signin__back-button {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 30px;
  }
}

.reset-signin__title {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  color: #00409a;
  text-align: center;
  padding: 80px 0 25px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-signin__title {
    padding: 0;
    padding: initial;
  }
}

@media (max-width: 767px) {
  .reset-signin__title {
    padding: 28px 0 15px;
    position: fixed;
    top: 12%;
    left: 30%;
  }

  .reset-signin__title:before {
    content: "Nova senha";
    display: block;
    clear: both;
    width: 150px;
    font-size: 18px;
    color: #0066b3;
    position: relative;
    z-index: 2;
  }
}

.reset-signin__form {
  display: flex;
  flex-direction: column;
}

.reset-signin__form-input {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #0066b3;
  padding-left: 15px;
  margin-bottom: 51px;
  font-size: 14px;
}

.reset-signin__form-input::-webkit-input-placeholder {
  color: #424b4a;
  opacity: 0.4;
  font-family: Open Sans;
}

.reset-signin__form-input::-moz-placeholder {
  color: #424b4a;
  opacity: 0.4;
  font-family: Open Sans;
}

.reset-signin__form-input::-ms-input-placeholder {
  color: #424b4a;
  opacity: 0.4;
  font-family: Open Sans;
}

.reset-signin__form-input:-ms-input-placeholder {
  color: #424b4a;
  opacity: 0.4;
  font-family: Open Sans;
}

.reset-signin__form-input::placeholder {
  color: #424b4a;
  opacity: 0.4;
  font-family: Open Sans;
}

.reset-signin__form-row {
  display: flex;
  flex-direction: column;
  position: relative;
}

.reset-signin__form-label {
  font-size: 14px;
  line-height: 19px;
  color: #0066b3;
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0 5px;
  background: #fff;
}

.reset-signin__form-button {
  width: 255px;
  background: #0066b3;
  color: #fff;
  height: 50px;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  font-family: Open Sans;
  border: none;
  font-weight: 800;
  border-radius: 25px;
  align-self: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  align-items: center;
  opacity: 1;
  -webkit-transition: opacity 0.175s ease-in-out;
  transition: opacity 0.175s ease-in-out;
}

.reset-signin__form-link {
  text-decoration: underline;
  color: #0066b3;
  padding: 0 0 23.5px;
  text-align: center;
}

.slick-list {
  overflow: hidden;
}

.slick-track {
  display: flex;
  padding: 0;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  margin-bottom: 87px;
  margin-top: 59px;
}

.slick-dots li {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  width: 15px;
  height: 15px;
  justify-content: space-between;
  margin: 0 12px;
}

@media (max-width: 767px) {
  .slick-dots li {
    width: 6px;
  }
}

.slick-dots li.slick-active button {
  background: #0066b3;
  opacity: 1;
  width: 15px;
  height: 15px;
}

.slick-dots li button {
  margin: auto;
  padding: 0;
  font-size: 0;
  width: 11px;
  height: 11px;
  background: #0066b3;
  opacity: 0.5;
  border: none;
}

.slick-arrow {
  background-color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 35px;
  width: 35px;
  border: none;
  font-size: 0;
}

.slick-next {
  content: "";
  display: block;
  border-right: 2px solid #0066b3;
  border-top: 2px solid #0066b3;
  width: 14px;
  height: 14px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 72%;
  position: absolute;
  right: 40%;
  background: transparent;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .slick-next {
    right: 36%;
    top: 75%;
  }
}

@media (max-width: 767px) {
  .slick-next {
    top: 75%;
    right: 2%;
  }
}

.slick-prev {
  content: "";
  display: block;
  border-right: 2px solid #0066b3;
  border-top: 2px solid #0066b3;
  width: 14px;
  height: 14px;
  -webkit-transform: rotate(-136deg);
  transform: rotate(-136deg);
  top: 72%;
  left: 40%;
  position: absolute;
  background: transparent;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .slick-prev {
    left: 36%;
    top: 75%;
  }
}

@media (max-width: 767px) {
  .slick-prev {
    top: 75%;
    left: 2%;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.reset-main {
  font-family: Open Sans;
  overflow: hidden;
}

.reset.has--no-scroll {
  overflow: hidden;
}

@media (max-width: 767px) {
  .reset {
    overflow: hidden;
  }

  .reset.js-disable-scroll {
    overflow: visible;
    overflow: initial;
  }
}

.reset-top {
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, #edf4fa), color-stop(50%, white));
  background: linear-gradient(90deg, #edf4fa 50%, white 50%);
  height: 650px;
  display: flex;
  justify-content: space-between;
  padding: 0 60px 0 60px;
}
.reset-top-opacity {
  opacity: 0.2;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-top {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0 0 0 60px;
    background: -webkit-gradient(linear, left top, right top, color-stop(72%, #edf4fa), color-stop(50%, white));
    background: linear-gradient(90deg, #edf4fa 72%, white 50%);
  }
}

@media (max-width: 767px) {
  .reset-top {
    padding: 0 40px;
    background: #edf4fa;
    height: auto;
  }
}

.reset-banner__info {
  width: 320px;
}

@media (max-width: 767px) {
  .reset-bottom {
    display: none;
  }
}

.reset-signin__form-button a {
  color: #fff;
}

.reset-signin__form-button a:hover {
  color: #fff;
}

.x-faq-intro {
	background: rgba(0, 102, 179, 0.1);
}

.x-faq-intro__container {
	max-width: 80rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	padding: 70px 0px 70px 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-intro__container {
		text-align: left;
		text-align: initial;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-intro__container {
		text-align: left;
		text-align: initial;
	}
}

.x-faq-intro__back {
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	max-width: 80rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
	padding: 57px 0px 30px 0px;
}

.x-faq-intro__back--text {
	margin: 0 0 0 10px;
	font-weight: bold;
	font-size: 0.875em;
	color: #424b4a;
}

@media only screen and (max-width: 766px) {
	.x-faq-intro__back--text {
		font-size: 0.75em;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-intro__back {
		margin: 0 0 0 5rem;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-intro__back {
		margin: 0 0 0 1.875rem;
	}
}

.x-faq-intro__title {
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	color: #00518b;
	font-size: 2.25em;
	font-weight: bold;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-intro__title {
		font-size: 1.5em;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-intro__title {
		font-size: 1.25em;
	}
}

.x-faq-category {
	max-width: 80rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	padding: 0 1.875rem;
}

.x-faq-category_container {
	padding: 0 0 0 1.875rem;
}

.x-faq-category__title {
	font-family: "Montserrat", sans-serif;
	color: #0066b3;
	font-size: 1.5625em;
	font-weight: bold;
	padding: 2.5rem 0;
}

.x-faq-category__title.gap {
	margin: 0 0 0 -2.5rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-category__title.gap {
		font-size: 1em;
		margin: 0 0 0 -1rem;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category__title.gap {
		font-size: 1em;
		margin: 0 0 0 -1rem;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category__title {
		font-size: 1em;
	}
}

.x-faq-category__item {
	font-family: "Montserrat", sans-serif;
	color: #8aa09d;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	padding: 0.75rem 1.25rem;
}

.x-faq-category__item:not(:first-child):nth-of-type(even)
	.x-faq-category__item-link {
	background: #f3f3f5;
}

.x-faq-category__item-link {
	font-size: 1em;
	font-family: "Montserrat", sans-serif;
	color: #00518a;
	font-weight: bold;
	padding: 1.25rem;
	-webkit-transform: translateX(-1.25rem);
	transform: translateX(-1.25rem);
	height: 100%;
}

.sign-hiden:after {
	content: "\002B";
	color: #00518a;
	position: absolute;
	right: 1.875rem;
	font-size: 1.5em;
	font-weight: 700;
}

.sign-show:after {
	content: "\002D";
	color: #00518a;
	position: absolute;
	right: 1.875rem;
	font-size: 1.5em;
	font-weight: 700;
	top: 1.5075rem;
}

.x-faq-category__item-link:hover,
.x-faq-category__item-link:hover::after,
.sign-hiden:hover:after,
.sign-show:hover:after,
.x-faq-category__item-link:active,
.x-faq-category__item-link:active::after,
.sign-hiden:active:after,
.sign-show:active:after,
.x-faq-category__item-link:focus,
.x-faq-category__item-link:focus::after,
.sign-hiden:focus:after,
.sign-show:focus:after {
	color: #0394db;
}

.btn-header-accordion-:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.btn-header-accordion {
	/* padding: 0.75rem 1.25rem; */
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	text-align: left;
}

.btn-header-accordion:hover {
	background-color: #f7f7f7;
}

.x-card-body {
	color: #00518a;
	padding: 1.3rem !important;
}

.x-faq-intro .text-center:not(:first-child) {
	margin-top: 2rem;
}

@media only screen and (max-width: 766px) {
	.x-faq-category__item-link:after {
		top: 0.9375rem;
		right: -1rem;
	}
}

.x-faq-category__item-link.active:after {
	content: "\2212";
}

@media only screen and (max-width: 766px) {
	.x-faq-category__item-link {
		padding: 1.25rem 0px 1.25rem 0.8125rem;
	}

	.x-faq-category__item-link,
	.x-card-body {
		font-size: 0.875em;
	}
}

.x-faq-category__item-icon {
	position: absolute;
	right: 1.875rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-category__item,
	.x-card-body {
		font-size: 1em;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category__item,
	.x-card-body {
		font-size: 0.875em;
	}
}

.x-faq-category__paragraph {
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	font-size: 0.875em;
	line-height: 1.5;
	padding: 0 1.875rem 0 0;
	pointer-events: none;
	background: #fff;
	border: 1 px solid red;
	display: none;
	max-width: 95%;
}

.x-faq-category__paragraph:last-of-type {
	margin: 0 0 1.875rem 0;
}

.x-faq-category__paragraph:first-of-type {
	padding: 1.25rem 0 0 0;
}

.x-faq-category__paragraph:nth-child(1n) {
	margin-bottom: 1.875rem;
}

.x-faq-category__paragraph.show {
	display: flex;
	flex-direction: column;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-category__paragraph {
		font-size: 0.875em;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category__paragraph {
		font-size: 0.75em;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-category {
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category {
	}
}

.faqback {
	margin: 2rem 4.5rem;
}

.x-web-ordering__container {
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    color: #424B4A;
    overflow: hidden;
    line-height: 1.4;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__container {
        flex-direction: column;
    }
}

.x-web-ordering__intro {
    display: flex;
    align-items: center;
    /* margin: 0 0 3.875rem 3.75rem; */
    justify-content: center;
    background: #F3F3F5;
}

.x-web-ordering__intro--container {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__intro--container {
        text-align: center;
    }
}

.x-web-ordering__intro-icon {
    max-width: 100%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__intro-icon {
        margin: 0 1.5rem;
    }
}

.x-web-ordering__intro--subtitle-bold {
    font-weight: bold;
    color: #0066B3;
}

.x-web-ordering__intro--link {
    color: #0066B3;
    text-decoration: underline;
}

.x-web-ordering__intro--text {
    font-family: "Montserrat", sans-serif;
    display: flex;
    font-size: 1rem;
    color: #3F3F3F;
    margin: 0 0 0.9375rem 0;
    font-weight: bold;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__intro--text {
        max-width: 80%;
        font-size: 0.875rem;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__intro--text {
        max-width: 80%;
        margin: 0.625rem 0 1.875rem 0;
        align-self: center;
        display: block
    }

    .x-web-ordering__intro--subtitle {
        align-self: center;
        max-width: 80%;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__intro {
        /* margin: 0 0 1.875rem 3.75rem; */
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__intro {
        flex-direction: column;
        margin: 0 0 3.875rem 0;
        padding: 2rem 0;
    }
}

.x-web-ordering__left {
    padding: 2.8125rem 0 3.25rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__left {
        width: 100%;
        padding: initial;
        padding: 2.0625rem 0 3.125rem 0;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__left {
        width: 100%;
        padding: 2.8125rem 0 1.25rem 0;
    }
}

.x-web-ordering__right {
    width: 100%;
    /* background: #F3F3F5; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__right {
        width: 100%;

    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__right {
        width: 100%;
    }
}

.x-web-ordering__labs {
    margin: 2.1875rem 0 2.1875rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs {
        margin: 2.1875rem 0 2.375rem 0;
    }
}

.x-web-ordering__labs--container {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--container {
        max-width: 18.375rem;
    }

    .x-web-ordering__labs {
        margin: 1.1875rem 0 1.375rem 0;
    }
}

.x-web-ordering__labs--title {
    font-size: 1.5em;
    color: #0066B3;
    align-self: center;
    margin: 0 0 0.9375rem 0;
    font-weight: bold;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--title {
        align-self: center;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--title {
        align-self: center;
        font-size: 1em;
    }
}

.x-web-ordering__labs--paragraph {
    font-size: .875em;
    color: #424B4A;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--paragraph {
        align-self: center;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--paragraph {
        text-align: center;
    }
}

.x-web-ordering__labs--slider {
    /* max-width: 51rem; */
    position: relative;
    align-self: center;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--slider {
        /* max-width: 24.875rem; */
    }
}

@media only screen and (max-width: 766px) {
    /* .x-web-ordering__labs--slider {
        max-width: 15.375rem;
    } */
}

.x-web-ordering__labs--item {
    display: flex;
    -webkit-box-orient: vertical;
    /* flex-direction: column; */
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--item {
        display: block;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--item {
        flex-direction: column;
    }
}

.x-web-ordering__labs--subitem {
    display: flex;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--subitem {
        display: flex;
        flex-direction: column;
    }
}

.x-web-ordering__labs--subitem-link {
    text-align: center;
    cursor: pointer;
    pointer-events: all;
    display: block;
    -webkit-transition: -webkit-transform .175s ease-in-out;
    transition: -webkit-transform .175s ease-in-out;
    transition: transform .175s ease-in-out;
    transition: transform .175s ease-in-out, -webkit-transform .175s ease-in-out;
}

.x-web-ordering__labs--subitem-link:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .175s ease-in-out;
    transition: all .175s ease-in-out;
}

.x-web-ordering__labs--image {
    /* max-width: 90%; */
    margin: 0 0.125rem -0.3125rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--image {
        padding: 0 0.0625rem 0 0;
    }
}

.x-web-ordering__labs--text {
    display: flex;
    align-self: center;
    font-size: .8125em;
    margin: 4.0625rem 0 2.3125rem 0;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--text {
        font-size: .8em;
        text-align: center;
        flex-direction: column;
        margin: 1.5rem 0 1.5625rem 0;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--text {
        font-size: .8em;
        margin: 2.5rem 0 1.5625rem 0;
    }
}

.x-web-ordering__labs--link {
    color: #00409A;
    text-decoration: underline;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--link {
        text-align: center;
        -webkit-transform: translate3d(70px, -16px, 0);
        transform: translate3d(70px, -16px, 0);
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs .slick-arrow.slick-next {
        right: 0 !important;
        top: 182px !important;
    }

    .x-web-ordering__labs .slick-arrow.slick-prev {
        left: 0 !important;
        top: 182px !important;
    }
}

.x-body .slick-list.draggable {
    overflow: hidden;
    padding: 0 !important;
}

.x-body .slick-slide {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-body .slick-slide {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 766px) {
    .x-body .slick-slide {
        flex-direction: column;
    }
}

.x-body .slick-arrow {
    font-size: 0;
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #00518B;
    border-right: 2px solid #00518B;
    top: 40%;
}

.x-body .slick-arrow.slick-prev {
    left: 0;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    margin: 0 0 0 -2.75rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-body .slick-arrow.slick-prev {
        margin: 1.25rem 0 0 -5rem;
    }
}

@media only screen and (max-width: 766px) {
    .x-body .slick-arrow.slick-prev {
        margin: 0;
        left: 37px;
        top: auto;
        top: initial;
    }
}

.x-body .slick-arrow.slick-next {
    right: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0 -2.75rem 0 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-body .slick-arrow.slick-next {
        margin: 1.25rem -5rem 0rem 0rem;
    }
}

@media only screen and (max-width: 766px) {
    .x-body .slick-arrow.slick-next {
        margin: 0;
        right: 37px;
        top: auto;
        top: initial;
    }
}

.x-body .slick-dots {
    position: absolute;
    display: flex;
    bottom: -1.875rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.x-body .slick-dots li {
    margin: 0 0.875rem;
}

.x-body .slick-dots li button {
    font-size: 0;
    width: 0.5rem;
    height: 0.5rem;
    background-color: rgba(0, 102, 179, 0.5);
    opacity: .8;
}

.x-body .slick-dots li.slick-active button {
    background: #0066B3;
}

.x-body .slick-track {
    display: flex;
}

@media only screen and (max-width: 766px) {
    .x-body .slick-track {
        width: 100%;
    }
}

.labSelect,
.x-web-ordering__labs--subitem-link img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    -webkit-transition: linear 0.4s;
    transition: linear 0.4s;
}

.labSelect:hover,
.x-web-ordering__labs--subitem-link img:hover {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    -webkit-transition: linear 0.4s;
    transition: linear 0.4s;
}

.x-web-ordering__labs--subitem-link label {
    margin-bottom: 0px;
    padding: 50px 25px;
}

.x-web-ordering__intro .hide-tutorial {
    display: none;
}

.x-web-ordering__intro .show-tutorial {
    display: content;
    opacity: 1;
    pointer-events: all;
    background: #fff;
    z-index: 99999;
    position: fixed;
    /* width: 920px; */
    margin: 0 auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 40px;
    /* max-height: 80%; */
    border: 2px solid #F8F8F8;
    border-radius: 5px;
    -webkit-transition: .5s bottom, .5s opacity;
    transition: .5s bottom, .5s opacity;
}

.x-web-ordering__intro .tutorial-close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding-bottom: 15px;
}

/* Carousel */
.arrow-container {
    z-index: 3;
    -webkit-transition: all .25s ease-in;
    transition: all .25s ease-in;
    opacity: 1;
    background: 0 0;
    border: 0;
    cursor: pointer;
    position: absolute !important;
    height: 100%;
    width: 25px;

    display: flex;
    align-items: center;
    color: #00A2E8;
}

.arrow-container-next {
    top: 0;
    right: 0;
}

.arrow-container-prev {
    top: 0;
    left: 0;
}

.slide>div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
}

.slide.selected>div {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
}

.slide.selected .x-web-ordering__labs--image,
.slide.selected .x-web-ordering__labs--imageNotFound {
    -webkit-transition: linear 0.6s;
    transition: linear 0.6s;
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    color: #076CB6;
    font-weight: bold;
}

@media (max-width: 765px) {
    .slide label {
        font-size: 12px
    }

    .slide.selected>div {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
        -webkit-transition: all .6s ease-in-out;
        transition: all .6s ease-in-out;
    }

    .arrow-container {
        width: 27px;
    }

    .carousel-container {
        width: 90vw;
    }
}

@media only screen and (min-width: 766px) and (max-width: 1170px) {
    .x-web-ordering__labs--image {
        width: 140px;
    }
}
.x-web-ordering-pedido__main ul {
  margin-bottom: 0px;
}

.formColoracao {
  margin-bottom: 70px;
}

.select-chackbox-container{
  display: flex;
  flex-direction: column;
  -webkit-transform: translate(0px, 26px);
          transform: translate(0px, 26px);
}
.p-check-box{
  font-size: small;
}
.check-box-container {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  -webkit-text-stroke-width: medium;

}
.x-web-ordering-pedido__main .x-web-ordering-pedido__summary a:hover {
  color: #00518b !important;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main * {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main img {
  display: block;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main a {
  text-decoration: none;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main a:hover {
  color: inherit;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main li {
  list-style: none;
}

.x-web-ordering-pedido__main .slick-track {
  display: flex;
}

.x-web-ordering-pedido__main .slick-next {
  right: 0;
}

.x-web-ordering-pedido__main .slick-prev {
  z-index: 99;
  display: none !important;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido {
  display: flex;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido {
    flex-direction: column;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__arrow {
  align-items: center;
  margin: 2.35rem 0 0 3.75rem;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__arrow {
    margin-left: 2.25rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__arrow--text {
  margin: 0 0 0 0.625rem;
  pointer-events: all;
  cursor: pointer;
  font-weight: bold;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__arrow--icon {
  margin: 2.5rem 0 0 0;
  -webkit-transform: translate3d(0, 2px, 0px);
  transform: translate3d(0, 2px, 0px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__arrow {
    margin: 0 0 0 2.75rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__container {
  display: flex;
  flex-direction: column;
  width: 75%;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__container {
    width: 100%;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    height: 100%;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__container.show-pedido {
    width: 100%;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    height: 100%;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__container {
    width: 100%;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__container.show-pedido {
    width: 100%;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu {
  font-family: "Montserrat", sans-serif;
  color: #424b4a;
  font-size: 0.875rem;
  width: 100% !important;
  display: flex;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu {
    font-size: 0.8125rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu .slick-list {
  display: flex;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu .slick-list .slick-track {
  width: 100% !important;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item {
  display: flex;
  margin: 0.5625rem 0;
  width: 22% !important;
  align-items: center;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item:first-child {
  margin: 0.5625rem 0 0.5625rem 3.75rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item:first-child {
    margin: 0.5625rem 0 0.5625rem 2.75rem;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item {
    align-items: baseline;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item:first-child {
    margin-left: 2.25rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item.slick-current .x-web-ordering-pedido-menu__link {
  color: #0066b3;
  -webkit-transition: text-shadow 0.175 ease-in-out;
  transition: text-shadow 0.175 ease-in-out;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item.slick-current .x-web-ordering-pedido-menu__link:before {
  background: #0394db;
  color: #fff;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link {
  margin: 1.25rem 0 0 0;
  align-items: baseline;
  color: rgba(105, 105, 105, 0.6);
  font-weight: bold;
  -webkit-transition: text-shadow 0.175 ease-in-out;
  transition: text-shadow 0.175 ease-in-out;
  pointer-events: none;
  display: flex;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link:before {
  content: "";
  display: inline-block;
  background: #dddddd;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  margin: 0 0.625rem 0 0;
  color: gray;
  padding: 0.3125rem 0 0 0.625rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link:before {
    font-size: 0.625rem;
    width: 1.4375rem;
    height: 1.4375rem;
    padding: 0.3125rem 0 0 0.5rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l1::before {
  content: "1";
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l2::before {
  content: "2";
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3 {
  margin-left: 2.5rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3::before {
  content: "3";
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3 {
    margin-left: 1.25rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3 {
    margin-left: 1.25rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l4::before {
  content: "4";
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link .link-text {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link .link-text {
    display: none;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link {
    display: flex;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__shape {
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(105, 105, 105, 0.3);
}

.x-web-ordering-pedido__main .opcional {
  font-weight: normal;
  font-weight: initial;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__dados--container {
  padding: 2rem 3.25rem 0 3.75rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__dados--container {
    padding: 2rem 0 0 2.8125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__dados--container {
    padding: 1rem 2rem 0 1rem;
  }
}

.x-web-ordering-pedido__main .input-align {
  display: contents;
}

.x-web-ordering-pedido__main .input-align input {
  color: rgba(66, 75, 74, 0.8);
}

.x-web-ordering-pedido__main .select-align {
  display: contents;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__dados--card {
  position: relative;
  display: flex;
}

.x-web-ordering-pedido__dados--card .crm-button-orange {
  margin: unset;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__lens--container {
  padding: 2rem 3.25rem 0 3.75rem;
  display: flex;
  flex-direction: column;
}

.lens-opacity {
  opacity: 0.2;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__lens--container {
    padding: 2rem 0 0 2.8125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__lens--container {
    padding: 2rem 2.5rem 0 2.3125rem;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__dados--card {
    display: unset;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__lens--row {
  position: relative;
  display: flex;
}

input#quantidade {
  text-align: center;
  padding-right: 40px;
  padding-left: 40px;
}

@media (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__lens--row {
    display: block;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__lens--services-container {
  position: relative;
  margin-bottom: 20px;
}

.order-link-wrapper {
	color: #0066cc;
	text-decoration: underline;
	font-weight: bold;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--container {
  padding: 2rem 3.25rem 0 3.75rem;
  margin-bottom: 4.6875rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--container {
    padding: 2rem 0 0 3.75rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--container {
    padding: 1rem 2rem 0 1rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body.hidden {
  display: none;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body {
  margin-bottom: 1.1rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body {
    display: flex;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body.is--tablet {
    display: flex;
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body.is--mobile {
    display: flex;
    -webkit-box-orient: horizontal;
    /* -webkit-box-direction: reverse;
	flex-direction: row-reverse; */
    margin-top: 20px;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body.is--mobile.hidden {
    display: none;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row {
    padding: 0 2.5rem 0 0;
    flex-direction: column;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row-both {
  display: flex;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row {
    flex-direction: column;
    margin: 0 0 0.625rem 0;
    display: block;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row-both {
    flex-direction: column;
    margin: 0 0 0.625rem 0;
    display: block;
    display: table-cell;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row.is--mobile {
    margin: 0;
    margin: initial;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row.is--tablet-2 {
    display: flex;
    flex-direction: column;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--item {
  position: relative;
  margin: 0 0 0 -0.625rem;
  width: 100%;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--item {
    margin: 0;
    margin: initial;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--item {
    margin: 0;
    margin: initial;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-right {
  margin: 0 2.5rem 0 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  width: 70px;
  margin: 1.375rem 2rem 0 0;
  padding-top: 10px;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-right {
    width: 14%;
    position: relative;
    top: 10px;
    margin: 0 2rem 0 0;
    width: 110px;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-right {
    margin: 0 17px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-right.flex-start {
    display: flex;
    align-self: flex-start;
    margin: 1.875rem 0 0 0;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-left {
  margin: 0 2.5rem 0 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  width: 70px;
  margin: 1.375rem 2rem 0 0;
  padding-top: 10px;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-left {
    margin: 0 10px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-left {
    width: 14%;
    position: relative;
    top: 10px;
    margin: 0 2rem 0 0;
    width: 110px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-left.flex-start {
    display: flex;
    align-self: flex-start;
    margin: 1.875rem 0 0 0;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--container {
  padding: 2rem 3.25rem 0 3.75rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--container {
    padding: 2rem 2.5rem 0 2.3125rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--body img {
  width: 405px;
  height: auto;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--body {
    width: 100%;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--column {
  max-width: 46.25rem;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--column {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--row {
  display: flex;
  margin: 1.875rem 0 1.875rem 0;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.grid-item {
  text-align: center;
  font-size: 12px;
  color: #212529;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin: initial;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--row:first-of-type {
    margin: 1.875rem 0 0 0;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--item {
  flex-direction: row;
  width: 100%;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--item-2 {
  display: inline;
  display: initial;
  position: relative;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--info {
  display: flex;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--button {
  width: 11.25rem;
  border-radius: 50px;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  position: relative;
  align-self: flex-end;
  margin: 0 7% 0 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--button {
    margin: 1.875rem 1.875rem 0 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--button {
    margin: 1.5rem 0 0 0;
    width: 6.75rem;
    padding: 0.25rem;
    float: right;
    height: 3rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--text {
  display: flex;
  margin: auto 10%;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--text {
    text-align: left;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--icon {
  position: absolute;
  right: 0.625rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--glasses {
  max-width: 100%;
  align-self: flex-end;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  height: 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo.activeSvgToInput:after {
  content: "";
  background-color: #005991;
  display: inline-block;
  width: 11px;
  left: 3px;
  top: 3px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo:before {
  content: "";
  position: relative;
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: solid 1px #005991;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo:checked:after {
  content: "";
  background-color: #005991;
  display: inline-block;
  width: 11px;
  left: 3px;
  top: 3px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo--icon {
  cursor: pointer;
  pointer-events: all;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__solicitation {
  overflow: hidden;
  position: relative;
  min-height: 500px;
}

.x-web-ordering-pedido__main [type="text"],
.x-web-ordering-pedido__main [type="number"] {
  /* box-shadow: 0 0 0 1px #b5b5b5; */
  /* border: initial; */
  /* padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem; */
  border-radius: 5px;
  /* margin: 1.375rem 1rem 0 0; */
  position: relative;
  z-index: 0;
}

.x-web-ordering-pedido__main input:focus {
  outline: none;
}

.x-web-ordering-pedido__main [type="text"]:hover,
.x-web-ordering-pedido__main [type="text"]:active,
.x-web-ordering-pedido__main [type="text"]:focus,
.x-web-ordering-pedido__main [type="number"]:hover,
.x-web-ordering-pedido__main [type="number"]:active,
.x-web-ordering-pedido__main [type="number"]:focus,
.x-web-ordering-pedido__main [type="email"]:hover,
.x-web-ordering-pedido__main [type="email"]:active,
.x-web-ordering-pedido__main [type="email"]:focus {
  border: 1px solid #0066b3;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main [type="text"],
  .x-web-ordering-pedido__main [type="number"] {
    width: 100%;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .title-mobile:after {
    content: "";
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    margin: 1.25rem 0 0 0;
    color: #0066b3;
  }

  .t-dados:after {
    content: "Dados do pedido";
  }

  .t-lens:after {
    content: "Prescrição";
  }

  .t-prescription:after {
    content: "Especificações da lente";
  }

  .t-frame:after {
    content: "Armação e montagem";
  }
}

.x-web-ordering-pedido__main .is--label {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 10;
  padding: 0 0.3125rem;
}

@media only screen and (min-width: 375px) and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--label.gap-label {
    margin-left: 0.5625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--label {
    position: relative;
    top: 1.1rem;
    left: 0.75rem;
    /* min-width: 2.56rem; */
  }

  .x-web-ordering-pedido__main .is--label.is--eixo {
    position: relative;
    top: 2rem;
    right: 15rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label-estabilidade {
    -webkit-transform: translate3d(331px, -57px, 0);
    transform: translate3d(331px, -57px, 0);
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label-estabilidade {
    -webkit-transform: translate3d(371px, -57px, 0);
    transform: translate3d(371px, -57px, 0);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-coeficiente {
    -webkit-transform: translate3d(-630px, 88px, 0px);
    transform: translate3d(-630px, 88px, 0px);
    width: 23%;
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-coeficiente {
    -webkit-transform: translate3d(-710px, 83px, 0px);
    transform: translate3d(-710px, 83px, 0px);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-dis-progressao {
    -webkit-transform: translate3d(-308px, 13px, 0px);
    transform: translate3d(-308px, 13px, 0px);
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-dis-progressao {
    -webkit-transform: translate3d(-348px, 13px, 0px);
    transform: translate3d(-348px, 13px, 0px);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-vertice {
    -webkit-transform: translate3d(-629px, 90px, 0px);
    transform: translate3d(-629px, 90px, 0px);
    width: 16%;
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-vertice {
    -webkit-transform: translate3d(-353px, 15px, 0px);
    transform: translate3d(-353px, 15px, 0px);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-olho-dominante {
    -webkit-transform: translate3d(-302px, 19px, 0px);
    transform: translate3d(-302px, 19px, 0px);
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-olho-dominante {
    -webkit-transform: translate3d(-349px, 19px, 0px);
    transform: translate3d(-349px, 19px, 0px);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-cvp {
    -webkit-transform: translate3d(-633px, 90px, 10px);
    transform: translate3d(-633px, 90px, 10px);
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-cvp {
    -webkit-transform: translate3d(-709px, 85px, 10px);
    transform: translate3d(-709px, 85px, 10px);
  }
}

.x-web-ordering-pedido__main .width-input__dados--num-pedido {
  width: calc(30.333% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__dados--num-pedido {
    width: calc(37% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__dados--num-pedido {
    width: 91%;
  }
}

.x-web-ordering-pedido__main .width-input__dados--empresa-fat {
  width: calc(62% - 10px);
}

/* Problema acima */

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__dados--empresa-fat {
    width: calc(55% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__dados--empresa-fat {
    width: 91%;
  }
}

.x-web-ordering-pedido__main .width-input__dados--cliente {
  width: calc(30.555% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__dados--cliente {
    width: calc(26.666% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__dados--cliente.first {
    width: calc(37% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__dados--cliente {
    width: 91%;
  }
}

.x-web-ordering-pedido__main .width-input__lens--tp-producao {
  width: calc(48.666% - 10px);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__lens--tp-producao {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__lens--tp-produto {
  width: calc(44.666% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__lens--tp-produto {
    width: calc(41.666% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__lens--tp-produto {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__lens--esc-produto {
  width: calc(94% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__lens--esc-produto {
    width: calc(92% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__lens--esc-produto {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__lens--servicos {
  width: calc(30.666% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__lens--servicos {
    width: calc(29.333% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__lens--servicos {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__prescription--olhos {
  width: calc(23% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__prescription--olhos {
    width: calc(23% - 10px);
  }
}

.x-web-ordering-pedido__main .width-input__prescription--olhos.input-tablet {
  width: calc(22% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__prescription--olhos.input-tablet {
    width: calc(31% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__prescription--olhos.input-tablet {
    width: 80%;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__prescription--olhos {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--olhos.is--mobile {
    width: 90% !important;
  }

  .x-web-ordering-pedido__main .width-input__prescription--olhos.is--mobile.scale {
    width: 93%;
  }
}

.x-web-ordering-pedido__main .width-input__prescription--ini-clientes {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--dis-leitura {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--cvp {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--cro {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--wmap {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--angulo {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--coeficiente {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--estabilidade {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--dis-progressao {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--distancia {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--olho-domiannte {
  width: calc(25% - 1rem);
  font-size: 0.875rem;
  color: rgba(66, 75, 74, 0.8);
  font-family: "Open Sans";
  padding: 0.9375rem 3rem 0.9375rem 1.25rem;
  box-shadow: 0 0 0 1px #b5b5b5;
  border: medium none currentColor;
  border: initial;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 1;
  background: transparent;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__prescription--ini-clientes {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-leitura {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--cvp {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--cro {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--wmap {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--angulo {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--coeficiente {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--estabilidade {
    /* -webkit-transform: translate3d(322px, -69px, 0);
        transform: translate3d(322px, -69px, 0); */
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-progressao {
    /* -webkit-transform: translate3d(-324px, 2px, 0px);
        transform: translate3d(-324px, 2px, 0px); */
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--distancia {
    /* -webkit-transform: translate3d(322px, -67px, 0);
        transform: translate3d(322px, -67px, 0); */
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--olho-domiannte {
    /* -webkit-transform: translate3d(-324px, 2px, 0px);
        transform: translate3d(-324px, 2px, 0px); */
    width: calc(47% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__prescription--ini-clientes {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-leitura {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--cvp {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--cro {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--wmap {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--angulo {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--coeficiente {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--estabilidade {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-progressao {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--distancia {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--olho-domiannte {
    width: 90%;
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .width-input__prescription--estabilidade {
    -webkit-transform: translate3d(361px, -69px, 0);
    transform: translate3d(361px, -69px, 0);
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-progressao {
    -webkit-transform: translate3d(-363px, 2px, 0px);
    transform: translate3d(-363px, 2px, 0px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--distancia {
    -webkit-transform: translate3d(361px, -67px, 0);
    transform: translate3d(361px, -67px, 0);
  }
}

.x-web-ordering-pedido__main .width-input__frame--position {
  width: calc(47% - 10px);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__frame--position {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__frame--modelo {
  width: calc(96.333% - 15px);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__frame--modelo {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .is--text-select {
  color: rgba(66, 75, 74, 0.8);
  font-family: "Open Sans";
  /* padding: 0.9375rem 3rem 0.9375rem 1.25rem; */
  /* box-shadow: 0 0 0 1px #b5b5b5; */
  border: medium none currentColor;
  border: initial;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 1;
  background: transparent;
}

.x-web-ordering-pedido__lens .is--text-select {
  width: calc(100% - 1rem);
  z-index: 9;
}

.x-web-ordering-pedido__main .is--text-select:hover,
.x-web-ordering-pedido__main .is--text-select:active,
.x-web-ordering-pedido__main .is--text-select:focus {
  box-shadow: 0 0 0 1px #0066b3;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--text-select {
    font-size: 0.75rem;
  }
}

.x-web-ordering-pedido__main .intro__form--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0% 7% 0% 0%;
}

.x-web-ordering-pedido__main .intro__form--container.space {
  margin-top: 3rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .intro__form--container {
    margin: 0 3.75rem 0.9375rem 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .intro__form--container {
    margin: 0;
    margin: initial;
    flex-direction: column-reverse;
    justify-content: initial;
    align-items: initial;
  }
}

.x-web-ordering-pedido__main .intro__form--title {
  color: #424b4a;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.x-web-ordering-pedido__main .intro__form--subtitle {
  color: #424b4a;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .intro__form--subtitle {
    margin: 0 0 1.875rem 0;
  }
}

.x-web-ordering-pedido__main .intro__form--subtitle-lg {
  font-size: 0.875rem;
  font-weight: bold;
  margin: 1.625rem 0 0 0;
  font-family: "Montserrat", sans-serif;
}

p.x-web-ordering-pedido__frame--subtitle.intro__form--subtitle.title-mobile.t-frame.step4 {
  text-align: right;
}

.x-web-ordering-pedido__main .intro__form--subtitle.step4 {
  color: #424b4a;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  /* float: right; */
  width: 85%;
  /* clear: both; */
  /* text-align: left; */
}

.x-web-ordering-pedido__main .intro__form--subtitle-lg-2 {
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 1.625rem;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .intro__form--subtitle-lg-2 {
    margin: 2.3125rem 0 0 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .intro__form--subtitle-lg-2 {
    margin: 3rem 0 0 0;
  }
}

.x-web-ordering-pedido__main .intro__form--subtitle-sm {
  font-size: 0.875rem;
  margin: 1.625rem 0 1.125rem 0;
  font-family: "Montserrat", sans-serif;
}

/* .x-web-ordering-pedido__main .intro__form--subtitle-sm-2 {
  margin: 0 0.9375rem 0 0;
  font-size: 0.875rem;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .intro__form--subtitle-sm-2 {
    font-size: 0.775rem;
    margin: 0 0.625rem 0 0;
  }
} */

.x-web-ordering-pedido__main .intro__form--subtitle-sm-3 {
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
}

.x-web-ordering-pedido__main .no--gap {
  margin: 0;
  margin: initial;
}

.x-web-ordering-pedido__main .is--glasses {
  display: flex;
  justify-content: center;
  margin: 0 3.125rem 0 2.25rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--glasses {
    display: none;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--glasses {
    display: none;
  }
}

/* .is--arrow {
    position: relative;
    z-index: 0;
    right: 3.125rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering-pedido__main .is--arrow {
        right: 6%;
    }
} */

.x-web-ordering-pedido__main .is--arrow:before {
  content: "";
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  position: absolute;
  border-bottom: 1px solid #00639d;
  border-right: 1px solid #00639d;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 0;
}

.x-web-ordering-pedido__main .is--arrow-2 {
  position: absolute;
  z-index: 0;
  top: 2.6rem;
  margin-left: 10px;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--arrow-2 {
    /* top: 6.8125rem; */
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--arrow-2.ajust-arrow-left {
    top: 6.875rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--arrow-2.ajust-arrow-right {
    right: 0.5rem;
    top: 6.9375rem;
  }

  .x-web-ordering-pedido__main .is--arrow-2-step2:before {
    content: "";
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    position: absolute;
    border-bottom: 1px solid #00639d;
    border-right: 1px solid #00639d;
    right: 3.7rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 8rem;
  }
}

.x-web-ordering-pedido__main .is--arrow-2:before {
  content: "";
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  position: absolute;
  border-bottom: 1px solid #00639d;
  border-right: 1px solid #00639d;
  right: 3rem;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--arrow-2:before {
    right: 0;
    top: 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--arrow-2 {
    position: relative;
    float: right;
    right: 3rem;
    /* top: -1.9rem; */
  }

  .x-web-ordering-pedido__main .is--arrow-2.prisma {
    position: relative;
    float: right;
    right: 3rem;
    top: -1.9rem;
  }

  .x-web-ordering-pedido__main .is--arrow-2.adicao-right-left {
    position: relative;
    float: right;
    right: 3.5rem;
  }

  .x-web-ordering-pedido__main .is--arrow-2.dominante {
    position: relative;
    float: right;
    right: 3rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--arrow-2.is--mobile-right {
    right: 2.6rem;
    top: -1.85rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--arrow-2.is--mobile-left {
    right: 2.6rem;
    top: -1.85rem;
  }
}

.x-web-ordering-pedido__main .x-button__slick-slider {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.x-web-ordering-pedido__main .x-button__slick-slider .first-btn {
  opacity: 0;
  pointer-events: none;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next {
  width: 11.125rem;
  padding: 0.8125rem;
  border-radius: 3.125rem;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  color: #fff;
  font-weight: bold;
  font-size: 0.875rem;
  position: relative;
  align-self: flex-end;
  margin: 3% 7% 0% 0%;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next:hover {
  color: #fff;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next {
    margin: 1.8125rem 1.25rem 0 0;
    width: 7.25rem;
    height: 3rem;
  }
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next-icon {
  position: absolute;
  right: 0.625rem;
  top: 25%;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next-text {
    justify-content: initial;
    align-self: flex-start;
  }
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev {
  width: 11.125rem;
  padding: 0.8125rem;
  border-radius: 3.125rem;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  color: #fff;
  font-weight: bold;
  font-size: 0.875rem;
  position: relative;
  align-self: flex-end;
  margin: 3% 7% 0% 0%;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--confirm {
  width: 11.125rem;
  padding: 0.8125rem;
  position: relative;
  align-self: flex-end;
  margin: 3% 7% 0% 0%;
  padding-bottom: 0px;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev:hover {
  color: #fff;
}

.x-web-ordering-pedido__main .dontshow {
  width: 11.125rem;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev {
    margin: 1.8125rem 0.625rem 0 0;
    width: 7.25rem;
    height: 3rem;
  }
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev-icon {
  position: absolute;
  left: 0.625rem;
  top: 25%;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev-text {
    justify-content: flex-end;
    /* margin-top: 0.3rem; */
  }

  .x-web-ordering-pedido__prescription--row-both {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .flex-tablet {
    display: flex;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .column-tablet {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .flex-row {
    flex-direction: row;
    align-items: center;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary {
  width: 25%;
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  padding: 2.9375rem 2rem 6.75rem 2rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary.confirm {
  width: 45%;
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  padding: 2.9375rem 3.75rem 6.75rem 2.75rem;
  -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
  transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary {
    position: absolute;
    top: 8rem;
    width: 100%;
    height: 100%;
    right: -92vw;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    padding: 2.9375rem 2.75rem 6.75rem 2.75rem;
    overflow-y: scroll;
    height: 85%;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary.show-summary {
    -webkit-transform: translateX(-92%);
    transform: translateX(-92%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 999;
  }

  .x-my-account__back {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary {
    position: absolute;
    width: 50%;
    top: 9.6rem;
    height: 100%;
    right: -45vw;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    overflow-y: scroll;
    height: 85%;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary.show-summary {
    display: inline;
    display: initial;
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 999;
  }

  .x-my-account__back {
    margin-top: 40px;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow {
  display: none;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow {
    display: inline-table;
    position: relative;
    right: 50px;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin: 20px;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow {
    display: inline-table;
    right: 10%;
    position: relative;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--container.show-summary {
    display: inline;
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--container.show-summary {
    display: inline;
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--intro {
  display: flex;
  flex-direction: column;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--icon {
  align-self: center;
  margin: 0 0 2.25rem 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--paragraph {
  color: #424b4a;
  font-size: 0.775rem;
  font-family: "Montserrat", sans-serif;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--item {
  position: relative;
  border-bottom: 1px solid #d8d8da;
}

.x-web-ordering-pedido__summary--item:not(:last-child) {
  border-bottom: 1px solid #707070;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--link {
  color: #00518b;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0.625rem 1rem 0;
  display: block;
  cursor: pointer;
  pointer-events: all;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--link:after {
  content: "\002B";
  color: #0066b3;
  position: absolute;
  right: 0;
  font-size: 1.5em;
  top: 0.8125rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--link.active:after {
  content: "\2212";
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card {
  display: flex;
  justify-content: space-between;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.left:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.right {
  display: flex;
  justify-content: space-between;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.right:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.shape {
  display: flex;
  justify-content: space-between;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.shape:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text {
  margin: 1.5rem 0 0.5rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  font-weight: bold;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text-sub {
  margin: 0.3rem 0 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  font-weight: bold;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text-2 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text.no--gap {
  margin-bottom: 0;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text {
    font-size: 0.775rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
}

.x-web-ordering-pedido__summary--card-text {
  font-family: "Montserrat", sans-serif;
}

.x-web-ordering-pedido__summary--subitem-text {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 100%;
}

.x-web-ordering-pedido__summary--subitem-text.title {
  font-weight: bold;
}

.x-web-ordering-pedido__summary--subitem-text.desc {
  text-align: right;
}

.x-web-ordering-pedido__summary--subitem-text:nth-child(even) {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__summary--card-text:last-child .x-web-ordering-pedido__summary--subitem-text:nth-child(even) {
  border-bottom: 0px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__summary--side-left .x-web-ordering-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 59%;
}

.x-web-ordering-pedido__summary--side-right .x-web-ordering-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 59%;
}

.x-web-ordering-pedido__summary--subitem.right {
  text-align: right;
  width: 41%;
}

.x-web-ordering-pedido__summary--subitem.left {
  width: 41%;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--content {
  display: none;
  margin: 0 0 0 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--content.show {
  display: flex;
  flex-direction: column;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--group {
  display: flex;
  justify-content: space-between;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--side-left {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin: 0 1rem 0 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--side-right {
  width: 45%;
  display: flex;
  flex-direction: column;
}

/* CSS animação de página */

@-webkit-keyframes slideInFromRight {
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes slideInFromRight {
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@-webkit-keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* Slides pra esquerda */
.x-web-ordering-pedido__dados.right {
  -webkit-animation: 0.3s ease-out 0s 1 slideInFromRight;
          animation: 0.3s ease-out 0s 1 slideInFromRight;
}

.x-web-ordering-pedido__lens.right {
  -webkit-animation: 0.3s ease-out 0s 1 slideInFromRight;
          animation: 0.3s ease-out 0s 1 slideInFromRight;
}

.x-web-ordering-pedido__frame.right {
  -webkit-animation: 0.3s ease-out 0s 1 slideInFromRight;
          animation: 0.3s ease-out 0s 1 slideInFromRight;
}

.x-web-ordering-pedido__prescription.right {
  -webkit-animation: 0.3s ease-out 0s 1 slideInFromRight;
          animation: 0.3s ease-out 0s 1 slideInFromRight;
}

/* Slides pra direita */
.x-web-ordering-pedido__dados.left {
  -webkit-animation: 0.3s ease-out 0s 1 slideInFromLeft;
          animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

.x-web-ordering-pedido__lens.left {
  -webkit-animation: 0.3s ease-out 0s 1 slideInFromLeft;
          animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

.x-web-ordering-pedido__frame.left {
  -webkit-animation: 0.3s ease-out 0s 1 slideInFromLeft;
          animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

.x-web-ordering-pedido__prescription.left {
  -webkit-animation: 0.3s ease-out 0s 1 slideInFromLeft;
          animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

/* Acordeon */

.x-web-ordering-pedido__main .card-header {
  padding: 0px;
}

.x-web-ordering-pedido__main .card {
  border: 0px;
}

.x-web-ordering-pedido__main .card-body {
  flex: none;
  min-height: 0px !important;
  padding: 0px !important;
}

/* Campo auto complete */

.x-web-ordering-pedido__main .no-suggestions,
.x-web-ordering-pedido__main .suggestions li {
  padding: 7px;
}

.x-web-ordering-pedido__main .suggestions li:hover {
  background-color: #efefef;
}

.x-web-ordering-pedido__main .suggestion-active {
  background-color: #efefef;
}

.suggestion-active {
  background-color: #efefef;
}

.order-opacity {
  opacity: 0.2;
}

.x-web-ordering-pedido__lens--body .no-suggestions,
.x-web-ordering-pedido__lens--body .suggestions {
  color: rgba(66, 75, 74, 0.8);
  font-family: "Montserrat", sans-serif;
  position: absolute;
  z-index: 4;
  background: white;
  width: calc(99% - 10px);
  margin-top: 3px;
  border: 1px solid #efefef;
  overflow-y: scroll;
  height: 10rem;
  top: 90px;
}

.x-web-ordering-pedido__prescription--body .no-suggestions,
.x-web-ordering-pedido__prescription--body .suggestions {
  color: rgba(66, 75, 74, 0.8);
  font-family: "Montserrat", sans-serif;
  position: absolute;
  z-index: 4;
  background: white;
  width: calc(23% - 10px);
  margin-top: 3px;
  border: 1px solid #efefef;
  overflow-y: scroll;
  height: 10rem;
}

.x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(5),
.x-web-ordering-pedido__prescription--body .suggestions:nth-child(5) {
  left: 12.1rem;
}

.x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(7),
.x-web-ordering-pedido__prescription--body .suggestions:nth-child(7) {
  left: 24.1rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(5),
  .x-web-ordering-pedido__prescription--body .suggestions:nth-child(5) {
    left: 8.2rem;
  }

  .x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(7),
  .x-web-ordering-pedido__prescription--body .suggestions:nth-child(7) {
    left: 16.45rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__prescription--body .no-suggestions,
  .x-web-ordering-pedido__prescription--body .suggestions {
    width: calc(100% - 15px);
  }

  .x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(5),
  .x-web-ordering-pedido__prescription--body .suggestions:nth-child(5) {
    left: unset;
  }

  .x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(7),
  .x-web-ordering-pedido__prescription--body .suggestions:nth-child(7) {
    left: unset;
  }
}

/* CSS do passo 5 de confirmação */

.x-confirm-order__main {
  font-family: "Montserrat", sans-serif;
  color: #424b4a;
}

.x-confirm-order__container {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__container {
    flex-direction: column;
  }
}

.x-confirm-order__left {
  display: flex;
  flex-direction: column;
  padding: 0 3.75rem 0 0;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__left {
    padding: 0 2.75rem 0 0;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__left {
    padding: 0;
    padding: initial;
    width: 100%;
  }
}

.x-confirm-order__button {
  display: flex;
  justify-content: space-between;
  max-width: 41.8125rem;
  margin: 0 0 12.1875rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__button {
    margin: 0 0 2.8125rem 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__button {
    flex-direction: column;
    margin: 0 0 2rem 0;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__button {
    max-width: 75%;
    margin: 0 0 12.1875rem 3.75rem;
  }
}

.x-confirm-order__list {
  margin: 0 0 1rem 0;
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__list {
    max-width: 90%;
    margin: 0 0 1rem 3.75rem;
  }
}

.x-confirm-order__dados--container {
  margin: 0 0 0 3.75rem;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--container {
    margin: 0 0 0 2.75rem;
  }
}

@media only screen and (min-width: 1367px) {
  /* .x-confirm-order__dados--container {
    width: 100%;
  } */
}

.x-confirm-order__dados--intro {
  max-width: 44.0625rem;
  margin: 2.875rem 0 0 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__dados--intro {
    max-width: none;
    max-width: initial;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__dados--intro {
    padding: 0 0 0 3.75rem;
    max-width: 75%;
  }
}

.x-confirm-order__dados--square {
  margin-top: 1.5rem;
  margin-bottom: 1.75rem;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #0394db;
  display: block;
}

.x-confirm-order__dados--title {
  color: #00409a;
  font-size: 2rem;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--title {
    font-size: 1.25rem;
    text-align: center;
  }
}

.x-confirm-order__dados--paragraph {
  color: #424b4a;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 2.125rem 0;
}

.x-confirm-order__dados--paragraph_small {
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 0 0 2.125rem 0;
}

.x-confirm-order__dados--paragraph_small > a {
  color: #0056b3;
  text-decoration: underline;
}

.x-confirm-order__dados--paragraph_small > a:hover {
  color: #0056b3;
  text-decoration: underline !important;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--paragraph {
    font-size: 0.875rem;
  }
}

.x-confirm-order__dados--list {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.5;
  height: 3.125rem;
  align-items: center;
  padding: 0 0 0 1.25rem;
  max-width: 41.8125rem;
}

.x-confirm-order__dados--list:nth-of-type(2n + 1) {
  background: rgba(0, 102, 179, 0.1);
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--list {
    display: flex;
    flex-direction: column;
    align-items: initial;
    height: auto;
    height: initial;
    padding: 0.9375rem;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__dados--list {
    max-width: 100%;
  }
}

.x-confirm-order__dados--item {
  margin: 0 1.25rem 0 0;
}

.x-confirm-order__dados--item.is--large {
  font-weight: bold;
}

.x-confirm-order__dados--item.blue {
  color: #0066b3;
  font-weight: bold;
}

.x-confirm-order__dados--icon {
  margin: 0 0.625rem 0 0.625rem;
}

.x-confirm-order__dados--icon.is--svg {
  margin: 0rem 0.9375rem 0 0.625rem;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--icon {
    margin: 0 0 0 0.3125rem;
  }
}

.x-confirm-order__dados--view {
  margin: 0 0 2.6875rem 0;
  padding: 0 1.25rem 0 1.25rem;
  text-decoration: underline;
  color: #0066b3;
  font-size: 0.875rem;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--view {
    display: flex;
    justify-content: center;
    margin: 0;
    margin: initial;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__dados--view {
    margin: 0 0 2.6875rem 3.75rem;
  }
}

.x-confirm-order__dados--button {
  padding: 0.6875rem 0;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #ffffff;
  border-radius: 3.125rem;
  margin: 2.5rem 0 0 0;
  cursor: pointer;
  pointer-events: all;
}

.x-confirm-order__dados--button.is--corrigir {
  width: 9rem;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--button.is--corrigir {
    width: 100%;
    order: 1;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--button.is--confirmar {
    width: 100%;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--button:nth-of-type(1) {
    margin: 1.25rem 0 0 0;
  }
}

.x-confirm-order__dados--text {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin: 0 0 0 1.25rem;
}

.x-confirm-order__dados--text.is--text-corrigir {
  margin: 0 0 0 0;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--text.is--text-corrigir {
    margin: 0 30%;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--text {
    position: relative;
    margin: 0 auto;
  }
}

.x-confirm-order__right {
  display: flex;
  flex-direction: column;
  background: #f3f3f5;
  color: #424b4a;
  width: 40%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__right {
    width: 100%;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__right {
    width: 100%;
  }
}

.x-confirm-order__summary {
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  padding: 2.9375rem 3.75rem 6.75rem 2.75rem;
}

.x-confirm-order__summary--arrow {
  display: none;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__summary--arrow {
    display: block;
    position: relative;
    right: 30px;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-confirm-order__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__summary--arrow {
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    right: 10%;
    position: relative;
  }

  .x-confirm-order__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-confirm-order__summary--container.show-summary {
    display: inline;
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__summary--container.show-summary {
    display: inline;
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

.x-confirm-order__summary--intro {
  display: flex;
  flex-direction: column;
}

.x-confirm-order__summary--icon {
  align-self: center;
  margin: 0 0 2.25rem 0;
}

.x-confirm-order__summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.x-confirm-order__summary--paragraph {
  color: #424b4a;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
}

.x-confirm-order__summary--body {
  margin: 1.6875rem 0 0 0;
}

.x-confirm-order__summary--item {
  position: relative;
}

.x-confirm-order__summary--item:not(:last-child) {
  border-bottom: 1px solid #707070;
}

.x-confirm-order__summary--link {
  color: #00518b;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0.625rem 1rem 0;
  display: block;
  cursor: pointer;
  pointer-events: all;
}

.x-confirm-order__summary--link:after {
  content: "\002B";
  color: #0066b3;
  position: absolute;
  right: 0;
  font-size: 1.5em;
  top: 0.8125rem;
}

.x-confirm-order__summary--link.active:after {
  content: "\2212";
}

.x-confirm-order__summary--card {
  display: flex;
  justify-content: space-between;
}

.x-confirm-order__summary--card.left:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-confirm-order__summary--card.right {
  display: flex;
  justify-content: space-between;
}

.x-confirm-order__summary--card.right:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-confirm-order__summary--card.shape {
  display: flex;
  justify-content: space-between;
}

.x-confirm-order__summary--card.shape:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-confirm-order__summary--text {
  margin: 1.25rem 0 0.625rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
}

.x-confirm-order__summary--text-2 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.x-confirm-order__summary--text.no--gap {
  margin-bottom: 0;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__summary--text {
    font-size: 0.775rem;
  }
}

.x-confirm-order__summary--subitem {
  padding: 0.625rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.x-confirm-order__summary--content {
  display: none;
  margin: 0 0 0 0;
}

.x-confirm-order__summary--content.show {
  display: flex;
  flex-direction: column;
}

.x-confirm-order__summary--group {
  display: flex;
  justify-content: space-between;
}

.x-confirm-order__summary--side-left {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin: 0 1.875rem 0 0;
}

.x-confirm-order__summary--side-right {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.x-web-ordering-pedido__frame--item input[type="radio"] {
  display: none;
}

.x-web-ordering-pedido__frame--item label {
  border-radius: 17px;
  width: 8.4rem;
  text-align: center;
  border: 1px solid transparent;
}

.x-web-ordering-pedido__frame--item input[type="radio"]:checked + label {
  border: 1px solid #0394db;
}

.x-web-ordering-pedido__frame--item svg {
  vertical-align: middle;
  height: 5.5rem;
}

.x-confirm-order__dados--button a,
.x-confirm-order__dados--button a:hover,
.x-confirm-order__dados--button label {
  color: #fff !important;
}

.cod-modelo-armacao {
  text-align: center;
  font-size: 12px;
  color: #212529;
  font-family: "Montserrat", sans-serif;
}

.x-confirm-order__main .hide-termoscompra {
  display: none;
}

.x-confirm-order__main .show-termoscompra {
  display: content;
  opacity: 1;
  pointer-events: all;
  background: #fff;
  z-index: 99999;
  position: fixed;
  width: 745px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 60px;
  max-height: 80%;
  border: 2px solid #f8f8f8;
  border-radius: 5px;
  -webkit-transition: 0.5s bottom, 0.5s opacity;
  transition: 0.5s bottom, 0.5s opacity;
}

.termoscompra-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

/* Css novo */

h2.order-title {
  color: #424b4a;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 766px) {
  h2.order-title {
    margin-top: 20px;
  }
}

p.order-paragraph {
  color: #424b4a;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
}

p.order-paragraph.right {
  text-align: right;
  margin-right: 1rem;
}

.order-buttons-confirm {
  padding: 0 3.75rem 0 3.75rem !important;
}

.order-buttons {
  display: flex;
}

p.order-status-voucher {
  margin: 2.4rem 0 0 1rem;
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

p.order-status-voucher.green {
  color: green;
}

p.order-status-voucher.red {
  color: red;
}

.order-next-button {
  width: 11.125rem;
  margin-left: auto;
  margin-top: 2rem;
  margin-right: 2rem;
  padding-left: 0.5rem;
  padding-right: 12rem;
}

.order-next-button.detail {
  padding-right: 12rem;
  float: none;
}

.order-next-button.confirm {
  margin-right: 3rem;
}

.order-prev-button {
  width: 11.125rem;
  margin-right: auto;
  margin-top: 2rem;
  padding-right: 0.5rem;
}

input#codigoParticipante {
  padding-right: 40px;
}

.order-main-container {
  padding: 2rem 3.25rem 0 3.75rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 766px) {
  .order-main-container {
    padding: 1.5rem 2rem 0 1.5rem;
  }

  .x-web-ordering-pedido__main [type="text"],
  .x-web-ordering-pedido__main [type="number"],
  .x-web-ordering-pedido__main [type="email"] {
    margin-right: 1rem;
  }

  p.order-status-voucher {
    margin: 1rem 0 0 0;
  }
  .x-order-confirmed__container1 .button-mobile-only .crm-button-orange {
    margin: 0 auto;
  }

  .x-order-confirmed__container1 .button-mobile-only {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
    padding: 0 !important;
  }

  .button-mobile-only .crm-button-orange {
    width: 13.125rem !important;
  }

  .tooltip-voucher {
    margin: 0.5rem 0 0 0 !important;
    width: calc(100% - 1rem) !important;
  }
}

@media only screen and (max-width: 1100px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--body img {
    width: 305px;
    height: auto;
  }
}

.tooltip-voucher {
  visibility: visible;
  width: calc(33.3% - 1rem);
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%;
	left: 50%;
	margin-left: -60px; */
  /* opacity: 0; */
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  margin: 5.4rem 0 0 0;
}

.tooltip-voucher::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

@media screen and (max-width: 766px) {
  .tooltip-voucher::before {
    position: unset;
  }

  .tooltip-voucher {
    position: unset;
  }
}

.x-web-ordering-pedido__prescription .buttonyn_label {
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido .crm-select-input {
    margin: 1.375rem 1rem 0 0;
  }
}

ul#ul-rpl {
  list-style-position: inside;
  list-style: disc !important;
  width: 85%;
  margin: 0 auto;
  font-size: 90%;
}

.swal2-html-container {
  font-size: 95% !important;
}

.warn_message_voucher {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 50px;
  margin-top: 25px;
}

.warn_message_voucher svg {
  fill: #0394db;
}

.warn_message_voucher p {
  color: #424b4a;
  font-size: 0.65rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

@media only screen and (max-width: 675px) {
  .warn_message_voucher {
    margin-top: 0px;
  }
}

.colorInputRadio {
  -moz-appearance: none !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  position: relative;
  height: 0;
}

.colorInputRadio.activeSvgToInput:after {
  content: "";
  background-color: #005991;
  display: inline-block;
  width: 11px;
  left: 3px;
  top: 3px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
}

.colorInputRadio:before {
  content: "";
  display: none;
}

.colorInputRadio:checked:after {
  content: "";
  display: none;
}

.checkboxOne input[type="radio"]:checked + label {
  border: 1px solid #0394db;
  border-radius: 17px;
  width: 120px;
  margin-left: 20px;
  padding: 10px;
  height: 125px;
}

.checkboxOne input[type="radio"]:checked + .colorLabelRadio {
  border: 1px solid #0394db;
  border-radius: 17px;
  width: 120px;
  height: 140px;
}

.colorLabelRadio {
  border: 1px solid transparent;
  cursor: pointer;
  pointer-events: all;
  width: 120px;
  margin-left: 20px;
  padding: 10px;
  height: 140px;
}

.colorLabelRadio > img {
  width: 100px;
  height: 100px;
}

.logolab {
  text-align: center;
  background: -webkit-gradient(linear, left top, right top, color-stop(5%, rgba(243, 243, 245, 1)), color-stop(15%, rgba(255, 255, 255, 1)), color-stop(85%, rgba(255, 255, 255, 1)), color-stop(95%, rgba(243, 243, 245, 1)));
  background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
}

.colorLabelRadio > small {
  font-size: 12px;
}

@media only screen and (max-width: 786px) {
  .logolab {

    text-align: center;
    background: -webkit-gradient(linear, left top, right top, color-stop(5%, rgba(243, 243, 245, 1)), color-stop(15%, rgba(255, 255, 255, 1)), color-stop(85%, rgba(255, 255, 255, 1)), color-stop(95%, rgba(243, 243, 245, 1)));
    background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
  }
}

@media only screen and (max-width: 766px) {
  .order-next-button {
    width: unset !important;
  }

  .crm-button-blue,
  .crm-button-orange {
    width: 100% !important;
  }

  .order-next-button.detail {
    padding-right: 0;
    float: left;
  }

  .order-buttons {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    grid-gap: 1.2rem;
    gap: 1.2rem;
  }

  .x-confirm-order__dados--container .order-buttons {
    grid-gap: 0.32rem;
    gap: 0.32rem;
  }

  .order-next-button {
    width: 9rem !important;
    padding: 0;
    margin: 2rem 0 0 0;
  }

  .order-prev-button {
    width: 9rem !important;
    padding: 0;
    margin: 2rem 0 0 0;
  }

  .x-web-ordering-pedido__dados--card .crm-button-orange {
    margin: 0 auto;
  }

  p.order-paragraph.right {
    text-align: right;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .x-web-ordering-pedido__main .intro__form--title {
    margin-top: 20px;
  }

  .x-confirm-order__dados--container .order-buttons-confirm {
    padding: 0 !important;
  }
}
.order-lens-coloracao-cor {
  display: inline-flex;
}

.descricaoIMG {
  overflow-wrap: break-word;
}

.voucher-container {
  font-family: Arial, sans-serif;
  margin: 20px;
}


.voucher-info {
  margin-bottom: 20px;
  color: #aaaead;
    font-size: 0.9rem;
}


.voucher-table {
  margin: 0px;
}

.voucher-table th,
.voucher-table td {
    padding: 8px;
  text-align: center;
}
.voucher-table td {
  padding: 8px;
    font-size: small;
    text-align: center;
    border: none;
    background: white;
    color: #949999;
}

.voucher-table th {
  color: #0394db;
  background-color: white;
  font-weight: unset;
  font-size: small;
  border: none;
}

.pactoRadioButton::before{
  width: 14px !important;
  height: 15px !important;
}
.radio-button-container {
  justify-content: center;
}
.pactoRadioButton:checked:after{
  width: 8px !important;
  left: 3px !important;
  top: 40% !important;
  height: 8px !important;
}

.pactoRadioButton{
  width: 14px !important;
  height: 19px !important;
}
.crm-switch-container {
    display: flex;
    margin: 17px 0;
}

.crm-switch-obrigatorio {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    height: 0;
    padding-top: 45px;
    padding-bottom: 10px;
}

.crm-switch {
    justify-content: center;
    align-items: center;
}

.crm-switch-lable-bold {
    font-weight: bold;
}

.custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-36px + 2px);
    width: calc(1rem - 0px);
    height: calc(1rem - 0px);
    background-color: #adb5bd;
    border-radius: .5rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 40px;
    pointer-events: all;
    border-radius: .5rem;
    height: 20px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}
.buttonyn_radio--container {
	display: flex;
	align-items: center;
	margin: 0.7rem 0 0 0;
	overflow: hidden;
}

.buttonyn_label {
	margin: 0 0.9375rem 0 0;
	font-size: 0.875rem;
	font-family: 'Montserrat', sans-serif;
}

.buttonyn_radio--input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
  }

.buttonyn_radio--input:checked + .buttonyn_radio--label {
	background: #0394db;
	color: #fff;
	font-weight: bold;
	border: 1px solid rgba(0, 0, 0, 0);
}

.buttonyn_radio--label {
	color: #b3b7b7;
	font-family: 'Montserrat', sans-serif;
	font-size: 0.75rem;
	line-height: 1;
	text-align: center;
	padding: 0.75rem 1.3125rem;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

.buttonyn_radio--label.radio--left {
	border-top-left-radius: 2.375rem;
	border-bottom-left-radius: 2.375rem;
}

.buttonyn_radio--label.radio--right {
	border-top-right-radius: 2.375rem;
	border-bottom-right-radius: 2.375rem;
}

.buttonyn_radio--label.radio--label.is-radio--label-mobile {
	padding: 0.75rem 1.3125rem;
}

.buttonyn_radio--label:hover {
	cursor: pointer;
}

@media only screen and (max-width: 766px) {
	.buttonyn_radio--container {
		margin-right: 1.1875rem;
	}

	.buttonyn_label {
		font-size: 0.775rem;
		margin: 0 0.625rem 0 0;
	}

}
.crm-input {
  display: inline-flex;
  position: relative;
}

.auto-complete-input {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, -7px, 0);
  transform: translate3d(10px, -7px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
}

.auto-complete-tooltip-shape {
  top: 22px !important;
}

.crm-input-label {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
}

.crm-input textarea {
  box-shadow: 0 0 0 1px #b5b5b5 !important;
  border: medium none currentColor;
  border: initial;
  padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 0;
  width: 100%;
}

.crm-input textarea:-moz-read-only {
  color: #a6abaa;
  border-color: #cbcbcb;
}

.crm-input textarea:read-only {
  color: #a6abaa;
  border-color: #cbcbcb;
}

.crm-input textarea:-moz-read-only {
  border: 1px solid #cbcbcb;
  box-shadow: unset;
}

.crm-input textarea:read-only {
  border: 1px solid #cbcbcb;
  box-shadow: unset;
}

.crm-input textarea:disabled {
  background-color: unset;
}

.crm-input textarea {
  color: rgba(66, 75, 74, 0.8);
}

.tooltip-shape {
  position: absolute;
  width: 0.9375rem;
  height: 0.9375rem;
  right: 25px;
  top: 42px;
}

.tooltip-shape:before {
  content: "i";
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.625rem;
  color: #0394db;
  z-index: 15;
  border: 1px solid #0394db;
  border-radius: 50%;
  width: 0.9375rem;
  height: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  opacity: 1 !important;
}

.tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: relative;
  z-index: 5;
  left: 9.2rem;
  top: 4.5rem;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-shape:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 766px) {
  .crm-input {
    width: 100% !important;
  }

  .crm-input textarea {
    width: 100%;
    margin: 1.375rem 0 0 0;
  }
}



.buttonrl-radio--container {
	display: flex;
	align-items: center;
	margin: 0.7rem 0 0;
	overflow: hidden;
}

.buttonrl-radio--input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.buttonrl-radio--label {
	color: #b3b7b7;
	font-family: Montserrat, sans-serif;
	font-size: 0.75rem;
	line-height: 1;
	text-align: center;
	padding: 0.75rem 1.3125rem;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

.buttonrl-radio--input:checked + .buttonrl-radio--label {
	background: #0394db;
	color: #fff;
	font-weight: 700;
	border: 1px solid transparent;
}

.buttonrl-radio--left {
	border-top-left-radius: 2.375rem;
	border-bottom-left-radius: 2.375rem;
}

.buttonrl-radio--right {
	border-top-right-radius: 2.375rem;
	border-bottom-right-radius: 2.375rem;
}

.buttonrl-radio--label:hover {
	cursor: pointer;
}

@media only screen and (max-width: 766px) {
	.buttonyn_radio--container {
		margin-right: 1.1875rem;
	}

	.buttonrl-radio--container {
		width: 100%;
		justify-content: space-between;
		padding-right: 1rem;
	}

	.buttonrl-radio--container label {
		width: 100%;
		height: 3rem;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.buttonrl-radio--label {
		padding: 0.75rem 0.3125rem;
	}
}

.crm-input1 {
  display: inline-block;
  position: relative;
}

.crm-input-label1 {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
  top: 0px;
}

.container-auto-select {
  border: medium none currentColor;
  border: initial;
  position: relative;
  margin-top: 1.375rem;
}

.select__control:hover,
.select__control--is-focused:hover {
  border-color: #b5b5b5 !important;
}

.css-2b097c-container,
.css-14jk2my-container {
  margin-right: 1rem;
}

.css-yk16xz-control,
.css-yk16xz-control:hover {
  border-color: #b5b5b5 !important;
}

.css-yk16xz-control,
.css-1pahdxg-control,
.css-1fhf3k1-control {
  padding: 0.55rem 0;
}

.css-1fhf3k1-control {
  background-color: unset !important;
  border-color: #cbcbcb !important;
}

.css-2b097c-container input {
  position: relative;
}

.css-1okebmr-indicatorSeparator,
.css-109onse-indicatorSeparator {
  background-color: unset !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  color: #00639d !important;
}

.css-4ljt47-MenuList {
  max-height: 260px !important;
}

.css-26l3qy-menu {
  z-index: 99 !important;
}

@media only screen and (max-width: 766px) {
  .crm-input1 {
    width: 100% !important;
    margin-top: 10px;
  }

  .css-2b097c-container,
  .css-14jk2my-container {
    margin-right: 0;
  }
}

.upload-content-remote {
	padding: 0 15px 0 15px;
}

.upload-content-remote > .fileContainer {
	background: transparent !important;
	box-shadow: none !important;
	position: relative;
	border: 1px dashed #ccc;
	border-radius: 10px;
	padding: none !important;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 10px auto;
	-webkit-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	width: 100%;
	height: 85px;
}

.edge .tolltip {
	top: 0;
	right: 0;
}
.edge{
	position: absolute;
    width: 42%;
    height: 110px;
}

.edge .image-upload{
	width: 100%;
	position: inherit;
}


@media only screen and (max-width: 768px){
    .edge {
		width: 82%;
	}
}




.x-order-confirmed__container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 3.5625rem 0 0 0;
  font-family: "Montserrat", sans-serif;
}

.x-order-confirmed__container1 {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  /* padding: 3.5625rem 0 0 0; */
  font-family: "Montserrat", sans-serif;
}

.x-order-confirmed__title {
  margin: 1.0625rem 0 1.1875rem 0;
  font-size: 1.625rem;
  font-family: "Montserrat", sans-serif;
  color: #0066b3;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__title {
    font-size: 1.125rem;
  }

  .button-mobile-only {
    padding-right: 0;
  }
}

.x-order-confirmed__paragraph {
  display: flex;
  margin: 0 auto;
  text-align: center;
  font-size: 0.875rem;
}

.x-order-confirmed__paragraph:nth-of-type(1) {
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__paragraph {
    max-width: 15.5rem;
    font-size: 0.75rem;
  }
}

.confirmed-opacity {
  opacity: 0.2;
}

.x-order-confirmed__list {
  display: flex;
  justify-content: space-between;
  margin: 1.875rem 0 2.5rem 0;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__list {
    flex-direction: column;
    justify-content: center;
    max-width: 15.5rem;
    margin: 1.625rem 0 1.375rem 0;
  }
}

.x-order-confirmed__item {
  font-size: 0.875rem;
  background: white;
  padding: 1.1875rem 1.875rem;
}

.x-order-confirmed__item--number {
  color: #0066b3;
  font-weight: bold;
}

.x-order-confirmed__item--lab {
  font-weight: bold;
}

.x-order-confirmed__item:nth-of-type(1) {
  margin: 0 1rem 0 0;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__item {
    font-size: 0.75rem;
    padding: 1.25rem 1.125rem;
    text-align: center;
  }

  .x-order-confirmed__item:nth-of-type(1) {
    margin: 0 0 0.3125rem 0;
    padding: 0.8125rem;
  }
}

.x-order-confirmed__button {
  padding: 14px 0;
  border-radius: 3.125rem;
  margin: 0 0 0.875rem 0;
  display: flex;
  align-items: center;
  pointer-events: all;
  cursor: pointer;
}

.x-order-confirmed__button:hover {
  cursor: pointer;
}

.x-order-confirmed__button--text {
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0 1.25rem;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__button--text {
    order: 1;
    margin: 0rem 0 0rem 1.25rem;
  }
}

.x-order-confirmed__button--icon {
  margin: 0 1rem 0 1rem;
  order: 2;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__button--icon {
    margin: 0 0.625rem 0 1rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__button {
    padding: 0.625rem 0;
  }
}

.x-order-confirmed__link {
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
  text-decoration: underline;
  color: #424b4a;
  margin: 0 0 3.375rem 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__link {
    margin: 0 0 2.5rem 0;
  }
}

/* ********* SUMMARY PDF ********* */

.pdf-pedido__summary {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0rem 3.75rem 0rem 2.75rem;
  -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
  transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
}

.pdf-pedido__summary--intro {
  display: flex;
  flex-direction: column;
}

.pdf-pedido__summary--header {
  margin-top: 1rem;
}

.pdf-pedido__summary--barcode {
  float: right;
}

.pdf-pedido__summary--icon {
  align-self: center;
  margin: 0 0 1.25rem 0;
}

.pdf-pedido__summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.pdf-pedido__summary--paragraph {
  color: #424b4a;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
}

.pdf-pedido__summary--body {
  /* margin: 1.6875rem 0 0 0; */
}

.pdf-pedido__summary--item {
  position: relative;
  border-bottom: 1px solid #d8d8da;
}

.pdf-pedido__summary--item:not(:last-child) {
  border-bottom: 1px solid #707070;
}

.pdf-pedido__summary--link {
  color: #00518b !important;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0.625rem 1rem 0;
  display: block;
  cursor: default;
  text-decoration: none !important;
}

.pdf-pedido__summary--print {
  color: #0d81d3 !important;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0.625rem 1rem 0;
  display: block;
  cursor: pointer;
}

.pdf-pedido__summary--card {
  display: flex;
  justify-content: space-between;
}

.pdf-pedido__summary--card.left:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.pdf-pedido__summary--card.right {
  display: flex;
  justify-content: space-between;
}

.pdf-pedido__summary--card.right:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.pdf-pedido__summary--card.shape {
  display: flex;
  justify-content: space-between;
}

.pdf-pedido__summary--card.shape:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.pdf-pedido__summary--text {
  margin: 0.625rem 0 0.125rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
}

.pdf-pedido__summary--text-2 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.pdf-pedido__summary--text.no--gap {
  margin-bottom: 0;
}

.pdf-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 10rem;
}

.pdf-pedido__summary--side-left .pdf-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 10rem;
}

.pdf-pedido__summary--content {
  display: none;
  margin: 0 0 0 0;
}

.pdf-pedido__summary--content.show {
  display: flex;
  flex-direction: column;
}

.pdf-pedido__summary--group {
  display: flex;
  justify-content: space-between;
}

.pdf-pedido__summary--side-left {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin: 0 1.875rem 0 0;
}

.pdf-pedido__summary--side-right {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.pdf-pedido__summary--subitem.right {
  text-align: right;
  width: auto;
}
.pdf-pedido__summary--subitem.left {
  width: auto;
}

/*.x-confirm-order__list__pdf {
  width: 100%;
  margin: 1rem 0 1rem 0;
  background: rgba(0, 102, 179, 0.1);
}*/

.x-confirm-order__dados--list__pdf {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.5;
  height: 3.125rem;
  align-items: center;
  padding: 0 3.75rem 0 3.75rem;
  width: 100%;
}

.x-confirm-order__dados--item {
  margin: 0 1.25rem 0 0;
}

.x-confirm-order__dados--item.is--large {
  font-weight: bold;
}

.x-confirm-order__dados--item.blue {
  color: #0066b3;
  font-weight: bold;
}

.x-order-confirmed__list__pdf {
  display: flex;
  justify-content: space-between;
  /*margin: 1rem 0 2.5rem 0;*/
}

.x-order-confirmed__item__pdf {
  font-size: 0.875rem;
  background: white;
  padding: 1.1875rem 3.75rem;
  width: 49%;
}

.x-web-ordering-pedido__main .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0;
}

.card-body {
  padding: 0.25rem;
}



.pagination .previous.disabled {
  display: none;
}

.pagination .next.disabled {
  display: none;
}



.pagination-container {
  text-align: center
}

.pagination {
  margin: 15px auto;
  display: inline-flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: #0066b3 ;
  border-color: #0066b3 ;
  color: #fff;
}
.pagination > li > a{
  /* border: 1px solid #0066b3 ; */
  font-family: 'Montserrat', sans-serif;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #0066b3 ;
  border-color: #0066b3;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #0066b3;
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.search-media {
		margin-bottom: 30px;
	}
}


.x-downloads__search {
  display: flex;
  margin: 1rem 0 3.75rem 0;
}

.x-downloads__search--form {
  width: 100%;
}

.x-downloads__search--container {
  display: flex;
  align-items: center;
}

.x-downloads__search--field {
  display: flex;
  width: 100%;
}

.x-downloads__search--text {
  font-family: 'Open Sans';
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
}

.midia-search-button {
  border-radius: 1.875rem;
  width: 100%;
  height: 3.125rem;
  padding: 0 .9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  margin: 0 auto;
  margin-top: 1.75rem;
  -webkit-transition: all .155s ease-in;
  transition: all .155s ease-in;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.midia-search-button:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.284)
}

.limparFiltro{
  color:#0066b3
}

@media only screen and (max-width: 766px) {
  .x-downloads__search--container {
    flex-direction: column;
  }

  .x-downloads__search--field {
    width: 100%;
    flex-direction: column;
    text-align: center;
  }

  .x-downloads__search--field {
    margin: 0 0 0.625rem 0;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-downloads__search--container {
    flex-direction: column;
  }

  .image-upload {
    width: 35%;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 766px) and (min-width: 200px) {
  .image-upload {
    width: 75%;
    margin-top: 10px;
  }
}
.x-downloads__search--item {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
}

.x-downloads__search--label {
  font-size: 0.875rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.x-downloads__search--position-select {
  position: relative;
}

.x-downloads__search--select {
  max-width: 100%;
  -webkit-appearance: none;
  border: none;
  box-shadow: 0rem 0rem 0rem 0.125rem rgba(181, 181, 181, 0.5);
  padding: 0.75rem 0.875rem 0.75rem 0.4375rem;
  border-radius: 0.3125rem;
  position: relative;
  width: 100%;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  color: rgba(66, 75, 74);
  position: relative;
  z-index: 99;
  background: transparent;
}

.x-downloads__search--select:focus {
  outline: none;
  box-shadow: 0 0 2px 2px rgba(0, 89, 147, 0.4);
  border-radius: 0.3125rem;
}

.x-downloads__search--arrow:after {
  content: '';
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  border-right: 2px solid #005991;
  border-bottom: 2px solid #005991;
  position: absolute;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  right: 1rem;
  top: 50%;
  z-index: 1;
}

@media only screen and (max-width: 766px) {
  .x-downloads__search--item {
    margin: 0 0 1rem 0;
    max-width: 100%;
  }

  .x-downloads__search--label {
    margin: 0 0 0.5rem 0;
  }

  .x-downloads__search--select {
    width: 100%;
  }

  .x-downloads__search--arrow:after {
    top: 45%;
    right: 1.25rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-downloads__search--arrow:after {
    right: 25%;
  }
}

.x-downloads__search--input {
	-webkit-appearance: none;
	border: none;
	box-shadow: 0rem 0rem 0rem 0.125rem rgba(181, 181, 181, 0.5);
	padding: 0.75rem 0.875rem 0.75rem 0.4375rem;
	border-radius: 0.3125rem;
	position: relative;
	width: 100%;
	font-family: 'Open Sans';
	font-size: 0.875rem;
	color: rgba(66, 75, 74);
	position: relative;
	z-index: 99;
	background: transparent;
  }

  .x-downloads__search--input:focus {
	outline: none;
	box-shadow: 0 0 2px 2px rgba(0, 89, 147, 0.4);
	border-radius: 0.3125rem;
  }

  @media only screen and (max-width: 766px) {

	.x-downloads__search--input {
	  width: 100%;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-downloads__search--input {
		width: 100%;
	}
}

.x-downloads-not-files__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 8.25rem 0;
}
.x-downloads-not-files__body--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.x-downloads-not-files__body--svg {
  margin: 0 0 1.875rem 0;
  max-width: 100%;
}
@media only screen and (max-width: 766px) {
  .x-downloads-not-files__body--svg {
    max-width: 50%;
  }
}
.x-downloads-not-files__body--subtitle {
  font-size: 1.625rem;
  color: #0066b3;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 1.5625rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-downloads-not-files__body--subtitle {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 766px) {
  .x-downloads-not-files__body--subtitle {
    font-size: 0.9375rem;
  }
}
.x-downloads-not-files__body--paragraph {
  font-size: 0.875rem;
  color: #424b4a;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-downloads-not-files__body--paragraph {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 766px) {
  .x-downloads-not-files__body--paragraph {
    font-size: 0.6875rem;
  }
}

.media-center-card {
  box-shadow: 0 0 1px 1px rgba(181, 181, 181, 0.5);
  display: flex;
  flex-direction: column;
  margin: 0 1% 10% 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.media-center-card:hover 
.media-center-card-image {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
  opacity: 0.95;
  -webkit-transition: opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}

.media-center-card-bg {
  overflow: hidden;
}

.media-center-card-image {
  display: block;
  width: 17.375rem;
  max-width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}

.media-center-card-text {
  max-width: 17.375rem;
  font-size: 0.875rem;
  color: #0066b3;
  font-family: 'Montserrat', sans-serif;
  padding: 1.0625rem 1.0625rem 0 1.0625rem;
}

.media-center-card-text.text {
  height: 80px;
}

.media-center-card-text.is-text--lg {
  padding: 0.75rem 1.0625rem 0.75rem 1.0625rem;
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans';
  font-size: 0.875rem;
}

.media-center-card-field {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0.625rem 0;
}

.media-center-card-button {
  background: #0394db;
  background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
  background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
  width: 90%;
  justify-content: center;
  position: relative;
}

.media-center-card-svg {
  position: absolute;
  right: 0.625rem;
}

.flex-column {
  flex-direction: column;
}

img.media-center-card-image {
  height: 250px;
  object-fit: contain;
}

.card-recordname {
  font-size: 10px;
  color: rgb(171, 171, 171);
  z-index: 10;
  padding: 7px;
  top: 222px;
  right: 17px;
}

@media only screen and (max-width: 766px) {
  .media-center-card-image {
    width: 150px;
    height: 100%;
  }

  .media-center-card-text {
    font-size: 0.75rem;
  }

  .flex-row--mobile {
    justify-content: space-between;
    flex-direction: row;
    box-shadow: 0 0 2px 1px rgba(181, 181, 181, 0.5);
    padding: 0 0.625rem 0 0;
  }

  .display-contents--mobile {
    display: contents;
  }

  .flex-column {
    flex-direction: row !important;
  }

  .media-center-card-bg {
    overflow: hidden;
    margin-right: 15px;
  }

  img.media-center-card-image {
    height: 100px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .media-center-card-text.is-text--lg {
    margin: 0 1.25rem 0 0;
  }

  .media-center-card-text {
    font-size: 0.75rem;
  }
}

.img-modal{
    width: 100%;
}

.media-center-download-image {
    display: flex;
    height: 100%;
    flex-flow: column-reverse;
}

.media-center-share-image {
    display: none;
}

.modal-mobile-only {
    background: transparent;
    display: contents;
}

.media-center-legenda {
    margin-top: 10px;
}

.media-center-download-image .media-center-card-button {
    width: 100%;
    vertical-align: baseline;
}

.media-center-modal-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.media-center-modal-flex .float-end {
    text-align: end;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0 transparent;
}

.media-center-modal-copy-clipboard {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}

.modal-copiar {
    cursor: pointer;
}

@media only screen and (max-width: 766px) {
    .modal-mobile-only {
        background: #efefef;
        padding: 13px 0;
        display: inline;
    }

    .media-center-download-image {
        display: none;
    }

    .media-center-share-image {
        display: block;
        margin: 10px 0!important;
        color: #999;
        font-size: 12px;
    }
}
.media-center-img-preview {
    /* height: 450px; */
    width: 70%!important;
}

.carousel .slide {
    background: transparent !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000 !important;
}

.media-center-preview {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .img-modal{
    width: 100%;
}

.media-center-preview .carousel {
    width: 100%!important;
    padding-bottom: 20px;
} */

/* .media-center-preview .carousel.carousel-slider {
    display: flex;
    justify-content: flex-start;
} */

/* @media (min-width: 960px){
    .carousel .control-dots {
        bottom: -15px;
    }
}

.carousel .control-dots {
    position: absolute;
    bottom: -15px;
    margin: 10px 0;
    text-align: center;
    width: 100%;
}

.media-center-preview .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    opacity: 1;
    width: 10px;
    height: 10px;
}

.media-center-preview  .carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: 1;
    box-shadow: 0px 0px 0px rgba(0,0,0,.9);
    background: #0066B3;
    border-radius: 0%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
} */

.media-center-preview .carousel .control-arrow, .carousel .carousel-slider .control-arrow {
    -webkit-transition: all .25s ease-in;
    transition: all .25s ease-in;
    opacity: 1;
    z-index: 2;
    /* top: 20px; */
    background: 0 0;
    border: 0;
    /* font-size: 32px; */
    cursor: pointer;
    position: absolute !important;
}

/* .media-center-preview .carousel .control-arrow:before, .media-center-preview  .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: '';
}

.media-center-preview  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}

.media-center-preview  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #0066B3;
}

.media-center-preview  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #0066B3;
}

@media (min-width: 768px) {
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
        margin: 0 auto;
    }
} */

.media-center-banner img {
    width:100%;
    height:auto;
    margin: 10px 0;
}

.media-center-banner img:hover {
    cursor:pointer;
}

.media-center-banner .mobile {
    display:none;
}

.media-center-banner .desktop {
    display:block;
}

@media only screen and (max-width: 766px) {

    .media-center-banner .mobile {
        display:block;
    }

    .media-center-banner .desktop {
        display:none;
    }
}

.x-user-consultant__container {
  display: flex;
  flex-direction: column;
  margin: 0 3.75rem 0 3.75rem;
}


.x-user-consultant__body {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 766px) {
  .x-user-consultant__container {
    margin: 0 1.875rem 0 1.875rem;
  }

  .x-user-consultant__body {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-user-consultant__body {
    flex-direction: column;
  }
}

.gestao-papeis .btn-link {
  font-weight: 400;
  color: #212529 !important;
  text-decoration: none !important;
}

.gestao-papeis .btn-link:hover {
  color: #212529 !important;
  text-decoration: none !important;
}

.roles-box {
  width: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.x-user-consultant__left {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
}

.x-user-consultant__title {
  font-size: 2.25rem;
  color: #0066b3;
  margin: 2.375rem 0 0 0;
}

.x-user-consultant__shape {
  width: 0.625rem;
  height: 0.625rem;
  background: #0394db;
  margin: 1.6875rem 0;
}

.x-user-consultant__paragraph {
  font-size: 1rem;
  color: #424b4a;
}

.x-user-consultant__paragraph a {
  text-decoration: underline !important;
  font-weight: 600;
  color: #0394db !important;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-user-consultant__title {
    margin: 2.375rem auto auto auto;
  }

  .x-user-consultant__shape {
    margin: 1.6875rem auto 1.6875rem auto;
  }

  .x-user-consultant__paragraph {
    max-width: 100%;
    text-align: center;
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-user-consultant__title {
    font-size: 1.75rem;
    margin: 2.375rem auto auto auto;
    text-align: center;
  }

  .x-user-consultant__shape {
    margin: 1.6875rem auto;
  }

  .x-user-consultant__paragraph {
    max-width: 95%;
    margin: 0 auto;
    font-size: 0.875rem;
    text-align: center;
  }
}

thead > tr > th{
    background-color: #424B4A;;
    border-color: #454d55;
    color: #fff;
}

.roles-management-table-header-th, .roles-management-table-body-tr{
min-width: 210px;
    max-width: 210px;
    word-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-wrap: balance;
}
.essilor-table{
    width: 100%;
    border-collapse: collapse;
}
.table-container{
    max-width: 100%; 
    overflow-x: auto;
}
tbody > tr > td{
    vertical-align: middle !important;
    text-wrap: pretty;
}

.btn-excluir{
    font-size: 0.875rem;
}

.roles-select {
    font-size: 1rem;
    color: #707070;
}

.roles-select {
    width: 100%;
    border-radius: 6px;
    padding: 10px 1.375rem;
    position: relative;
    border: 1px solid #B5B5B5;
    background: transparent;
    z-index: 1;
}

.roles-select-arrow {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #00518B;
    border-right: 2px solid #00518B;
    bottom: 20px;
    right: 1.25rem;
    z-index: 1;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.roles-select-option {
    padding: .625rem 0;
}

.x-user-consultant__intro--form {
  display: flex;
  align-items: center;
}

.x-user-consultant__intro--container {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.x-user-consultant__intro--title {
  margin: 0 0 1.25rem 0;
  font-family: 'Open Sans';
  color: #424b4a;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
}

.x-user-consultant__intro--input {
  -webkit-appearance: none;
  border: none;
  box-shadow: 0rem 0rem 0rem 0.125rem rgba(181, 181, 181, 0.5);
  padding: 0.9375rem 1.875rem 0.9375rem 1.4375rem;
  border-radius: 0.3125rem;
  position: relative;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  color: #000;
  position: relative;
  z-index: 99;
  background: transparent;
  margin: 0 0 1.875rem 0;
  max-width: 100%;
}

.x-user-consultant__intro--input:focus {
  outline: none;
  box-shadow: 0 0 2px 2px rgba(0, 89, 147, 0.4);
  border-radius: 0.3125rem;
}

.x-user-consultant__intro--button {
  position: relative;
  top: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Open Sans';
  color: #fff;
  font-weight: bold;
  width: 15%;
  max-width: 100%;
  cursor: pointer;
  pointer-events: all;
  padding: 0.625rem;
  background: #00518b;
  border-radius: 50px;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-user-consultant__intro--form {
    margin: 1.875rem 0 0 0;
  }

  .x-user-consultant__intro--container {
    width: 50%;
  }

  .x-user-consultant__intro--button {
    width: 50%;
  }
}

@media only screen and (max-width: 766px) {
  .x-user-consultant__intro--form {
    margin: 1.875rem 0 0 0;
    flex-direction: column;
  }

  .x-user-consultant__intro--container {
    width: 100%;
  }

  .x-user-consultant__intro--title {
    text-align: center;
  }

  .x-user-consultant__intro--input {
    max-width: 100%;
    margin: 0 0 1.25rem 0;
  }

  .x-user-consultant__intro--button {
    width: 100%;
  }
}

.form-check-input:checked {
  background-color: #0394db !important;
  border-color: #0394db !important;
}
.MenuHorizontal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MenuHorizontal_menu-options {
  width: 60%;
  margin-right: 14px;
}

.MenuHorizontal_menu-itens {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 50px 3px 50px;
}

.MenuHorizontal_menu-itens button {
  width: 50%;
  background-color: rgba(0, 64, 154, 0.07);
  color: #00518b !important;
  padding: 10px 20px;
  border: 2px solid #00518b;
  margin: 1px 5px;
  line-height: 1.5;
  font-family: "Open Sans";
  font-size: 1em;
  font-weight: bold;
}

.MenuHorizontal_menu-itens .selected {
  color: #fff !important;

  background-color: #00518b;
}
.MenuHorizontal_menu-itens button:hover {
  color: #fff !important;
  border: 2px solid rgb(0, 148, 219);
  background-color: rgb(0, 148, 219);
}
.MenuHorizontal_selected-page {
  width: 96%;
}

@media only screen and (max-width: 1110px) {
  .MenuHorizontal_menu-itens button {
    width: 100%;
  }
  .MenuHorizontal_menu-options {
    width: 80% !important;
  }
}
@media only screen and (max-width: 620px) {
  .MenuHorizontal_menu-itens {
    display: flex;
    flex-direction: column;
    margin: 50px 3px 50px;
  }
}

.media-upload {
    width:100%;
    height: 100vh;
    position: relative;
    color: #333333;
    font-weight: normal;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
}

.media-upload-login{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.media-upload-content {
    width: 60%;
    margin: 20px auto;
}

.media-upload-img-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-upload-input {
    width: 130px;
    margin: 0 5px;
}

.media-upload h1{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0px;
    white-space: nowrap;
    color: #2c2926;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
}


.media-upload-box {
    width: 100%;
}

.media-upload-box > .fileContainer{
    background: transparent !important;
    box-shadow: none !important;
    position: relative;
    border: 1px solid #DFE4E7;
    border-radius: 2px 0 0 2px;
    padding: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px auto;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    width: 100%;
    height: 100px;
}

.btn-upload{
    background: transparent !important;
    border-radius: 30px;
    color: #00688c !important;
    border-radius: 10px !important;
    width: 100%;
    height: 100px;
    font-weight: normal;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
}

.picturesWrapper {
    width: 100%;
}


.pictureContent {
    flex-wrap: wrap;
    background: transparent !important;
    position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px auto;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    width: 100%;
    height: 85px;
}

.pictureContainer {
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    position: relative;
}

.pictureFile {
    width: auto;
    height: 80%;
}

.media-upload-removeFile{
    position: absolute;
    top: -9px;
    right: -9px;
    color: #000;
    background: transparent;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    width: 20px;
    height: 20px;
}

.media-upload-input-text {
    font-weight: normal;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: #DFE4E7;
    color: #333333;
    border-radius: 2px;
    background-color: #FCFDFE;
    border: 1px solid #DFE4E7;
    padding: 6px 5px 5px 5px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 28px;
    margin: 0px;
    margin-bottom: 25px;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
}

.media-upload-loading {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.media-upload-add {
    color:#ddd;
    font-size: 35px;
    line-height: 35px;
    width: 47px;
    padding: auto;
    border: 7px solid #ddd;
    border-radius: 50%;
    text-align: center;
    margin-left: 10px ;
}

.media-upload-add:hover {
    cursor: pointer;
}

.show-media-preview {
    cursor: pointer;
    color: blue;
}
.doc-main {
    padding: 0 5.75rem;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 1.75rem;
	display: flex;
}

.doc-menu {
	width: 25%;
	margin-right: 30px;
}

.doc-body {
	width: 100%;
}

.doc-paragraph {
    font-size: 0.925em;
    color: #313131;
    margin: 1.3125rem 0;
}

.doc-paragraph b {
	color: #313131;
	font-weight: 600;
}

.doc-menu ul {
	width: 100%;
	list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #d3d3d3;
}

.doc-menu ul li {
    display: block;
    color: #000;
    padding: 8px 0 8px 16px;
    text-decoration: none;
}

.doc-menu ul li:hover {
    background-color: #555;
    color:white;
	cursor: pointer;
}

.doc-main pre {
	font-family: Consolas,"courier new";
	color: crimson;
	padding: 15px;
	font-size: 90%;
	background-color: rgb(236, 236, 236);
}

.doc-info-box {
	border: 1px solid #00FF7F;
	border-radius: 5px;
	background-color: #98FB98;
	padding: 10px;
	font-weight: bold;
	color: #555;
	margin-bottom: 0rem;
}

.doc-info-box:after {
	content: '';
	float: right;
	border: solid #555;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	margin-top:7px;
	transform: rotate(45deg);
  	-webkit-transform: rotate(45deg);
}

.doc-info-box:hover {
	cursor: pointer;
}

.doc-main table {
	width: 100%;
	margin-top: 10px;;
}

.doc-main table, .doc-main th, .doc-main td {
	border: 1px solid rgb(167, 167, 167);
	border-collapse: collapse;
}

.doc-main th, .doc-main td {
	padding: 5px;
	text-align: left;
}
.switch-container {
    display: flex;
    margin: 17px 0;
}

.switch-container p {
	margin-bottom: 0;
}

.switch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
}

.custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-36px + 2px);
    width: calc(1rem - 0px);
    height: calc(1rem - 0px);
    background-color: #adb5bd;
    border-radius: .5rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 40px;
    pointer-events: all;
    border-radius: .5rem;
    height: 20px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}
.crm-input1 {
  display: inline-block;
  position: relative;
}

.crm-input-label1 {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
  top: 0px;
}



.css-yk16xz-control,
.css-yk16xz-control:hover {
  border-color: #b5b5b5 !important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  padding: 0.55rem 0;
}

.css-1okebmr-indicatorSeparator {
  background-color: unset !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  color: #00639d !important;
}


.dash .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff !important;
    border-bottom: none !important;
}
.dataModule-dashboardLab{
    display: flex;
    justify-content: space-around;
 }

 .containerDados{
    display: flex;
    justify-content: space-evenly;
 }
 @media only screen and (max-width: 620px) {
    .dataModule-dashboardLab {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
  }
  
.graph-titulo{
    display: flex;
    justify-content: center;
}

.graph-titulo p{
    font-size: larger;
    font-weight: 600;
    color: #888585;
}
.total{
  font-weight: 600 !important;
  display: flex;
  justify-content: flex-end;
}
.customized-tooltip-content{
  margin-bottom: 5px;
  background-color: white;
  padding:  5px 10px 0px 10px;
  border: 1px solid black ;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label-portal-conecta {
    font-weight: bold;
    fill: #abc7fc;
  }
  
  .label-optisoul {
    font-weight: bold;
    fill: #dfdfdf;
  }
  
  .label-shop9 {
    font-weight: bold;
    fill: #5a2400;
  }
.tagADownloadComponent {
	text-decoration: underline !important;
	color: #0d6efd;
	font-size: inherit;
}
.CleanAnchorFilter{
    display: flex;
    align-items: center;
}
/* CustomSelect.css */
.custom-select-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  align-items: flex-end;
  margin: 8px;
  max-width: 300px;
  min-width: 120px;
}
.p-custom-select-container{
  font-size: small;
    position: absolute;
    left: 0;
    top: 0;
    color: #757575;
}
.custom-select-container > .p-custom-select-container + .select-box {
  margin-top: 24px;
}

.custom-select-container > .select-box{
  margin-top: 0;
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #949494;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow: auto;
  min-height: 0px;
  margin-bottom: 23px;
}

.chip {
  overflow: hidden;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 5px 10px;
  margin: 2px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.dropdown-arrow {
  content: "";
  position: absolute;
  top: 35px;
  right: 3px;
  width: 11px;
  height: 6px;
  background-color: #757575;
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
}
.dropdown-menu-select {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  width: 200px;
}

.group {
  flex-direction: column;
}

.group-header {
  color: #949494;
  background-color: #f1f1f1;
  padding: 5px;
  font-weight: bold;
}

.dropdown-item {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.dropdown-item.selected {
  background-color: #ebebeb;
}

.select-box {
  width: 100%;
  min-height: 49px;
  margin-top: 37px;

}

.p-select-box {
  color: #757575;
  margin: 0;
}

.graph-container{
    height: 343px;
    border: 2px solid #b0c4de;
}


#responsiveContainer{
    max-height: 400px !important;
}

.filtrosSuperiores{
    display: flex;
    justify-content: flex-end;
}
.underlined-text {
    border-bottom: 2px solid #c9c9c9;
    padding-bottom: 7px;
}
p.totalValue {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    font-family: monospace;
}

.containerModulo{
    width: 100%;
}

.cardImg{
    display: flex;
    justify-content: center;
}

.cardValue{
    display: flex;
    margin: auto;
}

.value{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.totalTitulo{
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    font-family: monospace;
    margin: 0;
}
.main-lab {
    padding: 0 5.75rem;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 1.75rem;
}

.main-lab .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important; 
    margin-left: 0 !important; 
}

@media only screen and (max-width: 766px){
    .main-lab {
        padding: 0 1.75rem;
    }
}
.lab-title {
    font-size: 2.25rem;
    color: #0066B3;
    margin: 40px 0 0 0;
    position: relative;
    z-index: 1;
    font-weight: bold;
}

.lab-shape {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background: #0394db;
    position: relative;
    margin: 10px 0;
}

.lab-paragraph {
    font-size: 1rem;
    color: #424B4A;
    position: relative;
    z-index: 1;
}

.lab-svg{
    width: 100%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .lab-title {
        align-self: flex-start;
    }

    .lab-shape  {
        align-self: flex-start;
    }

    .lab-paragraph {
        max-width: 100%;
        text-align: left;
        font-size: 0.875rem;
    }
}

@media only screen and (max-width: 766px) {
    .lab-title {
        font-size: 1.75rem;
        margin: 2.375rem auto auto auto;
        text-align: center;
    }

    .lab-shape {
        margin: 1.6875rem auto;
    }

    .lab-paragraph {
        max-width: 95%;
        margin: 0 auto;
        font-size: 0.875rem;
        text-align: center;
    }
}
.lab-form {
    display: flex;
    align-items: center;
}

.lab-form-container {
    display: flex;
    flex-direction: column;
    width: 50%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .lab-form-container {
        width: 100%;
    }
}

@media only screen and (max-width: 766px) {
    .lab-form {
        margin: 0 0 0 0;
        flex-direction: column;
    }

    .lab-form-container {
        width: 100%;
    }
}
.lab-card {
	display: inline-grid;
	box-shadow: 0 0 0 2px rgb(181 181 181 / 40%);
	border-radius: 0.1875rem;
	padding: 1rem 1rem 1.25rem 1rem;
	background: #fff;
	width: 100%;
	height: 350px;
}

.lab-card-image {
	margin: 0 auto;
    max-width: 100%;
	max-height: 96.23%;
}

.lab-card-title {
	font-size: 0.75rem;
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	line-height: 2;
	font-weight: bold;
}

.lab-card-paragraph {
	font-size: 0.75rem;
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	line-height: 2;
	margin-bottom: 0px !important;
	word-break: break-all;
}

.lab-card-link {
	font-size: 0.75rem;
	font-family: "Montserrat", sans-serif;
	color: #0066b3;
	line-height: 1.5;
	font-weight: bold;
	text-decoration: underline;
}

@media only screen and (max-width: 766px) {
    .lab-card {
        flex-direction: column;
        margin: 0.7rem 0px;
		width: 105.5%;
		word-wrap: break-word;
    }

    .lab-card-title {
		line-height: 1.5;
    }

    .lab-card-paragraph {
		line-height: 1.5;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.lab-card-title {
		font-size: 0.6875rem;
	}

	.lab-card-paragraph {
		font-size: 0.6875rem;
	}

	.lab-card-link {
		font-size: 0.6875rem;
	}
}

.alert{
    margin-bottom: 0px !important;
}
.pedido-atacado .badge {
  border-radius: 25px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: #0394db !important;
}

p.x-web-ordering-pedido__summary--paragraph.mt- {
  color: #424b4a;
  font-size: 0.775rem;
  font-family: "Montserrat", sans-serif;
}

#logoAtacado {
  text-align: center;
}

.pedido-atacado .divAtacado {
  /* max-width: 290px !important; */
  /* width: 100% !important; */
  /* padding: 0 0.5rem !important; */
}

.pedido-atacado .atacado-receita-olho {
  /* max-width: 270px;
  width: 265px; */
  /* min-width: 260px; */
  margin-top: -6px;
}
.pedido-atacado .atacado-receita-olho .atacado-label-olho {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
          transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: relative;
  z-index: 2;
  padding: 0 5px;
  top: 0px;
  margin-bottom: 5px;
}

.pedido-atacado .crm-button-blue::-moz-selection {
  outline: -webkit-focus-ring-color auto 1px !important;
}

.pedido-atacado .crm-button-blue::selection,
.pedido-atacado .crm-button-blue:focus,
.pedido-atacado .crm-button-blue:focus-within,
.pedido-atacado .crm-button-blue:focus-visible,
.pedido-atacado .crm-button-blue:target {
  outline: -webkit-focus-ring-color auto 1px !important;
}

.pedido-atacado button:focus:not(:focus-visible) {
  outline: none;
}

.pedido-atacado .crm-button-blue:focus:not(:focus-visible) {
  outline: none;
}

.pedido-atacado .my-5 {
  margin-top: 0 !important;
}

.pedido-atacado .titulo-sessao {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

.pedido-atacado .novo-produto {
  color: #0394db;
  font-weight: bold;
}

.pedido-atacado .btnNovoProduto {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 1rem;
}

.pedido-atacado .tabela-produtos {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 2rem;
  /* overflow-x: auto; */
}

.pedido-atacado .dioptria-section .crm-input1 {
  /* max-width: 290px !important;   */
}

.pedido-atacado input#quantidade {
  margin-left: 3px !important;
}

.titulo-olho-dioptria {
  margin-top: -0.25rem;
  margin-bottom: 0.25rem;
  color: #00518b;
  font-size: 14px !important;
}

.pedido-atacado .btn-dash-plus {
  color: #0394db !important;
}

.pedido-atacado .btn-dash-plus > svg {
  color: #0394db !important;
}

.pedido-atacado .icon-dash-plus {
  color: #0394db !important;
  /* margin-top: 20px; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pedido-atacado input {
  margin-right: 0px !important;
}

.pedido-atacado .labSelect {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-transition: linear 0.4s;
  transition: linear 0.4s;
}

.pedido-atacado .select__control:hover,
.pedido-atacado .select__control--is-focused:hover {
  border-color: #b5b5b5 !important;
}

.pedido-atacado .labSelect:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  -webkit-transition: linear 0.4s;
  transition: linear 0.4s;
}

.pedido-atacado .logolab {
  position: unset !important;
  width: 100% !important;
  text-align: center;
  right: 0;
  top: 200px;
  background: rgb(243, 243, 245);
  background: -webkit-gradient(linear, left top, right top, color-stop(5%, rgba(243, 243, 245, 1)), color-stop(15%, rgba(255, 255, 255, 1)), color-stop(85%, rgba(255, 255, 255, 1)), color-stop(95%, rgba(243, 243, 245, 1)));
  background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
}
.row.atacado-buttons.atacado-buttons-confirm {
  max-width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.red-border {
  border: red 1px solid !important;
  border-radius: 5px !important;
}

.red-border > div {
  border: none;
  border-radius: 5px !important;
}

.pedido-atacado #tipo_produto {
  margin: 1.375rem 0 1.25rem 0;
}

.pedido-atacado #inputProduto {
  margin-right: 0 !important;
}

.pedido-atacado #dados_empresa_faturamento_cnpj {
  margin-right: 0 !important;
}

.pedido-atacado .inputDiv {
  padding: 0 !important;
}

.pedido-atacado .crm-input1 .container-auto-select > div {
  margin-right: 0 !important;
}

.pedido-atacado .inputQntTable {
  margin-right: 0px !important;
  width: 50px;
  background: transparent;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  border: 1px solid rgb(128, 128, 128);
}

.x-order-confirmed-atacado .button-mobile-only {
  margin: 0 0 1rem;
  width: 14rem;
}

@media (max-width: 767px) {
  .row.atacado-buttons.atacado-buttons-confirm {
    max-width: 100%;
    flex-direction: column;
  }
  .product-list-td {
    padding: 0 20px !important;
  }
  .pedido-atacado .inputDiv {
    width: 40%;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-right: 5px;
  }
}

/*@media (max-width: 767px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    /* border-bottom: 3px solid #ddd; */
/*   display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
   
    font-size: 0.8em;
    text-align: initial;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }

  table td:last-child {
    border-bottom: 0;
    text-align: center;
  }

  .table > :not(caption) > * > * {
    padding: 0.4rem 0.4rem;
  }

  td:nth-of-type(4)::before {
    margin-top: 5px;
  }
}
*/
@media (min-width: 1200px) {
  .dioptria-section .col-xl-3 {
    max-width: 290px !important;
    /* width: 100% !important; */
    padding: 0 0.5rem !important;
  }
  .dioptria-section .col-xl-2 {
    max-width: 180px;
    padding: 0 0.5rem;
  }
}

@media (max-width: 1400px) {
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios {
    display: flex;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    min-height: 55.785px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    flex-flow: wrap row;
    justify-content: unset;
  }

  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios .radio-button-container {
    margin: 5px 1px 1px 5px;
  }
}
@media (min-width: 1401px) {
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios {
    display: flex;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    justify-content: space-around;
    min-height: 55.785px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    flex-flow: unset;
  }

  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios .radio-button-container {
    margin: 0 0 0 3.7px;
  }
}
@media (max-width: 767px) {
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios {
    display: block;
    min-width: 100px;
  }
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios .radio-button-container:first-of-type {
    margin-top: 10px !important;
  }
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios .radio-button-container {
    margin: 5px 10px;
  }
}

@media (min-width: 766px) and (max-width: 1299px) {
  .pedido-atacado .tabela-produtos {
    width: 100%;
  }
}
@media (min-width: 1300px) and (max-width: 1359px) {
  .pedido-atacado .tabela-produtos {
    width: 98%;
  }
}
@media (min-width: 1360px) and (max-width: 1400px) {
  .pedido-atacado .tabela-produtos {
    width: 96%;
  }
}
@media (min-width: 1401px) and (max-width: 1440px) {
  .pedido-atacado .tabela-produtos {
    width: 94%;
  }
}
@media (min-width: 1441px) and (max-width: 1470px) {
  .pedido-atacado .tabela-produtos {
    width: 92%;
  }
}
@media (min-width: 1471px) and (max-width: 1500px) {
  .pedido-atacado .tabela-produtos {
    width: 90%;
  }
}
@media (min-width: 1501px) {
  .pedido-atacado .tabela-produtos {
    width: 85%;
  }
}
@media (min-width: 1365px) {
  .row.atacado-buttons.atacado-buttons-confirm {
    padding: 0 0 0 3.75rem;
  }
}
@media only screen and (max-width: 766px) {
  .pedido-atacado .x-confirm-order__left {
    padding: 0;
  }
  .pedido-atacado .x-confirm-order__dados--item.is--large {
    margin-left: 10px;
  }
  .pedido-atacado .x-confirm-order__dados--item.blue {
    margin-left: 10px;
  }
  .pedido-atacado .atacado-buttons {
    display: block;
  }
  .pedido-atacado .atacado-buttons {
    padding: 0 !important;
  }
  .pedido-atacado .atacado-prev-button {
    width: auto;
    margin: 2rem 0 0;
    padding: 0;
  }
  .pedido-atacado .atacado-prev-button .crm-button-orange {
    width: 13.125rem;
    height: 3.125rem;
    margin: 20px 0px 0px 0;
  }

  .pedido-atacado .atacado-next-button.confirm {
    width: auto;
    margin: 0;
    padding: 0;
  }

  #crm-button-blue-atacado {
    margin-left: 0 !important;
  }
  .pedido-atacado .atacado-next-button.confirm .crm-button-blue {
    width: 13.125rem;
    height: 3.125rem;
    margin: 20px 0px 0px 0;
  }
  .x-order-confirmed-atacado .pdf-pedido__summary--print {
    padding: 1rem 0 1rem 0;
    text-decoration: none;
  }
  .x-order-confirmed-atacado .button-mobile-only {
    width: 13.125rem;
    margin: 0 0 1rem;
    padding: 0;
  }
}

/* leo */
.x-web-ordering-pedido__summary_Atacado {
  width: 25%;
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  padding: 2.9375rem 2rem 6.75rem 2rem;
}

.x-web-ordering-pedido__summary_Atacado a:hover {
  color: #00518b !important;
}

.x-web-ordering-pedido__summary--arrow {
  display: none;
}

@media only screen and (max-width: 766px) {
  .align-middle:nth-child(2) {
    padding-left: 38px;
  }
  .align-middle:nth-child(3) {
    padding-left: 117px;
  }

  .align-middle:nth-child(4) {
    padding-left: 104px;
  }
  .tabela-produtos {
    max-width: 90%;
    overflow: auto;
  }
  .x-web-ordering-pedido__summary_Atacado {
    position: absolute;
    top: 8rem;
    width: 100%;
    height: 100%;
    right: -92vw;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    padding: 2.9375rem 2.75rem 6.75rem 2.75rem;
    overflow-y: scroll;
    height: 85%;
  }

  .x-web-ordering-pedido__summary_Atacado.show-summary {
    -webkit-transform: translateX(-92%);
    transform: translateX(-92%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 999;
  }

  .x-web-ordering-pedido__summary--arrow {
    display: inline-table;
    position: relative;
    right: 50px;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin: 20px;
  }

  .x-web-ordering-pedido__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__summary--container.show-summary {
    display: inline;
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .p-5 {
    padding: 1rem !important;
  }

  .float-end {
    float: none !important;
  }

  .crm-input [type="textarea"] {
    margin: 0 !important;
  }

  .juntos {
    display: flex;
    justify-content: space-around;
  }

  .botao.col-1.text-center.justify-content-center.align-self-center {
    margin-left: 8px;
  }

  tr.linha-produto {
    display: table-row;
  }

  .product-list-td {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .dupla {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .separator {
    display: none;
  }
}
@media (max-width: 1150px) {
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios {
    display: block;
  }
  .auto-complete-tooltip-shape {
    top: 40% !important;
  }
}
@media (max-width: 550px) {
  .align-middle {
    display: ruby;
  }
}
.ancor-matriz-dioptrias {
  display: flex;
  align-items: center;
  height: 60px;
}

.divTable {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  position: relative;
  overflow: hidden;
  min-height: 20vh;
  max-height: 65vh;
}
.divCardTable {
  grid-column: 2 / 15;
  overflow: scroll;
  height: 100%;
}
.div-container-input {
  display: flex;
  margin: 5px;
}

.input-matriz-dioptrias {
  width: 45px !important;
  border-radius: 4px;
  border: 1px solid;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
}
.containerModalMatriz {
  width: 100%;
  height: 100%;
}
.th-container-matriz-dioptrias {
  height: 100%;
  display: flex;
  align-items: center;
}
.th-matriz-dioptrias {
  height: 39px !important;
  width: 70px;
  background-color: white;
  color: #525456;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  padding-left: 6px;
}
.table-header-horizontal {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
table#tablePositive\ tabUmaLinha {
  display: inline;
  display: initial;
  width: 41%;
}
table#tableNegative\ tabUmaLinha {
  display: inline;
  display: initial;
  width: 41%;
}

.divTable table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

.divContainerTable {
  display: flex;
  justify-content: center;
}

.CilindricoContainer {
  grid-column: 2 / 15;
  display: flex;
  justify-content: space-around;
}

.EsfericoContainer {
  grid-row: 1 / 3;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  display: flex;
  justify-content: center;
  margin: 0px 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
#degativoOuPositivo {
  z-index: 6;
}
#tablePositive th#degativoOuPositivo::after {
  content: "( + )";
}
#tableNegative th#degativoOuPositivo::after {
  content: "( - )";
}
.focusInputMatriz {
  border: 2px solid #88c0ff;
  box-shadow: 0 0 9px #afd5ff;
}
.tabSemPsitivosOuNegativos {
  justify-content: center;
  height: 54vh;
  width: 100%;
}
#thEmpty {
  z-index: 2;
}
.dioptriasNotFound {
  font-weight: 700;
  color: #979797;
}

#contained-modal-title-vcenter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.td-matriz-dioptrias {
  vertical-align: inherit !important;
}
@media (max-height: 1024px) {
  .divTable {
    min-height: 20vh;
    max-height: 60vh;
  }
}

.containerDioptriasNotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (max-width: 992px) {
  .modal-dialog {
    max-width: 892px !important;
    margin: 1.75rem auto;
  }
  .modal-content {
    width: 90vw !important;
    margin: auto;
  }
}
@media (max-width: 767px) {
  .tabSemPsitivosOuNegativos {
    width: 100%;
    height: 15vh;
    justify-content: center;
  }
  .divTable {
    overflow: auto;
  }
  #thEmpty {
    z-index: 2;
    width: 185px;
  }
  .divContainerTable {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .mapaDioptriasTR {
    display: table !important;
  }
  .divTable {
    margin-bottom: 41px;
  }
  .table-header-horizontal {
    top: -3px;
  }
  .th-matriz-dioptrias {
    left: -6px;
  }
}

@media (max-width: 500px) {
  .EsfericoContainer {
    margin: 0px 11px 0px 0px;
  }
}

.x-order-confirmed__container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 3.5625rem 0 0 0;
  font-family: "Montserrat", sans-serif;
}

.x-order-confirmed__container1 {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  /* padding: 3.5625rem 0 0 0; */
  font-family: "Montserrat", sans-serif;
}

.x-order-confirmed__title {
  margin: 1.0625rem 0 1.1875rem 0;
  font-size: 1.625rem;
  font-family: "Montserrat", sans-serif;
  color: #0066b3;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__title {
    font-size: 1.125rem;
  }

  .button-mobile-only {
    padding-right: 1rem;
  }
}

.x-order-confirmed__paragraph {
  display: flex;
  margin: 0 auto;
  text-align: center;
  font-size: 0.875rem;
}

.x-order-confirmed__paragraph:nth-of-type(1) {
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__paragraph {
    max-width: 15.5rem;
    font-size: 0.75rem;
  }
}

.confirmed-opacity {
  opacity: 0.2;
}

.x-order-confirmed__list {
  display: flex;
  justify-content: space-between;
  margin: 1.875rem 0 2.5rem 0;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__list {
    flex-direction: column;
    justify-content: center;
    max-width: 15.5rem;
    margin: 1.625rem 0 1.375rem 0;
  }
}

.x-order-confirmed__item {
  font-size: 0.875rem;
  background: white;
  padding: 1.1875rem 1.875rem;
}

.x-order-confirmed__item--number {
  color: #0066b3;
  font-weight: bold;
}

.x-order-confirmed__item--lab {
  font-weight: bold;
}

.x-order-confirmed__item:nth-of-type(1) {
  margin: 0 1rem 0 0;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__item {
    font-size: 0.75rem;
    padding: 1.25rem 1.125rem;
    text-align: center;
  }

  .x-order-confirmed__item:nth-of-type(1) {
    margin: 0 0 0.3125rem 0;
    padding: 0.8125rem;
  }
}

.x-order-confirmed__button {
  padding: 14px 0;
  border-radius: 3.125rem;
  margin: 0 0 0.875rem 0;
  display: flex;
  align-items: center;
  pointer-events: all;
  cursor: pointer;
}

.x-order-confirmed__button:hover {
  cursor: pointer;
}

.x-order-confirmed__button--text {
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0 1.25rem;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__button--text {
    order: 1;
    margin: 0rem 0 0rem 1.25rem;
  }
}

.x-order-confirmed__button--icon {
  margin: 0 1rem 0 1rem;
  order: 2;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__button--icon {
    margin: 0 0.625rem 0 1rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__button {
    padding: 0.625rem 0;
  }
}

.x-order-confirmed__link {
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
  text-decoration: underline;
  color: #424b4a;
  margin: 0 0 3.375rem 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__link {
    margin: 0 0 2.5rem 0;
  }
}

/* ********* SUMMARY PDF ********* */

.pdf-pedido__summary {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0rem 3.75rem 0rem 2.75rem;
  -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
  transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
}

.pdf-pedido__summary--intro {
  display: flex;
  flex-direction: column;
}

.pdf-pedido__summary--header {
  margin-top: 1rem;
}

.pdf-pedido__summary--barcode {
  float: right;
}

.pdf-pedido__summary--icon {
  align-self: center;
  margin: 0 0 1.25rem 0;
}

.pdf-pedido__summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.pdf-pedido__summary--paragraph {
  color: #424b4a;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
}

.pdf-pedido__summary--body {
  /* margin: 1.6875rem 0 0 0; */
}

.pdf-pedido__summary--item {
  position: relative;
  border-bottom: 1px solid #d8d8da;
}

.pdf-pedido__summary--item:not(:last-child) {
  border-bottom: 1px solid #707070;
}

.pdf-pedido__summary--link {
  color: #00518b !important;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0 1rem 0;
  display: block;
  cursor: default;
  text-decoration: none !important;
}

.pdf-pedido__summary--print {
  color: #0d81d3 !important;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0 1rem 0;
  display: block;
  cursor: pointer;
}

.pdf-pedido__summary--card {
  display: flex;
  justify-content: space-between;
}

.pdf-pedido__summary--card.left:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.pdf-pedido__summary--card.right {
  display: flex;
  justify-content: space-between;
}

.pdf-pedido__summary--card.right:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.pdf-pedido__summary--card.shape {
  display: flex;
  justify-content: space-between;
}

.pdf-pedido__summary--card.shape:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.pdf-pedido__summary--text {
  margin: 0.625rem 0 0.125rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
}

.pdf-pedido__summary--text-2 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.pdf-pedido__summary--text.no--gap {
  margin-bottom: 0;
}

.pdf-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 10rem;
}

.pdf-pedido__summary--side-left .pdf-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 10rem;
}

.pdf-pedido__summary--content {
  display: none;
  margin: 0 0 0 0;
}

.pdf-pedido__summary--content.show {
  display: flex;
  flex-direction: column;
}

.pdf-pedido__summary--group {
  display: flex;
  justify-content: space-between;
}

.pdf-pedido__summary--side-left {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin: 0 1.875rem 0 0;
}

.pdf-pedido__summary--side-right {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.pdf-pedido__summary--subitem.right {
  text-align: right;
  width: auto;
}
.pdf-pedido__summary--subitem.left {
  width: auto;
}

/*.x-confirm-order__list__pdf {
  width: 100%;
  margin: 1rem 0 1rem 0;
  background: rgba(0, 102, 179, 0.1);
}*/

.x-confirm-order__dados--list__pdf {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.5;
  height: 3.125rem;
  align-items: center;
  padding: 0 3.75rem 0 3.75rem;
  width: 100%;
}

.x-confirm-order__dados--item {
  margin: 0 1.25rem 0 0;
}

.x-confirm-order__dados--item.is--large {
  font-weight: bold;
}

.x-confirm-order__dados--item.blue {
  color: #0066b3;
  font-weight: bold;
}

.x-order-confirmed__list__pdf {
  display: flex;
  justify-content: space-between;
  /*margin: 1rem 0 2.5rem 0;*/
}

.x-order-confirmed__item__pdf {
  font-size: 0.875rem;
  background: white;
  padding: 1.1875rem 3.75rem;
  width: 49%;
}

.x-web-ordering-pedido__main .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0;
}

.card-body {
  padding: 0.25rem;
}

.x-web-ordering__container {
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    color: #424B4A;
    overflow: hidden;
    line-height: 1.4;
}
.x-web-ordering__intro--subtitle2{
    font-size: small;
}
@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__container {
        flex-direction: column;
    }
}

.x-web-ordering__intro {
    display: flex;
    align-items: center;
    /* margin: 0 0 3.875rem 3.75rem; */
    justify-content: center;
    background: #F3F3F5;
}

.x-web-ordering__intro--container {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__intro--container {
        text-align: center;
    }
}

.x-web-ordering__intro-icon {
    max-width: 100%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__intro-icon {
        margin: 0 1.5rem;
    }
}

.x-web-ordering__intro--subtitle-bold {
    font-weight: bold;
    color: #0066B3;
}

.x-web-ordering__intro--link {
    color: #0066B3;
    text-decoration: underline;
}

.x-web-ordering__intro--text {
    font-family: "Montserrat", sans-serif;
    display: flex;
    font-size: 1rem;
    color: #3F3F3F;
    margin: 0 0 0.9375rem 0;
    font-weight: bold;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__intro--text {
        max-width: 80%;
        font-size: 0.875rem;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__intro--text {
        max-width: 80%;
        margin: 0.625rem 0 1.875rem 0;
        align-self: center;
        display: block
    }

    .x-web-ordering__intro--subtitle {
        align-self: center;
        max-width: 80%;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__intro {
        /* margin: 0 0 1.875rem 3.75rem; */
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__intro {
        flex-direction: column;
        margin: 0 0 3.875rem 0;
        padding: 2rem 0;
    }
}

.x-web-ordering__left {
    padding: 2.8125rem 0 3.25rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__left {
        width: 100%;
        padding: initial;
        padding: 2.0625rem 0 3.125rem 0;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__left {
        width: 100%;
        padding: 2.8125rem 0 1.25rem 0;
    }
}

.x-web-ordering__right {
    width: 100%;
    /* background: #F3F3F5; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__right {
        width: 100%;

    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__right {
        width: 100%;
    }
}

.x-web-ordering__labs {
    margin: 2.1875rem 0 2.1875rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs {
        margin: 2.1875rem 0 2.375rem 0;
    }
}

.x-web-ordering__labs--container {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--container {
        max-width: 18.375rem;
    }

    .x-web-ordering__labs {
        margin: 1.1875rem 0 1.375rem 0;
    }
}

.x-web-ordering__labs--title {
    font-size: 1.5em;
    color: #0066B3;
    align-self: center;
    margin: 0 0 0.9375rem 0;
    font-weight: bold;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--title {
        align-self: center;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--title {
        align-self: center;
        font-size: 1em;
    }
}

.x-web-ordering__labs--paragraph {
    font-size: .875em;
    color: #424B4A;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--paragraph {
        align-self: center;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--paragraph {
        text-align: center;
    }
}

.x-web-ordering__labs--slider {
    /* max-width: 51rem; */
    position: relative;
    align-self: center;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--slider {
        /* max-width: 24.875rem; */
    }
}

@media only screen and (max-width: 766px) {
    /* .x-web-ordering__labs--slider {
        max-width: 15.375rem;
    } */
}

.x-web-ordering__labs--item {
    display: flex;
    -webkit-box-orient: vertical;
    /* flex-direction: column; */
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--item {
        display: block;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--item {
        flex-direction: column;
    }
}

.x-web-ordering__labs--subitem {
    display: flex;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--subitem {
        display: flex;
        flex-direction: column;
    }
}

.x-web-ordering__labs--subitem-link {
    text-align: center;
    cursor: pointer;
    pointer-events: all;
    display: block;
    -webkit-transition: -webkit-transform .175s ease-in-out;
    transition: -webkit-transform .175s ease-in-out;
    transition: transform .175s ease-in-out;
    transition: transform .175s ease-in-out, -webkit-transform .175s ease-in-out;
}

.x-web-ordering__labs--subitem-link:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .175s ease-in-out;
    transition: all .175s ease-in-out;
}

.x-web-ordering__labs--image {
    /* max-width: 90%; */
    margin: 0 0.125rem -0.3125rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--image {
        padding: 0 0.0625rem 0 0;
    }
}

.x-web-ordering__labs--text {
    display: flex;
    align-self: center;
    font-size: .8125em;
    margin: 4.0625rem 0 2.3125rem 0;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--text {
        font-size: .8em;
        text-align: center;
        flex-direction: column;
        margin: 1.5rem 0 1.5625rem 0;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--text {
        font-size: .8em;
        margin: 2.5rem 0 1.5625rem 0;
    }
}

.x-web-ordering__labs--link {
    color: #00409A;
    text-decoration: underline;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--link {
        text-align: center;
        -webkit-transform: translate3d(70px, -16px, 0);
        transform: translate3d(70px, -16px, 0);
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs .slick-arrow.slick-next {
        right: 0 !important;
        top: 182px !important;
    }

    .x-web-ordering__labs .slick-arrow.slick-prev {
        left: 0 !important;
        top: 182px !important;
    }
}

.x-body .slick-list.draggable {
    overflow: hidden;
    padding: 0 !important;
}

.x-body .slick-slide {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-body .slick-slide {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 766px) {
    .x-body .slick-slide {
        flex-direction: column;
    }
}

.x-body .slick-arrow {
    font-size: 0;
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #00518B;
    border-right: 2px solid #00518B;
    top: 40%;
}

.x-body .slick-arrow.slick-prev {
    left: 0;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    margin: 0 0 0 -2.75rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-body .slick-arrow.slick-prev {
        margin: 1.25rem 0 0 -5rem;
    }
}

@media only screen and (max-width: 766px) {
    .x-body .slick-arrow.slick-prev {
        margin: 0;
        left: 37px;
        top: auto;
        top: initial;
    }
}

.x-body .slick-arrow.slick-next {
    right: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0 -2.75rem 0 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-body .slick-arrow.slick-next {
        margin: 1.25rem -5rem 0rem 0rem;
    }
}

@media only screen and (max-width: 766px) {
    .x-body .slick-arrow.slick-next {
        margin: 0;
        right: 37px;
        top: auto;
        top: initial;
    }
}

.x-body .slick-dots {
    position: absolute;
    display: flex;
    bottom: -1.875rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.x-body .slick-dots li {
    margin: 0 0.875rem;
}

.x-body .slick-dots li button {
    font-size: 0;
    width: 0.5rem;
    height: 0.5rem;
    background-color: rgba(0, 102, 179, 0.5);
    opacity: .8;
}

.x-body .slick-dots li.slick-active button {
    background: #0066B3;
}

.x-body .slick-track {
    display: flex;
}

@media only screen and (max-width: 766px) {
    .x-body .slick-track {
        width: 100%;
    }
}

.labSelect,
.x-web-ordering__labs--subitem-link img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    -webkit-transition: linear 0.4s;
    transition: linear 0.4s;
}

.labSelect:hover,
.x-web-ordering__labs--subitem-link img:hover {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    -webkit-transition: linear 0.4s;
    transition: linear 0.4s;
}

.x-web-ordering__labs--subitem-link label {
    margin-bottom: 0px;
    padding: 50px 25px;
}

.x-web-ordering__intro .hide-tutorial {
    display: none;
}

.x-web-ordering__intro .show-tutorial {
    display: content;
    opacity: 1;
    pointer-events: all;
    background: #fff;
    z-index: 99999;
    position: fixed;
    /* width: 920px; */
    margin: 0 auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 40px;
    /* max-height: 80%; */
    border: 2px solid #F8F8F8;
    border-radius: 5px;
    -webkit-transition: .5s bottom, .5s opacity;
    transition: .5s bottom, .5s opacity;
}

.x-web-ordering__intro .tutorial-close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding-bottom: 15px;
}

/* Carousel */
.arrow-container {
    z-index: 3;
    -webkit-transition: all .25s ease-in;
    transition: all .25s ease-in;
    opacity: 1;
    background: 0 0;
    border: 0;
    cursor: pointer;
    position: absolute !important;
    height: 100%;
    width: 25px;

    display: flex;
    align-items: center;
    color: #00A2E8;
}

.arrow-container-next {
    top: 0;
    right: 0;
}

.arrow-container-prev {
    top: 0;
    left: 0;
}

.slide>div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
}

.slide.selected>div {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
}

.slide.selected .x-web-ordering__labs--image,
.slide.selected .x-web-ordering__labs--imageNotFound {
    -webkit-transition: linear 0.6s;
    transition: linear 0.6s;
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    color: #076CB6;
    font-weight: bold;
}

@media (max-width: 765px) {
    .slide label {
        font-size: 12px
    }

    .slide.selected>div {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
        -webkit-transition: all .6s ease-in-out;
        transition: all .6s ease-in-out;
    }

    .arrow-container {
        width: 27px;
    }

    .carousel-container {
        width: 90vw;
    }
}

@media only screen and (min-width: 766px) and (max-width: 1170px) {
    .x-web-ordering__labs--image {
        width: 140px;
    }
}
.main-tracking {
  margin: 0 auto;
  margin-top: 1.75rem;
  position: relative;
  display: flex;
  justify-content: center;
}
.tooltipFixoColuna {
  display: flex;
  align-items: center;
  margin: 27px 0 0 0;
}
.tooltipDivFinanceiro {
  display: flex;
  align-items: center;
  margin-top: -7px;
}
.tooltipFixo {
  margin-right: 10px;
  content: "i";
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.625rem;
  color: #0394db;
  z-index: 15;
  border: 1px solid #0394db;
  border-radius: 50%;
  width: 2rem;
  max-width: 14px;
  height: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  opacity: 1 !important;
}
.tooltipDivFinanceiro p {
  margin: 0px;
  font-size: smaller;
}
#table-head-financeiro{
  width: 100%;
}
#thead-table {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.main-tracking .crm-select-input {
  margin: 1.375rem 0 0 0 !important;
}

.main-tracking .crm-input [type="text"],
.main-tracking .crm-input [type="number"],
.main-tracking .crm-input [type="email"],
.main-tracking .crm-input [type="password"],
.main-tracking .crm-input [type="tel"],
.main-tracking .crm-input [type="url"] {
  margin: 1.375rem 0rem 0 0 !important;
}

.main-tracking .crm-select-arrow {
  position: absolute;
  z-index: 0;
  right: 30px;
  top: 43px;
}




.tracking-filters {
  padding: 1.75rem 0;
  position: relative;
}

.tracking-filters-row {
  display: flex;
  align-items: flex-end;
}

.tracking-filters-row:not(:first-child) {
  margin-top: 1.75rem;
}

.tracking-filters-box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.tracking-filters-box.lab {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tracking-filters-box.cnpj {
  position: relative;
  display: flex;
  flex-direction: column;
}

.tracking-filters-label {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  color: #424b4a;
  position: relative;
  text-align: center;
}

.tracking-filters-text {
  font-weight: bold;
  font-size: 1rem;
  color: #00518b;
  text-transform: uppercase;
  line-height: 65px;
}

.Opacity {
  opacity: 0.2;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1210px) {
  .x-main .tracking-filters-row {
    justify-content: space-between;
  }

  .tracking-filters-text {
    width: unset;
    text-align: center;
    margin: 0;
  }
}

/* CSS for mobile small devices */

@media only screen and (max-width: 766px) {
  .tracking-filters-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .tracking-filters-box {
    width: 100%;
    padding-top: 1.75rem;
  }

  .tracking-filters-box.cnpj {
    margin-left: 0px;
  }

  .tracking-filters-row:not(:first-child) {
    margin-top: unset;
  }

  .tracking-filters-text {
    margin: 0;
    margin-top: 1.75rem;
    width: 100%;
    line-height: 15px;
  }
}

/* Descrição lateral */

.x-web-ordering-tracking__summary {
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  padding: 2.9375rem 2rem 6.75rem 2rem;
}

.x-web-ordering-tracking__summary.confirm {
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  padding: 2.9375rem 3.75rem 6.75rem 2.75rem;
  -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
  transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
}

.main-tracking .x-web-ordering-tracking__summary--intro {
  display: flex;
  flex-direction: column;
}

.main-tracking .x-web-ordering-tracking__summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.main-tracking .x-web-ordering-tracking__summary--paragraph {
  color: #424b4a;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
}

.main-tracking .x-web-ordering-tracking__summary--icon {
  align-self: center;
  margin: 0 0 2.25rem 0;
}

.main-tracking .x-web-ordering-tracking__main {
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  position: relative;
}

.x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow {
  display: none;
}

@media only screen and (min-width: 1210px) {
  .main-tracking .x-web-ordering-tracking__main {
    width: 370px;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary {
    position: absolute;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    padding: 2.9375rem 2.75rem 6.75rem 2.75rem;
    overflow-y: auto;
    margin-left: 2vw;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary.show-summary {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 5;
  }

  .x-my-account__back {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1210px) and (min-width: 767px) {
  .x-web-ordering-tracking__main.active-arrow .x-web-ordering-tracking__summary {
    width: 100%;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary {
    position: absolute;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    overflow-y: auto;
  }

  .x-web-ordering-tracking__main.active-arrow {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 500;
  }

  .x-my-account__back {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-tracking__main.active-arrow .x-web-ordering-tracking__summary {
    width: 100%;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin-left: 5px;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--container.show-summary {
    display: inline;
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1210px) and (min-width: 767px) {
  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1210px) and (max-width: 1210px) and (min-width: 767px) {
  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--container.show-summary {
    display: inline;
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-tracking__main.active-arrow {
    width: 90vw;
    right: 0;
    position: absolute;
    height: 100%;
    z-index: 500;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-tracking__main {
    width: 5vw;
    overflow: hidden;
  }

  .main-tracking .x-web-ordering-tracking__main {
    background: #f3f3f5;
    display: flex;
    flex-direction: column;
    height: 700px !important;
    -webkit-transform: translate(0px, 80px) !important;
            transform: translate(0px, 80px) !important;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow {
    display: inline-table;
    position: relative;
    left: -62px;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin: 20px;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1210px) and (min-width: 767px) {
  .x-web-ordering-tracking__main.active-arrow {
    width: 500px;
    right: 0;
    position: absolute;
    height: 100%;
    z-index: 500;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-tracking__main {
    width: 40px;
    overflow: hidden;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow {
    display: inline-table;
    left: -27px;
    position: relative;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin-left: 5% !important;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

.logolabTracking {
  /* background-color: rgb(255, 255, 255); */
  /* position: absolute; */
  /* width: 25%; */
  /* margin-left: -2rem; */
  text-align: center;
  right: 0;
  top: 200px;
  background: rgb(243, 243, 245);
  background: -webkit-gradient(linear, left top, right top, color-stop(5%, rgba(243, 243, 245, 1)), color-stop(15%, rgba(255, 255, 255, 1)), color-stop(85%, rgba(255, 255, 255, 1)), color-stop(95%, rgba(243, 243, 245, 1)));
  background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
}

@media only screen and (max-width: 1460px) {
  #table-head-financeiro {
    max-width: 100%;
    overflow: auto;
  }

  .pedidos.table {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1210) {
  .x-web-ordering-tracking__summary--intro {
    padding: 2vw;
  }
}

@media only screen and (max-width: 770) {
  #thead-table {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  #pedido-table {
    overflow-x: auto;
    max-width: 100%;
    scrollbar-color: #00518b;
    scrollbar-width: thin;
  }

  #table-head-financeiro {
    max-width: 95%;
    overflow: auto;
  }

  .financeiro-pedidos-tr-th {
    padding: 1.5rem 0.75rem;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 90%;
  }

  .pedidos.table td,
  .table th {
    padding: 0.5rem;
    vertical-align: middle;
    word-wrap: break-word;
    max-width: 45vw;
    padding: 1.5rem 1.2rem !important;
  }

  .pedidos.table td {
    padding: 1.5rem 2.5rem !important;
  }
}

@media only screen and (max-width: 765) {
  .col-xs-12.col-md-6.status-container {
    width: 100% !important;
  }
  .financeiro-container-status-radios {
    margin:0px 0px 0px 0px !important;
  }
  .x-web-ordering-tracking__main {
    width: 5vw;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary {
    position: absolute;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    padding: 2.9375rem 2.75rem 6.75rem 2.75rem;
    overflow-y: auto;
    margin-left: 2vw;
  }
}
@media only screen and (max-width: 1135px) {
  .tooltipDivFinanceiro {
    display: flex;
    height: 72%;
  }
  .financeiro-container-status-radios {
    justify-content: space-between !important;
  }
}

.financeiro-label-status {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
          transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: relative;
  z-index: 2;
  padding: 0 5px;
  top: 0px;
  margin-bottom: 5px;
}

.financeiro-container-status-radios {
  margin: 0rem 1rem 0 0;
  display: flex;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  min-height: 55.785px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  flex-flow: wrap row;
  justify-content: space-evenly;
}

.financeiro-container-status-radios .radio-button-container {
  margin: 5px 1px 1px 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #00518b;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f2f2f2;
}

@media only screen and (max-width: 1210px) {
  #container-financeiro-page {
    width: 100% !important;
  }
}

@media only screen and (max-width: 850px) {
  #container-financeiro-page {
    width: 90% !important;
  }
}

.tracking-filters-input,
.tracking-filters-date,
.tracking-filters-option,
.tracking-filters-select {
  font-size: 1rem;
  color: #707070;
}

.tracking-filters-select {
  width: 100%;
  border-radius: 6px;
  padding: 1rem 1.375rem;
  position: relative;
  border: 1px solid #b5b5b5;
  background: transparent;
  z-index: 1;
  height: 58px;
}

.tracking-filters-select-cnpj {
  width: 100%;
  border-radius: 6px;
  padding: 1rem 1.375rem;
  position: relative;
  border: 1px solid #b5b5b5;
  background: transparent;
  z-index: 1;
  height: 58px;
  font-size: 1rem;
  color: #707070;
}

.tracking-filters-select-arrow {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid #00518b;
  border-right: 2px solid #00518b;
  bottom: 1.55rem;
  right: 1.25rem;
  z-index: 1;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.tracking-filters-select-option {
  padding: 0.625rem 0;
}

.tracking-filters-input {
  height: 3.125rem;
  border-radius: 0.375rem;
  border: 1px solid #b5b5b5;
  padding: 0.875rem 1rem;
  width: 100%;
}

.tracking-filters-input.cnpj {
  border-radius: 0.375rem;
  border: 1px solid #b5b5b5;
  width: 100%;
  padding: 1rem 1.375rem;
  height: 58px;
}

.tracking-filters-date-container {
  display: flex;
}

.tracking-filters-date {
  border: 1px solid #b5b5b5;
  padding: 0.875rem 1rem;
  width: 100%;
  text-align: center;
}

.tracking-filters-box.is--date {
  margin-left: auto;
}

.tracking-filters-date.start-date {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tracking-filters-date.end-date {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.cnpj .tooltip-shape:before {
  content: "i";
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.625rem;
  color: #0394db;
  z-index: 15;
  border: 1px solid #0394db;
  border-radius: 50%;
  width: 0.9375rem;
  height: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  right: 0;
  top: 4.2rem;
  opacity: 1 !important;
}

.tracking-search-button {
  border-radius: 1.875rem;
  width: 20.1875rem;
  height: 3.125rem;
  padding: 0 0.9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  margin: 0 auto;
  margin-top: 2.5rem;
  -webkit-transition: all 0.155s ease-in;
  transition: all 0.155s ease-in;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tracking-search-button:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.284);
}

.inputDataInicio {
  padding-right: 1rem;
}
.inputDataFim {
  padding-left: 1rem;
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .tracking-filters-input,
  .tracking-filters-date {
    width: 9rem;
  }

  .tracking-filters-select .cnpj {
    width: 12rem;
  }

  .main-tracking .tooltiptext {
    left: unset;
    right: 92px;
  }
}

/* CSS for mobile small devices */

@media only screen and (max-width: 766px) {
  .tracking-filters-input,
  .tracking-filters-input.cnpj,
  .tracking-filters-date {
    width: 100%;
  }

  .is__shape--position {
    position: absolute;
  }

  .tracking-filters-box.is--date {
    width: 100%;
    margin-left: unset;
  }

  .tracking-search-button {
    width: 100%;
  }

  .main-tracking .tooltip-shape:before {
    right: 0;
  }

  .main-tracking .tooltiptext {
    top: 99px;
    right: 156px;
    left: unset;
  }

  .main-tracking .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 82%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}

.financeiro-order-detail-column {
	width: 100%;
	display: flex;
	justify-content: center;
}

th.financeiro-detalhes-modal {
	font-size: smaller;
	font-family: var(--bs-font-sans-serif), sans-serif;
}

td.financeiro-detalhes-modal-td {
	font-family: var(--bs-font-sans-serif), sans-serif;
	font-size: smaller;

}

.financeiro-table-body-dropdown-th-text {
	max-width: 135px;
	min-width: 50px;
}

.financeiro-table-body-dropdown-th {
	padding: 0 17px;
	font-size: small !important;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
}

.continer-financeiro-table {
	overflow: auto;
}

.financeiro-faturas-table-head-th {
	text-transform: uppercase;
	display: table-cell;
	border-bottom: none;
}

.financeiro-table-body-td {
	display: table-cell;
}

.financeiro-faturas-table-head {
	position: relative;
}

.financeiro-details-content-dropdown {
	background: white;
	max-height: 400px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	overflow: auto;
	direction: rtl;
	width: 100%;
}

.arrow-financeiro {
	display: block;
	width: 10px;
	height: 10px;
	border-right: 2px solid #00518b;
	border-bottom: 2px solid #00518b;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	margin: 0 0 0 2rem;
}

.tracking-main-table-row-details-td {
	max-width: 600px;
}

spam.arrow-financeiro.inverse {
	-webkit-transform: rotate(225deg);
	        transform: rotate(225deg);
}

td.tracking-main-table-row-details-td.inverse {
	height: 0;
	overflow: hidden;
	-webkit-transition: height 0.3s ease;
	transition: height 0.3s ease;
}

.financeiro-fatura-financeiro-table-td-status-dropdown {
	width: 100px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.div-th-header {
	display: flex;
	justify-content: center;
	align-items: center;
}

.faturas-body-dropdown {
	font-size: small;
	padding: 0 0.6rem !important;
}

.search-icon {
	margin-left: 1rem;
}

.faturas-body-dropdown.div {
	display: flex;
	width: 32px;
	justify-content: center;
	align-items: center;
}

tbody.financeiro-main-table-body-faturas-drop {
	background: white;
	border: 2px solid rgb(229, 237, 243);
}

.table-header-dropdown {
	border: 3px solid rgb(229, 237, 243);
}

.financeiro-td-status-fatura {
	width: 100px;
	height: 35px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.financeiro-fatura-financeiro-table-td-status-dropdown p {
	position: absolute;
	margin: 0;
	background-color: rgb(0, 85, 143);
	font-size: small;
	color: white;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
}

.financeiro-td-status-fatura p {
	font-size: small;
	position: absolute;
	margin: 0;
	background-color: rgb(0, 85, 143);
	font-weight: 700;
	color: white;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.financeiro-td-status-fatura p[data-status="EM ABERTO"] {
	background-color: rgb(0, 81, 139);
}

.financeiro-td-status-fatura p[data-status="VENCIDO"] {
	background-color: rgb(36, 36, 36);
}

.financeiro-td-status-fatura p[data-status="AVENCER"] {
	background-color: rgb(36, 36, 36);
}

.financeiro-td-status-fatura p[data-status="PAGO"] {
	background-color: rgb(28, 142, 62);
}

.financeiro-fatura-financeiro-table-td-status-dropdown p[data-status="Pago"] {
	font-weight: 700;
	background-color: rgb(28, 142, 62);
}

.financeiro-fatura-financeiro-table-td-status-dropdown p[data-status="Em Aberto"] {
	font-weight: 700;
	background-color: rgb(0, 81, 139);
}

.financeiro-fatura-financeiro-table-td-status-dropdown p[data-status="Vencido"] {
	font-weight: 700;
	background-color: rgb(237, 28, 36);
}

.financeiro-fatura-financeiro-table-td-status-dropdown p[data-status="A Vencer"] {
	font-weight: 700;
	background-color: rgb(255, 244, 245);
	color: rgb(237, 28, 36);
	border: 3px solid rgb(237, 28, 36);
}

.financeiro-table-td-status {
	padding: 0.5rem 8px !important;
	display: flex;
	justify-content: center;
}

.table-line-pointer {
	cursor: pointer;
}

td.financeiro-table-body-td {
  padding: 1.5rem 0.75rem;
}

table.financeiro-faturas-table.table.text-center.table {
	width: 100%;
}

td.financeiro-table-body-td.inverse.financeiro-table-body-td-dropdown {
	background-color: white;
	max-width: 500px;
	padding: 1.5rem 1.5rem;
}

.main-financeiro {
  width: 90%;
  min-height: 73vh;
  margin: 0 auto;
  margin-top: 1.75rem;
  position: relative;
  display: flex;
}
#container-financeiro-page {
  width: 80%;
}
.classNameContainerTable{
  overflow: visible !important;

  max-height: 386px !important;
}
.financeiro-table-td {
  padding: 0.5rem 0.6rem !important;
}
.financeiro-pedidos-tr-th {
  padding: 30px 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 90%;
}
.divTagA {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.financeiro-pedidos-tr-th:first-child {
  border-radius: 10px 0 0 0;
}

.financeiro-pedidos-tr-th:last-child {
  border-radius: 0 10px 0 0;
}

.tracking-main-table-body:nth-of-type(odd) {
  background-color: #e5edf3;
}

.tracking-main-table-body:nth-of-type(even) {
  background-color: #fafaff;
}

.pedidos.table tbody + tbody {
  border-top: 0px;
}

.pedidos.table thead tr th,
.table tbody tr td {
  border: none;
}

.pedidos.table td,
.table th {
  padding: 1.5rem 0.75rem;
  vertical-align: middle;
}

.tracking-main-table-status {
  position: relative;
}




.tracking-main-table-status-arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  right: 5px;
  bottom: 15px;
  border-right: 2px solid #00518b;
  border-bottom: 2px solid #00518b;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}

.tracking-main-table-status-arrow.inverse {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}

.tracking-main-table-row-details {
  background-color: #fff;
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
  /* border-bottom: 1px solid #E5EDF3; */
}

.tracking-main-table-row-details-td {
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}

.tracking-order-detail {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.tracking-order-detail-column.direita {
  width: calc(60% - 20px);
  margin-left: 20px;
}

.detalhes.table {
  width: 100%;
  color: #212529;
  border: 0px;
  text-align: left;
}

.detalhes.table thead tr th,
.detalhes.table tbody tr td {
  border: none;
}

.detalhes.table td,
.detalhes.table th {
  border-top: 0px;
}

.detalhes.table tbody + tbody {
  border: 0px;
}

.detalhes.table thead th {
  border-bottom: 0px;
  /* background: #EDF4FA; */
  text-transform: uppercase;
  color: #00518b;
  font-family: "Montserrat", sans-serif;
  font-size: 90%;
}

.detalhes.table td,
.table th {
  padding: 0.5rem 0.75rem;
  vertical-align: middle;
  color: #585858;
}

.tracking-order-detail-column.esquerda {
  width: 40%;
}

.tracking-order-detail-column.esquerda .card-pedido {
  border-radius: 12px;
  background-color: #edf4fa;
  padding: 20px;
}

.tracking-order-detail-column.esquerda h3 {
  text-transform: uppercase;
  color: #00518b;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

/* .tracking-order-detail-column.esquerda svg {
    margin-bottom: 15px;
} */

.tracking-order-detail-column.esquerda p {
  text-align: left;
}

.tracking-detail-card-title {
  margin-top: 7px;
  font-weight: bold;
  color: #585858;
}

.tracking-detail-card-p {
  color: #707070;
}

.pedidos .hide-table-row {
  display: none;
}

.tracking-detail-card-title.mobile,
.tracking-detail-card-p.mobile,
.tracking-detail-card-title.tablet,
.tracking-detail-card-p.tablet {
  display: none;
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .tracking-order-detail {
    display: flex;
    flex-direction: column;
  }

  .tracking-order-detail-column.esquerda {
    width: 100%;
  }

  .tracking-order-detail-column.direita {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .hide-table-column-tablet {
    display: none;
  }

  .tracking-detail-card-title.tablet,
  .tracking-detail-card-p.tablet {
    display: grid;
  }
}

/* CSS for mobile small devices */

@media only screen and (max-width: 766px) {
  .financeiro-pedidos-tr-th {
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 90%;
  }

  .financeiro-pedidos-tr-th:nth-child(3n) {
    border-radius: 0 10px 0 0;
  }

  .pedidos.table td,
  .table th {
    padding: 0.5rem;
    vertical-align: middle;
  }

  .tracking-main-table-status {
    display: inline;
  }

  

  .tracking-main-table-status-arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    right: 0px;
    bottom: 0px;
    top: 50px;
    border-right: 2px solid #00518b;
    border-bottom: 2px solid #00518b;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .tracking-order-detail {
    display: flex;
    flex-direction: column;
  }

  .tracking-order-detail-column.esquerda {
    width: 100%;
  }

  .tracking-order-detail-column.direita {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .pedidos .hide-table-column {
    display: none;
  }

  .pedidos .hide-table-row {
    display: table-row;
  }

  .pedidos .status-row {
    padding-bottom: 30px !important;
  }

  .detalhes.table {
    font-size: 80%;
  }

  .tracking-detail-card-title.mobile,
  .tracking-detail-card-p.mobile {
    display: grid;
  }
}

/* https://www.florin-pop.com/blog/2019/04/how-to-create-a-timeline-with-react/ */
.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px;
}

.timeline-container::after {
  background-color: #00518b;
  content: "";
  position: absolute;
  left: calc(10% - 2px);
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  padding-left: 91px;
  padding-right: 0;
  position: relative;
  margin: 10px 0;
  width: 100%;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 500px;
  max-width: 100%;
  text-align: right;
}

.timeline-item-content::after {
  content: " ";
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item .timeline-item-content {
  text-align: left;
  align-items: flex-start;
  min-height: 70px;
}

.timeline-item .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item .timeline-item-content .tag {
  left: auto;
  right: 5px;
  color: #00518b;
}

.timeline-item-content time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 13px;
  line-height: 24px;
}

.timeline-item-content a {
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: " ►";
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #00518b;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}


@media only screen and (max-width: 1024px) {
  .timeline-item-content {
    max-width: 100%;
    width: 360px;
  }

}

@media only screen and (min-width: 464px) and (max-width: 768px) {
  .timeline-item-content {
    width: 90%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 463px) {
  .timeline-item-content {
    width: 90%;
  }

}

@media only screen and (min-width: 150px) and (max-width: 424px) {

  .financeiro-pedidos-tr-th:nth-child(n + 1):nth-child(-n + 2) {
    padding-left: 40px !important;
  }
  .financeiro-pedidos-tr-th:nth-child(n + 4):nth-child(-n + 6) {
    padding-left: 10px !important;
    padding-right: 5px !important;
  }
}

@media only screen and (max-width: 767px) {
	.timeline-item {
		padding-left: 59px;
	}
	.financeiro-table-td {
		border-bottom: 1px solid #ddd !important;
		display: table-cell !important;
		font-size: 0.8em !important;
		text-align: unset !important;
	}
	.timeline-item-content,
	.timeline-item .timeline-item-content {
		padding: 15px 10px;
		text-align: center;
		align-items: center;
		width: 220px;
		min-height: 90px;
	}

  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }

  .timeline-item-content time {
    margin-top: 20px;
  }

  .timeline-item-content a {
    text-decoration: underline;
  }

  .timeline-item-content a::after {
    display: none;
  }
}

.pagination .previous.disabled {
  display: none;
}

.pagination .next.disabled {
  display: none;
}

.pagination-container {
  text-align: center;
}

.pagination {
  margin: 15px auto;
  display: inline-flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #0066b3;
  border-color: #0066b3;
  color: #fff;
}

.pagination > li > a {
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #0066b3;
  border-color: #0066b3;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #0066b3;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
.expired {
  color: red;
  font-weight: bold;
}

.financeiro-overlay-content {
	display: flex;
	width: 100%;
	left: 0;
	top: 0;
	align-items: center;
	height: 100%;
	justify-content: center;
	position: fixed;
	z-index: 15;
	background: rgba(0, 0, 0, 0.4) !important;
	flex-direction: column;
}

.financeiro-div-nota-fiscal-download-xml-pdf-modal {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
    align-items: center;
    min-width: 130px;
}

.financeiro-div-nota-fiscal-download-xml-pdf-modal p{
	margin: 0!important;
}

.financeiro-overlay-content .financeiro-wrapper {
	display: inherit;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.financeiro-overlay-content .financeiro-wrapper .financeiro-details-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
	padding: 20px;
	background-color: #fff;
	border: none;
	border-radius: 0.3125em;
	height: 90%;
}

.title span {
	font-size: 2em;
	color: #585858;
	font-family: 'Montserrat';
}

.content-center {
	direction: ltr;
	font-family: 'Montserrat';
	font-size: 14px !important;
	max-height: 70%;
    overflow: overlay;
	width: 100%;
}

.content-center table {
	position: relative;
}

.content-center thead tr th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: #fff;
	width: 100px;
	padding: 5px !important;
}

.content-center tbody tr td {
	padding: 5px !important;
}

.button button {
	background-color: #0394db;
	padding: 15px 40px;
	border-radius: 5px;
	color: #fff;
}
.finaceiro-modal-thead{
	position: -webkit-sticky;
	position: sticky;
	border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
}

@media (max-width: 1400px) {
	.financeiro-overlay-content .financeiro-wrapper .financeiro-details-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 10px;
		grid-gap: 10px;
		gap: 10px;
		padding: 20px;
		background-color: #fff;
		border: none;
		border-radius: 0.3125em;
		width: 90%;
		height: 90%;
	}
}

@media (max-width: 890px) {

	.content-center {
		direction: ltr;
		position: relative;
		max-height: 70%;
		overflow: visible;
		width: 100%;
		font-family: inherit;
		font-size: 12px !important;
	}
	.financeiro-detalhes-modal-td{
		border-bottom: 1px solid #ddd;
		display: table-cell;
		font-size: 0.8em;
		text-align: left;
		text-align: initial;
	}
	
	::-webkit-scrollbar-thumb {
		background-color: #00518b;
		border-radius: 5px;
	  }
	
	  ::-webkit-scrollbar-track {
		background-color: #f2f2f2;
	  }
	
	  ::-webkit-scrollbar {
		width: 12px;
		height: 12px;
		background-color: #f2f2f2;
	  }
	  #financeiro-modal-table{
		scrollbar-color: #00518b;
    	scrollbar-width: thin;
		overflow-x: auto;
    	max-width: 100%;
		table-layout: fixed;
		
	  }
	  .financeiro-modal-table-div{
		width: 100%;
		overflow-x: auto;
	  }
	  .pedidos.table thead tr th, .table tbody tr td {
		padding: 20px;
		border: none;
		text-align: center;
	}

	
}
@media only screen and (max-width: 767px){
	.financeiro-detalhes-modal-tr {
		display: table-row !important;
	}
	.table {
		font-size: 16px;
	}
}
.tagADownloadCSV{
    font-size: small;
    text-decoration: underline !important;   
    color:#0d6efd;
    -webkit-text-stroke-width: thin;
   }
.back {
	margin: 40px 0 0 60px;
}

.suporte__main {
	margin: 0 1rem;
}

.suporte__container {
	display: flex;
	margin: 0 60px;
	align-items: center;
}

.suporte__menu-options {
	width: 25%;
}

.suporte__menu-itens {
	display: flex;
	flex-direction: column;

	width: -webkit-fit-content;

	width: -moz-fit-content;

	width: fit-content;

	margin-top: 20px;
	background-color: rgba(0, 64, 154, .07);
}

.suporte__menu-itens button {
	background-color: unset;
	color: #00518b !important;

	padding: 10px 20px;
	border: 2px solid #00518b;
	margin: 1px 0 0 0;

	line-height: 1.5;
	font-family: "Open Sans";
	font-size: 1em;
	font-weight: bold;
}

.suporte__menu-itens .selected {
	color: #fff !important;

	background-color: #00518b;
}

.suporte__selected-page {
	width: 50%;
}
* {
	margin: 0;
	padding: 0;
}
.consultar-cupom__container{
	display: flex;
    justify-content: center;
}
.consultar-search-button {
	border-radius: 1.875rem;
	width: 20.1875rem;
	height: 3.125rem;
	padding: 0 .9375rem;
	position: relative;
	color: white;
	font-size: 1rem;
	text-align: center;
	font-weight: bold;
	background: #00518b;
	background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
	background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
	margin: 0 auto;
	margin-top: 1.75rem;
	-webkit-transition: all .155s ease-in;
	transition: all .155s ease-in;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.result-card-main {
	width: 60%;
	margin: 20px auto 0;
}

.result-card-main thead tr {
	background-color: #00518b;
}

.result-card-main thead tr th {
	text-align: center;
	color: white;
}

.result-card-main tbody tr td {
	color: #424b4a;
	text-transform: uppercase;
	/* width: 50%; */
}

.table-striped tbody tr:nth-child(odd) td {
	background-color: #E5EDF3;
}

.td-label {
	text-align: end;
}

.td-value {
	padding-left: 20px !important;
}

.tabela-pares-main {
	/* width: 50%; */
	margin: 40px auto 0;
}

.tabela-pares-main table thead tr th {
	padding: 0 0.75rem;
}

.par-select {
	display: inline-flex;
	position: relative;
	width: 70%;
	font-size: 90%;
}

.par-select-input {
	/* font-size: 0.875rem; */
	color: rgba(66, 75, 74, 0.8);
	font-family: "Open Sans";
	padding-left: 1.25rem;
	box-shadow: 0 0 0 1px #b5b5b5;
	border: medium none currentColor;
	border: initial;
	border-radius: 5px;
	/* margin: 1.375rem 1rem 0 0; */
	position: relative;
	z-index: 1;
	background: transparent;
	width: 100%;
}

.par-select-arrow {
	position: absolute;
	z-index: 0;
	right: 20px;
	top: 5px;
}

.par-select-arrow:before {
	content: "";
	display: block;
	width: 0.545rem;
	height: 0.545rem;
	position: absolute;
	border-bottom: 2px solid #00639d;
	border-right: 2px solid #00639d;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
* {
	margin: 0;
	padding: 0;
}
.precos-e-servicos-col-info-clientes{
	background-color: #d9e1e7 !important;
}.p-modal-verifica-redes{
	font-size: smaller;
    text-align: left;
    line-height: 30px;
}
.p-modal-sem-redes{
	text-align: center;
	font-size: smaller;

}
.custom-swal-height {
	height: 300px !important; 
	justify-content: space-around !important;
}
.DivVerificarRedeLink{
	display: flex;
    justify-content: space-between;
}
.info-cliente-resultcard-main {
	margin: 20px auto 0;
}
#info-cliente-main{
	width: 80%;
}
.LinkVerificaRede{
	font-weight: 600;
	text-decoration: underline;
}
.LinkVerificaRede:hover {
    color: #050708;
    font-weight: bold;
}
.info-cliente-resultcard-main thead tr {
	background-color: #00518B;
}

.info-cliente-resultcard-main thead tr th {
	text-align: center;
	color: white;
}

.info-cliente-resultcard-main tbody tr td {
	padding: 0.25rem;
	font-size: 12px;
	color: #424b4a;
}

.info-cliente-resultcard-main .table {
	border: 1px solid #E5EDF3;
}

.info-cliente-resultcard-main .table-striped tbody tr:nth-child(odd) td {
	background-color: #E5EDF3;
}

.info-cliente-resultcard-main .table-striped tbody tr:nth-child(even) td {
	background-color: #FAFAFF;
}

.td-label {
	text-align: end;
}

.td-value {
	padding-left: 20px !important;
	font-weight: bold;
}

.search-button {
	border-radius: 1.875rem;
	width: 20.1875rem;
	height: 3.125rem;
	padding: 0 .9375rem;
	position: relative;
	color: white;
	font-size: 1rem;
	text-align: center;
	font-weight: bold;
	background: #00518b;
	background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
	background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
	margin: 0 auto;
	margin-top: 1.75rem;
	-webkit-transition: all .155s ease-in;
	transition: all .155s ease-in;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
* {
	margin: 0;
	padding: 0;
}

.links ul {
	margin-top: 50px;

	list-style-type: square;
	list-style-position: inside;

	font-size: 20px;
}

.links ul li::marker {
	color: #0394db;
	font-size: 21px;
}

.links ul li {
	margin-bottom: 15px;
}

.links ul li a {
	color: #00518b;
}
.links-uteis_container {
    display: flex;
    justify-content: center;
}
.links-uteis-main {
    width: 80%;
}
.MenuLateral_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.MenuLateral_menu-options {
  width: 30vw;
  max-width: 350px;
}

.MenuLateral_menu-itens {
  display: block;
  margin: 20px 3px 50px;
}
.MenuLateral_menu-itens button:hover {
  color: #fff !important;
  border: 2px solid rgb(0, 148, 219);
  background-color: rgb(0, 148, 219);
}
.MenuLateral_menu-itens button {
  width: 100%;
  background-color: rgba(0, 64, 154, 0.07);
  color: #00518b !important;
  padding: 10px 20px;
  border: 2px solid #00518b;
  margin: 1px 5px;
  line-height: 1.5;
  font-family: "Open Sans";
  font-size: 1em;
  font-weight: bold;
}

.MenuLateral_menu-itens .selected {
  color: #fff !important;

  background-color: #00518b;
}

.MenuLateral_selected-page {
  width: 96%;
}

@media only screen and (max-width: 1110px) {
  .MenuLateral_menu-itens button {
    width: 100%;
  }
}
@media only screen and (max-width: 620px) {
  .MenuLateral_menu-itens {
    display: block;
    margin: 50px 3px 50px;
  }
}

@media only screen and (max-width: 730px) {
  .MenuLateral_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .MenuLateral_menu-options {
    max-width: 350px;
    width: 100%;
  }
}

.x-downloads__search--item {
	/* width: 100%; */
	display: flex;
	flex-direction: column;
	flex: 0 0 25%;
}

.x-downloads__search--label {
	font-size: 0.875rem;
	font-family: 'Montserrat', sans-serif;
	font-weight: bold;
	margin-bottom: 0.3rem;
}

.x-downloads__search--position-select {
	position: relative;
}

.x-downloads__search--select {
	max-width: 100%;
	-webkit-appearance: none;
	border: none;
	box-shadow: 0rem 0rem 0rem 0.125rem rgba(181, 181, 181, 0.5);
	padding: 0.75rem 0.875rem 0.75rem 0.4375rem;
	border-radius: 0.3125rem;
	position: relative;
	width: 100%;
	font-family: 'Open Sans';
	font-size: 0.875rem;
	color: rgba(66, 75, 74);
	position: relative;
	z-index: 99;
	background: transparent;
}

.x-downloads__search--select:focus {
	outline: none;
	box-shadow: 0 0 2px 2px rgba(0, 89, 147, 0.4);
	border-radius: 0.3125rem;
}

.x-downloads__search--arrow:after {
	content: '';
	display: block;
	width: 0.625rem;
	height: 0.625rem;
	border-right: 2px solid #005991;
	border-bottom: 2px solid #005991;
	position: absolute;
	-webkit-transform: translateY(-50%) rotate(45deg);
	transform: translateY(-50%) rotate(45deg);
	right: 1rem;
	top: 50%;
	z-index: 1;
}

@media only screen and (max-width: 766px) {
	.x-downloads__search--item {
		margin: 0 0 1rem 0;
		max-width: 100%;
	}

	.x-downloads__search--label {
		margin: 0 0 0.5rem 0;
	}

	.x-downloads__search--select {
		width: 100%;
	}

	.x-downloads__search--arrow:after {
		top: 45%;
		right: 1.25rem;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-downloads__search--arrow:after {
		right: 25%;
	}
}
.precos-e-servicos-file-card {
	box-shadow: 0 0 1px 1px rgba(181, 181, 181, 0.5);
	display: flex;
	flex-direction: column;
	margin: 0 1% 10% 0;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	overflow: hidden;
	width: 90%;
}

.precos-e-servicos-file-card-text {
	max-width: 17.375rem;
	font-size: 0.875rem;
	color: #0066b3;
	font-family: 'Montserrat', sans-serif;
	padding: 1.0625rem 1.0625rem 0 1.0625rem;
}

.precos-e-servicos-file-card-text.text {
	height: 80px;
}

.precos-e-servicos-file-card-text.is-text--lg {
	padding: 0.75rem 1.0625rem 0.75rem 1.0625rem;
	color: #fff;
	font-weight: bold;
	font-family: 'Open Sans';
	font-size: 0.875rem;
}


.precos-e-servicos-file-card-text {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* margin: 0 0 0.625rem 0; */
}

.precos-e-servicos-file-card-field {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 0.625rem 0;
	padding-top: 10px;
}

.precos-e-servicos-file-card-image {
	display: block;
	width: 150px;
	max-width: 100%;
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	-webkit-transition: opacity 0.3s ease-in-out,
		-webkit-transform 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
	-webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
		-webkit-transform 0.3s ease-in-out;
}

.precos-e-servicos-file-card-button {
	background: #0394db;
	background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
	background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
	border-radius: 1.25rem;
	display: flex;
	align-items: center;
	padding: 0 0.625rem;
	width: 90%;
	justify-content: center;
	position: relative;
}

.precos-e-servicos-file-card-svg {
	position: absolute;
	right: 0.625rem;
}

.flex-column {
	flex-direction: column;
}

.deleteIcon {
    display: flex;
    justify-content: flex-end;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; 
    color: rgb(208, 35, 35);
}

.deleteIcon.active {
    color: rgb(255, 123, 116);
}
.createFileFiv{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 90%;
    width: 90%;
    padding-top: 15px;
}
.createFileFiv p{
    text-align: -webkit-center;
    max-width: 17.375rem;
    font-size: 0.875rem;
    color: #0066b3;
    font-family: 'Montserrat', sans-serif;
    padding: 1.0625rem 1.0625rem 0 1.0625rem;
}
.labelCreateFile{
    height: 100%;
}
.pgvalue{
    display: flex;
    justify-content: center;
    margin-top: 5px;
    flex-direction: column;
    align-items: center;
}
.pgvalue p{
    font-size: small;
    color: #7b7b7b;
    margin-bottom: 5px;
}
.back {
  margin: 40px 0 0 60px;
}

.precos-e-servicos__main {
  margin: 0 1rem;
}
.preco-e-servicos-filter-div{
    display: flex;
    flex-wrap: wrap;
}

.precos-e-servicos_container {
  /* display: -webkit-box; */
  /* display: flex; */
  margin: 0 60px;
  align-items: center;
}

.files-container {
  margin-top: 50px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
  max-width: 904px;
}

.precos-e-servicos-intro {
  display: flex;
  flex-direction: column;
}

.precos-e-servicos-title {
  font-size: 2.25rem;
  font-family: "Montserrat", sans-serif;
  color: #0066b3;
  margin: 2.375rem 0 0 0;
}

.precos-e-servicos-text p{
  font-family: "Montserrat", sans-serif;
  font-size: .875em;
  color: #424B4A;
}

.precos-e-servicos-shape {
  width: 0.625rem;
  height: 0.625rem;
  background: #0394db;
  margin: 0.625rem 0 1.5625rem 0;
}

.precos-e-servicos-button {
  background: #0394db;
  background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
  background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
  width: 90%;
  justify-content: center;
  position: relative;
}

.precos-e-servicos-svg {
	position: absolute;
	right: 0.625rem;
}

.precos-e-servicos-text.is-text--lg {
	padding: 0.75rem 1.0625rem 0.75rem 1.0625rem;
	color: #fff;
	font-weight: bold;
	font-family: 'Open Sans';
	font-size: 0.875rem;
}

.limparFiltro {
	color: #0066b3
}

@media (max-width: 899px){

  .precos-e-servicos-text p {
    font-family: "Montserrat", sans-serif;
    font-size: .775em;
    color: #424B4A;
  }
  .precos-e-servicos-p{
    margin: 0px;
  }
  .precos-e-servicos-text {
    margin-top: 2vw;
    margin-bottom: 2vw;
  }
  .filters-container{
    margin-bottom: 5vw;
  }
  .files-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .precos-e-servicos-file-card-image {
    margin-top: 2vw;
    display: block;
    width: 100px;
    max-width: 100%;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }

}

@media (max-width: 768px){

  .container, .container-sm {
    max-width: 100%; 
  }
  .files-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
.precos-e-servicos-file-card-field {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 0.625rem 0;
  padding-top: 10px;
  flex-wrap: wrap;
  justify-content: center;
  }
}

@media (max-width: 450px){
  .files-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
.x-downloads__search--label {
	font-size: 0.875rem;
	font-family: 'Montserrat', sans-serif;
	font-weight: bold;
	margin-bottom: 0.3rem;
}

.x-downloads__search--position-input {
	position: relative;
}

.x-downloads__search--input {
	max-width: 100%;
	-webkit-appearance: none;
	border: none;
	box-shadow: 0rem 0rem 0rem 0.125rem rgba(181, 181, 181, 0.5);
	padding: 0.75rem 0.875rem 0.75rem 0.4375rem;
	border-radius: 0.3125rem;
	position: relative;
	width: 100%;
	font-family: 'Open Sans';
	font-size: 0.875rem;
	color: rgba(66, 75, 74);
	position: relative;
	z-index: 99;
	background: transparent;
}

.x-downloads__search--input:focus {
	outline: none;
	box-shadow: 0 0 2px 2px rgba(0, 89, 147, 0.4);
	border-radius: 0.3125rem;
}

@media only screen and (max-width: 766px) {
	.x-downloads__search--label {
		margin: 0 0 0.5rem 0;
	}

	.x-downloads__search--input {
		width: 100%;
	}
}
.colorInputCheck {
	-moz-appearance: none !important;
	appearance: none !important;
	-webkit-appearance: none !important;
	position: relative;
	height: 0;
}

.colorInputCheck.activeSvgToInput:after {
	content: "";
	background-color: #005991;
	display: inline-block;
	width: 11px;
	left: 3px;
	top: 3px;
	height: 11px;
	border-radius: 50%;
	position: absolute;
}

.colorInputCheck:before {
	content: "";
	display: none;
}

.colorInputCheck:checked:after {
	content: "";
	display: none;
}

.checkboxOne input[type=checkbox]:checked + label{
	border: 1px solid #0394db;
	border-radius: 17px;
    /* width: 120px;
    height: 125px; */
    margin-left: 20px;
    padding: 10px;

}

.colorLabelCheck {
	border: 1px solid transparent;
	cursor: pointer;
	pointer-events: all;
	/* width: 120px;
    height: 125px; */
    margin-left: 20px;
    padding: 10px;
}

.colorLabelCheck > img {
	/* width: 300px;
	height: 300px; */
}
.cEkUkh div:first-child {
  white-space: normal !important;
}
.hOGwzP div:first-child {
    white-space: normal !important;
}
.bgXvAY div:first-child {
  white-space: normal !important;
}
.koHINl div:first-child {
  white-space: normal !important;
}
.product-management-search-button {
  margin-bottom: 2px;
  border-radius: 1.875rem;
  width: 20.1875rem;
  height: 3.125rem;
  padding: 0 0.9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  -webkit-transition: all 0.155s ease-in;
  transition: all 0.155s ease-in;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cadastralBuscaColSelect {
  display: flex;
}
.col12PieData {
  display: flex !important;
  justify-content: flex-end !important;
  flex-direction: column;
  align-items: center;
}
.CleanAnchorFilter {
  text-decoration: underline !important;
  color: #00409a;
  font-family: var(--bs-font-sans-serif);
  font-size: small;
}
.CleanAnchorFilter:hover {
  text-decoration: underline !important;
  color: #0394db;
}
.w-100.d-flex.justify-content-between.align-items-end.headerCardPieChart.card-header {
  padding: 10px !important;
}
.CleanAnchordownload {
  font-family: var(--bs-font-sans-serif);
  font-size: small;
  text-decoration: underline !important;
  color: #00409a;
}
.CleanAnchordownload:hover {
  text-decoration: underline !important;
  color: #0394db;
}

.downloadButton {
  background-color: #0394db;
  color: white;
  padding: 5px 11px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  margin-bottom: 1vw;
  margin-top: 1vw;
  font-size: medium;
}
.chartjs-render-monitor {
  display: block !important;
  height: 215px !important;
  width: 437px !important;
}
/*.col12PieData .chartjs-render-monitor {
    width: 100% !important;
    height: 100% !important;
    margin-top: 2vw;
    padding: 0; 
    transform: translate(67px, 5px);
  }*/
.card-header {
  padding: 0 !important;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.headerColapse {
  display: flex;
  justify-content: space-between;
  height: 45px;
  padding: 10px 32px 7px 28px;
}
.cadatralPieData {
  color: #00409a;
  display: flex;
  flex-direction: row-reverse;
}
.conecta-h1 {
  font-size: 1.25rem;
}
/*
.chartjs-render-monitor{
    width: 100% !important;
    height: 100% !important;
    margin-top: 2vw;
    padding-left: 1vw;
    transform: translate(67px, 5px);
}*/

.cadastralFiltroRow1 {
  width: 100%;
}
.col6CadastralFiltroLinha {
  display: flex !important;
  justify-content: space-around !important;
}
.cardDivClasse {
  width: 45%;
}

.col-12.headerColapse {
  font-weight: bolder;
}
.col6PieData {
  padding: 0px 0px 50px 0px;
}
@media (max-width: 1000px) {
  .col6PieData {
    width: 100% !important;
  }
  .setaFiltroSelect {
    width: 10px !important;
  }
}

@media (max-width: 900) {
  .colBaseCOlapse {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .cadastralFiltroRow1 {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
  }

  .col-6.my-3.text-center.col6PieData {
    width: 100% !important;
  }
}

.setaFiltroSelect {
  width: 10px !important;
}

.containerLink{
    display: flex;
    height: 91px;
    align-items: center;
}
.filtroInputlabel {
	font-size: small;
    -webkit-transform: translate3d(10px, 15px, 0);
            transform: translate3d(10px, 15px, 0);
    color: #00518b;
    background: #fff;
    font-family: "Open Sans";
    position: absolute;
    z-index: 2;
    padding: 0 5px;
    margin-right: 5px;
}

.divFiltroSelectContainer{
    position: relative; 
}
.setaFiltroSelect {
    position: absolute; 
    right: 11px;
    top: 21px;
}
.filtroInput {
    font-size: small;
	border: 1px solid #b5b5b5;
    padding: 0.45rem 0.20rem 0.45rem 0.55rem;
    border-radius: 5px;
    margin: 0.5rem 0rem 0 0;
	position: relative;
    z-index: 0;
    width: 312px;

}

.filtroInput:focus {
    outline: none;
    border: 2px solid rgba(0, 89, 147, 0.4);
    box-shadow: inset 0 0 6px 2px hsla(204, 92%, 61%, 0.4);
    border-radius: 0.3125rem;
}
.filtroInputLabel{
    font-size: small;
    margin-right: 5px;
}

@media only screen and (max-width: 766px) {
	.filtroInputLabel {
		margin-top: 1vw;
	}

	.filtroInput {
		width: 100%;
	}
}



.container .divColapseFiltro,
.container .divColapseFiltro * {
    display: none; 
}

.container.uncollapsed .divColapseFiltro,
.container.uncollapsed .divColapseFiltro * {
    
    display: inline-block;
}
.container.uncollapsed .divColapseFiltro,
.container.uncollapsed .divColapseFiltroFlex * {
    
    display: flex; 
}
.container .divColapseFiltro,
.container .divColapseFiltroFlex * {
    display: none; 
}
.divColapseFiltroFlex{
    width: 100%;
    margin-bottom: 3vw;
}
.divColapseFiltro{
    width: 100%;
    margin-bottom: 3vw;
}
.filterButton {
    background-color: #0394db;
    color: white;
    padding: 6px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
    margin: 0vw 0px 20px 0px;
    width: 9rem;
}
.container.uncollapsed{
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.container.collapsed{
    padding-right: 0px !important; 
    padding-left: 0px !important;
}


.containerFilterButtonClean{
    display: flex !important;
    justify-content: flex-end !important;
}

.aFilterCleanButton:hover{
    color:white !important;
}
.filterButton:hover{
    box-shadow: inset;
    background-color: #00518b;
    color: white;
}

.CleanfilterButton:hover{
    
    background-color:#6aa4cd;
}
.CleanfilterButton{
    background-color:#6088a4;/* Cor de fundo em azul neon */
    color: white; /* Cor do texto em branco */
    padding: 10px 20px; /* Espaçamento interno */
    border: none; /* Remova a borda */
    border-radius: 5px; /* Borda arredondada */
    cursor: pointer; /* Altera o cursor para indicar que é clicável */
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease; 
    margin-bottom: 2vw;
    margin-top: 2vw;
}
.colBaseCOlapse{
    margin-bottom: 1vw;
}
.colBaseColapseButton{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 4vw;
    font-size: small;
}
.baseColapse{
    width: inherit;
     display: flex !important;
    justify-content: center !important;
}
.divColapseFiltro{
    width: 100%;
    
}

@media only screen and (max-width: 500px) {
    #colBaseCOlapse{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    
    .filterButton {
        background-color: #0394db;
        color: white;
        padding: 6px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        -webkit-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;
        margin: 0vw 0px 20px 0px;
        width: 100%;
    }


#colBaseCOlapse{
    margin: 0;
    padding: 0;
}
}



.x-downloads__search--item {
	/* width: 100%; */
	display: flex;
	flex-direction: column;
	flex: 0 0 25%;
}
.filtroSelectOptionSlected {
    width: 312px;
}
.x-downloads__search--label {
	font-family: 'Montserrat', sans-serif;
	font-weight: bold;
	margin-bottom: 0.3rem;
}

.x-downloads__search--position-select {
	position: relative;
}

.filtroSelect {
	font-size: small;
	border: 1px solid #b5b5b5;
    padding: 0.45rem 0.20rem 0.45rem 0.55rem;
    border-radius: 5px;
    margin: 0.50rem 1rem 0 0;
    z-index: 0;
    width: 100%;
}

.x-downloads__search--select:focus {
	outline: none;
	box-shadow: 0 0 2px 2px rgba(0, 89, 147, 0.4);
	border-radius: 0.3125rem;
}
.divFiltroSelectContainer{
	height: 42px;
}

.x-downloads__search--arrow:after {
	content: '';
	display: block;
	width: 0.625rem;
	height: 0.625rem;
	border-right: 2px solid #005991;
	border-bottom: 2px solid #005991;
	position: absolute;
	-webkit-transform: translateY(-50%) rotate(45deg);
	transform: translateY(-50%) rotate(45deg);
	right: 1rem;
	top: 50%;
	z-index: 1;
}
.setaFiltroSelect{

    width: 0.625rem;
    height: 0.625rem;
    border-right: 2px solid #005991;
    border-bottom: 2px solid #005991;
	position: absolute;
	-webkit-transform: translateY(-50%) rotate(45deg);
	        transform: translateY(-50%) rotate(45deg);

}

@media only screen and (max-width: 766px) {
	.x-downloads__search--item {
		margin: 0 0 1rem 0;
		max-width: 100%;
	}

	.x-downloads__search--label {
		margin: 0 0 0.5rem 0;
	}

	.x-downloads__search--select {
		width: 100%;
	}

	.x-downloads__search--arrow:after {
		top: 45%;
		right: 1.25rem;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-downloads__search--arrow:after {
		right: 25%;
	}
}
.card-result .table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: rgba(0, 64, 154, 0.07);
}

.card-result .table {
  width: auto !important;
  border: #e1e6eb 1px solid !important;
}

.card-result .table > thead {
  background-color: #00538c;
}

.card-result .table > thead > tr > th {
  color: #fff !important;
}

.card-result {
  font-size: 12px !important;
}

.strong-codigo-vazio {
  padding-left: 1.069rem;
}

#laboratorio_cnpj {
  margin-bottom: 2.5rem;
}

.product-management-container {
  margin: 0px 60px;
}
#main-product-management {
  margin: 0 1rem;
}

.product-managemente-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.product-managemente-card {
  width: 90%;
}

.conecta-produtos-h1{
    padding: 35px 0px;
}



.tableFixHead          { overflow: auto; height: 100px; }
.tableFixHead thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1; }
