.MenuHorizontal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MenuHorizontal_menu-options {
  width: 60%;
  margin-right: 14px;
}

.MenuHorizontal_menu-itens {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 50px 3px 50px;
}

.MenuHorizontal_menu-itens button {
  width: 50%;
  background-color: rgba(0, 64, 154, 0.07);
  color: #00518b !important;
  padding: 10px 20px;
  border: 2px solid #00518b;
  margin: 1px 5px;
  line-height: 1.5;
  font-family: "Open Sans";
  font-size: 1em;
  font-weight: bold;
}

.MenuHorizontal_menu-itens .selected {
  color: #fff !important;

  background-color: #00518b;
}
.MenuHorizontal_menu-itens button:hover {
  color: #fff !important;
  border: 2px solid rgb(0, 148, 219);
  background-color: rgb(0, 148, 219);
}
.MenuHorizontal_selected-page {
  width: 96%;
}

@media only screen and (max-width: 1110px) {
  .MenuHorizontal_menu-itens button {
    width: 100%;
  }
  .MenuHorizontal_menu-options {
    width: 80% !important;
  }
}
@media only screen and (max-width: 620px) {
  .MenuHorizontal_menu-itens {
    display: flex;
    flex-direction: column;
    margin: 50px 3px 50px;
  }
}
