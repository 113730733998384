#reset-padlock-success{
  position: relative;
}
#reset-padlock{
  padding-bottom:40px ;
}
.p-check-new-password{
  font-size: 14px;
  line-height: 26px;
  color: #424B4A;
}
.passwordRoles{
  background: #f3f3f394;
  padding: 10px;
  margin: 10px 22px 10px 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
#btn_enviarNewPassaword{
  margin-top: 30px;
}
.newPassword__title {
    font-family: "Montserrat";
    font-weight: 800;
    color: #00409a;
    text-align: center;
    padding: 20px 0 0 0px;
    margin: 0px;
}
.newPassword-signin__text{
  text-align: center;
  max-width: 100%;
  color: #0066b3;
  font-family: "Open Sans";
  font-size: 1em;
  margin-bottom: 37px;
}
.newPassword-text {
 
  max-width: 95%;
  font-family: "Open Sans", sans-serif;
  font-size: 16px; 
  margin-bottom: 0px; 
  color: #333; 
  background-color: #f9f9f9; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  padding: 15px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.error-message {
  background-color: #f8d7da;
  color: #721c24; 
  padding: 10px;
  border: 1px solid #f5c6cb; 
  border-radius: 4px;
  margin-top: 10px;
}
.reset-banner {
  width: 70%;
  display: -webkit-box;
  display: flex;
}

.reset-banner__wallpapper_1 {
  background-image: url("../index/img/personagem02.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 460px;
  width: 100%;
  height: 100%;
}

.reset-banner__wallpapper_2 {
  background-image: url("../index/img/personagem02.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 460px;
  width: 100%;
  height: 100%;
}

.reset-banner__wallpapper_3 {
  background-image: url("../index/img/personagem03.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 460px;
  width: 100%;
  height: 100%;
}

.reset-pass__alert-modal {
  width: 40rem;
  height: 50rem;
}

.swal2-html-container {
  max-height: 30rem;
  text-align: center;
}

.reset-pass__alert-modal-img {
  border: ghostwhite;
  border-style: groove;
  border-radius: 10px;
}

.reset-pass__alert-modal-link {
  color: #0066b3;
  text-decoration: underline !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__wallpapper {
    height: initial;
    background-size: 392px;
    position: relative;
    -webkit-transform: translate3d(30%, 20%, 0);
    transform: translate3d(30%, 20%, 0);
    z-index: 0;
  }
}

.reset-banner__color {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__color {
    width: 80%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .reset-banner {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
  }
}

.reset-banner__button-mobile {
  display: none;
}

@media (max-width: 767px) {
  .reset-banner__button-mobile {
    display: block;
    width: 100%;
    background: #0066b3;
    color: #fff;
    height: 50px;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    font-family: Open Sans;
    border: none;
    font-weight: 800;
    border-radius: 25px;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 20px;
  }
}

.reset-banner__logo {
  padding: 27px 0 68px;
}

@media (max-width: 767px) {
  .reset-banner__logo {
    padding: 0 0 68px;
    position: relative;
    z-index: 1;
    margin: 0 60%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__logo {
    padding: 0 0 68px;
    margin: auto 20%;
    width: 0;
    display: -webkit-box;
    display: flex;
    align-self: flex-end;
  }
}

.reset-banner__logo-image {
  max-height: 67px;
}

.reset-banner__title {
  font-size: 50px;
  font-family: Montserrat;
  font-weight: 800 !important;
  position: relative;
  padding-bottom: 41px;
  color: #00409a;
  line-height: 60px;
  max-width: 100%;
}

@media (max-width: 767px) {
  .reset-banner__title {
    font-size: 32px;
    line-height: 37px;
  }
}

.reset-banner__title:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #0394db;
  position: absolute;
  bottom: 0;
}

@media (max-width: 767px) {
  .reset-banner__title-text {
    width: 100%;
  }
}

.reset-banner__paragraph {
  font-size: 16px;
  line-height: 26px;
  color: #424b4a;
  max-width: 100%;
  padding-top: 34px;
}

@media (max-width: 767px) {
  .reset-banner__paragraph {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-banner__paragraph {
    max-width: 100%;
  }
}

.reset-signin__text {
  text-align: center;
  max-width: 100%;
  color: #0066b3;
  font-family: "Open Sans";
  font-size: 1em;
  margin-bottom: 37px;
  margin-top: 27px;
}

.reset-signin__text.tp-2 {
  color: #424b4a;
}

.reset-signin__text.tp-2 > a {
  color: #0066b3;
}

.reset-signin__text.tp-3 > a {
  color: #0066b3;
  font-size: 0.8185em;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-signin {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px auto 45px auto;
    background: #fff;
    padding-bottom: 40px;
    margin-left: 0;
    width: 90%;
    box-shadow: 1px 4px 6px -2px rgba(186, 186, 186, 0.56);
  }
}

@media (max-width: 767px) {
  .reset-signin {
    position: fixed;
    background: white;
    padding: 0 32px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    left: 0;
    /* overflow: hidden; */
    width: 100%;
    height: 105%;
  }

  .reset-signin.is--active {
    display: block;
    z-index: 2;
    overflow-y: scroll;
  }
}

.reset-signin__container {
  width: 349px;
}

.reset-signin__container.js--padlock {
  position: absolute;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.reset-signin__container.js--email {
  position: relative;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.reset-signin__container.is--disable {
  pointer-events: none;
  cursor: none;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.175s ease-in-out;
  transition: opacity 0.175s ease-in-out;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-signin__container {
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .reset-signin__container {
    width: auto;
  }
}

.reset-signin__padlock {
  background: url("./img/reset-password-padlock.png") no-repeat center center;
  height: 100px;
  position: relative;
  margin-top: 20%;
}

@media (max-width: 767px) {
  .reset-signin__padlock {
    margin-top: 30%;
  }
}

.reset-signin__email {
  background: url("./img/reset-password-email.png") no-repeat center center;
  height: 100px;
  position: relative;
  margin-top: 20%;
}

@media (max-width: 767px) {
  .reset-signin__email {
    margin-top: 50%;
  }
}

.reset-signin__logo-image {
  display: none;
}

@media (max-width: 767px) {
  .reset-signin__logo-image {
    max-height: 67px;
    display: block;
    margin: 0 auto;
  }
}

.reset-signin__back-button {
  display: none;
}

@media (max-width: 767px) {
  .reset-signin__back-button {
    position: absolute;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    top: 30px;
  }
}

.reset-signin__title {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  color: #00409a;
  text-align: center;
  padding: 80px 0 25px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-signin__title {
    padding: initial;
  }
}

@media (max-width: 767px) {
  .reset-signin__title {
    padding: 28px 0 15px;
    position: fixed;
    top: 12%;
    left: 30%;
  }

  .reset-signin__title:before {
    content: "Nova senha";
    display: block;
    clear: both;
    width: 150px;
    font-size: 18px;
    color: #0066b3;
    position: relative;
    z-index: 2;
  }
}

.reset-signin__form {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.reset-signin__form-input {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #0066b3;
  padding-left: 15px;
  margin-bottom: 51px;
  font-size: 14px;
}

.reset-signin__form-input::-webkit-input-placeholder {
  color: #424b4a;
  opacity: 0.4;
  font-family: Open Sans;
}

.reset-signin__form-input::-moz-placeholder {
  color: #424b4a;
  opacity: 0.4;
  font-family: Open Sans;
}

.reset-signin__form-input::-ms-input-placeholder {
  color: #424b4a;
  opacity: 0.4;
  font-family: Open Sans;
}

.reset-signin__form-input::placeholder {
  color: #424b4a;
  opacity: 0.4;
  font-family: Open Sans;
}

.reset-signin__form-row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative;
}

.reset-signin__form-label {
  font-size: 14px;
  line-height: 19px;
  color: #0066b3;
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0 5px;
  background: #fff;
}

.reset-signin__form-button {
  width: 255px;
  background: #0066b3;
  color: #fff;
  height: 50px;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  font-family: Open Sans;
  border: none;
  font-weight: 800;
  border-radius: 25px;
  align-self: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  align-items: center;
  opacity: 1;
  -webkit-transition: opacity 0.175s ease-in-out;
  transition: opacity 0.175s ease-in-out;
}

.reset-signin__form-link {
  text-decoration: underline;
  color: #0066b3;
  padding: 0 0 23.5px;
  text-align: center;
}

.slick-list {
  overflow: hidden;
}

.slick-track {
  display: -webkit-box;
  display: flex;
  padding: 0;
}

.slick-dots {
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 87px;
  margin-top: 59px;
}

.slick-dots li {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  width: 15px;
  height: 15px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0 12px;
}

@media (max-width: 767px) {
  .slick-dots li {
    width: 6px;
  }
}

.slick-dots li.slick-active button {
  background: #0066b3;
  opacity: 1;
  width: 15px;
  height: 15px;
}

.slick-dots li button {
  margin: auto;
  padding: 0;
  font-size: 0;
  width: 11px;
  height: 11px;
  background: #0066b3;
  opacity: 0.5;
  border: none;
}

.slick-arrow {
  background-color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 35px;
  width: 35px;
  border: none;
  font-size: 0;
}

.slick-next {
  content: "";
  display: block;
  border-right: 2px solid #0066b3;
  border-top: 2px solid #0066b3;
  width: 14px;
  height: 14px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 72%;
  position: absolute;
  right: 40%;
  background: transparent;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .slick-next {
    right: 36%;
    top: 75%;
  }
}

@media (max-width: 767px) {
  .slick-next {
    top: 75%;
    right: 2%;
  }
}

.slick-prev {
  content: "";
  display: block;
  border-right: 2px solid #0066b3;
  border-top: 2px solid #0066b3;
  width: 14px;
  height: 14px;
  -webkit-transform: rotate(-136deg);
  transform: rotate(-136deg);
  top: 72%;
  left: 40%;
  position: absolute;
  background: transparent;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .slick-prev {
    left: 36%;
    top: 75%;
  }
}

@media (max-width: 767px) {
  .slick-prev {
    top: 75%;
    left: 2%;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.reset-main {
  font-family: Open Sans;
  overflow: hidden;
}

.reset.has--no-scroll {
  overflow: hidden;
}

@media (max-width: 767px) {
  .reset {
    overflow: hidden;
  }

  .reset.js-disable-scroll {
    overflow: initial;
  }
}

.reset-top {
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, #edf4fa), color-stop(50%, white));
  background: linear-gradient(90deg, #edf4fa 50%, white 50%);
  height: 650px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 0 60px 0 60px;
}
.reset-top-opacity {
  opacity: 0.2;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-top {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: auto;
    padding: 0 0 0 60px;
    background: -webkit-gradient(linear, left top, right top, color-stop(82%, #edf4fa), color-stop(50%, white));
    background: linear-gradient(90deg, #edf4fa 72%, white 50%);
  }
}

@media (max-width: 767px) {
  .reset-top {
    padding: 0 40px;
    background: #edf4fa;
    height: auto;
  }
}

.reset-banner__info {
  width: 320px;
}

@media (max-width: 767px) {
  .reset-bottom {
    display: none;
  }
}

.reset-signin__form-button a {
  color: #fff;
}

.reset-signin__form-button a:hover {
  color: #fff;
}
