.media-center-banner img {
    width:100%;
    height:auto;
    margin: 10px 0;
}

.media-center-banner img:hover {
    cursor:pointer;
}

.media-center-banner .mobile {
    display:none;
}

.media-center-banner .desktop {
    display:block;
}

@media only screen and (max-width: 766px) {

    .media-center-banner .mobile {
        display:block;
    }

    .media-center-banner .desktop {
        display:none;
    }
}
