.pedido-atacado .badge {
  border-radius: 25px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: #0394db !important;
}

p.x-web-ordering-pedido__summary--paragraph.mt- {
  color: #424b4a;
  font-size: 0.775rem;
  font-family: "Montserrat", sans-serif;
}

#logoAtacado {
  text-align: center;
}

.pedido-atacado .divAtacado {
  /* max-width: 290px !important; */
  /* width: 100% !important; */
  /* padding: 0 0.5rem !important; */
}

.pedido-atacado .atacado-receita-olho {
  /* max-width: 270px;
  width: 265px; */
  /* min-width: 260px; */
  margin-top: -6px;
}
.pedido-atacado .atacado-receita-olho .atacado-label-olho {
  font-size: 0.75rem;
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: relative;
  z-index: 2;
  padding: 0 5px;
  top: 0px;
  margin-bottom: 5px;
}

.pedido-atacado .crm-button-blue::selection,
.pedido-atacado .crm-button-blue:focus,
.pedido-atacado .crm-button-blue:focus-within,
.pedido-atacado .crm-button-blue:focus-visible,
.pedido-atacado .crm-button-blue:target {
  outline: -webkit-focus-ring-color auto 1px !important;
}

.pedido-atacado button:focus:not(:focus-visible) {
  outline: none;
}

.pedido-atacado .crm-button-blue:focus:not(:focus-visible) {
  outline: none;
}

.pedido-atacado .my-5 {
  margin-top: 0 !important;
}

.pedido-atacado .titulo-sessao {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

.pedido-atacado .novo-produto {
  color: #0394db;
  font-weight: bold;
}

.pedido-atacado .btnNovoProduto {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 1rem;
}

.pedido-atacado .tabela-produtos {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 2rem;
  /* overflow-x: auto; */
}

.pedido-atacado .dioptria-section .crm-input1 {
  /* max-width: 290px !important;   */
}

.pedido-atacado input#quantidade {
  margin-left: 3px !important;
}

.titulo-olho-dioptria {
  margin-top: -0.25rem;
  margin-bottom: 0.25rem;
  color: #00518b;
  font-size: 14px !important;
}

.pedido-atacado .btn-dash-plus {
  color: #0394db !important;
}

.pedido-atacado .btn-dash-plus > svg {
  color: #0394db !important;
}

.pedido-atacado .icon-dash-plus {
  color: #0394db !important;
  /* margin-top: 20px; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pedido-atacado input {
  margin-right: 0px !important;
}

.pedido-atacado .labSelect {
  filter: grayscale(100%);
  -webkit-transition: linear 0.4s;
  transition: linear 0.4s;
}

.pedido-atacado .select__control:hover,
.pedido-atacado .select__control--is-focused:hover {
  border-color: #b5b5b5 !important;
}

.pedido-atacado .labSelect:hover {
  filter: grayscale(0);
  -webkit-transition: linear 0.4s;
  transition: linear 0.4s;
}

.pedido-atacado .logolab {
  position: unset !important;
  width: 100% !important;
  text-align: center;
  right: 0;
  top: 200px;
  background: rgb(243, 243, 245);
  background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
}
.row.atacado-buttons.atacado-buttons-confirm {
  max-width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.red-border {
  border: red 1px solid !important;
  border-radius: 5px !important;
}

.red-border > div {
  border: none;
  border-radius: 5px !important;
}

.pedido-atacado #tipo_produto {
  margin: 1.375rem 0 1.25rem 0;
}

.pedido-atacado #inputProduto {
  margin-right: 0 !important;
}

.pedido-atacado #dados_empresa_faturamento_cnpj {
  margin-right: 0 !important;
}

.pedido-atacado .inputDiv {
  padding: 0 !important;
}

.pedido-atacado .crm-input1 .container-auto-select > div {
  margin-right: 0 !important;
}

.pedido-atacado .inputQntTable {
  margin-right: 0px !important;
  width: 50px;
  background: transparent;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  border: 1px solid rgb(128, 128, 128);
}

.x-order-confirmed-atacado .button-mobile-only {
  margin: 0 0 1rem;
  width: 14rem;
}

@media (max-width: 767px) {
  .row.atacado-buttons.atacado-buttons-confirm {
    max-width: 100%;
    flex-direction: column;
  }
  .product-list-td {
    padding: 0 20px !important;
  }
  .pedido-atacado .inputDiv {
    width: 40%;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-right: 5px;
  }
}

/*@media (max-width: 767px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    /* border-bottom: 3px solid #ddd; */
/*   display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
   
    font-size: 0.8em;
    text-align: initial;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }

  table td:last-child {
    border-bottom: 0;
    text-align: center;
  }

  .table > :not(caption) > * > * {
    padding: 0.4rem 0.4rem;
  }

  td:nth-of-type(4)::before {
    margin-top: 5px;
  }
}
*/
@media (min-width: 1200px) {
  .dioptria-section .col-xl-3 {
    max-width: 290px !important;
    /* width: 100% !important; */
    padding: 0 0.5rem !important;
  }
  .dioptria-section .col-xl-2 {
    max-width: 180px;
    padding: 0 0.5rem;
  }
}

@media (max-width: 1400px) {
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios {
    display: flex;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    min-height: 55.785px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    flex-flow: wrap row;
    justify-content: unset;
  }

  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios .radio-button-container {
    margin: 5px 1px 1px 5px;
  }
}
@media (min-width: 1401px) {
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios {
    display: flex;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    justify-content: space-around;
    min-height: 55.785px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    flex-flow: unset;
  }

  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios .radio-button-container {
    margin: 0 0 0 3.7px;
  }
}
@media (max-width: 767px) {
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios {
    display: block;
    min-width: 100px;
  }
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios .radio-button-container:first-of-type {
    margin-top: 10px !important;
  }
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios .radio-button-container {
    margin: 5px 10px;
  }
}

@media (min-width: 766px) and (max-width: 1299px) {
  .pedido-atacado .tabela-produtos {
    width: 100%;
  }
}
@media (min-width: 1300px) and (max-width: 1359px) {
  .pedido-atacado .tabela-produtos {
    width: 98%;
  }
}
@media (min-width: 1360px) and (max-width: 1400px) {
  .pedido-atacado .tabela-produtos {
    width: 96%;
  }
}
@media (min-width: 1401px) and (max-width: 1440px) {
  .pedido-atacado .tabela-produtos {
    width: 94%;
  }
}
@media (min-width: 1441px) and (max-width: 1470px) {
  .pedido-atacado .tabela-produtos {
    width: 92%;
  }
}
@media (min-width: 1471px) and (max-width: 1500px) {
  .pedido-atacado .tabela-produtos {
    width: 90%;
  }
}
@media (min-width: 1501px) {
  .pedido-atacado .tabela-produtos {
    width: 85%;
  }
}
@media (min-width: 1365px) {
  .row.atacado-buttons.atacado-buttons-confirm {
    padding: 0 0 0 3.75rem;
  }
}
@media only screen and (max-width: 766px) {
  .pedido-atacado .x-confirm-order__left {
    padding: 0;
  }
  .pedido-atacado .x-confirm-order__dados--item.is--large {
    margin-left: 10px;
  }
  .pedido-atacado .x-confirm-order__dados--item.blue {
    margin-left: 10px;
  }
  .pedido-atacado .atacado-buttons {
    display: block;
  }
  .pedido-atacado .atacado-buttons {
    padding: 0 !important;
  }
  .pedido-atacado .atacado-prev-button {
    width: auto;
    margin: 2rem 0 0;
    padding: 0;
  }
  .pedido-atacado .atacado-prev-button .crm-button-orange {
    width: 13.125rem;
    height: 3.125rem;
    margin: 20px 0px 0px 0;
  }

  .pedido-atacado .atacado-next-button.confirm {
    width: auto;
    margin: 0;
    padding: 0;
  }

  #crm-button-blue-atacado {
    margin-left: 0 !important;
  }
  .pedido-atacado .atacado-next-button.confirm .crm-button-blue {
    width: 13.125rem;
    height: 3.125rem;
    margin: 20px 0px 0px 0;
  }
  .x-order-confirmed-atacado .pdf-pedido__summary--print {
    padding: 1rem 0 1rem 0;
    text-decoration: none;
  }
  .x-order-confirmed-atacado .button-mobile-only {
    width: 13.125rem;
    margin: 0 0 1rem;
    padding: 0;
  }
}

/* leo */
.x-web-ordering-pedido__summary_Atacado {
  width: 25%;
  background: #f3f3f5;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 2.9375rem 2rem 6.75rem 2rem;
}

.x-web-ordering-pedido__summary_Atacado a:hover {
  color: #00518b !important;
}

.x-web-ordering-pedido__summary--arrow {
  display: none;
}

@media only screen and (max-width: 766px) {
  .align-middle:nth-child(2) {
    padding-left: 38px;
  }
  .align-middle:nth-child(3) {
    padding-left: 117px;
  }

  .align-middle:nth-child(4) {
    padding-left: 104px;
  }
  .tabela-produtos {
    max-width: 90%;
    overflow: auto;
  }
  .x-web-ordering-pedido__summary_Atacado {
    position: absolute;
    top: 8rem;
    width: 100%;
    height: 100%;
    right: -92vw;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    padding: 2.9375rem 2.75rem 6.75rem 2.75rem;
    overflow-y: scroll;
    height: 85%;
  }

  .x-web-ordering-pedido__summary_Atacado.show-summary {
    -webkit-transform: translateX(-92%);
    transform: translateX(-92%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 999;
  }

  .x-web-ordering-pedido__summary--arrow {
    display: inline-table;
    position: relative;
    right: 50px;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin: 20px;
  }

  .x-web-ordering-pedido__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__summary--container.show-summary {
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .p-5 {
    padding: 1rem !important;
  }

  .float-end {
    float: none !important;
  }

  .crm-input [type="textarea"] {
    margin: 0 !important;
  }

  .juntos {
    display: flex;
    justify-content: space-around;
  }

  .botao.col-1.text-center.justify-content-center.align-self-center {
    margin-left: 8px;
  }

  tr.linha-produto {
    display: table-row;
  }

  .product-list-td {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .dupla {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .separator {
    display: none;
  }
}
@media (max-width: 1150px) {
  .pedido-atacado .atacado-receita-olho .atacado-container-olho-radios {
    display: block;
  }
  .auto-complete-tooltip-shape {
    top: 40% !important;
  }
}
@media (max-width: 550px) {
  .align-middle {
    display: ruby;
  }
}
.ancor-matriz-dioptrias {
  display: flex;
  align-items: center;
  height: 60px;
}
