.x-web-ordering__container {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    color: #424B4A;
    overflow: hidden;
    line-height: 1.4;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.x-web-ordering__intro {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    /* margin: 0 0 3.875rem 3.75rem; */
    -webkit-box-pack: center;
    justify-content: center;
    background: #F3F3F5;
}

.x-web-ordering__intro--container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__intro--container {
        text-align: center;
    }
}

.x-web-ordering__intro-icon {
    max-width: 100%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__intro-icon {
        margin: 0 1.5rem;
    }
}

.x-web-ordering__intro--subtitle-bold {
    font-weight: bold;
    color: #0066B3;
}

.x-web-ordering__intro--link {
    color: #0066B3;
    text-decoration: underline;
}

.x-web-ordering__intro--text {
    font-family: "Montserrat", sans-serif;
    display: -webkit-box;
    display: flex;
    font-size: 1rem;
    color: #3F3F3F;
    margin: 0 0 0.9375rem 0;
    font-weight: bold;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__intro--text {
        max-width: 80%;
        font-size: 0.875rem;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__intro--text {
        max-width: 80%;
        margin: 0.625rem 0 1.875rem 0;
        align-self: center;
        display: block
    }

    .x-web-ordering__intro--subtitle {
        align-self: center;
        max-width: 80%;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__intro {
        /* margin: 0 0 1.875rem 3.75rem; */
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__intro {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin: 0 0 3.875rem 0;
        padding: 2rem 0;
    }
}

.x-web-ordering__left {
    padding: 2.8125rem 0 3.25rem 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__left {
        width: 100%;
        padding: initial;
        padding: 2.0625rem 0 3.125rem 0;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__left {
        width: 100%;
        padding: 2.8125rem 0 1.25rem 0;
    }
}

.x-web-ordering__right {
    width: 100%;
    /* background: #F3F3F5; */
    margin: 0 auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__right {
        width: 100%;

    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__right {
        width: 100%;
    }
}

.x-web-ordering__labs {
    margin: 2.1875rem 0 2.1875rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs {
        margin: 2.1875rem 0 2.375rem 0;
    }
}

.x-web-ordering__labs--container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--container {
        max-width: 18.375rem;
    }

    .x-web-ordering__labs {
        margin: 1.1875rem 0 1.375rem 0;
    }
}

.x-web-ordering__labs--title {
    font-size: 1.5em;
    color: #0066B3;
    align-self: center;
    margin: 0 0 0.9375rem 0;
    font-weight: bold;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--title {
        align-self: center;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--title {
        align-self: center;
        font-size: 1em;
    }
}

.x-web-ordering__labs--paragraph {
    font-size: .875em;
    color: #424B4A;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--paragraph {
        align-self: center;
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--paragraph {
        text-align: center;
    }
}

.x-web-ordering__labs--slider {
    /* max-width: 51rem; */
    position: relative;
    align-self: center;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--slider {
        /* max-width: 24.875rem; */
    }
}

@media only screen and (max-width: 766px) {
    /* .x-web-ordering__labs--slider {
        max-width: 15.375rem;
    } */
}

.x-web-ordering__labs--item {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    /* flex-direction: column; */
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--item {
        display: block;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--item {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.x-web-ordering__labs--subitem {
    display: -webkit-box;
    display: flex;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--subitem {
        display: -webkit-box;
        display: flex;
        flex-direction: column;
    }
}

.x-web-ordering__labs--subitem-link {
    text-align: center;
    cursor: pointer;
    pointer-events: all;
    display: block;
    -webkit-transition: -webkit-transform .175s ease-in-out;
    transition: -webkit-transform .175s ease-in-out;
    transition: transform .175s ease-in-out;
    transition: transform .175s ease-in-out, -webkit-transform .175s ease-in-out;
}

.x-web-ordering__labs--subitem-link:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .175s ease-in-out;
    transition: all .175s ease-in-out;
}

.x-web-ordering__labs--image {
    /* max-width: 90%; */
    margin: 0 0.125rem -0.3125rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--image {
        padding: 0 0.0625rem 0 0;
    }
}

.x-web-ordering__labs--text {
    display: -webkit-box;
    display: flex;
    align-self: center;
    font-size: .8125em;
    margin: 4.0625rem 0 2.3125rem 0;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--text {
        font-size: .8em;
        text-align: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin: 1.5rem 0 1.5625rem 0;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering__labs--text {
        font-size: .8em;
        margin: 2.5rem 0 1.5625rem 0;
    }
}

.x-web-ordering__labs--link {
    color: #00409A;
    text-decoration: underline;
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs--link {
        text-align: center;
        -webkit-transform: translate3d(70px, -16px, 0);
        transform: translate3d(70px, -16px, 0);
    }
}

@media only screen and (max-width: 766px) {
    .x-web-ordering__labs .slick-arrow.slick-next {
        right: 0 !important;
        top: 182px !important;
    }

    .x-web-ordering__labs .slick-arrow.slick-prev {
        left: 0 !important;
        top: 182px !important;
    }
}

.x-body .slick-list.draggable {
    overflow: hidden;
    padding: 0 !important;
}

.x-body .slick-slide {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-body .slick-slide {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
    }
}

@media only screen and (max-width: 766px) {
    .x-body .slick-slide {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.x-body .slick-arrow {
    font-size: 0;
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #00518B;
    border-right: 2px solid #00518B;
    top: 40%;
}

.x-body .slick-arrow.slick-prev {
    left: 0;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    margin: 0 0 0 -2.75rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-body .slick-arrow.slick-prev {
        margin: 1.25rem 0 0 -5rem;
    }
}

@media only screen and (max-width: 766px) {
    .x-body .slick-arrow.slick-prev {
        margin: 0;
        left: 37px;
        top: initial;
    }
}

.x-body .slick-arrow.slick-next {
    right: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0 -2.75rem 0 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-body .slick-arrow.slick-next {
        margin: 1.25rem -5rem 0rem 0rem;
    }
}

@media only screen and (max-width: 766px) {
    .x-body .slick-arrow.slick-next {
        margin: 0;
        right: 37px;
        top: initial;
    }
}

.x-body .slick-dots {
    position: absolute;
    display: -webkit-box;
    display: flex;
    bottom: -1.875rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.x-body .slick-dots li {
    margin: 0 0.875rem;
}

.x-body .slick-dots li button {
    font-size: 0;
    width: 0.5rem;
    height: 0.5rem;
    background-color: rgba(0, 102, 179, 0.5);
    opacity: .8;
}

.x-body .slick-dots li.slick-active button {
    background: #0066B3;
}

.x-body .slick-track {
    display: -webkit-box;
    display: flex;
}

@media only screen and (max-width: 766px) {
    .x-body .slick-track {
        width: 100%;
    }
}

.labSelect,
.x-web-ordering__labs--subitem-link img {
    filter: grayscale(100%);
    -webkit-transition: linear 0.4s;
    transition: linear 0.4s;
}

.labSelect:hover,
.x-web-ordering__labs--subitem-link img:hover {
    filter: grayscale(0);
    -webkit-transition: linear 0.4s;
    transition: linear 0.4s;
}

.x-web-ordering__labs--subitem-link label {
    margin-bottom: 0px;
    padding: 50px 25px;
}

.x-web-ordering__intro .hide-tutorial {
    display: none;
}

.x-web-ordering__intro .show-tutorial {
    display: content;
    opacity: 1;
    pointer-events: all;
    background: #fff;
    z-index: 99999;
    position: fixed;
    /* width: 920px; */
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    /* max-height: 80%; */
    border: 2px solid #F8F8F8;
    border-radius: 5px;
    transition: .5s bottom, .5s opacity;
}

.x-web-ordering__intro .tutorial-close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding-bottom: 15px;
}

/* Carousel */
.arrow-container {
    z-index: 3;
    transition: all .25s ease-in;
    opacity: 1;
    background: 0 0;
    border: 0;
    cursor: pointer;
    position: absolute !important;
    height: 100%;
    width: 25px;

    display: flex;
    align-items: center;
    color: #00A2E8;
}

.arrow-container-next {
    top: 0;
    right: 0;
}

.arrow-container-prev {
    top: 0;
    left: 0;
}

.slide>div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
}

.slide.selected>div {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
}

.slide.selected .x-web-ordering__labs--image,
.slide.selected .x-web-ordering__labs--imageNotFound {
    -webkit-transition: linear 0.6s;
    transition: linear 0.6s;
    filter: grayscale(0);
    color: #076CB6;
    font-weight: bold;
}

@media (max-width: 765px) {
    .slide label {
        font-size: 12px
    }

    .slide.selected>div {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
        -webkit-transition: all .6s ease-in-out;
        transition: all .6s ease-in-out;
    }

    .arrow-container {
        width: 27px;
    }

    .carousel-container {
        width: 90vw;
    }
}

@media only screen and (min-width: 766px) and (max-width: 1170px) {
    .x-web-ordering__labs--image {
        width: 140px;
    }
}