.cEkUkh div:first-child {
  white-space: normal !important;
}
.hOGwzP div:first-child {
    white-space: normal !important;
}
.bgXvAY div:first-child {
  white-space: normal !important;
}
.koHINl div:first-child {
  white-space: normal !important;
}
.product-management-search-button {
  margin-bottom: 2px;
  border-radius: 1.875rem;
  width: 20.1875rem;
  height: 3.125rem;
  padding: 0 0.9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: #00518b;
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  transition: all 0.155s ease-in;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cadastralBuscaColSelect {
  display: flex;
}
.col12PieData {
  display: flex !important;
  justify-content: flex-end !important;
  flex-direction: column;
  align-items: center;
}
.CleanAnchorFilter {
  text-decoration: underline !important;
  color: #00409a;
  font-family: var(--bs-font-sans-serif);
  font-size: small;
}
.CleanAnchorFilter:hover {
  text-decoration: underline !important;
  color: #0394db;
}
.w-100.d-flex.justify-content-between.align-items-end.headerCardPieChart.card-header {
  padding: 10px !important;
}
.CleanAnchordownload {
  font-family: var(--bs-font-sans-serif);
  font-size: small;
  text-decoration: underline !important;
  color: #00409a;
}
.CleanAnchordownload:hover {
  text-decoration: underline !important;
  color: #0394db;
}

.downloadButton {
  background-color: #0394db;
  color: white;
  padding: 5px 11px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 1vw;
  margin-top: 1vw;
  font-size: medium;
}
.chartjs-render-monitor {
  display: block !important;
  height: 215px !important;
  width: 437px !important;
}
/*.col12PieData .chartjs-render-monitor {
    width: 100% !important;
    height: 100% !important;
    margin-top: 2vw;
    padding: 0; 
    transform: translate(67px, 5px);
  }*/
.card-header {
  padding: 0 !important;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.headerColapse {
  display: flex;
  justify-content: space-between;
  height: 45px;
  padding: 10px 32px 7px 28px;
}
.cadatralPieData {
  color: #00409a;
  display: flex;
  flex-direction: row-reverse;
}
.conecta-h1 {
  font-size: 1.25rem;
}
/*
.chartjs-render-monitor{
    width: 100% !important;
    height: 100% !important;
    margin-top: 2vw;
    padding-left: 1vw;
    transform: translate(67px, 5px);
}*/

.cadastralFiltroRow1 {
  width: 100%;
}
.col6CadastralFiltroLinha {
  display: flex !important;
  justify-content: space-around !important;
}
.cardDivClasse {
  width: 45%;
}

.col-12.headerColapse {
  font-weight: bolder;
}
.col6PieData {
  padding: 0px 0px 50px 0px;
}
@media (max-width: 1000px) {
  .col6PieData {
    width: 100% !important;
  }
  .setaFiltroSelect {
    width: 10px !important;
  }
}

@media (max-width: 900) {
  .colBaseCOlapse {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .cadastralFiltroRow1 {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
  }

  .col-6.my-3.text-center.col6PieData {
    width: 100% !important;
  }
}

.setaFiltroSelect {
  width: 10px !important;
}
