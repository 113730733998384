/**!
 * Initialize.css (v1.3.2) is a complete and customizable collection of CSS best practices based on Normalize.css and HTML5 Boilerplate.
 * http://jeroenoomsnl.github.io/initialize-css
 *
 * HTML5 Boilerplate: https://github.com/h5bp/html5-boilerplate
 * Normalize.css: http://github.com/necolas/normalize.css
 */
/**
 * Predefined values
 */
/**
  * Options
  */
/**
  * Mixin for optional properties
  */
/* ==========================================================================
    General
    ========================================================================== */
/**
  * 1. Set default font family to $initialize-font-family (default: sans-serif)
  * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
  * 3. Configurable defaults
  */
  html {
    box-sizing: border-box;
    font-family: "Open Sans";
    /* 1 */
    line-height: 1.4;
    /* 3 */
    font-size: 1em;
    /* 3 */
    -ms-text-size-adjust: 100%;
    /* 2 */
    -webkit-text-size-adjust: 100%;
    /* 2 */ }

  /**
    * Remove the margin in all browsers
    */
  body {
    margin: 0; }

  /**
    * Inheriting box-sizing
    * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
    */
  *, *:before, *:after {
    box-sizing: inherit; }

  /* ==========================================================================
      HTML5 display defenitions
      ========================================================================== */
  /**
    * Add the correct display in IE 9-.
    * 1. Add the correct display in Edge, IE, and Firefox.
    * 2. Add the correct display in IE.
    */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block; }

  /**
        * Add the correct display in IE 9-.
        */
  audio,
  canvas,
  progress,
  video {
    display: inline-block; }

  /**
    * Add the correct display in iOS 4-7.
    */
  audio:not([controls]) {
    display: none;
    height: 0; }

  /**
    * Add the correct vertical alignment in Chrome, Firefox, and Opera.
    */
  progress {
    vertical-align: baseline; }

  /**
    * Add the correct display in IE 10-.
    * 1. Add the correct display in IE.
    */
  template,
  [hidden] {
    display: none; }

  /* ==========================================================================
      Links
      ========================================================================== */
  /**
    * 1. Remove the gray background on active links in IE 10.
    * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
    */
  a {
    background-color: transparent;
    text-decoration: none;
    /* 1 */
    -webkit-text-decoration-skip: objects;
    /* 2 */ }

  /**
    * Remove the outline on focused links when they are also active or hovered
    * in all browsers (opinionated).
    */
  a:active,
  a:hover {
    outline-width: 0; }

  /* ==========================================================================
      Text-level semantics
      ========================================================================== */
  /**
    * 1. Remove the bottom border in Firefox 39-.
    * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
    */
  abbr[title] {
    border-bottom: none;
    /* 1 */
    text-decoration: underline;
    /* 2 */
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    /* 2 */ }

  /**
    * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
    */
  b,
  strong {
    font-weight: inherit; }

  /**
    * Add the correct font weight in Chrome, Edge, and Safari.
    */
  b,
  strong {
    font-weight: bolder; }

  /**
    * Add the correct font style in Android 4.3-.
    */
  dfn {
    font-style: italic; }

  /**
    * Correct the font size and margin on `h1` elements within `section` and
    * `article` contexts in Chrome, Firefox, and Safari.
    */
  /**
       * Address styling not present in IE 8/9.
       */
  mark {
    background: #ff0;
    color: #000; }

  /**
    * Add the correct font size in all browsers.
    */
  small {
    font-size: 80%; }

  /**
    * Prevent `sub` and `sup` elements from affecting the line height in
    * all browsers.
    */
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }

  sub {
    bottom: -0.25em; }

  sup {
    top: -0.5em; }

  /* ==========================================================================
      Embedded content
      ========================================================================== */
  /**
        * Remove the border on images inside links in IE 10-.
        */
  img {
    border-style: none; }

  /**
    * Hide the overflow in IE.
    */
  svg:not(:root) {
    overflow: hidden; }

  /* ==========================================================================
      Grouping content
      ========================================================================== */
  /**
    * 1. Correct the inheritance and scaling of font size in all browsers.
    * 2. Correct the odd `em` font sizing in all browsers.
    */
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */ }

  /**
    * Add the correct margin in IE 8.
    */
  figure {
    margin: 1em 40px; }

  /**
    * 1. Add the correct box sizing in Firefox.
    * 2. Show the overflow in Edge and IE.
    */
  hr {
    box-sizing: content-box;
    /* 1 */
    height: 0;
    /* 1 */
    overflow: visible;
    /* 2 */ }

  /* ==========================================================================
      Forms
      ========================================================================== */
  /**
    * 1. Change font properties to `inherit` in all browsers (opinionated).
    * 2. Remove the margin in Firefox and Safari.
    */
  button,
  input,
  select,
  textarea {
    margin: 0;
    /* 2 */
    font-family: "Open Sans"; }

  /**
    * Restore the font weight unset by the previous rule.
    */
  optgroup {
    font-weight: bold; }

  /**
    * Show the overflow in IE.
    * 1. Show the overflow in Edge.
    */
  button,
  input {
    /* 1 */
    overflow: visible; }

  /**
    * Remove the inheritance of text transform in Edge, Firefox, and IE.
    * 1. Remove the inheritance of text transform in Firefox.
    */
  button,
  select {
    /* 1 */
    text-transform: none; }

  /**
    * Remove the inner border and padding in Firefox.
    */
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }

  /**
    * Restore the focus styles unset by the previous rule.
    */
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

  /**
        * Change the border, margin, and padding in all browsers (opinionated).
        */
  /**
    * 1. Correct the text wrapping in Edge and IE.
    * 2. Correct the color inheritance from `fieldset` elements in IE.
    * 3. Remove the padding so developers are not caught out when they zero out
    *    `fieldset` elements in all browsers.
    */
  legend {
    box-sizing: border-box;
    /* 1 */
    color: inherit;
    /* 2 */
    display: table;
    /* 1 */
    max-width: 100%;
    /* 1 */
    padding: 0;
    /* 3 */
    white-space: normal;
    /* 1 */ }

  /**
    * Remove the default vertical scrollbar in IE.
    */
  textarea {
    overflow: auto; }

  /**
        * 1. Add the correct box sizing in IE 10-.
        * 2. Remove the padding in IE 10-.
        */
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */ }

  /**
    * Correct the cursor style of increment and decrement buttons in Chrome.
    */
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto; }

  /**
    * 1. Correct the odd appearance in Chrome and Safari.
    * 2. Correct the outline style in Safari.
    */
  [type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    outline-offset: -2px;
    /* 2 */ }

  /**
    * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
    */
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

  /**
    * Correct the text style of placeholders in Chrome, Edge, and Safari.
    */
  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54; }

  /**
    * 1. Correct the inability to style clickable types in iOS and Safari.
    * 2. Change font properties to `inherit` in Safari.
    */
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */ }

  /* ==========================================================================
      Optional configurations
      ========================================================================== */
  /**
    * Headings
    */
  /**
    * Block elements
    */
  /* ==========================================================================
      Print styles
      ========================================================================== */
  /* http://meyerweb.com/eric/tools/css/reset/
     v2.0 | 20110126
     License: none (public domain)
  */
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
 }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section {
    display: block; }

  blockquote,
  q {
    quotes: none; }
    blockquote:before, blockquote:after,
    q:before,
    q:after {
      content: "";
      content: none; }

  table {
    border-collapse: collapse;
    border-spacing: 0; }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: ''; }

  /* Hand cursor on clickable input elements */
  a,
  input[type="button"],
  input[type="submit"],
  input[type="file"],
  input[type="image"],
  button {
    cursor: pointer; }

  a {
    text-decoration: none; }

  ul {
    list-style: none; 
    padding-left: 0 !important
  }

  button {
    -webkit-appearance: none;
    border: none;
    background: none;
    outline: 0;
    margin: 0;
    padding: 0; }

  /** settings */
  .is--blue {
    background: #00518b;
    background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
    background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%); }

  .is--orange {
    background: #0394db;
    background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
    background: linear-gradient(90deg, #0394db 0%, #0394db 100%); }

  /**
   * Screen Sizes
   */
  /**
   * Breakpoints - Media Queries
   */
  /**
   * Media Queries
   * Mobile first
   */
  /**
   * Font Weight
   */
  /**
   * Common Helpers
   */
  /**
   * Clearfix: contain floats
   *
   * For modern browsers
   * 1. The space content is one way to avoid an Opera bug when the
   *    `contenteditable` attribute is included anywhere else in the document.
   *    Otherwise it causes space to appear at the top and bottom of elements
   *    that receive the `clearfix` class.
   * 2. The use of `table` rather than `block` is only necessary if using
   *    `:before` to contain the top-margins of child elements.
   */
  /**
   * Custom text selection style
   */
  /**
   * Custom input placeholders
   */
  /**
   * Custom default browser scrollbar
   */
  /**
   * Generate arrow on a pseudo-element
   * To set color, use 'border-color' inside a class
   *
   * @param  {string} $position:     up              Arrow position (up, right, bottom, left)
   * @param  {string} $arrow-width:  20px             Arrow Width
   * @param  {string} $arrow-size:   $arrow-width/4   Arrow size
   *
   * @return A CSS Arrow
   */
  /**
   * @include triangle within a pseudo element and add positioning properties (ie. top, left)
   * $direction: up, down, left, right
   */

/* tira a borda preta do chrome dos botões */
button:focus,button:hover,button::selection{
    outline:none!important;
}

/* CSS Headings */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.conecta-h1-container {
	position: relative;
    padding-bottom: 35px;
}

.conecta-h1-with-dot, .conecta-h1 {
	font-size: 2.5rem;
    font-weight: 800;
    color: #00409A;
    font-family: 'Montserrat', sans-serif;
}

.conecta-h1-with-dot:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #0394db;
    position: absolute;
    bottom: 0;
	left: 0;
}

@media screen and (max-width: 767px) {
	.conecta-h1-with-dot:after {
		left: 50%;
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.conecta-h1-with-dot:after {
		left: 50%;
	}
}

.conecta-h2 {
	font-family: 'Montserrat', sans-serif;
	color: #00409A;
	font-size: 1.5rem;
    font-weight: bold;
}

.conecta-h3 {
	font-family: 'Montserrat', sans-serif;
	color: #424b4a;
    font-size: 0.975rem;
    font-weight: bold;
}

.ancorThinDark {
  text-decoration: underline !important;
  color: #00518b;
  -webkit-text-stroke-width: thin;
}