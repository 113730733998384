.x-user-consultant__left {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
}

.x-user-consultant__title {
  font-size: 2.25rem;
  color: #0066b3;
  margin: 2.375rem 0 0 0;
}

.x-user-consultant__shape {
  width: 0.625rem;
  height: 0.625rem;
  background: #0394db;
  margin: 1.6875rem 0;
}

.x-user-consultant__paragraph {
  font-size: 1rem;
  color: #424b4a;
}

.x-user-consultant__paragraph a {
  text-decoration: underline !important;
  font-weight: 600;
  color: #0394db !important;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-user-consultant__title {
    margin: 2.375rem auto auto auto;
  }

  .x-user-consultant__shape {
    margin: 1.6875rem auto 1.6875rem auto;
  }

  .x-user-consultant__paragraph {
    max-width: 100%;
    text-align: center;
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-user-consultant__title {
    font-size: 1.75rem;
    margin: 2.375rem auto auto auto;
    text-align: center;
  }

  .x-user-consultant__shape {
    margin: 1.6875rem auto;
  }

  .x-user-consultant__paragraph {
    max-width: 95%;
    margin: 0 auto;
    font-size: 0.875rem;
    text-align: center;
  }
}
