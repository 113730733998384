.precos-e-servicos-file-card {
	box-shadow: 0 0 1px 1px rgba(181, 181, 181, 0.5);
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	margin: 0 1% 10% 0;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	overflow: hidden;
	width: 90%;
}

.precos-e-servicos-file-card-text {
	max-width: 17.375rem;
	font-size: 0.875rem;
	color: #0066b3;
	font-family: 'Montserrat', sans-serif;
	padding: 1.0625rem 1.0625rem 0 1.0625rem;
}

.precos-e-servicos-file-card-text.text {
	height: 80px;
}

.precos-e-servicos-file-card-text.is-text--lg {
	padding: 0.75rem 1.0625rem 0.75rem 1.0625rem;
	color: #fff;
	font-weight: bold;
	font-family: 'Open Sans';
	font-size: 0.875rem;
}


.precos-e-servicos-file-card-text {
	width: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	/* margin: 0 0 0.625rem 0; */
}

.precos-e-servicos-file-card-field {
	width: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	margin: 0 0 0.625rem 0;
	padding-top: 10px;
}

.precos-e-servicos-file-card-image {
	display: block;
	width: 150px;
	max-width: 100%;
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	-webkit-transition: opacity 0.3s ease-in-out,
		-webkit-transform 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
		-webkit-transform 0.3s ease-in-out;
}

.precos-e-servicos-file-card-button {
	background: #0394db;
	background: -webkit-gradient(linear,
			left top,
			right top,
			from(#0394db),
			to(#0394db));
	background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
	border-radius: 1.25rem;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding: 0 0.625rem;
	width: 90%;
	-webkit-box-pack: center;
	justify-content: center;
	position: relative;
}

.precos-e-servicos-file-card-svg {
	position: absolute;
	right: 0.625rem;
}

.flex-column {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
}