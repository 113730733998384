
.container .divColapseFiltro,
.container .divColapseFiltro * {
    display: none; 
}

.container.uncollapsed .divColapseFiltro,
.container.uncollapsed .divColapseFiltro * {
    
    display: inline-block;
}
.container.uncollapsed .divColapseFiltro,
.container.uncollapsed .divColapseFiltroFlex * {
    
    display: flex; 
}
.container .divColapseFiltro,
.container .divColapseFiltroFlex * {
    display: none; 
}
.divColapseFiltroFlex{
    width: 100%;
    margin-bottom: 3vw;
}
.divColapseFiltro{
    width: 100%;
    margin-bottom: 3vw;
}
.filterButton {
    background-color: #0394db;
    color: white;
    padding: 6px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0vw 0px 20px 0px;
    width: 9rem;
}
.container.uncollapsed{
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.container.collapsed{
    padding-right: 0px !important; 
    padding-left: 0px !important;
}


.containerFilterButtonClean{
    display: flex !important;
    justify-content: flex-end !important;
}

.aFilterCleanButton:hover{
    color:white !important;
}
.filterButton:hover{
    box-shadow: inset;
    background-color: #00518b;
    color: white;
}

.CleanfilterButton:hover{
    
    background-color:#6aa4cd;
}
.CleanfilterButton{
    background-color:#6088a4;/* Cor de fundo em azul neon */
    color: white; /* Cor do texto em branco */
    padding: 10px 20px; /* Espaçamento interno */
    border: none; /* Remova a borda */
    border-radius: 5px; /* Borda arredondada */
    cursor: pointer; /* Altera o cursor para indicar que é clicável */
    transition: background-color 0.3s ease; 
    margin-bottom: 2vw;
    margin-top: 2vw;
}
.colBaseCOlapse{
    margin-bottom: 1vw;
}
.colBaseColapseButton{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 4vw;
    font-size: small;
}
.baseColapse{
    width: inherit;
     display: flex !important;
    justify-content: center !important;
}
.divColapseFiltro{
    width: 100%;
    
}

@media only screen and (max-width: 500px) {
    #colBaseCOlapse{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    
    .filterButton {
        background-color: #0394db;
        color: white;
        padding: 6px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin: 0vw 0px 20px 0px;
        width: 100%;
    }


#colBaseCOlapse{
    margin: 0;
    padding: 0;
}
}

