* {
	margin: 0;
	padding: 0;
}

.links ul {
	margin-top: 50px;

	list-style-type: square;
	list-style-position: inside;

	font-size: 20px;
}

.links ul li::marker {
	color: #0394db;
	font-size: 21px;
}

.links ul li {
	margin-bottom: 15px;
}

.links ul li a {
	color: #00518b;
}
.links-uteis_container {
    display: flex;
    justify-content: center;
}
.links-uteis-main {
    width: 80%;
}