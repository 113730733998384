.x-downloads-not-files__body {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0 0 8.25rem 0;
}
.x-downloads-not-files__body--container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.x-downloads-not-files__body--svg {
  margin: 0 0 1.875rem 0;
  max-width: 100%;
}
@media only screen and (max-width: 766px) {
  .x-downloads-not-files__body--svg {
    max-width: 50%;
  }
}
.x-downloads-not-files__body--subtitle {
  font-size: 1.625rem;
  color: #0066b3;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 1.5625rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-downloads-not-files__body--subtitle {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 766px) {
  .x-downloads-not-files__body--subtitle {
    font-size: 0.9375rem;
  }
}
.x-downloads-not-files__body--paragraph {
  font-size: 0.875rem;
  color: #424b4a;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-downloads-not-files__body--paragraph {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 766px) {
  .x-downloads-not-files__body--paragraph {
    font-size: 0.6875rem;
  }
}
