.resumo-lateral_summary {
  background: #f3f3f5;
  display: flex;
  flex-direction: column;
  padding: 38px 6px 6.75rem 2rem;
  height: 100%;
  overflow: hidden;
}

.resumo-lateral_summary.confirm {
  background: #f3f3f5;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 2.9375rem 3.75rem 6.75rem 2.75rem;
  -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
  transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
}

.resumo-lateral_summary--intro {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.resumo-lateral_summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.resumo-lateral_summary--paragraph {
  color: #424b4a;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
}

.resumo-lateral_summary--icon {
  align-self: center;
  margin: 0 0 2.25rem 0;
}

.resumo-lateral_main {
  background: #f3f3f5;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative;
  width: 30%;
  max-width: 400px;
  margin-left: 15px;
}
.resumo-lateral_main .resumo-lateral_summary--arrow {
  display: inline-table;
  left: 10px;
  top: 30px;
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  border-bottom: 3px solid #0394db;
  border-left: 3px solid #0394db;
  transform: translateX(0) rotate(45deg);
  transition: all 0.175s ease-in-out;
}


span.resumo-lateral_summary--arrow.active-arrow {
  transform: translateX(0) rotate(223deg);
}
@media only screen and (min-width: 1150px) {
  .span.resumo-lateral_summary--arrow {
    display: none;
  }
  .resumo-lateral_summary--arrowContainer {
    display: none;
  }
}

@media only screen and (max-width: 1150px) {
  .resumo-lateral_summary--container {
    display: none;
  }
  .resumo-lateral_summary--container.active-arrow {
    display: inline-block;
  }
  .resumo-lateral_main.active-arrow {
    width: 450px;
    right: 0;
    position: absolute;
    height: 100vh;
    z-index: 500;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin-right: 0px !important;
  }

  .resumo-lateral_main {

    width: 31px;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
  .resumo-lateral_summary--arrowContainer {
    height: 100%;
    width: 34px;
    position: absolute;
    transform: translate(-29px, -37px);
  }
}


@media only screen and (max-width: 800px) {
  .resumo-lateral_main {
    margin: 0px 0px 0 20px;
    width: 31px;
    transition: all 0.175s ease-in-out;
    height: 100vh;
  }
}

@media only screen and (max-width: 500px) {
  .resumo-lateral_main.active-arrow {
    width: 100%;
    right: 0;
    position: absolute;
    height: 100vh;
    z-index: 500;
    transition: all 0.175s ease-in-out;
    margin-right: 0px !important;
  }
  .resumo-lateral_summary--container.active-arrow {
    display: inline-block;
}
}
