.upload-content-remote {
	padding: 0 15px 0 15px;
}

.upload-content-remote > .fileContainer {
	background: transparent !important;
	box-shadow: none !important;
	position: relative;
	border: 1px dashed #ccc;
	border-radius: 10px;
	padding: none !important;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 10px auto;
	transition: all 0.3s ease-in;
	width: 100%;
	height: 85px;
}

.edge .tolltip {
	top: 0;
	right: 0;
}
.edge{
	position: absolute;
    width: 42%;
    height: 110px;
}

.edge .image-upload{
	width: 100%;
	position: inherit;
}


@media only screen and (max-width: 768px){
    .edge {
		width: 82%;
	}
}



