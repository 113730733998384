.buttonyn_radio--container {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	margin: 0.7rem 0 0 0;
	overflow: hidden;
}

.buttonyn_label {
	margin: 0 0.9375rem 0 0;
	font-size: 0.875rem;
	font-family: 'Montserrat', sans-serif;
}

.buttonyn_radio--input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
  }

.buttonyn_radio--input:checked + .buttonyn_radio--label {
	background: #0394db;
	color: #fff;
	font-weight: bold;
	border: 1px solid rgba(0, 0, 0, 0);
}

.buttonyn_radio--label {
	color: #b3b7b7;
	font-family: 'Montserrat', sans-serif;
	font-size: 0.75rem;
	line-height: 1;
	text-align: center;
	padding: 0.75rem 1.3125rem;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

.buttonyn_radio--label.radio--left {
	border-top-left-radius: 2.375rem;
	border-bottom-left-radius: 2.375rem;
}

.buttonyn_radio--label.radio--right {
	border-top-right-radius: 2.375rem;
	border-bottom-right-radius: 2.375rem;
}

.buttonyn_radio--label.radio--label.is-radio--label-mobile {
	padding: 0.75rem 1.3125rem;
}

.buttonyn_radio--label:hover {
	cursor: pointer;
}

@media only screen and (max-width: 766px) {
	.buttonyn_radio--container {
		margin-right: 1.1875rem;
	}

	.buttonyn_label {
		font-size: 0.775rem;
		margin: 0 0.625rem 0 0;
	}

}