.guia-atacado-img {
  width: 12%;
  margin: 0 1rem;
}
.labSelect-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.title-container {
  display: flex;
  flex-direction: column;
}
.main-title {
  font-size: 2.25rem;
  font-family: "Montserrat", sans-serif;
  color: #0066b3;
  margin: 2.375rem 0 0 0;
}
.title-shape {
  width: 0.625rem;
  height: 0.625rem;
  background: #0394db;
  margin: 0.625rem 0 1.5625rem 0;
}
.subtitle-container {
  width: 100%;
  display: flex;
}
.subtitle {
  font-size: 1em;
  color: #424b4a;
  margin: 0 0 2.3125rem 0;
}
.linkBold {
  font-size: small;
  text-decoration: underline !important;
  color: #0d6efd;
  -webkit-text-stroke-width: thin;
}
.bold {
  -webkit-text-stroke-width: thin;
  font-size: small;
  color: #4c5655;
}
.linkBoldDDark {
  text-decoration: underline;
  color: #717979;
  -webkit-text-stroke-width: thin;
}
.ul-dot {
  list-style-type: disc;
  margin-left: 15px;
}
/*mobile*/
@media only screen and (max-width: 905px) {
  img.guia-atacado-img {
    width: 133px;
  }
}
