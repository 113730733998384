

.pagination .previous.disabled {
  display: none;
}

.pagination .next.disabled {
  display: none;
}



.pagination-container {
  text-align: center
}

.pagination {
  margin: 15px auto;
  display: inline-flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: #0066b3 ;
  border-color: #0066b3 ;
  color: #fff;
}
.pagination > li > a{
  /* border: 1px solid #0066b3 ; */
  font-family: 'Montserrat', sans-serif;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #0066b3 ;
  border-color: #0066b3;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #0066b3;
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.search-media {
		margin-bottom: 30px;
	}
}

