.x-faq-intro {
	background: rgba(0, 102, 179, 0.1);
}

.x-faq-intro__container {
	max-width: 80rem;
	margin: 0 auto;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	text-align: center;
	-webkit-box-pack: center;
	justify-content: center;
	padding: 70px 0px 70px 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-intro__container {
		text-align: initial;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-intro__container {
		text-align: initial;
	}
}

.x-faq-intro__back {
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	max-width: 80rem;
	margin: 0 auto;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding: 57px 0px 30px 0px;
}

.x-faq-intro__back--text {
	margin: 0 0 0 10px;
	font-weight: bold;
	font-size: 0.875em;
	color: #424b4a;
}

@media only screen and (max-width: 766px) {
	.x-faq-intro__back--text {
		font-size: 0.75em;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-intro__back {
		margin: 0 0 0 5rem;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-intro__back {
		margin: 0 0 0 1.875rem;
	}
}

.x-faq-intro__title {
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	color: #00518b;
	font-size: 2.25em;
	font-weight: bold;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-intro__title {
		font-size: 1.5em;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-intro__title {
		font-size: 1.25em;
	}
}

.x-faq-category {
	max-width: 80rem;
	margin: 0 auto;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	padding: 0 1.875rem;
}

.x-faq-category_container {
	padding: 0 0 0 1.875rem;
}

.x-faq-category__title {
	font-family: "Montserrat", sans-serif;
	color: #0066b3;
	font-size: 1.5625em;
	font-weight: bold;
	padding: 2.5rem 0;
}

.x-faq-category__title.gap {
	margin: 0 0 0 -2.5rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-category__title.gap {
		font-size: 1em;
		margin: 0 0 0 -1rem;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category__title.gap {
		font-size: 1em;
		margin: 0 0 0 -1rem;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category__title {
		font-size: 1em;
	}
}

.x-faq-category__item {
	font-family: "Montserrat", sans-serif;
	color: #8aa09d;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	position: relative;
	-webkit-box-pack: center;
	justify-content: center;
	padding: 0.75rem 1.25rem;
}

.x-faq-category__item:not(:first-child):nth-of-type(even)
	.x-faq-category__item-link {
	background: #f3f3f5;
}

.x-faq-category__item-link {
	font-size: 1em;
	font-family: "Montserrat", sans-serif;
	color: #00518a;
	font-weight: bold;
	padding: 1.25rem;
	-webkit-transform: translateX(-1.25rem);
	transform: translateX(-1.25rem);
	height: 100%;
}

.sign-hiden:after {
	content: "\002B";
	color: #00518a;
	position: absolute;
	right: 1.875rem;
	font-size: 1.5em;
	font-weight: 700;
}

.sign-show:after {
	content: "\002D";
	color: #00518a;
	position: absolute;
	right: 1.875rem;
	font-size: 1.5em;
	font-weight: 700;
	top: 1.5075rem;
}

.x-faq-category__item-link:hover,
.x-faq-category__item-link:hover::after,
.sign-hiden:hover:after,
.sign-show:hover:after,
.x-faq-category__item-link:active,
.x-faq-category__item-link:active::after,
.sign-hiden:active:after,
.sign-show:active:after,
.x-faq-category__item-link:focus,
.x-faq-category__item-link:focus::after,
.sign-hiden:focus:after,
.sign-show:focus:after {
	color: #0394db;
}

.btn-header-accordion-:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.btn-header-accordion {
	/* padding: 0.75rem 1.25rem; */
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	text-align: left;
}

.btn-header-accordion:hover {
	background-color: #f7f7f7;
}

.x-card-body {
	color: #00518a;
	padding: 1.3rem !important;
}

.x-faq-intro .text-center:not(:first-child) {
	margin-top: 2rem;
}

@media only screen and (max-width: 766px) {
	.x-faq-category__item-link:after {
		top: 0.9375rem;
		right: -1rem;
	}
}

.x-faq-category__item-link.active:after {
	content: "\2212";
}

@media only screen and (max-width: 766px) {
	.x-faq-category__item-link {
		padding: 1.25rem 0px 1.25rem 0.8125rem;
	}

	.x-faq-category__item-link,
	.x-card-body {
		font-size: 0.875em;
	}
}

.x-faq-category__item-icon {
	position: absolute;
	right: 1.875rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-category__item,
	.x-card-body {
		font-size: 1em;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category__item,
	.x-card-body {
		font-size: 0.875em;
	}
}

.x-faq-category__paragraph {
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	font-size: 0.875em;
	line-height: 1.5;
	padding: 0 1.875rem 0 0;
	pointer-events: none;
	background: #fff;
	border: 1 px solid red;
	display: none;
	max-width: 95%;
}

.x-faq-category__paragraph:last-of-type {
	margin: 0 0 1.875rem 0;
}

.x-faq-category__paragraph:first-of-type {
	padding: 1.25rem 0 0 0;
}

.x-faq-category__paragraph:nth-child(1n) {
	margin-bottom: 1.875rem;
}

.x-faq-category__paragraph.show {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-category__paragraph {
		font-size: 0.875em;
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category__paragraph {
		font-size: 0.75em;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-faq-category {
	}
}

@media only screen and (max-width: 766px) {
	.x-faq-category {
	}
}

.faqback {
	margin: 2rem 4.5rem;
}
