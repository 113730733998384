.input-password {
	display: inline-flex;
	position: relative;
}

.input-password-label {
    font-size: 0.75rem;
    -webkit-transform: translate3d(10px, 15px, 0);
    transform: translate3d(10px, 15px, 0);
    color: #00518b;
    background: #fff;
    font-family: 'Open Sans';
    position: absolute;
    z-index: 2;
    padding: 0 0.3125rem;
}

.input-password [type='text'],
.input-password [type='password'] {
    /* box-shadow: 0 0 0 1px #b5b5b5; */
    border: 1px solid #b5b5b5;
    padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
    border-radius: 5px;
    margin: 1.375rem 1rem 0 0;
    position: relative;
    z-index: 0;
    width: 100%;
}

.input-password input:read-only {
	color:#A6ABAA;
	border-color:#CBCBCB;
}

.input-password [type='text']:read-only,
.input-password [type='password']:read-only {
	border: 1px solid #CBCBCB;
	box-shadow: unset;
}

.input-password [type='text']:disabled ,
.input-password [type='password']:disabled {
	background-color: unset;
}

.input-password input {
    color: rgba(66, 75, 74, 0.8);
}


.eye-position {
	position: absolute;
    width: 0.9375rem;
    height: 0.9375rem;
    right: 10%;
    top: 37px;
}

@media only screen and (max-width: 766px) {
	.input-password {
		width: 100%!important;
	}

    .input-password [type='text'],
	.input-password [type='password'] {
		width: 100%;
		margin: 1.375rem 0 0 0;
	}
}