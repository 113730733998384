.media-center-card {
  box-shadow: 0 0 1px 1px rgba(181, 181, 181, 0.5);
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0 1% 10% 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.media-center-card:hover 
.media-center-card-image {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
  opacity: 0.95;
  -webkit-transition: opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}

.media-center-card-bg {
  overflow: hidden;
}

.media-center-card-image {
  display: block;
  width: 17.375rem;
  max-width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}

.media-center-card-text {
  max-width: 17.375rem;
  font-size: 0.875rem;
  color: #0066b3;
  font-family: 'Montserrat', sans-serif;
  padding: 1.0625rem 1.0625rem 0 1.0625rem;
}

.media-center-card-text.text {
  height: 80px;
}

.media-center-card-text.is-text--lg {
  padding: 0.75rem 1.0625rem 0.75rem 1.0625rem;
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans';
  font-size: 0.875rem;
}

.media-center-card-field {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 0 0 0.625rem 0;
}

.media-center-card-button {
  background: #0394db;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0394db),
    to(#0394db)
  );
  background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
  border-radius: 1.25rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 0.625rem;
  width: 90%;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
}

.media-center-card-svg {
  position: absolute;
  right: 0.625rem;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

img.media-center-card-image {
  height: 250px;
  object-fit: contain;
}

.card-recordname {
  font-size: 10px;
  color: rgb(171, 171, 171);
  z-index: 10;
  padding: 7px;
  top: 222px;
  right: 17px;
}

@media only screen and (max-width: 766px) {
  .media-center-card-image {
    width: 150px;
    height: 100%;
  }

  .media-center-card-text {
    font-size: 0.75rem;
  }

  .flex-row--mobile {
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    box-shadow: 0 0 2px 1px rgba(181, 181, 181, 0.5);
    padding: 0 0.625rem 0 0;
  }

  .display-contents--mobile {
    display: contents;
  }

  .flex-column {
    flex-direction: row !important;
  }

  .media-center-card-bg {
    overflow: hidden;
    margin-right: 15px;
  }

  img.media-center-card-image {
    height: 100px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .media-center-card-text.is-text--lg {
    margin: 0 1.25rem 0 0;
  }

  .media-center-card-text {
    font-size: 0.75rem;
  }
}
