.crm-select {
  display: inline-flex;
  position: relative;
}

.crm-select-label {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 0.3125rem;
}

.crm-select-input {
  /* font-size: 0.875rem; */
  color: rgba(66, 75, 74, 0.8);
  font-family: "Open Sans";
  padding: 0.9375rem 3rem 0.9375rem 1.25rem;
  box-shadow: 0 0 0 1px #b5b5b5;
  border: initial;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 1;
  background: transparent;
  width: 100%;
}

.crm-ou {
  display: block;
  margin-top: 13px;
}

.crm-select-input > div {
  border-color: #b5b5b5 !important;
  box-shadow: 0 0 0 1px #b5b5b5 !important;
}

.crm-select-arrow {
  position: absolute;
  z-index: 0;
  right: 45px;
  top: 43px;
}

.crm-select-arrow:before {
  content: "";
  display: block;
  width: 0.545rem;
  height: 0.545rem;
  position: absolute;
  border-bottom: 2px solid #00639d;
  border-right: 2px solid #00639d;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.font-order {
  color: #333333;
  font-family: "Segoe UI";
  font-weight: 400;
}

/* .crm-select-input select:active,
.crm-select-input select:hover {
  outline-color: red;
  color: rgba(66, 75, 74, 0.8);
  box-shadow: 0 0 10px 100px #000 inset;
}
.crm-select-input option:checked {
  outline: none;
  color: #fff;
  background-color: #2684ff !important;
} */

@media only screen and (max-width: 766px) {
  .crm-select {
    width: 100% !important;
  }

  .crm-select-input {
    margin: 1.375rem 0.04rem 0 0.04rem;
  }
}
