.profileInput > .input-password [type='password'],
.profileInput > .crm-input [type="text"],
.profileInput > .crm-input [type="number"],
.profileInput > .crm-input [type="email"],
.profileInput > .crm-input [type="password"],
.profileInput > .crm-input [type="tel"],
.profileInput > .crm-input [type="url"],
.profileInput > .crm-select > .crm-select-input
{
    margin: 1.375rem 0 0 0 !important;
}

.back{
    margin: 40px 0 0 60px;
}

.my-account__main {
    margin: 5% 1rem;
}

.my-account__container {
    display: -webkit-box;
    display: flex;
    margin: 0 60px;
}

.my-account_button-left {
	width: 50%;
	display: flex;
	padding-top: 30px;
	align-items: center;
	justify-content: center;
}

.my-account_button-link {
	text-decoration: underline;
}

.my-account_button-link:hover {
	text-decoration: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .my-account__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin: 0;
    }
}

.my-account__info {
    max-width: 325px;
    margin: 0 60px 0 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__info {
        max-width: 400px;
        align-self: center;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .my-account__info {
        max-width: initial;
        display: -webkit-box;
        display: flex;
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        margin: 0;
    }
}

.my-account__paragraph {
    font-size: 1em;
    max-width: 90%;
    line-height: 1.5;
    font-family: "Open Sans";
    margin: 1rem 0 0 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__paragraph {
        max-width: 100%;
        font-size: .875em
    }
}

.my-account__profile {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 0 0 65px 0
}

@media screen and (max-width: 767px) {
    .my-account__profile {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: initial;
        align-items: initial
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .is-tablet {
        max-width: 767px;
        margin: 0 auto;
        padding: 10px
    }
}

.my-account__photo {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center
}

.my-account__photo-img ,
.my-account__photo-img-preview {
    width: 89px;
    height: 89px;
    border-radius: 50%;
    object-fit: cover;
}

.my-account__photo-change{
    margin-left: 20px;
}

.my-account__photo-change-link {
    text-decoration: underline;
    font-family: "Open Sans";
    font-size: .875em;
    font-weight: 600;
    margin: 0 0 0 20px;
    position: relative;
    cursor:pointer;
}

input[type='file'] {
    display: none
}

@media screen and (max-width: 767px) {
    .my-account__photo-change-link {
        margin: 0 0 0 30px
    }
}

.my-account__photo-detalhes {
    text-decoration: underline;
    color: #424B4A;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100px;
    visibility: visible;
    cursor: pointer;
    pointer-events: all;
    opacity: 1
}

.my-account__photo-detalhes.is-opacity {
    visibility: hidden;
    pointer-events: none;
    cursor: none;
    opacity: 0;
    -webkit-transition: opacity .175 ease-in-out, visibility .175 ease-in-out;
    transition: opacity .175 ease-in-out, visibility .175 ease-in-out
}

.my-account__photo-detalhes-2 {
    text-decoration: underline;
    position: absolute;
    left: -20px;
    bottom: 0;
    width: 100px;
    visibility: visible;
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
    -webkit-transition: opacity .175 ease-in-out, visibility .175 ease-in-out;
    transition: opacity .175 ease-in-out, visibility .175 ease-in-out
}

.my-account__photo-detalhes-2.is-opacity {
    visibility: hidden;
    pointer-events: none;
    cursor: none;
    opacity: 0;
    -webkit-transition: opacity .175 ease-in-out, visibility .175 ease-in-out;
    transition: opacity .175 ease-in-out, visibility .175 ease-in-out
}

.my-account__form {
    margin: 40px 0 0 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
	width: 100%;
}

.my-account__form-item {
    /* padding: 0 0 1.375rem 0; */
	display: flex;
	vertical-align: center;
}

.my-account__form-session {
	padding: 1.975rem 0 0 0;
}

.my-account__form-genre {
	padding: 1.375rem 0 0 0;
	display: flex;
}

@media screen and (max-width: 767px) {
    .my-account__form-item {
        max-width: 320px;
        padding: 10px 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column
    }
}

.my-account__form-cep {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    /* margin: 0 0 20px 0; */
    position: relative;
}

@media screen and (max-width: 767px) {
    .my-account__form-cep {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column
    }
}

.my-account__form-reminder {
    font-size: .75em;
    max-width: 300px;
    /* font-weight: bold; */
    font-family: "Open Sans"
}

.my-account__form-reminder.tp2 {
    font-size: .875em;
    display: block;
    color: #0066B3;
    text-decoration: underline;
	margin: 1.375rem 1rem 0 0;
}

@media screen and (max-width: 767px) {
    .my-account__form-reminder.tp2 {
        align-self: flex-start
    }
}

.my-account__register {
    max-width: 768px
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__register {
        margin: 0 auto
    }
}

@media screen and (max-width: 767px) {
    .my-account__register {
        max-width: 320px;
        margin: 30px auto auto;
        overflow: hidden
    }
}

.register-opacity {
    opacity: 0.2;
}

.my-account__form-access-checkbox {
    margin: 30px 0 20px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    /* align-items: center; */
    align-self: flex-start;
    overflow: hidden
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .my-account__form-access-checkbox {
        align-self: center
    }
}

.my-account__menu-anchor {
	display: flex;
	flex-direction: column;
	width: 90%;

	margin-top: 20px;
	border: 1px solid #00409A;
	background-color: rgba(0,64,154,.07);
}

.my-account__menu-anchor a {
	text-decoration: none;
	color: #00409A !important;

	padding: 8px 20px;
	border-bottom: 1px solid #00409A;
	margin: 0;

	line-height: 1.5;
    font-family: "Open Sans";
	font-size: 1em;

	cursor: pointer;
}

.my-account__menu-anchor a:hover {
	background-color: #00409A;
    color: #fff !important;
    font-weight: 700;
}

.my-account__menu-anchor a:last-child {
	border: none;
}