.x-user-consultant__container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0 3.75rem 0 3.75rem;
}


.x-user-consultant__body {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 766px) {
  .x-user-consultant__container {
    margin: 0 1.875rem 0 1.875rem;
  }

  .x-user-consultant__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-user-consultant__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.gestao-papeis .btn-link {
  font-weight: 400;
  color: #212529 !important;
  text-decoration: none !important;
}

.gestao-papeis .btn-link:hover {
  color: #212529 !important;
  text-decoration: none !important;
}

.roles-box {
  width: 200px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
