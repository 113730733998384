.x-main .x-welcome {
  margin-top: 3.375rem;
  width: 100%;
  padding: 0 3.75rem;
}
.x-main__first-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .x-main .x-welcome {
    padding: 0 5rem;
    margin-top: 1.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-welcome {
    padding: 0 2.25rem;
  }
}

.x-main .x-welcome__title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #00518b;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 766px) {
  .x-main .x-welcome__title {
    font-size: 0.9375rem;
  }
}

.x-main .x-welcome__square {
  margin-top: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #0394db;
  display: block;
}

@media only screen and (max-width: 766px) {
  .x-main .x-welcome__square {
    font-size: 1rem;
  }
}

.x-main .x-welcome__desc {
  margin-top: 1.75rem;
  font-size: 1rem;
}

.x-main .x-welcome__desc.is--desk {
  display: block;
}

.x-main .x-welcome__desc.is--mobile {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-welcome__desc {
    max-width: 23.4375rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-welcome__desc.is--desk {
    display: none;
  }

  .x-main .x-welcome__desc.is--mobile {
    display: block;
  }
}

.x-main .x-welcome__desc-bold {
  font-weight: bold;
  color: #0394db;
}

.x-main .x-my-essilor {
  background-color: #f3f3f5;
  margin-top: 2.5rem;
  padding: 0 3.75rem;
  width: calc(100% - 7.5rem);
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor {
    width: calc(100% - 4.5rem);
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__title {
    text-align: center !important;
  }

  .x-main .x-my-essilor {
    padding: 0 2.25rem;
  }

  .x-main .x-my-essilor__title:before {
    width: 100% !important;
  }
}

.x-main .x-my-essilor__title {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  color: #0066b3;
  font-weight: bold;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__title {
    font-size: 1rem;
  }
}

.x-main .x-my-essilor__title:before {
  content: "";
  width: 10.625rem;
  height: 0.25rem;
  background-color: #0066b3;
  display: block;
  position: absolute;
  bottom: -1px;
  z-index: 1;
}

.x-main .x-my-essilor__title:after {
  content: "";
  width: 100%;
  height: 0.125rem;
  background-color: #e1e1e2;
  display: block;
  position: absolute;
  bottom: 0;
}

.x-main .x-my-essilor .slick-slide {
  margin: 0 8px;
}

.x-main .x-my-essilor .slick-list {
  margin: 0 -8px;
}

.x-main .x-my-essilor__list {
  overflow: hidden;
  margin-top: 1.625rem;
  padding-bottom: 1.125rem;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__list {
    padding-bottom: 1.0625rem;
  }
}

.x-main .x-my-essilor__list .slick-dots {
  bottom: 2rem;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__list .slick-dots {
    bottom: 1.75rem;
    position: relative;
  }
}

.x-main .x-my-essilor__list .slick-arrow {
  bottom: 2rem;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__list .slick-arrow {
    bottom: 1.75rem;
  }
}

.x-main .x-my-essilor__list .slick-arrow.slick-prev {
  left: calc(50% - 74px);
}

.x-main .x-my-essilor__list .slick-arrow.slick-next {
  right: calc(50% - 74px);
}

.x-main .x-my-essilor__item {
  height: 10.9rem;
  width: 10.9rem;
  padding: 1rem;
  margin: 0 8px 16px 8px;
  border-radius: 1.25rem;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: white;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-my-essilor__item {
    /* height: 11.875rem; */
    padding: 1rem 0.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__item {
    width: 97%;
    height: 16.4375rem;
    padding: 2.125rem 0.875rem;
  }

  .x-main .x-my-essilor__item:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.x-main .x-my-essilor__logo {
  display: -webkit-box;
  display: contents;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: center;
  padding: 0.25rem;
}

.x-main .x-my-essilor__logo-text,
.x-main .x-my-essilor__logo-img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__logo-text.is--tracking,
  .x-main .x-my-essilor__logo-text.is--pedidos,
  .x-main .x-my-essilor__logo-text.is--vantagens,
  .x-main .x-my-essilor__logo-img.is--tracking,
  .x-main .x-my-essilor__logo-img.is--pedidos,
  .x-main .x-my-essilor__logo-img.is--vantagens {
    width: 6.875rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__logo-text.is--varilux,
  .x-main .x-my-essilor__logo-img.is--varilux {
    width: 9.0625rem;
  }
}

.x-main .x-my-essilor__button {
  border-radius: 1.25rem;
  width: 9rem;
  height: 2.3125rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background: #00518b;
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-main .x-my-essilor__button {
    width: 9.8rem;
  }

  .x-main .x-welcome {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__button {
    height: 3rem;
  }
}

.x-main .x-my-essilor__button.is--blue {
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
}

.x-main .x-my-essilor__button.is--orange {
  background: #0394db;
  background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
  background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
}

.x-main .x-my-essilor__button.is--pink {
  background: #ec7b9b;
}

.x-main .x-my-essilor__button.is--light-green {
  background: #95cb4e;
}

.x-main .x-my-essilor__button.is--light-blue {
  background: #5ac4bc;
}

.x-main .x-my-essilor__button.is--gray {
  background: #6a757a;
}

@media screen and (max-width: 767px) {
  .x-main .x-my-essilor__button {
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0 20px;
    width: 15.8rem;
  }
}

.x-main .x-my-essilor__button-arrow {
  position: absolute;
  right: 0.5625rem;
}

.x-main .x-my-essilor__button-arrow.btn-is--pink {
  fill: #ec7b9b;
}

.x-main .x-my-essilor__button-arrow.btn-is--light-green {
  fill: #95cb4e;
}

.x-main .x-my-essilor__button-arrow.btn-is--light-blue {
  fill: #5ac4bc;
}

.x-main .x-my-essilor__button-arrow.btn-is--gray {
  fill: #6a757a;
}

.x-main .x-my-essilor__button-text {
  font-weight: bold;
  color: white;
  font-size: 0.75rem;
}

@media only screen and (max-width: 766px) {
  .x-main .x-my-essilor__button-text {
    font-size: 0.9375rem;
  }
}

.x-main .x-conecta {
  width: 100%;
  max-width: 23.6875rem;
  padding-left: 2.25rem;
  padding-right: 3.75rem;
  background-color: #ededed;
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-main .x-conecta {
    max-width: none;
    padding: 0 5rem;
    min-height: 500px;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-conecta {
    padding: 0 2.25rem;
    min-height: 500px;
    max-width: none;
  }
}

.x-main .x-conecta__title {
  padding-top: 3.375rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: #00518b;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-conecta__title {
    padding-top: 1.25rem;
  }
}

.x-main .x-conecta__square {
  margin-top: 1.5rem;
  margin-bottom: 1.75rem;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #0394db;
  display: block;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-conecta__square {
    margin-top: 0.875rem;
    margin-bottom: 1.25rem;
  }
}

.x-main .x-conecta__slider {
  width: 100%;
  max-width: 17.375rem;
  /* padding-bottom: 4.875rem; */
  /* overflow: hidden; */
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-conecta__slider {
    max-width: none;
    /* padding-bottom: 3.75rem; */
  }
}

.x-main .x-conecta__slider .slick-dots {
  position: relative;
  bottom: 2.25rem;
}

@media only screen and (max-width: 767px) and (max-width: 1023px) {
  .x-main .x-conecta__slider .slick-dots {
    bottom: 1.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-conecta__slider .slick-dots {
    bottom: 3.625rem;
  }
}

.x-main .x-conecta__slider .slick-arrow {
  bottom: 2.25rem;
  margin-top: 80px;
}

.x-main .x-conecta__slider .slick-arrow.slick-prev {
  left: calc(50% - 4.5rem);
}

.x-main .x-conecta__slider .slick-arrow.slick-next {
  right: calc(50% - 4.5rem);
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-main .x-conecta__slider .slick-arrow {
    bottom: 1.625rem;
    margin-top: 75px;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-conecta__slider .slick-arrow {
    margin-top: 53px;
  }
}

.x-main .x-conecta .slick-slide {
  margin: 0 8px;
}

.x-main .x-conecta .slick-list {
  margin: 0 -8px;
}

.x-main .x-conecta__img {
  display: inline-block;
}

@media only screen and (max-width: 1023px) {
  .x-main .x-conecta__img {
    /* height: 12.0625rem; */
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .x-conecta__img {
    max-width: none;
    width: 100%;
    height: auto;
  }
}

.x-body .slick-arrow {
  font-size: 0;
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid #00518b;
  border-right: 2px solid #00518b;
  bottom: 0;
}

.x-body .slick-arrow.slick-prev {
  left: 0;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  /* margin-top: 5rem; */
}

/* .x-body .x-my-essilor .slick-arrow.slick-prev {
    margin-top: -80px;
} */

.x-body .slick-arrow.slick-next {
  right: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  /* margin-top: 5rem; */
}

/* .x-body .x-my-essilor .slick-arrow.slick-next {
    margin-top: -80px;
} */

/* @media only screen and (max-width: 766px) {
    .x-body .x-conecta .slick-arrow.slick-prev {
        margin-top: 4.8rem;
    }
    .x-body .x-conecta .slick-arrow.slick-next {
        margin-top: 4.8rem;
    }
    .x-body .x-my-essilor .slick-arrow.slick-prev {
        margin-top: -50px;
    }
    .x-body .x-my-essilor .slick-arrow.slick-next {
        margin-top: -50px;
    }
} */

/* @media only screen and (max-width: 1023px) {
    .x-body .slick-arrow.slick-prev {
        margin-top: 75px;
    }
    .x-body .slick-arrow.slick-next {
        margin-top: 75px;
    }
} */

.x-body .slick-dots {
  position: absolute;
  display: -webkit-box;
  display: flex;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.x-body .slick-dots li {
  margin: 0 0.875rem;
}

.x-body .slick-dots li button {
  font-size: 0;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #00518b;
  opacity: 0.8;
}

.x-body .slick-dots li.slick-active button {
  width: 0.625rem;
  height: 0.625rem;
}

.homelog {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}

@media only screen and (max-width: 1023px) {
  .x-main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

/* CSS Carousel */

.carousel-essilor {
  width: 280px;
  margin: auto;
}

.carousel-essilor .customarrows {
  width: 277px;
}

.carousel-essilor .customarrows button {
  border: solid #00518b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
}

.carousel-essilor .customarrows .prev {
  float: left;
  margin: 15px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carousel-essilor .customarrows .prev:focus {
  outline: none !important;
}

.carousel-essilor .customarrows .next {
  float: right;
  margin: 15px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel-essilor .customarrows .next:focus {
  outline: none !important;
}

.carousel-essilor .carousel.carousel-slider {
  height: 335px;
}

/* CSS Custom Modulo */

.x-main .module {
  height: 10.9rem;
  width: 10.9rem;
  padding: 1rem;
  margin: 0 8px 16px 8px;
  border-radius: 1.25rem;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  /* align-items: flex-start; */
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: white;
}

@media only screen and (max-width: 1023px) {
  .x-main .module {
    /* height: 11.875rem; */
    padding: 1rem 0.625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .module {
    width: 75%;
    height: 13.4375rem;
    padding: 1.125rem 0.875rem;
    margin: 0 40px;
  }

  .x-main .module:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.x-main .module_logo {
  display: -webkit-box;
  display: contents;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: center;
  padding: 0.25rem;
}

.x-main .module_logo-img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 766px) {
  .x-main .module_logo-img {
    max-height: 70%;
  }
}

.x-main .module_button {
  border-radius: 1.25rem;
  width: 100%;
  height: 2.3125rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background: #00518b;
  padding: 0 1rem;
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .x-main .module_button {
    width: 9.8rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-main .module_button {
    height: 3rem;
  }
}

.x-main .module_button-text {
  font-weight: bold;
  color: white;
  font-size: 0.75rem;
  float: left;
}

@media only screen and (max-width: 766px) {
  .x-main .module_button-text {
    font-size: 0.9375rem;
  }
}

.x-main .module_svg {
  float: right;
}

.banner-home {
  margin: 7px 0;
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .banner-home {
    margin: 7px;
  }
}

.swal2-html-container {
  max-height: 20em;
  /* To be adjusted as you like */
  overflow-y: auto;
  width: 100%;
}

.x-main .x-conecta-guides {
  /* display: flex; */
  text-align: center;
  margin-top: 2rem;
  align-content: center;
  max-width: 280px;
  align-items: center;
  padding-bottom: 2rem;
}

.x-main .x-qrcode-home-guides {
  max-width: 125px;
}

.x-main .x-qrcode-title {
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #00518b;
  font-family: "Montserrat", sans-serif;
}

.x-main .x-qrcode-title a {
  color: #0394db;
}
@media only screen and (max-width: 400px) {
  .x-main .module {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .x-main .module_button {
    width: 100%;
    margin: auto;
  }
  .x-main .module_logo-img {
    max-width: 85%;
    max-height: 100%;
    display: block;
    margin: auto;
    padding: 15px;
}
.x-main .module_button-text {
  font-size: smaller;
}
}
