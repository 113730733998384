.x-web-ordering-pedido__main ul {
  margin-bottom: 0px;
}

.formColoracao {
  margin-bottom: 70px;
}

.select-chackbox-container{
  display: flex;
  flex-direction: column;
  transform: translate(0px, 26px);
}
.p-check-box{
  font-size: small;
}
.check-box-container {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  -webkit-text-stroke-width: medium;

}
.x-web-ordering-pedido__main .x-web-ordering-pedido__summary a:hover {
  color: #00518b !important;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main * {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main img {
  display: block;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main a {
  text-decoration: none;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main a:hover {
  color: inherit;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__main li {
  list-style: none;
}

.x-web-ordering-pedido__main .slick-track {
  display: -webkit-box;
  display: flex;
}

.x-web-ordering-pedido__main .slick-next {
  right: 0;
}

.x-web-ordering-pedido__main .slick-prev {
  z-index: 99;
  display: none !important;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido {
  display: -webkit-box;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__arrow {
  -webkit-box-align: center;
  align-items: center;
  margin: 2.35rem 0 0 3.75rem;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__arrow {
    margin-left: 2.25rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__arrow--text {
  margin: 0 0 0 0.625rem;
  pointer-events: all;
  cursor: pointer;
  font-weight: bold;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__arrow--icon {
  margin: 2.5rem 0 0 0;
  -webkit-transform: translate3d(0, 2px, 0px);
  transform: translate3d(0, 2px, 0px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__arrow {
    margin: 0 0 0 2.75rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 75%;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__container {
    width: 100%;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    height: 100%;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__container.show-pedido {
    width: 100%;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    height: 100%;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__container {
    width: 100%;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__container.show-pedido {
    width: 100%;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu {
  font-family: "Montserrat", sans-serif;
  color: #424b4a;
  font-size: 0.875rem;
  width: 100% !important;
  display: flex;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu {
    font-size: 0.8125rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu .slick-list {
  display: -webkit-box;
  display: flex;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu .slick-list .slick-track {
  width: 100% !important;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item {
  display: -webkit-box;
  display: flex;
  margin: 0.5625rem 0;
  width: 22% !important;
  -webkit-box-align: center;
  align-items: center;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item:first-child {
  margin: 0.5625rem 0 0.5625rem 3.75rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item:first-child {
    margin: 0.5625rem 0 0.5625rem 2.75rem;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item {
    align-items: baseline;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item:first-child {
    margin-left: 2.25rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item.slick-current .x-web-ordering-pedido-menu__link {
  color: #0066b3;
  -webkit-transition: text-shadow 0.175 ease-in-out;
  transition: text-shadow 0.175 ease-in-out;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__item.slick-current .x-web-ordering-pedido-menu__link:before {
  background: #0394db;
  color: #fff;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link {
  margin: 1.25rem 0 0 0;
  -webkit-box-align: center;
  align-items: baseline;
  color: rgba(105, 105, 105, 0.6);
  font-weight: bold;
  -webkit-transition: text-shadow 0.175 ease-in-out;
  transition: text-shadow 0.175 ease-in-out;
  pointer-events: none;
  display: -webkit-box;
  display: flex;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link:before {
  content: "";
  display: inline-block;
  background: #dddddd;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  margin: 0 0.625rem 0 0;
  color: gray;
  padding: 0.3125rem 0 0 0.625rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link:before {
    font-size: 0.625rem;
    width: 1.4375rem;
    height: 1.4375rem;
    padding: 0.3125rem 0 0 0.5rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l1::before {
  content: "1";
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l2::before {
  content: "2";
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3 {
  margin-left: 2.5rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3::before {
  content: "3";
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3 {
    margin-left: 1.25rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l3 {
    margin-left: 1.25rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link.is--l4::before {
  content: "4";
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link .link-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link .link-text {
    display: none;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido-menu__link {
    display: -webkit-box;
    display: flex;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido-menu__shape {
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(105, 105, 105, 0.3);
}

.x-web-ordering-pedido__main .opcional {
  font-weight: initial;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__dados--container {
  padding: 2rem 3.25rem 0 3.75rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__dados--container {
    padding: 2rem 0 0 2.8125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__dados--container {
    padding: 1rem 2rem 0 1rem;
  }
}

.x-web-ordering-pedido__main .input-align {
  display: contents;
}

.x-web-ordering-pedido__main .input-align input {
  color: rgba(66, 75, 74, 0.8);
}

.x-web-ordering-pedido__main .select-align {
  display: contents;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__dados--card {
  position: relative;
  display: flex;
}

.x-web-ordering-pedido__dados--card .crm-button-orange {
  margin: unset;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__lens--container {
  padding: 2rem 3.25rem 0 3.75rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.lens-opacity {
  opacity: 0.2;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__lens--container {
    padding: 2rem 0 0 2.8125rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__lens--container {
    padding: 2rem 2.5rem 0 2.3125rem;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__dados--card {
    display: unset;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__lens--row {
  position: relative;
  display: flex;
}

input#quantidade {
  text-align: center;
  padding-right: 40px;
  padding-left: 40px;
}

@media (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__lens--row {
    display: block;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__lens--services-container {
  position: relative;
  margin-bottom: 20px;
}

.order-link-wrapper {
	color: #0066cc;
	text-decoration: underline;
	font-weight: bold;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--container {
  padding: 2rem 3.25rem 0 3.75rem;
  margin-bottom: 4.6875rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--container {
    padding: 2rem 0 0 3.75rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--container {
    padding: 1rem 2rem 0 1rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body.hidden {
  display: none;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body {
  margin-bottom: 1.1rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body {
    display: -webkit-box;
    display: flex;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body.is--tablet {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body.is--mobile {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    /* -webkit-box-direction: reverse;
	flex-direction: row-reverse; */
    margin-top: 20px;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--body.is--mobile.hidden {
    display: none;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row {
    padding: 0 2.5rem 0 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row-both {
  display: flex;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 0 0 0.625rem 0;
    display: block;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row-both {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 0 0 0.625rem 0;
    display: block;
    display: table-cell;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row.is--mobile {
    margin: initial;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--row.is--tablet-2 {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--item {
  position: relative;
  margin: 0 0 0 -0.625rem;
  width: 100%;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--item {
    margin: initial;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--item {
    margin: initial;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-right {
  margin: 0 2.5rem 0 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  width: 70px;
  margin: 1.375rem 2rem 0 0;
  padding-top: 10px;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-right {
    width: 14%;
    position: relative;
    top: 10px;
    margin: 0 2rem 0 0;
    width: 110px;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-right {
    margin: 0 17px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-right.flex-start {
    display: -webkit-box;
    display: flex;
    align-self: flex-start;
    margin: 1.875rem 0 0 0;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-left {
  margin: 0 2.5rem 0 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  width: 70px;
  margin: 1.375rem 2rem 0 0;
  padding-top: 10px;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-left {
    margin: 0 10px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-left {
    width: 14%;
    position: relative;
    top: 10px;
    margin: 0 2rem 0 0;
    width: 110px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__prescription--subtitle-left.flex-start {
    display: -webkit-box;
    display: flex;
    align-self: flex-start;
    margin: 1.875rem 0 0 0;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--container {
  padding: 2rem 3.25rem 0 3.75rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--container {
    padding: 2rem 2.5rem 0 2.3125rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--body {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--body img {
  width: 405px;
  height: auto;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--body {
    width: 100%;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--column {
  max-width: 46.25rem;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--row {
  display: -webkit-box;
  display: flex;
  margin: 1.875rem 0 1.875rem 0;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.grid-item {
  text-align: center;
  font-size: 12px;
  color: #212529;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin: initial;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--row:first-of-type {
    margin: 1.875rem 0 0 0;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  width: 100%;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--item-2 {
  display: initial;
  position: relative;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--info {
  display: -webkit-box;
  display: flex;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--button {
  width: 11.25rem;
  border-radius: 50px;
  padding: 0.75rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  align-self: flex-end;
  margin: 0 7% 0 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--button {
    margin: 1.875rem 1.875rem 0 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--button {
    margin: 1.5rem 0 0 0;
    width: 6.75rem;
    padding: 0.25rem;
    float: right;
    height: 3rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--text {
  display: -webkit-box;
  display: flex;
  margin: auto 10%;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--text {
    text-align: left;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--icon {
  position: absolute;
  right: 0.625rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame--glasses {
  max-width: 100%;
  align-self: flex-end;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  height: 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo.activeSvgToInput:after {
  content: "";
  background-color: #005991;
  display: inline-block;
  width: 11px;
  left: 3px;
  top: 3px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo:before {
  content: "";
  position: relative;
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: solid 1px #005991;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo:checked:after {
  content: "";
  background-color: #005991;
  display: inline-block;
  width: 11px;
  left: 3px;
  top: 3px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__frame .frame__modelo--icon {
  cursor: pointer;
  pointer-events: all;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__solicitation {
  overflow: hidden;
  position: relative;
  min-height: 500px;
}

.x-web-ordering-pedido__main [type="text"],
.x-web-ordering-pedido__main [type="number"] {
  /* box-shadow: 0 0 0 1px #b5b5b5; */
  /* border: initial; */
  /* padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem; */
  border-radius: 5px;
  /* margin: 1.375rem 1rem 0 0; */
  position: relative;
  z-index: 0;
}

.x-web-ordering-pedido__main input:focus {
  outline: none;
}

.x-web-ordering-pedido__main [type="text"]:hover,
.x-web-ordering-pedido__main [type="text"]:active,
.x-web-ordering-pedido__main [type="text"]:focus,
.x-web-ordering-pedido__main [type="number"]:hover,
.x-web-ordering-pedido__main [type="number"]:active,
.x-web-ordering-pedido__main [type="number"]:focus,
.x-web-ordering-pedido__main [type="email"]:hover,
.x-web-ordering-pedido__main [type="email"]:active,
.x-web-ordering-pedido__main [type="email"]:focus {
  border: 1px solid #0066b3;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main [type="text"],
  .x-web-ordering-pedido__main [type="number"] {
    width: 100%;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .title-mobile:after {
    content: "";
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    margin: 1.25rem 0 0 0;
    color: #0066b3;
  }

  .t-dados:after {
    content: "Dados do pedido";
  }

  .t-lens:after {
    content: "Prescrição";
  }

  .t-prescription:after {
    content: "Especificações da lente";
  }

  .t-frame:after {
    content: "Armação e montagem";
  }
}

.x-web-ordering-pedido__main .is--label {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 10;
  padding: 0 0.3125rem;
}

@media only screen and (min-width: 375px) and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--label.gap-label {
    margin-left: 0.5625rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--label {
    position: relative;
    top: 1.1rem;
    left: 0.75rem;
    /* min-width: 2.56rem; */
  }

  .x-web-ordering-pedido__main .is--label.is--eixo {
    position: relative;
    top: 2rem;
    right: 15rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label-estabilidade {
    -webkit-transform: translate3d(331px, -57px, 0);
    transform: translate3d(331px, -57px, 0);
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label-estabilidade {
    -webkit-transform: translate3d(371px, -57px, 0);
    transform: translate3d(371px, -57px, 0);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-coeficiente {
    -webkit-transform: translate3d(-630px, 88px, 0px);
    transform: translate3d(-630px, 88px, 0px);
    width: 23%;
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-coeficiente {
    -webkit-transform: translate3d(-710px, 83px, 0px);
    transform: translate3d(-710px, 83px, 0px);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-dis-progressao {
    -webkit-transform: translate3d(-308px, 13px, 0px);
    transform: translate3d(-308px, 13px, 0px);
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-dis-progressao {
    -webkit-transform: translate3d(-348px, 13px, 0px);
    transform: translate3d(-348px, 13px, 0px);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-vertice {
    -webkit-transform: translate3d(-629px, 90px, 0px);
    transform: translate3d(-629px, 90px, 0px);
    width: 16%;
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-vertice {
    -webkit-transform: translate3d(-353px, 15px, 0px);
    transform: translate3d(-353px, 15px, 0px);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-olho-dominante {
    -webkit-transform: translate3d(-302px, 19px, 0px);
    transform: translate3d(-302px, 19px, 0px);
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-olho-dominante {
    -webkit-transform: translate3d(-349px, 19px, 0px);
    transform: translate3d(-349px, 19px, 0px);
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--label.label-cvp {
    -webkit-transform: translate3d(-633px, 90px, 10px);
    transform: translate3d(-633px, 90px, 10px);
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .is--label.label-cvp {
    -webkit-transform: translate3d(-709px, 85px, 10px);
    transform: translate3d(-709px, 85px, 10px);
  }
}

.x-web-ordering-pedido__main .width-input__dados--num-pedido {
  width: calc(30.333% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__dados--num-pedido {
    width: calc(37% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__dados--num-pedido {
    width: 91%;
  }
}

.x-web-ordering-pedido__main .width-input__dados--empresa-fat {
  width: calc(62% - 10px);
}

/* Problema acima */

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__dados--empresa-fat {
    width: calc(55% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__dados--empresa-fat {
    width: 91%;
  }
}

.x-web-ordering-pedido__main .width-input__dados--cliente {
  width: calc(30.555% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__dados--cliente {
    width: calc(26.666% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__dados--cliente.first {
    width: calc(37% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__dados--cliente {
    width: 91%;
  }
}

.x-web-ordering-pedido__main .width-input__lens--tp-producao {
  width: calc(48.666% - 10px);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__lens--tp-producao {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__lens--tp-produto {
  width: calc(44.666% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__lens--tp-produto {
    width: calc(41.666% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__lens--tp-produto {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__lens--esc-produto {
  width: calc(94% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__lens--esc-produto {
    width: calc(92% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__lens--esc-produto {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__lens--servicos {
  width: calc(30.666% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__lens--servicos {
    width: calc(29.333% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__lens--servicos {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__prescription--olhos {
  width: calc(23% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__prescription--olhos {
    width: calc(23% - 10px);
  }
}

.x-web-ordering-pedido__main .width-input__prescription--olhos.input-tablet {
  width: calc(22% - 10px);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__prescription--olhos.input-tablet {
    width: calc(31% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__prescription--olhos.input-tablet {
    width: 80%;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__prescription--olhos {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--olhos.is--mobile {
    width: 90% !important;
  }

  .x-web-ordering-pedido__main .width-input__prescription--olhos.is--mobile.scale {
    width: 93%;
  }
}

.x-web-ordering-pedido__main .width-input__prescription--ini-clientes {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--dis-leitura {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--cvp {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--cro {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--wmap {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--angulo {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--coeficiente {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--estabilidade {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--dis-progressao {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--distancia {
  width: calc(20% - 10px);
}

.x-web-ordering-pedido__main .width-input__prescription--olho-domiannte {
  width: calc(25% - 1rem);
  font-size: 0.875rem;
  color: rgba(66, 75, 74, 0.8);
  font-family: "Open Sans";
  padding: 0.9375rem 3rem 0.9375rem 1.25rem;
  box-shadow: 0 0 0 1px #b5b5b5;
  border: initial;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 1;
  background: transparent;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .width-input__prescription--ini-clientes {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-leitura {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--cvp {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--cro {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--wmap {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--angulo {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--coeficiente {
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--estabilidade {
    /* -webkit-transform: translate3d(322px, -69px, 0);
        transform: translate3d(322px, -69px, 0); */
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-progressao {
    /* -webkit-transform: translate3d(-324px, 2px, 0px);
        transform: translate3d(-324px, 2px, 0px); */
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--distancia {
    /* -webkit-transform: translate3d(322px, -67px, 0);
        transform: translate3d(322px, -67px, 0); */
    width: calc(47% - 10px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--olho-domiannte {
    /* -webkit-transform: translate3d(-324px, 2px, 0px);
        transform: translate3d(-324px, 2px, 0px); */
    width: calc(47% - 10px);
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__prescription--ini-clientes {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-leitura {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--cvp {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--cro {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--wmap {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--angulo {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--coeficiente {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--estabilidade {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-progressao {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--distancia {
    width: 90%;
  }

  .x-web-ordering-pedido__main .width-input__prescription--olho-domiannte {
    width: 90%;
  }
}

@media (width: 850px) {
  .x-web-ordering-pedido__main .width-input__prescription--estabilidade {
    -webkit-transform: translate3d(361px, -69px, 0);
    transform: translate3d(361px, -69px, 0);
  }

  .x-web-ordering-pedido__main .width-input__prescription--dis-progressao {
    -webkit-transform: translate3d(-363px, 2px, 0px);
    transform: translate3d(-363px, 2px, 0px);
  }

  .x-web-ordering-pedido__main .width-input__prescription--distancia {
    -webkit-transform: translate3d(361px, -67px, 0);
    transform: translate3d(361px, -67px, 0);
  }
}

.x-web-ordering-pedido__main .width-input__frame--position {
  width: calc(47% - 10px);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__frame--position {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .width-input__frame--modelo {
  width: calc(96.333% - 15px);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .width-input__frame--modelo {
    width: 90%;
  }
}

.x-web-ordering-pedido__main .is--text-select {
  color: rgba(66, 75, 74, 0.8);
  font-family: "Open Sans";
  /* padding: 0.9375rem 3rem 0.9375rem 1.25rem; */
  /* box-shadow: 0 0 0 1px #b5b5b5; */
  border: initial;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 1;
  background: transparent;
}

.x-web-ordering-pedido__lens .is--text-select {
  width: calc(100% - 1rem);
  z-index: 9;
}

.x-web-ordering-pedido__main .is--text-select:hover,
.x-web-ordering-pedido__main .is--text-select:active,
.x-web-ordering-pedido__main .is--text-select:focus {
  box-shadow: 0 0 0 1px #0066b3;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--text-select {
    font-size: 0.75rem;
  }
}

.x-web-ordering-pedido__main .intro__form--container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin: 0% 7% 0% 0%;
}

.x-web-ordering-pedido__main .intro__form--container.space {
  margin-top: 3rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .intro__form--container {
    margin: 0 3.75rem 0.9375rem 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .intro__form--container {
    margin: initial;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: initial;
    justify-content: initial;
    -webkit-box-align: initial;
    align-items: initial;
  }
}

.x-web-ordering-pedido__main .intro__form--title {
  color: #424b4a;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.x-web-ordering-pedido__main .intro__form--subtitle {
  color: #424b4a;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .intro__form--subtitle {
    margin: 0 0 1.875rem 0;
  }
}

.x-web-ordering-pedido__main .intro__form--subtitle-lg {
  font-size: 0.875rem;
  font-weight: bold;
  margin: 1.625rem 0 0 0;
  font-family: "Montserrat", sans-serif;
}

p.x-web-ordering-pedido__frame--subtitle.intro__form--subtitle.title-mobile.t-frame.step4 {
  text-align: right;
}

.x-web-ordering-pedido__main .intro__form--subtitle.step4 {
  color: #424b4a;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  /* float: right; */
  width: 85%;
  /* clear: both; */
  /* text-align: left; */
}

.x-web-ordering-pedido__main .intro__form--subtitle-lg-2 {
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 1.625rem;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .intro__form--subtitle-lg-2 {
    margin: 2.3125rem 0 0 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .intro__form--subtitle-lg-2 {
    margin: 3rem 0 0 0;
  }
}

.x-web-ordering-pedido__main .intro__form--subtitle-sm {
  font-size: 0.875rem;
  margin: 1.625rem 0 1.125rem 0;
  font-family: "Montserrat", sans-serif;
}

/* .x-web-ordering-pedido__main .intro__form--subtitle-sm-2 {
  margin: 0 0.9375rem 0 0;
  font-size: 0.875rem;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .intro__form--subtitle-sm-2 {
    font-size: 0.775rem;
    margin: 0 0.625rem 0 0;
  }
} */

.x-web-ordering-pedido__main .intro__form--subtitle-sm-3 {
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
}

.x-web-ordering-pedido__main .no--gap {
  margin: initial;
}

.x-web-ordering-pedido__main .is--glasses {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0 3.125rem 0 2.25rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--glasses {
    display: none;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--glasses {
    display: none;
  }
}

/* .is--arrow {
    position: relative;
    z-index: 0;
    right: 3.125rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .x-web-ordering-pedido__main .is--arrow {
        right: 6%;
    }
} */

.x-web-ordering-pedido__main .is--arrow:before {
  content: "";
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  position: absolute;
  border-bottom: 1px solid #00639d;
  border-right: 1px solid #00639d;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 0;
}

.x-web-ordering-pedido__main .is--arrow-2 {
  position: absolute;
  z-index: 0;
  top: 2.6rem;
  margin-left: 10px;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--arrow-2 {
    /* top: 6.8125rem; */
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--arrow-2.ajust-arrow-left {
    top: 6.875rem;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .is--arrow-2.ajust-arrow-right {
    right: 0.5rem;
    top: 6.9375rem;
  }

  .x-web-ordering-pedido__main .is--arrow-2-step2:before {
    content: "";
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    position: absolute;
    border-bottom: 1px solid #00639d;
    border-right: 1px solid #00639d;
    right: 3.7rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 8rem;
  }
}

.x-web-ordering-pedido__main .is--arrow-2:before {
  content: "";
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  position: absolute;
  border-bottom: 1px solid #00639d;
  border-right: 1px solid #00639d;
  right: 3rem;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--arrow-2:before {
    right: 0;
    top: 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--arrow-2 {
    position: relative;
    float: right;
    right: 3rem;
    /* top: -1.9rem; */
  }

  .x-web-ordering-pedido__main .is--arrow-2.prisma {
    position: relative;
    float: right;
    right: 3rem;
    top: -1.9rem;
  }

  .x-web-ordering-pedido__main .is--arrow-2.adicao-right-left {
    position: relative;
    float: right;
    right: 3.5rem;
  }

  .x-web-ordering-pedido__main .is--arrow-2.dominante {
    position: relative;
    float: right;
    right: 3rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--arrow-2.is--mobile-right {
    right: 2.6rem;
    top: -1.85rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .is--arrow-2.is--mobile-left {
    right: 2.6rem;
    top: -1.85rem;
  }
}

.x-web-ordering-pedido__main .x-button__slick-slider {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.x-web-ordering-pedido__main .x-button__slick-slider .first-btn {
  opacity: 0;
  pointer-events: none;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next {
  width: 11.125rem;
  padding: 0.8125rem;
  border-radius: 3.125rem;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  color: #fff;
  font-weight: bold;
  font-size: 0.875rem;
  position: relative;
  align-self: flex-end;
  margin: 3% 7% 0% 0%;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next:hover {
  color: #fff;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next {
    margin: 1.8125rem 1.25rem 0 0;
    width: 7.25rem;
    height: 3rem;
  }
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next-icon {
  position: absolute;
  right: 0.625rem;
  top: 25%;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--next-text {
    -webkit-box-pack: initial;
    justify-content: initial;
    align-self: flex-start;
  }
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev {
  width: 11.125rem;
  padding: 0.8125rem;
  border-radius: 3.125rem;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  color: #fff;
  font-weight: bold;
  font-size: 0.875rem;
  position: relative;
  align-self: flex-end;
  margin: 3% 7% 0% 0%;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--confirm {
  width: 11.125rem;
  padding: 0.8125rem;
  position: relative;
  align-self: flex-end;
  margin: 3% 7% 0% 0%;
  padding-bottom: 0px;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev:hover {
  color: #fff;
}

.x-web-ordering-pedido__main .dontshow {
  width: 11.125rem;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev {
    margin: 1.8125rem 0.625rem 0 0;
    width: 7.25rem;
    height: 3rem;
  }
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev-icon {
  position: absolute;
  left: 0.625rem;
  top: 25%;
}

.x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-button__slick-slider .x-button__slick--prev-text {
    -webkit-box-pack: end;
    justify-content: flex-end;
    /* margin-top: 0.3rem; */
  }

  .x-web-ordering-pedido__prescription--row-both {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .flex-tablet {
    display: -webkit-box;
    display: flex;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .column-tablet {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    align-items: center;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary {
  width: 25%;
  background: #f3f3f5;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 2.9375rem 2rem 6.75rem 2rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary.confirm {
  width: 45%;
  background: #f3f3f5;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 2.9375rem 3.75rem 6.75rem 2.75rem;
  -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
  transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary {
    position: absolute;
    top: 8rem;
    width: 100%;
    height: 100%;
    right: -92vw;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    padding: 2.9375rem 2.75rem 6.75rem 2.75rem;
    overflow-y: scroll;
    height: 85%;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary.show-summary {
    -webkit-transform: translateX(-92%);
    transform: translateX(-92%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 999;
  }

  .x-my-account__back {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary {
    position: absolute;
    width: 50%;
    top: 9.6rem;
    height: 100%;
    right: -45vw;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    overflow-y: scroll;
    height: 85%;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary.show-summary {
    display: initial;
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 999;
  }

  .x-my-account__back {
    margin-top: 40px;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow {
  display: none;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow {
    display: inline-table;
    position: relative;
    right: 50px;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin: 20px;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow {
    display: inline-table;
    right: 10%;
    position: relative;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--container.show-summary {
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--container.show-summary {
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--intro {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--icon {
  align-self: center;
  margin: 0 0 2.25rem 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--paragraph {
  color: #424b4a;
  font-size: 0.775rem;
  font-family: "Montserrat", sans-serif;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--item {
  position: relative;
  border-bottom: 1px solid #d8d8da;
}

.x-web-ordering-pedido__summary--item:not(:last-child) {
  border-bottom: 1px solid #707070;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--link {
  color: #00518b;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0.625rem 1rem 0;
  display: block;
  cursor: pointer;
  pointer-events: all;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--link:after {
  content: "\002B";
  color: #0066b3;
  position: absolute;
  right: 0;
  font-size: 1.5em;
  top: 0.8125rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--link.active:after {
  content: "\2212";
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.left:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.right {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.right:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.shape {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--card.shape:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text {
  margin: 1.5rem 0 0.5rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  font-weight: bold;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text-sub {
  margin: 0.3rem 0 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  font-weight: bold;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text-2 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text.no--gap {
  margin-bottom: 0;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__summary--text {
    font-size: 0.775rem;
  }
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
}

.x-web-ordering-pedido__summary--card-text {
  font-family: "Montserrat", sans-serif;
}

.x-web-ordering-pedido__summary--subitem-text {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 100%;
}

.x-web-ordering-pedido__summary--subitem-text.title {
  font-weight: bold;
}

.x-web-ordering-pedido__summary--subitem-text.desc {
  text-align: right;
}

.x-web-ordering-pedido__summary--subitem-text:nth-child(even) {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__summary--card-text:last-child .x-web-ordering-pedido__summary--subitem-text:nth-child(even) {
  border-bottom: 0px solid rgba(112, 112, 112, 0.2);
}

.x-web-ordering-pedido__summary--side-left .x-web-ordering-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 59%;
}

.x-web-ordering-pedido__summary--side-right .x-web-ordering-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 59%;
}

.x-web-ordering-pedido__summary--subitem.right {
  text-align: right;
  width: 41%;
}

.x-web-ordering-pedido__summary--subitem.left {
  width: 41%;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--content {
  display: none;
  margin: 0 0 0 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--content.show {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--group {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--side-left {
  width: 45%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0 1rem 0 0;
}

.x-web-ordering-pedido__main .x-web-ordering-pedido__summary--side-right {
  width: 45%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

/* CSS animação de página */

@keyframes slideInFromRight {
  100% {
    transform: translateX(0);
  }

  0% {
    transform: translateX(100%);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

/* Slides pra esquerda */
.x-web-ordering-pedido__dados.right {
  animation: 0.3s ease-out 0s 1 slideInFromRight;
}

.x-web-ordering-pedido__lens.right {
  animation: 0.3s ease-out 0s 1 slideInFromRight;
}

.x-web-ordering-pedido__frame.right {
  animation: 0.3s ease-out 0s 1 slideInFromRight;
}

.x-web-ordering-pedido__prescription.right {
  animation: 0.3s ease-out 0s 1 slideInFromRight;
}

/* Slides pra direita */
.x-web-ordering-pedido__dados.left {
  animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

.x-web-ordering-pedido__lens.left {
  animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

.x-web-ordering-pedido__frame.left {
  animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

.x-web-ordering-pedido__prescription.left {
  animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

/* Acordeon */

.x-web-ordering-pedido__main .card-header {
  padding: 0px;
}

.x-web-ordering-pedido__main .card {
  border: 0px;
}

.x-web-ordering-pedido__main .card-body {
  flex: none;
  min-height: 0px !important;
  padding: 0px !important;
}

/* Campo auto complete */

.x-web-ordering-pedido__main .no-suggestions,
.x-web-ordering-pedido__main .suggestions li {
  padding: 7px;
}

.x-web-ordering-pedido__main .suggestions li:hover {
  background-color: #efefef;
}

.x-web-ordering-pedido__main .suggestion-active {
  background-color: #efefef;
}

.suggestion-active {
  background-color: #efefef;
}

.order-opacity {
  opacity: 0.2;
}

.x-web-ordering-pedido__lens--body .no-suggestions,
.x-web-ordering-pedido__lens--body .suggestions {
  color: rgba(66, 75, 74, 0.8);
  font-family: "Montserrat", sans-serif;
  position: absolute;
  z-index: 4;
  background: white;
  width: calc(99% - 10px);
  margin-top: 3px;
  border: 1px solid #efefef;
  overflow-y: scroll;
  height: 10rem;
  top: 90px;
}

.x-web-ordering-pedido__prescription--body .no-suggestions,
.x-web-ordering-pedido__prescription--body .suggestions {
  color: rgba(66, 75, 74, 0.8);
  font-family: "Montserrat", sans-serif;
  position: absolute;
  z-index: 4;
  background: white;
  width: calc(23% - 10px);
  margin-top: 3px;
  border: 1px solid #efefef;
  overflow-y: scroll;
  height: 10rem;
}

.x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(5),
.x-web-ordering-pedido__prescription--body .suggestions:nth-child(5) {
  left: 12.1rem;
}

.x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(7),
.x-web-ordering-pedido__prescription--body .suggestions:nth-child(7) {
  left: 24.1rem;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(5),
  .x-web-ordering-pedido__prescription--body .suggestions:nth-child(5) {
    left: 8.2rem;
  }

  .x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(7),
  .x-web-ordering-pedido__prescription--body .suggestions:nth-child(7) {
    left: 16.45rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido__prescription--body .no-suggestions,
  .x-web-ordering-pedido__prescription--body .suggestions {
    width: calc(100% - 15px);
  }

  .x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(5),
  .x-web-ordering-pedido__prescription--body .suggestions:nth-child(5) {
    left: unset;
  }

  .x-web-ordering-pedido__prescription--body .no-suggestions:nth-child(7),
  .x-web-ordering-pedido__prescription--body .suggestions:nth-child(7) {
    left: unset;
  }
}

/* CSS do passo 5 de confirmação */

.x-confirm-order__main {
  font-family: "Montserrat", sans-serif;
  color: #424b4a;
}

.x-confirm-order__container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.x-confirm-order__left {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 0 3.75rem 0 0;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__left {
    padding: 0 2.75rem 0 0;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__left {
    padding: initial;
    width: 100%;
  }
}

.x-confirm-order__button {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 41.8125rem;
  margin: 0 0 12.1875rem 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__button {
    margin: 0 0 2.8125rem 0;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 0 0 2rem 0;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__button {
    max-width: 75%;
    margin: 0 0 12.1875rem 3.75rem;
  }
}

.x-confirm-order__list {
  margin: 0 0 1rem 0;
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__list {
    max-width: 90%;
    margin: 0 0 1rem 3.75rem;
  }
}

.x-confirm-order__dados--container {
  margin: 0 0 0 3.75rem;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--container {
    margin: 0 0 0 2.75rem;
  }
}

@media only screen and (min-width: 1367px) {
  /* .x-confirm-order__dados--container {
    width: 100%;
  } */
}

.x-confirm-order__dados--intro {
  max-width: 44.0625rem;
  margin: 2.875rem 0 0 0;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__dados--intro {
    max-width: initial;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__dados--intro {
    padding: 0 0 0 3.75rem;
    max-width: 75%;
  }
}

.x-confirm-order__dados--square {
  margin-top: 1.5rem;
  margin-bottom: 1.75rem;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #0394db;
  display: block;
}

.x-confirm-order__dados--title {
  color: #00409a;
  font-size: 2rem;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--title {
    font-size: 1.25rem;
    text-align: center;
  }
}

.x-confirm-order__dados--paragraph {
  color: #424b4a;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 2.125rem 0;
}

.x-confirm-order__dados--paragraph_small {
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 0 0 2.125rem 0;
}

.x-confirm-order__dados--paragraph_small > a {
  color: #0056b3;
  text-decoration: underline;
}

.x-confirm-order__dados--paragraph_small > a:hover {
  color: #0056b3;
  text-decoration: underline !important;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--paragraph {
    font-size: 0.875rem;
  }
}

.x-confirm-order__dados--list {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.5;
  height: 3.125rem;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 0 0 1.25rem;
  max-width: 41.8125rem;
}

.x-confirm-order__dados--list:nth-of-type(2n + 1) {
  background: rgba(0, 102, 179, 0.1);
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--list {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: initial;
    align-items: initial;
    height: initial;
    padding: 0.9375rem;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__dados--list {
    max-width: 100%;
  }
}

.x-confirm-order__dados--item {
  margin: 0 1.25rem 0 0;
}

.x-confirm-order__dados--item.is--large {
  font-weight: bold;
}

.x-confirm-order__dados--item.blue {
  color: #0066b3;
  font-weight: bold;
}

.x-confirm-order__dados--icon {
  margin: 0 0.625rem 0 0.625rem;
}

.x-confirm-order__dados--icon.is--svg {
  margin: 0rem 0.9375rem 0 0.625rem;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--icon {
    margin: 0 0 0 0.3125rem;
  }
}

.x-confirm-order__dados--view {
  margin: 0 0 2.6875rem 0;
  padding: 0 1.25rem 0 1.25rem;
  text-decoration: underline;
  color: #0066b3;
  font-size: 0.875rem;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--view {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin: initial;
  }
}

@media only screen and (min-width: 1367px) {
  .x-confirm-order__dados--view {
    margin: 0 0 2.6875rem 3.75rem;
  }
}

.x-confirm-order__dados--button {
  padding: 0.6875rem 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-weight: bold;
  color: #ffffff;
  border-radius: 3.125rem;
  margin: 2.5rem 0 0 0;
  cursor: pointer;
  pointer-events: all;
}

.x-confirm-order__dados--button.is--corrigir {
  width: 9rem;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--button.is--corrigir {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--button.is--confirmar {
    width: 100%;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--button:nth-of-type(1) {
    margin: 1.25rem 0 0 0;
  }
}

.x-confirm-order__dados--text {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin: 0 0 0 1.25rem;
}

.x-confirm-order__dados--text.is--text-corrigir {
  margin: 0 0 0 0;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--text.is--text-corrigir {
    margin: 0 30%;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__dados--text {
    position: relative;
    margin: 0 auto;
  }
}

.x-confirm-order__right {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  background: #f3f3f5;
  color: #424b4a;
  width: 40%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__right {
    width: 100%;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__right {
    width: 100%;
  }
}

.x-confirm-order__summary {
  background: #f3f3f5;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 2.9375rem 3.75rem 6.75rem 2.75rem;
}

.x-confirm-order__summary--arrow {
  display: none;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__summary--arrow {
    display: block;
    position: relative;
    right: 30px;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-confirm-order__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__summary--arrow {
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    right: 10%;
    position: relative;
  }

  .x-confirm-order__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-confirm-order__summary--container.show-summary {
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1023px) and (min-width: 767px) {
  .x-confirm-order__summary--container.show-summary {
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

.x-confirm-order__summary--intro {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.x-confirm-order__summary--icon {
  align-self: center;
  margin: 0 0 2.25rem 0;
}

.x-confirm-order__summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.x-confirm-order__summary--paragraph {
  color: #424b4a;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
}

.x-confirm-order__summary--body {
  margin: 1.6875rem 0 0 0;
}

.x-confirm-order__summary--item {
  position: relative;
}

.x-confirm-order__summary--item:not(:last-child) {
  border-bottom: 1px solid #707070;
}

.x-confirm-order__summary--link {
  color: #00518b;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0.625rem 1rem 0;
  display: block;
  cursor: pointer;
  pointer-events: all;
}

.x-confirm-order__summary--link:after {
  content: "\002B";
  color: #0066b3;
  position: absolute;
  right: 0;
  font-size: 1.5em;
  top: 0.8125rem;
}

.x-confirm-order__summary--link.active:after {
  content: "\2212";
}

.x-confirm-order__summary--card {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.x-confirm-order__summary--card.left:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-confirm-order__summary--card.right {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.x-confirm-order__summary--card.right:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-confirm-order__summary--card.shape {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.x-confirm-order__summary--card.shape:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.x-confirm-order__summary--text {
  margin: 1.25rem 0 0.625rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
}

.x-confirm-order__summary--text-2 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.x-confirm-order__summary--text.no--gap {
  margin-bottom: 0;
}

@media only screen and (max-width: 766px) {
  .x-confirm-order__summary--text {
    font-size: 0.775rem;
  }
}

.x-confirm-order__summary--subitem {
  padding: 0.625rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.x-confirm-order__summary--content {
  display: none;
  margin: 0 0 0 0;
}

.x-confirm-order__summary--content.show {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.x-confirm-order__summary--group {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.x-confirm-order__summary--side-left {
  width: 45%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0 1.875rem 0 0;
}

.x-confirm-order__summary--side-right {
  width: 45%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.x-web-ordering-pedido__frame--item input[type="radio"] {
  display: none;
}

.x-web-ordering-pedido__frame--item label {
  border-radius: 17px;
  width: 8.4rem;
  text-align: center;
  border: 1px solid transparent;
}

.x-web-ordering-pedido__frame--item input[type="radio"]:checked + label {
  border: 1px solid #0394db;
}

.x-web-ordering-pedido__frame--item svg {
  vertical-align: middle;
  height: 5.5rem;
}

.x-confirm-order__dados--button a,
.x-confirm-order__dados--button a:hover,
.x-confirm-order__dados--button label {
  color: #fff !important;
}

.cod-modelo-armacao {
  text-align: center;
  font-size: 12px;
  color: #212529;
  font-family: "Montserrat", sans-serif;
}

.x-confirm-order__main .hide-termoscompra {
  display: none;
}

.x-confirm-order__main .show-termoscompra {
  display: content;
  opacity: 1;
  pointer-events: all;
  background: #fff;
  z-index: 99999;
  position: fixed;
  width: 745px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 60px;
  max-height: 80%;
  border: 2px solid #f8f8f8;
  border-radius: 5px;
  transition: 0.5s bottom, 0.5s opacity;
}

.termoscompra-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

/* Css novo */

h2.order-title {
  color: #424b4a;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 766px) {
  h2.order-title {
    margin-top: 20px;
  }
}

p.order-paragraph {
  color: #424b4a;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
}

p.order-paragraph.right {
  text-align: right;
  margin-right: 1rem;
}

.order-buttons-confirm {
  padding: 0 3.75rem 0 3.75rem !important;
}

.order-buttons {
  display: flex;
}

p.order-status-voucher {
  margin: 2.4rem 0 0 1rem;
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

p.order-status-voucher.green {
  color: green;
}

p.order-status-voucher.red {
  color: red;
}

.order-next-button {
  width: 11.125rem;
  margin-left: auto;
  margin-top: 2rem;
  margin-right: 2rem;
  padding-left: 0.5rem;
  padding-right: 12rem;
}

.order-next-button.detail {
  padding-right: 12rem;
  float: none;
}

.order-next-button.confirm {
  margin-right: 3rem;
}

.order-prev-button {
  width: 11.125rem;
  margin-right: auto;
  margin-top: 2rem;
  padding-right: 0.5rem;
}

input#codigoParticipante {
  padding-right: 40px;
}

.order-main-container {
  padding: 2rem 3.25rem 0 3.75rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

@media only screen and (max-width: 766px) {
  .order-main-container {
    padding: 1.5rem 2rem 0 1.5rem;
  }

  .x-web-ordering-pedido__main [type="text"],
  .x-web-ordering-pedido__main [type="number"],
  .x-web-ordering-pedido__main [type="email"] {
    margin-right: 1rem;
  }

  p.order-status-voucher {
    margin: 1rem 0 0 0;
  }
  .x-order-confirmed__container1 .button-mobile-only .crm-button-orange {
    margin: 0 auto;
  }

  .x-order-confirmed__container1 .button-mobile-only {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
    padding: 0 !important;
  }

  .button-mobile-only .crm-button-orange {
    width: 13.125rem !important;
  }

  .tooltip-voucher {
    margin: 0.5rem 0 0 0 !important;
    width: calc(100% - 1rem) !important;
  }
}

@media only screen and (max-width: 1100px) {
  .x-web-ordering-pedido__main .x-web-ordering-pedido__frame--body img {
    width: 305px;
    height: auto;
  }
}

.tooltip-voucher {
  visibility: visible;
  width: calc(33.3% - 1rem);
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%;
	left: 50%;
	margin-left: -60px; */
  /* opacity: 0; */
  transition: opacity 0.3s;
  margin: 5.4rem 0 0 0;
}

.tooltip-voucher::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

@media screen and (max-width: 766px) {
  .tooltip-voucher::before {
    position: unset;
  }

  .tooltip-voucher {
    position: unset;
  }
}

.x-web-ordering-pedido__prescription .buttonyn_label {
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-pedido .crm-select-input {
    margin: 1.375rem 1rem 0 0;
  }
}

ul#ul-rpl {
  list-style-position: inside;
  list-style: disc !important;
  width: 85%;
  margin: 0 auto;
  font-size: 90%;
}

.swal2-html-container {
  font-size: 95% !important;
}

.warn_message_voucher {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
  margin-top: 25px;
}

.warn_message_voucher svg {
  fill: #0394db;
}

.warn_message_voucher p {
  color: #424b4a;
  font-size: 0.65rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

@media only screen and (max-width: 675px) {
  .warn_message_voucher {
    margin-top: 0px;
  }
}

.colorInputRadio {
  -moz-appearance: none !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  position: relative;
  height: 0;
}

.colorInputRadio.activeSvgToInput:after {
  content: "";
  background-color: #005991;
  display: inline-block;
  width: 11px;
  left: 3px;
  top: 3px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
}

.colorInputRadio:before {
  content: "";
  display: none;
}

.colorInputRadio:checked:after {
  content: "";
  display: none;
}

.checkboxOne input[type="radio"]:checked + label {
  border: 1px solid #0394db;
  border-radius: 17px;
  width: 120px;
  margin-left: 20px;
  padding: 10px;
  height: 125px;
}

.checkboxOne input[type="radio"]:checked + .colorLabelRadio {
  border: 1px solid #0394db;
  border-radius: 17px;
  width: 120px;
  height: 140px;
}

.colorLabelRadio {
  border: 1px solid transparent;
  cursor: pointer;
  pointer-events: all;
  width: 120px;
  margin-left: 20px;
  padding: 10px;
  height: 140px;
}

.colorLabelRadio > img {
  width: 100px;
  height: 100px;
}

.logolab {
  text-align: center;
  background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
}

.colorLabelRadio > small {
  font-size: 12px;
}

@media only screen and (max-width: 786px) {
  .logolab {

    text-align: center;
    background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
  }
}

@media only screen and (max-width: 766px) {
  .order-next-button {
    width: unset !important;
  }

  .crm-button-blue,
  .crm-button-orange {
    width: 100% !important;
  }

  .order-next-button.detail {
    padding-right: 0;
    float: left;
  }

  .order-buttons {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    gap: 1.2rem;
  }

  .x-confirm-order__dados--container .order-buttons {
    gap: 0.32rem;
  }

  .order-next-button {
    width: 9rem !important;
    padding: 0;
    margin: 2rem 0 0 0;
  }

  .order-prev-button {
    width: 9rem !important;
    padding: 0;
    margin: 2rem 0 0 0;
  }

  .x-web-ordering-pedido__dados--card .crm-button-orange {
    margin: 0 auto;
  }

  p.order-paragraph.right {
    text-align: right;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .x-web-ordering-pedido__main .intro__form--title {
    margin-top: 20px;
  }

  .x-confirm-order__dados--container .order-buttons-confirm {
    padding: 0 !important;
  }
}
.order-lens-coloracao-cor {
  display: inline-flex;
}

.descricaoIMG {
  overflow-wrap: break-word;
}

.voucher-container {
  font-family: Arial, sans-serif;
  margin: 20px;
}


.voucher-info {
  margin-bottom: 20px;
  color: #aaaead;
    font-size: 0.9rem;
}


.voucher-table {
  margin: 0px;
}

.voucher-table th,
.voucher-table td {
    padding: 8px;
  text-align: center;
}
.voucher-table td {
  padding: 8px;
    font-size: small;
    text-align: center;
    border: none;
    background: white;
    color: #949999;
}

.voucher-table th {
  color: #0394db;
  background-color: white;
  font-weight: unset;
  font-size: small;
  border: none;
}

.pactoRadioButton::before{
  width: 14px !important;
  height: 15px !important;
}
.radio-button-container {
  justify-content: center;
}
.pactoRadioButton:checked:after{
  width: 8px !important;
  left: 3px !important;
  top: 40% !important;
  height: 8px !important;
}

.pactoRadioButton{
  width: 14px !important;
  height: 19px !important;
}