.media-upload {
    width:100%;
    height: 100vh;
    position: relative;
    color: #333333;
    font-weight: normal;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
}

.media-upload-login{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.media-upload-content {
    width: 60%;
    margin: 20px auto;
}

.media-upload-img-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.media-upload-input {
    width: 130px;
    margin: 0 5px;
}

.media-upload h1{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0px;
    white-space: nowrap;
    color: #2c2926;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
}


.media-upload-box {
    width: 100%;
}

.media-upload-box > .fileContainer{
    background: transparent !important;
    box-shadow: none !important;
    position: relative;
    border: 1px solid #DFE4E7;
    border-radius: 2px 0 0 2px;
    padding: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px auto;
    transition: all 0.3s ease-in;
    width: 100%;
    height: 100px;
}

.btn-upload{
    background: transparent !important;
    border-radius: 30px;
    color: #00688c !important;
    border-radius: 10px !important;
    width: 100%;
    height: 100px;
    font-weight: normal;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
}

.picturesWrapper {
    width: 100%;
}


.pictureContent {
    flex-wrap: wrap;
    background: transparent !important;
    position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px auto;
    transition: all 0.3s ease-in;
    width: 100%;
    height: 85px;
}

.pictureContainer {
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    position: relative;
}

.pictureFile {
    width: auto;
    height: 80%;
}

.media-upload-removeFile{
    position: absolute;
    top: -9px;
    right: -9px;
    color: #000;
    background: transparent;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    width: 20px;
    height: 20px;
}

.media-upload-input-text {
    font-weight: normal;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: #DFE4E7;
    color: #333333;
    border-radius: 2px;
    background-color: #FCFDFE;
    border: 1px solid #DFE4E7;
    padding: 6px 5px 5px 5px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 28px;
    margin: 0px;
    margin-bottom: 25px;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
}

.media-upload-loading {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.media-upload-add {
    color:#ddd;
    font-size: 35px;
    line-height: 35px;
    width: 47px;
    padding: auto;
    border: 7px solid #ddd;
    border-radius: 50%;
    text-align: center;
    margin-left: 10px ;
}

.media-upload-add:hover {
    cursor: pointer;
}

.show-media-preview {
    cursor: pointer;
    color: blue;
}