.upload-content {
  width: 100%;
  padding: 0 20px;
}

.upload-content > .fileContainer {
  background: transparent !important;
  box-shadow: none !important;
  position: relative;
  border: 1px dashed #ccc;
  border-radius: 10px;
  padding: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  transition: all 0.3s ease-in;
  width: 100%;
  height: 85px;
}

.upload-content-paragraph {
  font-size: 0.8rem;
  font-family: "Montserrat", sans-serif;
  /* font-weight: bold; */
  color: #424b4a;
  margin: 0 0 0.5rem 0;
  text-align: left;
}

.btn-upload {
  background: transparent !important;
  border-radius: 30px;
  color: #0066b3 !important;
  border-radius: 10px !important;
  width: 100%;
  height: 100px;
}

.picturesWrapper {
  width: 100%;
}

.pictureContent {
  flex-wrap: wrap;
  background: transparent !important;
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  transition: all 0.3s ease-in;
  width: 50%;
  height: 85px;
}

.pictureContainer {
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  position: relative;
}

.pictureFile {
  width: auto;
  height: 80%;
  border: solid 1px #ddd;
}

.removeFile {
  position: absolute;
  top: 0%;
  color: #fff;
  background: #0394db;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  width: 20px;
  height: 20px;
  z-index: 999;
  margin-left: 100%;
}

.main-otica .image-upload {
  position: absolute;
  width: 25%;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.tolltip {
  color: #0394db;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}

.oticaMedia .tolltip {
  color: #0394db;
  position: absolute;
  right: 0;
  top: 20%;
  z-index: 999;
}

.otica-menu-img {
  width: 3.375rem;
  height: 3.375rem;
  border-radius: 3.375rem;
  object-fit: cover;
  margin: 5px;
  border: 2px solid #00518b;
}

.main-otica .otica__photo-img,
.main-otica .otica__photo-img-preview,
#main-body .image-upload .otica__photo-img,
#main-body .image-upload .otica__photo-img-preview {
  width: 89px;
  height: 89px;
  border-radius: 50%;
  object-fit: cover;
  margin: 5px;
  border: 2px solid #00518b;
}

.main-otica .otica__photo-change-link,
#main-body .image-upload .otica__photo-change-link {
  text-decoration: underline;
  font-family: "Open Sans";
  font-size: 0.875em;
  font-weight: 600;
  margin: 0 0 0 20px;
  position: relative;
  cursor: pointer;
}

@media only screen and (min-width: 1025px) {
  .oticaCrm .image-upload {
    position: absolute;
    width: 18%;
  }
}
