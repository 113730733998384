.check-box-container {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 0 0 0 0;
    overflow: hidden;
	/* height: 22px; */
}

.check-box-label {
    font-size: .75em;
    color: #00518B;
    font-family: "Open Sans";
    padding: 0px 10px;
	/* margin-top: 10px; */
}

@media screen and (max-width: 767px) {
    .check-box-label {
        -webkit-transform: translate3d(23px, 2px, 0);
        transform: translate3d(23px, 2px, 0);
    }
}

.check-box-container [type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    width: 14px;
    height: 22px;
}

.check-box-container [type='checkbox']:checked:after,
.check-box-container [type='checkbox']:before {
    content: '';
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 20%;
    border: solid 1px #00518B;
}

.check-box-container [type='checkbox']:checked:after {
    content: '';
    background-color: #00518B;
    display: inline-block;
    width: 10px;
    left: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 10px;
    border-radius: 20%;
    position: absolute
}