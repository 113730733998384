.colorInputCheck {
	-moz-appearance: none !important;
	appearance: none !important;
	-webkit-appearance: none !important;
	position: relative;
	height: 0;
}

.colorInputCheck.activeSvgToInput:after {
	content: "";
	background-color: #005991;
	display: inline-block;
	width: 11px;
	left: 3px;
	top: 3px;
	height: 11px;
	border-radius: 50%;
	position: absolute;
}

.colorInputCheck:before {
	content: "";
	display: none;
}

.colorInputCheck:checked:after {
	content: "";
	display: none;
}

.checkboxOne input[type=checkbox]:checked + label{
	border: 1px solid #0394db;
	border-radius: 17px;
    /* width: 120px;
    height: 125px; */
    margin-left: 20px;
    padding: 10px;

}

.colorLabelCheck {
	border: 1px solid transparent;
	cursor: pointer;
	pointer-events: all;
	/* width: 120px;
    height: 125px; */
    margin-left: 20px;
    padding: 10px;
}

.colorLabelCheck > img {
	/* width: 300px;
	height: 300px; */
}