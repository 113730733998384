.pdf-order {
  font-family: "Montserrat", sans-serif;
}

.pdf-order-header {
  border-bottom: 1px solid #d8d8da;
}

.pdf-order-dados,
.pdf-order-prescricao,
.pdf-order-armacao {
  padding-bottom: 5px;
}

.pdf-order-footer {
  border-top: 1px solid #d8d8da;
  padding-top: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.pdf-order-footer > div > p {
  font-size: 0.775rem;
  margin-bottom: 2px;
}

.pdf-order-footer > div > p > small {
  color: #0066b3;
  font-weight: bold;
}

.pdf-title {
  color: #00518b !important;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0.625rem 10px 0;
  display: block;
  cursor: default;
  text-decoration: none !important;
  border-bottom: 1px solid;
}

.pdf-subtitle {
  color: #000;
  font-size: 0.875rem;
}

.pdf-subtitle-bold {
  margin: 0.625rem 0 0.125rem 0;
  font-size: 0.875rem;
  font-weight: bold;
}

.pdf-span {
  font-size: 0.6rem;
  border-bottom: 1px solid #d8d8da;
  margin: 0 0.5px;
  padding: 3px 0;
}

.pdf-span p {
  margin-bottom: 0.5px !important;
  color: #000 !important;
}

.pdf-order-dados-adicionais {
  font-size: 0.6rem;
  /* border-bottom: 1px solid #d8d8da; */
  margin: 0 0.5px;
  padding: 3px 0;
}

.pdf-order-dados-adicionais p {
  margin-bottom: 0.5px !important;
  color: #000 !important;
}

.pdf-button {
  color: #0066b3;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
}

.pdf-button:hover {
  text-decoration: underline;
}
