.MenuLateral_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.MenuLateral_menu-options {
  width: 30vw;
  max-width: 350px;
}

.MenuLateral_menu-itens {
  display: block;
  margin: 20px 3px 50px;
}
.MenuLateral_menu-itens button:hover {
  color: #fff !important;
  border: 2px solid rgb(0, 148, 219);
  background-color: rgb(0, 148, 219);
}
.MenuLateral_menu-itens button {
  width: 100%;
  background-color: rgba(0, 64, 154, 0.07);
  color: #00518b !important;
  padding: 10px 20px;
  border: 2px solid #00518b;
  margin: 1px 5px;
  line-height: 1.5;
  font-family: "Open Sans";
  font-size: 1em;
  font-weight: bold;
}

.MenuLateral_menu-itens .selected {
  color: #fff !important;

  background-color: #00518b;
}

.MenuLateral_selected-page {
  width: 96%;
}

@media only screen and (max-width: 1110px) {
  .MenuLateral_menu-itens button {
    width: 100%;
  }
}
@media only screen and (max-width: 620px) {
  .MenuLateral_menu-itens {
    display: block;
    margin: 50px 3px 50px;
  }
}

@media only screen and (max-width: 730px) {
  .MenuLateral_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .MenuLateral_menu-options {
    max-width: 350px;
    width: 100%;
  }
}
