.crm-input1 {
  display: inline-block;
  position: relative;
}

.crm-input-label1 {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
  top: 0px;
}



.css-yk16xz-control,
.css-yk16xz-control:hover {
  border-color: #b5b5b5 !important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  padding: 0.55rem 0;
}

.css-1okebmr-indicatorSeparator {
  background-color: unset !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  color: #00639d !important;
}
