.back {
  margin: 40px 0 0 60px;
}

.precos-e-servicos__main {
  margin: 0 1rem;
}
.preco-e-servicos-filter-div{
    display: flex;
    flex-wrap: wrap;
}

.precos-e-servicos_container {
  /* display: -webkit-box; */
  /* display: flex; */
  margin: 0 60px;
  align-items: center;
}

.files-container {
  margin-top: 50px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
  max-width: 904px;
}

.precos-e-servicos-intro {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.precos-e-servicos-title {
  font-size: 2.25rem;
  font-family: "Montserrat", sans-serif;
  color: #0066b3;
  margin: 2.375rem 0 0 0;
}

.precos-e-servicos-text p{
  font-family: "Montserrat", sans-serif;
  font-size: .875em;
  color: #424B4A;
}

.precos-e-servicos-shape {
  width: 0.625rem;
  height: 0.625rem;
  background: #0394db;
  margin: 0.625rem 0 1.5625rem 0;
}

.precos-e-servicos-button {
  background: #0394db;
  background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
  background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
  border-radius: 1.25rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 0.625rem;
  width: 90%;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
}

.precos-e-servicos-svg {
	position: absolute;
	right: 0.625rem;
}

.precos-e-servicos-text.is-text--lg {
	padding: 0.75rem 1.0625rem 0.75rem 1.0625rem;
	color: #fff;
	font-weight: bold;
	font-family: 'Open Sans';
	font-size: 0.875rem;
}

.limparFiltro {
	color: #0066b3
}

@media (max-width: 899px){

  .precos-e-servicos-text p {
    font-family: "Montserrat", sans-serif;
    font-size: .775em;
    color: #424B4A;
  }
  .precos-e-servicos-p{
    margin: 0px;
  }
  .precos-e-servicos-text {
    margin-top: 2vw;
    margin-bottom: 2vw;
  }
  .filters-container{
    margin-bottom: 5vw;
  }
  .files-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .precos-e-servicos-file-card-image {
    margin-top: 2vw;
    display: block;
    width: 100px;
    max-width: 100%;
    transform: scale(1);
    opacity: 1;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

}

@media (max-width: 768px){

  .container, .container-sm {
    max-width: 100%; 
  }
  .files-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
.precos-e-servicos-file-card-field {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 0.625rem 0;
  padding-top: 10px;
  flex-wrap: wrap;
  justify-content: center;
  }
}

@media (max-width: 450px){
  .files-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}