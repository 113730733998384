.crm-button-label {
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.crm-button-blue {
  border-radius: 1.875rem;
  padding: 0 0.9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  margin: 0 auto;
  -webkit-transition: all 0.155s ease-in;
  transition: all 0.155s ease-in;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.crm-button-orange {
  border-radius: 1.875rem;
  padding: 0 0.9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: -webkit-gradient(linear, left top, right top, from(#0394db), to(#0394db));
  background: linear-gradient(90deg, #0394db 0%, #0394db 100%);
  margin: 0 auto;
  margin-top: 1.75rem;
  -webkit-transition: all 0.155s ease-in;
  transition: all 0.155s ease-in;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.crm-button:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.284);
}

.crm-button p {
  margin-bottom: 0;
}

@media only screen and (max-width: 766px) {
  .crm-button-blue,
  .crm-button-orange {
    width: 100% !important;
    margin-right: 35px;
  }
}
