.switch-container {
    display: flex;
    margin: 17px 0;
}

.switch-container p {
	margin-bottom: 0;
}

.switch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
}

.custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-36px + 2px);
    width: calc(1rem - 0px);
    height: calc(1rem - 0px);
    background-color: #adb5bd;
    border-radius: .5rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    transform: translateX(20px);
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 40px;
    pointer-events: all;
    border-radius: .5rem;
    height: 20px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}