.buttonrl-radio--container {
	display: flex;
	align-items: center;
	margin: 0.7rem 0 0;
	overflow: hidden;
}

.buttonrl-radio--input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.buttonrl-radio--label {
	color: #b3b7b7;
	font-family: Montserrat, sans-serif;
	font-size: 0.75rem;
	line-height: 1;
	text-align: center;
	padding: 0.75rem 1.3125rem;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

.buttonrl-radio--input:checked + .buttonrl-radio--label {
	background: #0394db;
	color: #fff;
	font-weight: 700;
	border: 1px solid transparent;
}

.buttonrl-radio--left {
	border-top-left-radius: 2.375rem;
	border-bottom-left-radius: 2.375rem;
}

.buttonrl-radio--right {
	border-top-right-radius: 2.375rem;
	border-bottom-right-radius: 2.375rem;
}

.buttonrl-radio--label:hover {
	cursor: pointer;
}

@media only screen and (max-width: 766px) {
	.buttonyn_radio--container {
		margin-right: 1.1875rem;
	}

	.buttonrl-radio--container {
		width: 100%;
		justify-content: space-between;
		padding-right: 1rem;
	}

	.buttonrl-radio--container label {
		width: 100%;
		height: 3rem;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.buttonrl-radio--label {
		padding: 0.75rem 0.3125rem;
	}
}
