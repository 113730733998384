.main-financeiro {
  width: 90%;
  min-height: 73vh;
  margin: 0 auto;
  margin-top: 1.75rem;
  position: relative;
  display: flex;
}
#container-financeiro-page {
  width: 80%;
}
.classNameContainerTable{
  overflow: visible !important;

  max-height: 386px !important;
}
.financeiro-table-td {
  padding: 0.5rem 0.6rem !important;
}
.financeiro-pedidos-tr-th {
  padding: 30px 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 90%;
}
.divTagA {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.financeiro-pedidos-tr-th:first-child {
  border-radius: 10px 0 0 0;
}

.financeiro-pedidos-tr-th:last-child {
  border-radius: 0 10px 0 0;
}

.tracking-main-table-body:nth-of-type(odd) {
  background-color: #e5edf3;
}

.tracking-main-table-body:nth-of-type(even) {
  background-color: #fafaff;
}

.pedidos.table tbody + tbody {
  border-top: 0px;
}

.pedidos.table thead tr th,
.table tbody tr td {
  border: none;
}

.pedidos.table td,
.table th {
  padding: 1.5rem 0.75rem;
  vertical-align: middle;
}

.tracking-main-table-status {
  position: relative;
}




.tracking-main-table-status-arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  right: 5px;
  bottom: 15px;
  border-right: 2px solid #00518b;
  border-bottom: 2px solid #00518b;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}

.tracking-main-table-status-arrow.inverse {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}

.tracking-main-table-row-details {
  background-color: #fff;
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
  /* border-bottom: 1px solid #E5EDF3; */
}

.tracking-main-table-row-details-td {
  -webkit-transition: all 0.175s ease-in-out;
  transition: all 0.175s ease-in-out;
}

.tracking-order-detail {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.tracking-order-detail-column.direita {
  width: calc(60% - 20px);
  margin-left: 20px;
}

.detalhes.table {
  width: 100%;
  color: #212529;
  border: 0px;
  text-align: left;
}

.detalhes.table thead tr th,
.detalhes.table tbody tr td {
  border: none;
}

.detalhes.table td,
.detalhes.table th {
  border-top: 0px;
}

.detalhes.table tbody + tbody {
  border: 0px;
}

.detalhes.table thead th {
  border-bottom: 0px;
  /* background: #EDF4FA; */
  text-transform: uppercase;
  color: #00518b;
  font-family: "Montserrat", sans-serif;
  font-size: 90%;
}

.detalhes.table td,
.table th {
  padding: 0.5rem 0.75rem;
  vertical-align: middle;
  color: #585858;
}

.tracking-order-detail-column.esquerda {
  width: 40%;
}

.tracking-order-detail-column.esquerda .card-pedido {
  border-radius: 12px;
  background-color: #edf4fa;
  padding: 20px;
}

.tracking-order-detail-column.esquerda h3 {
  text-transform: uppercase;
  color: #00518b;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

/* .tracking-order-detail-column.esquerda svg {
    margin-bottom: 15px;
} */

.tracking-order-detail-column.esquerda p {
  text-align: left;
}

.tracking-detail-card-title {
  margin-top: 7px;
  font-weight: bold;
  color: #585858;
}

.tracking-detail-card-p {
  color: #707070;
}

.pedidos .hide-table-row {
  display: none;
}

.tracking-detail-card-title.mobile,
.tracking-detail-card-p.mobile,
.tracking-detail-card-title.tablet,
.tracking-detail-card-p.tablet {
  display: none;
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .tracking-order-detail {
    display: flex;
    flex-direction: column;
  }

  .tracking-order-detail-column.esquerda {
    width: 100%;
  }

  .tracking-order-detail-column.direita {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .hide-table-column-tablet {
    display: none;
  }

  .tracking-detail-card-title.tablet,
  .tracking-detail-card-p.tablet {
    display: grid;
  }
}

/* CSS for mobile small devices */

@media only screen and (max-width: 766px) {
  .financeiro-pedidos-tr-th {
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 90%;
  }

  .financeiro-pedidos-tr-th:nth-child(3n) {
    border-radius: 0 10px 0 0;
  }

  .pedidos.table td,
  .table th {
    padding: 0.5rem;
    vertical-align: middle;
  }

  .tracking-main-table-status {
    display: inline;
  }

  

  .tracking-main-table-status-arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    right: 0px;
    bottom: 0px;
    top: 50px;
    border-right: 2px solid #00518b;
    border-bottom: 2px solid #00518b;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .tracking-order-detail {
    display: flex;
    flex-direction: column;
  }

  .tracking-order-detail-column.esquerda {
    width: 100%;
  }

  .tracking-order-detail-column.direita {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .pedidos .hide-table-column {
    display: none;
  }

  .pedidos .hide-table-row {
    display: table-row;
  }

  .pedidos .status-row {
    padding-bottom: 30px !important;
  }

  .detalhes.table {
    font-size: 80%;
  }

  .tracking-detail-card-title.mobile,
  .tracking-detail-card-p.mobile {
    display: grid;
  }
}

/* https://www.florin-pop.com/blog/2019/04/how-to-create-a-timeline-with-react/ */
.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px;
}

.timeline-container::after {
  background-color: #00518b;
  content: "";
  position: absolute;
  left: calc(10% - 2px);
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  padding-left: 91px;
  padding-right: 0;
  position: relative;
  margin: 10px 0;
  width: 100%;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 500px;
  max-width: 100%;
  text-align: right;
}

.timeline-item-content::after {
  content: " ";
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item .timeline-item-content {
  text-align: left;
  align-items: flex-start;
  min-height: 70px;
}

.timeline-item .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item .timeline-item-content .tag {
  left: auto;
  right: 5px;
  color: #00518b;
}

.timeline-item-content time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 13px;
  line-height: 24px;
}

.timeline-item-content a {
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: " ►";
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #00518b;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}


@media only screen and (max-width: 1024px) {
  .timeline-item-content {
    max-width: 100%;
    width: 360px;
  }

}

@media only screen and (min-width: 464px) and (max-width: 768px) {
  .timeline-item-content {
    width: 90%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 463px) {
  .timeline-item-content {
    width: 90%;
  }

}

@media only screen and (min-width: 150px) and (max-width: 424px) {

  .financeiro-pedidos-tr-th:nth-child(n + 1):nth-child(-n + 2) {
    padding-left: 40px !important;
  }
  .financeiro-pedidos-tr-th:nth-child(n + 4):nth-child(-n + 6) {
    padding-left: 10px !important;
    padding-right: 5px !important;
  }
}

@media only screen and (max-width: 767px) {
	.timeline-item {
		padding-left: 59px;
	}
	.financeiro-table-td {
		border-bottom: 1px solid #ddd !important;
		display: table-cell !important;
		font-size: 0.8em !important;
		text-align: unset !important;
	}
	.timeline-item-content,
	.timeline-item .timeline-item-content {
		padding: 15px 10px;
		text-align: center;
		align-items: center;
		width: 220px;
		min-height: 90px;
	}

  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }

  .timeline-item-content time {
    margin-top: 20px;
  }

  .timeline-item-content a {
    text-decoration: underline;
  }

  .timeline-item-content a::after {
    display: none;
  }
}

.pagination .previous.disabled {
  display: none;
}

.pagination .next.disabled {
  display: none;
}

.pagination-container {
  text-align: center;
}

.pagination {
  margin: 15px auto;
  display: inline-flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #0066b3;
  border-color: #0066b3;
  color: #fff;
}

.pagination > li > a {
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #0066b3;
  border-color: #0066b3;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #0066b3;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
.expired {
  color: red;
  font-weight: bold;
}
