.product-management-container {
  margin: 0px 60px;
}
#main-product-management {
  margin: 0 1rem;
}

.product-managemente-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.product-managemente-card {
  width: 90%;
}

.conecta-produtos-h1{
    padding: 35px 0px;
}


