
.dash .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff !important;
    border-bottom: none !important;
}
.dataModule-dashboardLab{
    display: flex;
    justify-content: space-around;
 }

 .containerDados{
    display: flex;
    justify-content: space-evenly;
 }
 @media only screen and (max-width: 620px) {
    .dataModule-dashboardLab {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
  }
  