.otica-menu-main {
	padding: 10px 10px;
}

.otica-menu-main ul {
	margin-top: 0;
	margin-bottom: 0;
}

.otica-menu .dropdown-menu {
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 17px -1px rgb(0 0 0 / 45%);
	box-shadow: 0px 0px 17px -1px rgb(0 0 0 / 45%);
	width: 400px;
	height: 400px;
}

.otica-menu-main .list-unstyled {
    padding: 15px 0;
    list-style: none;
    width: 100%;
}

.otica-menu-main .list-unstyled:hover {
	background-color: #0394db;
}

.otica-menu-main .list-unstyled:hover .otica-ul-text {
	color: #ffffff !important;
}

.otica-menu-img {
	width: 3.375rem;
	height: 3.375rem;
	border-radius: 3.375rem;
	object-fit: cover;
	margin: 5px;
	border: 2px solid #00518b;
}

.otica-menu-btn {
	height: 3.875rem;
	z-index: 3;
	line-height: 3.875rem;
}

.otica-menu-text {
	color: #00518b;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	line-height: 25px;
}

.otica-ul-text {
	color: rgb(151, 151, 151);
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	line-height: 25px;
}

.ativo .otica-ul-text {
	color: #00518b;
}

.line-h-50 {
	line-height: 50px !important;
}

.otica-menu-main .dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 0;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 1px solid rgb(151, 151, 151);
	border-radius: 50px;
	margin: 10px 0;
}

.otica-menu-nova{
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 5px;
    margin: 10px auto;
    padding: 10px;
}

.otica-menu-content{
    overflow-x: hidden;
    height: 300px;
}

.otica-menu-content-mobile{
	overflow-x: hidden;
    height: 100%;
}

.otica-menu-footer{
    bottom: 0;
    position: fixed;
    width: 100%;
    padding: 0 20px;
    left: 0;
    background-color: #fff;
    border-radius: 10px;
}

.otica-menu-footer-center button{
	margin-bottom: 0.7rem;
	font-size: 0.75rem;
}


@media only screen and (max-width: 425px) {
	.otica-menu-btn {
		height: 3.5rem !important;
		z-index: 3;
		line-height: unset !important;
	}
}

.offcanvas-backdrop.show {
    opacity: .5;
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}