.lab-title {
    font-size: 2.25rem;
    color: #0066B3;
    margin: 40px 0 0 0;
    position: relative;
    z-index: 1;
    font-weight: bold;
}

.lab-shape {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background: #0394db;
    position: relative;
    margin: 10px 0;
}

.lab-paragraph {
    font-size: 1rem;
    color: #424B4A;
    position: relative;
    z-index: 1;
}

.lab-svg{
    width: 100%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .lab-title {
        align-self: flex-start;
    }

    .lab-shape  {
        align-self: flex-start;
    }

    .lab-paragraph {
        max-width: 100%;
        text-align: left;
        font-size: 0.875rem;
    }
}

@media only screen and (max-width: 766px) {
    .lab-title {
        font-size: 1.75rem;
        margin: 2.375rem auto auto auto;
        text-align: center;
    }

    .lab-shape {
        margin: 1.6875rem auto;
    }

    .lab-paragraph {
        max-width: 95%;
        margin: 0 auto;
        font-size: 0.875rem;
        text-align: center;
    }
}