* {
	margin: 0;
	padding: 0;
}
.consultar-cupom__container{
	display: flex;
    justify-content: center;
}
.consultar-search-button {
	border-radius: 1.875rem;
	width: 20.1875rem;
	height: 3.125rem;
	padding: 0 .9375rem;
	position: relative;
	color: white;
	font-size: 1rem;
	text-align: center;
	font-weight: bold;
	background: #00518b;
	background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
	background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
	margin: 0 auto;
	margin-top: 1.75rem;
	-webkit-transition: all .155s ease-in;
	transition: all .155s ease-in;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
}

.result-card-main {
	width: 60%;
	margin: 20px auto 0;
}

.result-card-main thead tr {
	background-color: #00518b;
}

.result-card-main thead tr th {
	text-align: center;
	color: white;
}

.result-card-main tbody tr td {
	color: #424b4a;
	text-transform: uppercase;
	/* width: 50%; */
}

.table-striped tbody tr:nth-child(odd) td {
	background-color: #E5EDF3;
}

.td-label {
	text-align: end;
}

.td-value {
	padding-left: 20px !important;
}

.tabela-pares-main {
	/* width: 50%; */
	margin: 40px auto 0;
}

.tabela-pares-main table thead tr th {
	padding: 0 0.75rem;
}

.par-select {
	display: inline-flex;
	position: relative;
	width: 70%;
	font-size: 90%;
}

.par-select-input {
	/* font-size: 0.875rem; */
	color: rgba(66, 75, 74, 0.8);
	font-family: "Open Sans";
	padding-left: 1.25rem;
	box-shadow: 0 0 0 1px #b5b5b5;
	border: initial;
	border-radius: 5px;
	/* margin: 1.375rem 1rem 0 0; */
	position: relative;
	z-index: 1;
	background: transparent;
	width: 100%;
}

.par-select-arrow {
	position: absolute;
	z-index: 0;
	right: 20px;
	top: 5px;
}

.par-select-arrow:before {
	content: "";
	display: block;
	width: 0.545rem;
	height: 0.545rem;
	position: absolute;
	border-bottom: 2px solid #00639d;
	border-right: 2px solid #00639d;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}