.lab-form {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.lab-form-container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 50%;
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .lab-form-container {
        width: 100%;
    }
}

@media only screen and (max-width: 766px) {
    .lab-form {
        margin: 0 0 0 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .lab-form-container {
        width: 100%;
    }
}