.back {
	margin: 40px 0 0 60px;
}

.suporte__main {
	margin: 0 1rem;
}

.suporte__container {
	display: -webkit-box;
	display: flex;
	margin: 0 60px;
	align-items: center;
}

.suporte__menu-options {
	width: 25%;
}

.suporte__menu-itens {
	display: flex;
	flex-direction: column;

	width: fit-content;

	margin-top: 20px;
	background-color: rgba(0, 64, 154, .07);
}

.suporte__menu-itens button {
	background-color: unset;
	color: #00518b !important;

	padding: 10px 20px;
	border: 2px solid #00518b;
	margin: 1px 0 0 0;

	line-height: 1.5;
	font-family: "Open Sans";
	font-size: 1em;
	font-weight: bold;
}

.suporte__menu-itens .selected {
	color: #fff !important;

	background-color: #00518b;
}

.suporte__selected-page {
	width: 50%;
}