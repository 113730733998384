.main-tracking {
  margin: 0 auto;
  margin-top: 1.75rem;
  position: relative;
  display: flex;
  justify-content: center;
}
.tooltipFixoColuna {
  display: flex;
  align-items: center;
  margin: 27px 0 0 0;
}
.tooltipDivFinanceiro {
  display: flex;
  align-items: center;
  margin-top: -7px;
}
.tooltipFixo {
  margin-right: 10px;
  content: "i";
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.625rem;
  color: #0394db;
  z-index: 15;
  border: 1px solid #0394db;
  border-radius: 50%;
  width: 2rem;
  max-width: 14px;
  height: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  opacity: 1 !important;
}
.tooltipDivFinanceiro p {
  margin: 0px;
  font-size: smaller;
}
#table-head-financeiro{
  width: 100%;
}
#thead-table {
  position: sticky !important;
}

.main-tracking .crm-select-input {
  margin: 1.375rem 0 0 0 !important;
}

.main-tracking .crm-input [type="text"],
.main-tracking .crm-input [type="number"],
.main-tracking .crm-input [type="email"],
.main-tracking .crm-input [type="password"],
.main-tracking .crm-input [type="tel"],
.main-tracking .crm-input [type="url"] {
  margin: 1.375rem 0rem 0 0 !important;
}

.main-tracking .crm-select-arrow {
  position: absolute;
  z-index: 0;
  right: 30px;
  top: 43px;
}




.tracking-filters {
  padding: 1.75rem 0;
  position: relative;
}

.tracking-filters-row {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
}

.tracking-filters-row:not(:first-child) {
  margin-top: 1.75rem;
}

.tracking-filters-box {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.tracking-filters-box.lab {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
}

.tracking-filters-box.cnpj {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.tracking-filters-label {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  color: #424b4a;
  position: relative;
  text-align: center;
}

.tracking-filters-text {
  font-weight: bold;
  font-size: 1rem;
  color: #00518b;
  text-transform: uppercase;
  line-height: 65px;
}

.Opacity {
  opacity: 0.2;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1210px) {
  .x-main .tracking-filters-row {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .tracking-filters-text {
    width: unset;
    text-align: center;
    margin: 0;
  }
}

/* CSS for mobile small devices */

@media only screen and (max-width: 766px) {
  .tracking-filters-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .tracking-filters-box {
    width: 100%;
    padding-top: 1.75rem;
  }

  .tracking-filters-box.cnpj {
    margin-left: 0px;
  }

  .tracking-filters-row:not(:first-child) {
    margin-top: unset;
  }

  .tracking-filters-text {
    margin: 0;
    margin-top: 1.75rem;
    width: 100%;
    line-height: 15px;
  }
}

/* Descrição lateral */

.x-web-ordering-tracking__summary {
  background: #f3f3f5;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 2.9375rem 2rem 6.75rem 2rem;
}

.x-web-ordering-tracking__summary.confirm {
  background: #f3f3f5;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 2.9375rem 3.75rem 6.75rem 2.75rem;
  -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
  transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
}

.main-tracking .x-web-ordering-tracking__summary--intro {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.main-tracking .x-web-ordering-tracking__summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.main-tracking .x-web-ordering-tracking__summary--paragraph {
  color: #424b4a;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
}

.main-tracking .x-web-ordering-tracking__summary--icon {
  align-self: center;
  margin: 0 0 2.25rem 0;
}

.main-tracking .x-web-ordering-tracking__main {
  background: #f3f3f5;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative;
}

.x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow {
  display: none;
}

@media only screen and (min-width: 1210px) {
  .main-tracking .x-web-ordering-tracking__main {
    width: 370px;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary {
    position: absolute;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    padding: 2.9375rem 2.75rem 6.75rem 2.75rem;
    overflow-y: auto;
    margin-left: 2vw;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary.show-summary {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 5;
  }

  .x-my-account__back {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1210px) and (min-width: 767px) {
  .x-web-ordering-tracking__main.active-arrow .x-web-ordering-tracking__summary {
    width: 100%;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary {
    position: absolute;
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    overflow-y: auto;
  }

  .x-web-ordering-tracking__main.active-arrow {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    z-index: 500;
  }

  .x-my-account__back {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-tracking__main.active-arrow .x-web-ordering-tracking__summary {
    width: 100%;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin-left: 5px;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--container.show-summary {
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1210px) and (min-width: 767px) {
  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--container {
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1210px) and (max-width: 1210px) and (min-width: 767px) {
  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--container.show-summary {
    display: initial;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 766px) {
  .x-web-ordering-tracking__main.active-arrow {
    width: 90vw;
    right: 0;
    position: absolute;
    height: 100%;
    z-index: 500;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-tracking__main {
    width: 5vw;
    overflow: hidden;
  }

  .main-tracking .x-web-ordering-tracking__main {
    background: #f3f3f5;
    display: flex;
    flex-direction: column;
    height: 700px !important;
    transform: translate(0px, 80px) !important;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow {
    display: inline-table;
    position: relative;
    left: -62px;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin: 20px;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

@media only screen and (max-width: 1210px) and (min-width: 767px) {
  .x-web-ordering-tracking__main.active-arrow {
    width: 500px;
    right: 0;
    position: absolute;
    height: 100%;
    z-index: 500;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-tracking__main {
    width: 40px;
    overflow: hidden;
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow {
    display: inline-table;
    left: -27px;
    position: relative;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 2px solid #0394db;
    border-left: 2px solid #0394db;
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
    margin-left: 5% !important;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary--arrow.active-arrow {
    -webkit-transform: rotate(220deg);
    transform: rotate(220deg);
    -webkit-transition: all 0.175s ease-in-out;
    transition: all 0.175s ease-in-out;
  }
}

.logolabTracking {
  /* background-color: rgb(255, 255, 255); */
  /* position: absolute; */
  /* width: 25%; */
  /* margin-left: -2rem; */
  text-align: center;
  right: 0;
  top: 200px;
  background: rgb(243, 243, 245);
  background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
}

@media only screen and (max-width: 1460px) {
  #table-head-financeiro {
    max-width: 100%;
    overflow: auto;
  }

  .pedidos.table {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1210) {
  .x-web-ordering-tracking__summary--intro {
    padding: 2vw;
  }
}

@media only screen and (max-width: 770) {
  #thead-table {
    position: sticky !important;
  }

  #pedido-table {
    overflow-x: auto;
    max-width: 100%;
    scrollbar-color: #00518b;
    scrollbar-width: thin;
  }

  #table-head-financeiro {
    max-width: 95%;
    overflow: auto;
  }

  .financeiro-pedidos-tr-th {
    padding: 1.5rem 0.75rem;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 90%;
  }

  .pedidos.table td,
  .table th {
    padding: 0.5rem;
    vertical-align: middle;
    word-wrap: break-word;
    max-width: 45vw;
    padding: 1.5rem 1.2rem !important;
  }

  .pedidos.table td {
    padding: 1.5rem 2.5rem !important;
  }
}

@media only screen and (max-width: 765) {
  .col-xs-12.col-md-6.status-container {
    width: 100% !important;
  }
  .financeiro-container-status-radios {
    margin:0px 0px 0px 0px !important;
  }
  .x-web-ordering-tracking__main {
    width: 5vw;
  }

  .x-web-ordering-tracking__main .x-web-ordering-tracking__summary {
    position: absolute;
    transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
    padding: 2.9375rem 2.75rem 6.75rem 2.75rem;
    overflow-y: auto;
    margin-left: 2vw;
  }
}
@media only screen and (max-width: 1135px) {
  .tooltipDivFinanceiro {
    display: flex;
    height: 72%;
  }
  .financeiro-container-status-radios {
    justify-content: space-between !important;
  }
}

.financeiro-label-status {
  font-size: 0.75rem;
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: relative;
  z-index: 2;
  padding: 0 5px;
  top: 0px;
  margin-bottom: 5px;
}

.financeiro-container-status-radios {
  margin: 0rem 1rem 0 0;
  display: flex;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  min-height: 55.785px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  flex-flow: wrap row;
  justify-content: space-evenly;
}

.financeiro-container-status-radios .radio-button-container {
  margin: 5px 1px 1px 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #00518b;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f2f2f2;
}

@media only screen and (max-width: 1210px) {
  #container-financeiro-page {
    width: 100% !important;
  }
}

@media only screen and (max-width: 850px) {
  #container-financeiro-page {
    width: 90% !important;
  }
}
