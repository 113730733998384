* {
	margin: 0;
	padding: 0;
}
.precos-e-servicos-col-info-clientes{
	background-color: #d9e1e7 !important;
}.p-modal-verifica-redes{
	font-size: smaller;
    text-align: left;
    line-height: 30px;
}
.p-modal-sem-redes{
	text-align: center;
	font-size: smaller;

}
.custom-swal-height {
	height: 300px !important; 
	justify-content: space-around !important;
}
.DivVerificarRedeLink{
	display: flex;
    justify-content: space-between;
}
.info-cliente-resultcard-main {
	margin: 20px auto 0;
}
#info-cliente-main{
	width: 80%;
}
.LinkVerificaRede{
	font-weight: 600;
	text-decoration: underline;
}
.LinkVerificaRede:hover {
    color: #050708;
    font-weight: bold;
}
.info-cliente-resultcard-main thead tr {
	background-color: #00518B;
}

.info-cliente-resultcard-main thead tr th {
	text-align: center;
	color: white;
}

.info-cliente-resultcard-main tbody tr td {
	padding: 0.25rem;
	font-size: 12px;
	color: #424b4a;
}

.info-cliente-resultcard-main .table {
	border: 1px solid #E5EDF3;
}

.info-cliente-resultcard-main .table-striped tbody tr:nth-child(odd) td {
	background-color: #E5EDF3;
}

.info-cliente-resultcard-main .table-striped tbody tr:nth-child(even) td {
	background-color: #FAFAFF;
}

.td-label {
	text-align: end;
}

.td-value {
	padding-left: 20px !important;
	font-weight: bold;
}
