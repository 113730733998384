.tracking-filters-input,
.tracking-filters-date,
.tracking-filters-option,
.tracking-filters-select {
  font-size: 1rem;
  color: #707070;
}

.tracking-filters-select {
  width: 100%;
  border-radius: 6px;
  padding: 1rem 1.375rem;
  position: relative;
  border: 1px solid #b5b5b5;
  background: transparent;
  z-index: 1;
  height: 58px;
}

.tracking-filters-select-cnpj {
  width: 100%;
  border-radius: 6px;
  padding: 1rem 1.375rem;
  position: relative;
  border: 1px solid #b5b5b5;
  background: transparent;
  z-index: 1;
  height: 58px;
  font-size: 1rem;
  color: #707070;
}

.tracking-filters-select-arrow {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid #00518b;
  border-right: 2px solid #00518b;
  bottom: 1.55rem;
  right: 1.25rem;
  z-index: 1;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.tracking-filters-select-option {
  padding: 0.625rem 0;
}

.tracking-filters-input {
  height: 3.125rem;
  border-radius: 0.375rem;
  border: 1px solid #b5b5b5;
  padding: 0.875rem 1rem;
  width: 100%;
}

.tracking-filters-input.cnpj {
  border-radius: 0.375rem;
  border: 1px solid #b5b5b5;
  width: 100%;
  padding: 1rem 1.375rem;
  height: 58px;
}

.tracking-filters-date-container {
  display: -webkit-box;
  display: flex;
}

.tracking-filters-date {
  border: 1px solid #b5b5b5;
  padding: 0.875rem 1rem;
  width: 100%;
  text-align: center;
}

.tracking-filters-box.is--date {
  margin-left: auto;
}

.tracking-filters-date.start-date {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tracking-filters-date.end-date {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.cnpj .tooltip-shape:before {
  content: "i";
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.625rem;
  color: #0394db;
  z-index: 15;
  border: 1px solid #0394db;
  border-radius: 50%;
  width: 0.9375rem;
  height: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  right: 0;
  top: 4.2rem;
  opacity: 1 !important;
}

.tracking-search-button {
  border-radius: 1.875rem;
  width: 20.1875rem;
  height: 3.125rem;
  padding: 0 0.9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  margin: 0 auto;
  margin-top: 2.5rem;
  -webkit-transition: all 0.155s ease-in;
  transition: all 0.155s ease-in;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.tracking-search-button:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.284);
}

.inputDataInicio {
  padding-right: 1rem;
}
.inputDataFim {
  padding-left: 1rem;
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .tracking-filters-input,
  .tracking-filters-date {
    width: 9rem;
  }

  .tracking-filters-select .cnpj {
    width: 12rem;
  }

  .main-tracking .tooltiptext {
    left: unset;
    right: 92px;
  }
}

/* CSS for mobile small devices */

@media only screen and (max-width: 766px) {
  .tracking-filters-input,
  .tracking-filters-input.cnpj,
  .tracking-filters-date {
    width: 100%;
  }

  .is__shape--position {
    position: absolute;
  }

  .tracking-filters-box.is--date {
    width: 100%;
    margin-left: unset;
  }

  .tracking-search-button {
    width: 100%;
  }

  .main-tracking .tooltip-shape:before {
    right: 0;
  }

  .main-tracking .tooltiptext {
    top: 99px;
    right: 156px;
    left: unset;
  }

  .main-tracking .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 82%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}
