.doc-main {
    padding: 0 5.75rem;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 1.75rem;
	display: flex;
}

.doc-menu {
	width: 25%;
	margin-right: 30px;
}

.doc-body {
	width: 100%;
}

.doc-paragraph {
    font-size: 0.925em;
    color: #313131;
    margin: 1.3125rem 0;
}

.doc-paragraph b {
	color: #313131;
	font-weight: 600;
}

.doc-menu ul {
	width: 100%;
	list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #d3d3d3;
}

.doc-menu ul li {
    display: block;
    color: #000;
    padding: 8px 0 8px 16px;
    text-decoration: none;
}

.doc-menu ul li:hover {
    background-color: #555;
    color:white;
	cursor: pointer;
}

.doc-main pre {
	font-family: Consolas,"courier new";
	color: crimson;
	padding: 15px;
	font-size: 90%;
	background-color: rgb(236, 236, 236);
}

.doc-info-box {
	border: 1px solid #00FF7F;
	border-radius: 5px;
	background-color: #98FB98;
	padding: 10px;
	font-weight: bold;
	color: #555;
	margin-bottom: 0rem;
}

.doc-info-box:after {
	content: '';
	float: right;
	border: solid #555;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	margin-top:7px;
	transform: rotate(45deg);
  	-webkit-transform: rotate(45deg);
}

.doc-info-box:hover {
	cursor: pointer;
}

.doc-main table {
	width: 100%;
	margin-top: 10px;;
}

.doc-main table, .doc-main th, .doc-main td {
	border: 1px solid rgb(167, 167, 167);
	border-collapse: collapse;
}

.doc-main th, .doc-main td {
	padding: 5px;
	text-align: left;
}