.search-button {
	border-radius: 1.875rem;
	width: 20.1875rem;
	height: 3.125rem;
	padding: 0 .9375rem;
	position: relative;
	color: white;
	font-size: 1rem;
	text-align: center;
	font-weight: bold;
	background: #00518b;
	background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
	background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
	margin: 0 auto;
	margin-top: 1.75rem;
	-webkit-transition: all .155s ease-in;
	transition: all .155s ease-in;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
}