.media-center-img-preview {
    /* height: 450px; */
    width: 70%!important;
}

.carousel .slide {
    background: transparent !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000 !important;
}

.media-center-preview {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .img-modal{
    width: 100%;
}

.media-center-preview .carousel {
    width: 100%!important;
    padding-bottom: 20px;
} */

/* .media-center-preview .carousel.carousel-slider {
    display: flex;
    justify-content: flex-start;
} */

/* @media (min-width: 960px){
    .carousel .control-dots {
        bottom: -15px;
    }
}

.carousel .control-dots {
    position: absolute;
    bottom: -15px;
    margin: 10px 0;
    text-align: center;
    width: 100%;
}

.media-center-preview .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    opacity: 1;
    width: 10px;
    height: 10px;
}

.media-center-preview  .carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: 1;
    box-shadow: 0px 0px 0px rgba(0,0,0,.9);
    background: #0066B3;
    border-radius: 0%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
} */

.media-center-preview .carousel .control-arrow, .carousel .carousel-slider .control-arrow {
    transition: all .25s ease-in;
    opacity: 1;
    z-index: 2;
    /* top: 20px; */
    background: 0 0;
    border: 0;
    /* font-size: 32px; */
    cursor: pointer;
    position: absolute !important;
}

/* .media-center-preview .carousel .control-arrow:before, .media-center-preview  .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: '';
}

.media-center-preview  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}

.media-center-preview  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #0066B3;
}

.media-center-preview  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #0066B3;
}

@media (min-width: 768px) {
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
        margin: 0 auto;
    }
} */
