.x-downloads__search--item {
	/* width: 100%; */
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	flex: 0 0 25%;
}
.filtroSelectOptionSlected {
    width: 312px;
}
.x-downloads__search--label {
	font-family: 'Montserrat', sans-serif;
	font-weight: bold;
	margin-bottom: 0.3rem;
}

.x-downloads__search--position-select {
	position: relative;
}

.filtroSelect {
	font-size: small;
	border: 1px solid #b5b5b5;
    padding: 0.45rem 0.20rem 0.45rem 0.55rem;
    border-radius: 5px;
    margin: 0.50rem 1rem 0 0;
    z-index: 0;
    width: 100%;
}

.x-downloads__search--select:focus {
	outline: none;
	box-shadow: 0 0 2px 2px rgba(0, 89, 147, 0.4);
	border-radius: 0.3125rem;
}
.divFiltroSelectContainer{
	height: 42px;
}

.x-downloads__search--arrow:after {
	content: '';
	display: block;
	width: 0.625rem;
	height: 0.625rem;
	border-right: 2px solid #005991;
	border-bottom: 2px solid #005991;
	position: absolute;
	-webkit-transform: translateY(-50%) rotate(45deg);
	transform: translateY(-50%) rotate(45deg);
	right: 1rem;
	top: 50%;
	z-index: 1;
}
.setaFiltroSelect{

    width: 0.625rem;
    height: 0.625rem;
    border-right: 2px solid #005991;
    border-bottom: 2px solid #005991;
	position: absolute;
	transform: translateY(-50%) rotate(45deg);

}

@media only screen and (max-width: 766px) {
	.x-downloads__search--item {
		margin: 0 0 1rem 0;
		max-width: 100%;
	}

	.x-downloads__search--label {
		margin: 0 0 0.5rem 0;
	}

	.x-downloads__search--select {
		width: 100%;
	}

	.x-downloads__search--arrow:after {
		top: 45%;
		right: 1.25rem;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-downloads__search--arrow:after {
		right: 25%;
	}
}