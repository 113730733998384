.radio-button-container {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 0 0 0 0;
    /* overflow: hidden; */
	height: 22px;
}

.radio-button-label {
    font-size: .75em;
    color: #00518B;
    font-family: "Open Sans";
    padding: 0px 10px;
	/* margin-top: 10px; */
}

.radio-button-container [type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    width: 14px;
    height: 22px;
}

.radio-button-container [type='radio']:checked:after,
.radio-button-container [type='radio']:before {
    content: '';
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: solid 1px #00518B;
}

.radio-button-container [type='radio']:checked:after {
    content: '';
    background-color: #00518B;
    display: inline-block;
    width: 10px;
    left: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 10px;
    border-radius: 50%;
    position: absolute
}