.crm-input1 {
  display: inline-block;
  position: relative;
}

.crm-input-label1 {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
  top: 0px;
}

.container-auto-select {
  border: initial;
  position: relative;
  margin-top: 1.375rem;
}

.select__control:hover,
.select__control--is-focused:hover {
  border-color: #b5b5b5 !important;
}

.css-2b097c-container,
.css-14jk2my-container {
  margin-right: 1rem;
}

.css-yk16xz-control,
.css-yk16xz-control:hover {
  border-color: #b5b5b5 !important;
}

.css-yk16xz-control,
.css-1pahdxg-control,
.css-1fhf3k1-control {
  padding: 0.55rem 0;
}

.css-1fhf3k1-control {
  background-color: unset !important;
  border-color: #cbcbcb !important;
}

.css-2b097c-container input {
  position: relative;
}

.css-1okebmr-indicatorSeparator,
.css-109onse-indicatorSeparator {
  background-color: unset !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  color: #00639d !important;
}

.css-4ljt47-MenuList {
  max-height: 260px !important;
}

.css-26l3qy-menu {
  z-index: 99 !important;
}

@media only screen and (max-width: 766px) {
  .crm-input1 {
    width: 100% !important;
    margin-top: 10px;
  }

  .css-2b097c-container,
  .css-14jk2my-container {
    margin-right: 0;
  }
}
