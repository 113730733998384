/* Basic CSS */


.main-tracking .crm-select-input {
	margin: 1.375rem 0 0 0 !important;
}
#main-body-tracking{
	width: 90%;
    min-height: 73vh;
    margin: 0 auto;
    margin-top: 1.75rem;
    position: relative;
    
}
#main-tabela{
	width: 80%;
	
	}
@media only screen and (max-width: 1220px) {
	
	#main-body-tracking {
			width: 100% !important;
		}
	}
.main-tracking .crm-input [type="text"],
.main-tracking .crm-input [type="number"],
.main-tracking .crm-input [type="email"],
.main-tracking .crm-input [type="password"],
.main-tracking .crm-input [type="tel"],
.main-tracking .crm-input [type="url"] {
	margin: 1.375rem 0rem 0 0 !important;
}

.main-tracking .crm-select-arrow {
	position: absolute;
	z-index: 0;
	right: 30px;
	top: 43px;
}



.tracking-filters {
	padding: 1.75rem 0;
	position: relative;
}

.tracking-filters-row {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: end;
	align-items: flex-end;
}

.tracking-filters-row:not(:first-child) {
	margin-top: 1.75rem;
}

.tracking-filters-box {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
}

.tracking-filters-box.lab {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	width: 100%;
}

.tracking-filters-box.cnpj {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
}

.tracking-filters-label {
	margin-bottom: 1.25rem;
	font-size: 1rem;
	color: #424b4a;
	position: relative;
	text-align: center;
}

.tracking-filters-text {
	font-weight: bold;
	font-size: 1rem;
	color: #00518b;
	text-transform: uppercase;
	line-height: 65px;
}

.Opacity {
	opacity: 0.2;
}

.react-datepicker-wrapper {
	display: block !important;
}

.react-datepicker-popper {
	z-index: 100 !important;
}

/* CSS for tablets  */

@media only screen and (min-width: 767px) and (max-width: 1210px) {

	.x-main .tracking-filters-row {
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.tracking-filters-text {
		width: unset;
		text-align: center;
		margin: 0;
	}
}

/* CSS for mobile small devices */

@media only screen and (max-width: 766px) {
	#main-tabela {
		width: 75%;
	}
	.tracking-filters-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		-webkit-box-align: start;
		align-items: flex-start;
	}

	.tracking-filters-box {
		width: 100%;
		padding-top: 1.75rem;
	}

	.tracking-filters-box.cnpj {
		margin-left: 0px;
	}

	.tracking-filters-row:not(:first-child) {
		margin-top: unset;
	}

	.tracking-filters-text {
		margin: 0;
		margin-top: 1.75rem;
		width: 100%;
		line-height: 15px;
	}
}



.main-tracking .x-web-ordering-tracking__main {
	background: #f3f3f5;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	position: relative;
}



@media only screen and (min-width: 1210px) {
	.main-tracking .x-web-ordering-tracking__main {
		width: 370px;
	}
}


@media only screen and (max-width: 766px) {
	
	.x-my-account__back {
		margin-top: 40px;
	}
}

@media only screen and (max-width: 1210px) and (min-width: 767px) {
	

	.x-web-ordering-tracking__main.active-arrow {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
		transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
		z-index: 500;
	}

	.x-my-account__back {
		margin-top: 40px;
	}
}


@media only screen and (max-width: 766px) {

	.x-web-ordering-tracking__main.active-arrow {
		width: 90vw;
		right: 0;
		position: absolute;
		height: 100%;
		z-index: 500;
		-webkit-transition: all 0.175s ease-in-out;
		transition: all 0.175s ease-in-out;
	}

	.x-web-ordering-tracking__main {
		width: 85px;
		overflow: hidden;
	}

}

@media only screen and (max-width: 1210px) and (min-width: 767px) {

	.x-web-ordering-tracking__main.active-arrow {
		width: 500px;
		right: 0;
		position: absolute;
		height: 100%;
		z-index: 500;
		-webkit-transition: all 0.175s ease-in-out;
		transition: all 0.175s ease-in-out;
	}

	.x-web-ordering-tracking__main {
		width: 40px;
		overflow: hidden;
		-webkit-transition: all 0.175s ease-in-out;
		transition: all 0.175s ease-in-out;
	}


}


.logolabTracking{
		/* background-color: rgb(255, 255, 255); */
		/* position: absolute; */
		/* width: 25%; */
		/* margin-left: -2rem; */
		text-align: center;
		right: 0;
		top: 200px;
		background: rgb(243, 243, 245);
		background: linear-gradient(90deg, rgba(243, 243, 245, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(243, 243, 245, 1) 95%);
	
}
@media only screen and (max-width: 440px) {
    #main-tabela {
        width: 85%;
        padding-left: 20px;
    }
}