thead > tr > th{
    background-color: #424B4A;;
    border-color: #454d55;
    color: #fff;
}

.roles-management-table-header-th, .roles-management-table-body-tr{
min-width: 210px;
    max-width: 210px;
    word-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-wrap: balance;
}
.essilor-table{
    width: 100%;
    border-collapse: collapse;
}
.table-container{
    max-width: 100%; 
    overflow-x: auto;
}
tbody > tr > td{
    vertical-align: middle !important;
    text-wrap: pretty;
}

.btn-excluir{
    font-size: 0.875rem;
}

.roles-select {
    font-size: 1rem;
    color: #707070;
}

.roles-select {
    width: 100%;
    border-radius: 6px;
    padding: 10px 1.375rem;
    position: relative;
    border: 1px solid #B5B5B5;
    background: transparent;
    z-index: 1;
}

.roles-select-arrow {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #00518B;
    border-right: 2px solid #00518B;
    bottom: 20px;
    right: 1.25rem;
    z-index: 1;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.roles-select-option {
    padding: .625rem 0;
}
