.x-downloads__search--input {
	-webkit-appearance: none;
	border: none;
	box-shadow: 0rem 0rem 0rem 0.125rem rgba(181, 181, 181, 0.5);
	padding: 0.75rem 0.875rem 0.75rem 0.4375rem;
	border-radius: 0.3125rem;
	position: relative;
	width: 100%;
	font-family: 'Open Sans';
	font-size: 0.875rem;
	color: rgba(66, 75, 74);
	position: relative;
	z-index: 99;
	background: transparent;
  }

  .x-downloads__search--input:focus {
	outline: none;
	box-shadow: 0 0 2px 2px rgba(0, 89, 147, 0.4);
	border-radius: 0.3125rem;
  }

  @media only screen and (max-width: 766px) {

	.x-downloads__search--input {
	  width: 100%;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.x-downloads__search--input {
		width: 100%;
	}
}
