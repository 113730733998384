.x-user-consultant__intro--form {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.x-user-consultant__intro--container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 30%;
}

.x-user-consultant__intro--title {
  margin: 0 0 1.25rem 0;
  font-family: 'Open Sans';
  color: #424b4a;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
}

.x-user-consultant__intro--input {
  -webkit-appearance: none;
  border: none;
  box-shadow: 0rem 0rem 0rem 0.125rem rgba(181, 181, 181, 0.5);
  padding: 0.9375rem 1.875rem 0.9375rem 1.4375rem;
  border-radius: 0.3125rem;
  position: relative;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  color: #000;
  position: relative;
  z-index: 99;
  background: transparent;
  margin: 0 0 1.875rem 0;
  max-width: 100%;
}

.x-user-consultant__intro--input:focus {
  outline: none;
  box-shadow: 0 0 2px 2px rgba(0, 89, 147, 0.4);
  border-radius: 0.3125rem;
}

.x-user-consultant__intro--button {
  position: relative;
  top: 0.3125rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-family: 'Open Sans';
  color: #fff;
  font-weight: bold;
  width: 15%;
  max-width: 100%;
  cursor: pointer;
  pointer-events: all;
  padding: 0.625rem;
  background: #00518b;
  border-radius: 50px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00518b),
    color-stop(86%, #0066a0),
    to(#006aa3)
  );
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-user-consultant__intro--form {
    margin: 1.875rem 0 0 0;
  }

  .x-user-consultant__intro--container {
    width: 50%;
  }

  .x-user-consultant__intro--button {
    width: 50%;
  }
}

@media only screen and (max-width: 766px) {
  .x-user-consultant__intro--form {
    margin: 1.875rem 0 0 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .x-user-consultant__intro--container {
    width: 100%;
  }

  .x-user-consultant__intro--title {
    text-align: center;
  }

  .x-user-consultant__intro--input {
    max-width: 100%;
    margin: 0 0 1.25rem 0;
  }

  .x-user-consultant__intro--button {
    width: 100%;
  }
}

.form-check-input:checked {
  background-color: #0394db !important;
  border-color: #0394db !important;
}