.filtroInputlabel {
	font-size: small;
    transform: translate3d(10px, 15px, 0);
    color: #00518b;
    background: #fff;
    font-family: "Open Sans";
    position: absolute;
    z-index: 2;
    padding: 0 5px;
    margin-right: 5px;
}

.divFiltroSelectContainer{
    position: relative; 
}
.setaFiltroSelect {
    position: absolute; 
    right: 11px;
    top: 21px;
}
.filtroInput {
    font-size: small;
	border: 1px solid #b5b5b5;
    padding: 0.45rem 0.20rem 0.45rem 0.55rem;
    border-radius: 5px;
    margin: 0.5rem 0rem 0 0;
	position: relative;
    z-index: 0;
    width: 312px;

}

.filtroInput:focus {
    outline: none;
    border: 2px solid rgba(0, 89, 147, 0.4);
    box-shadow: inset 0 0 6px 2px hsla(204, 92%, 61%, 0.4);
    border-radius: 0.3125rem;
}
.filtroInputLabel{
    font-size: small;
    margin-right: 5px;
}

@media only screen and (max-width: 766px) {
	.filtroInputLabel {
		margin-top: 1vw;
	}

	.filtroInput {
		width: 100%;
	}
}

