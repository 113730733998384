.button-my-profile p{
	font-size: 0.75rem;
}

.user-menu-main {
	padding: 10px 30px;
}

.user-menu-main ul {
    margin-top: 0;
    margin-bottom: 0;
}

.user-menu-btn {
    border-radius: 1.5rem;
    height: 44px;
    width: 44px;
    align-items: center;
    z-index: 3;
    position: relative;
    top: 10px;
    padding-top: 3px;
}

.user-menu-img {
	width: 89px;
    height: 89px;
    border-radius: 50%;
    object-fit: cover;
}

.user-menu .dropdown-menu {
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 17px -1px rgb(0 0 0 / 45%);
	box-shadow: 0px 0px 17px -1px rgb(0 0 0 / 45%);
	width: 300px;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
    width: 100%;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 0;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 50px;
    margin: 10px 0;
}

.dropdown-item > a{
    color: #000;
}

.user-menu-logout{
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 5px;
    margin: 10px auto;
    padding: 10px;
}

@media only screen and (max-width: 425px) {
	.user-menu-btn {
		top: 3px !important;
	}
}