/* CSS desktop */

.header__container {
	/* display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center; */
	padding: 0 3.75rem;
	height: 3.875rem;
	background-color: #f8f8f8;
}

.header__container.is--mobile {
	display: none;
}

.header__logo {
    height: 3.875rem;
}

.header__logo img {
	width: auto;
	height: 5.2rem;
}

.header__items {
	/* margin-left: 3.75rem; */
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
}

ul.header__items {
	margin-bottom: 0px;
}

.header__item {
	display: flex;
}

.header__link {
	color: #00518A;
	-webkit-transition: all .175s ease-in-out;
	transition: all .175s ease-in-out;
	font-size: 1rem;
	width: 100%;
	line-height: 3.875rem;
	padding: 0 20px;
	height: 3.875rem;
	font-weight: 600;
	text-decoration: none !important;
}

.header__link:hover {
	color: #ffffff;
	background-color: #0394db;
}

.header__menu {
	margin-left: auto;
	position: relative;
}

.header__menu-btn {
	background-color: #00518b;
	border-radius: 2.375rem;
	height: 2.375rem;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	z-index: 3;
	position: relative;
}

.header__menu-btn:after {
	content: '';
	display: block;
	position: relative;
	margin-left: 1rem;
	margin-right: 1.25rem;
	width: 10px;
	height: 10px;
	border-top: 2px solid white;
	border-right: 2px solid white;
	-webkit-transform-origin: center;
	transform-origin: center;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	will-change: transform;
	top: -2px;
}

.header__menu-text {
	margin-left: 1.25rem;
	line-height: 2.375rem;
	font-size: 0.75rem;
	color: white;
	font-weight: bold;
	cursor: pointer;
}

label.header__menu-text {
	margin-bottom: 0px;
}

.header__menu-list {
	position: absolute;
	width: 100%;
	border-radius: 0.625rem;
	top: 1rem;
	max-width: 11.25rem;
	background-color: white;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.284);
	padding-top: 1.875rem;
	-webkit-transform: translateY(-7%);
	transform: translateY(-7%);
	opacity: 0;
	pointer-events: none;
	z-index: 2;
	-webkit-transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.header__menu-list-item {
	padding: 0.5rem 0;
	text-align: center;
}

.header__menu-list-item:last-child {
	padding-bottom: 1.5rem;
}

.div-width-logout {
	width: 100%;
}


.div-width-logout p{
	font-size: 0.75rem;
}

.header__menu-list-link {
	font-size: 0.75rem;
	line-height: 0.9375rem;
	color: #424b4a;
	-webkit-transition: color all 0.175s ease-in-out;
	transition: color all 0.175s ease-in-out;
	display: block;
	width: 100%;
	text-align: center;
}

.header__menu-list-link:hover {
	color: #1e2222;
}

.header__menu-list.is--active {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	pointer-events: all;
	opacity: 1;
}

.header__side-menu {
	position: absolute;
	width: 15.625rem;
	background-color: white;
	padding: 1.5rem 2.375rem 3.75rem;
	z-index: 5;
	top: 0;
	left: 0;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: all 0.235s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.235s cubic-bezier(0.165, 0.84, 0.44, 1);
	height: 100%;
}

.header__side-menu.is--active {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.header__side-item {
	padding: 1.25rem 0;
}

.header__side-item:first-child {
	padding-top: 2.5rem;
}

.header__side-item:last-child {
	padding-bottom: 2.5rem;
}

.header__side-link {
	color: #424b4a;
}

.header__side-link.is--bold {
	color: #0066b3;
	font-weight: bold;
}

.header__background {
	background-color: black;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	z-index: 4;
	width: 100vw;
	height: 100vh;
	top: 0;
	right: 0;
	-webkit-transition: all 0.235s ease-in-out;
	transition: all 0.235s ease-in-out;
}

.header__background.is--active {
	opacity: 0.6;
	pointer-events: all;
}

.my-account__avatar {
	width: 2.375rem;
	height: 2.375rem;
	border-radius: 2.375rem;
	object-fit: cover;
}

.my-account__avatar img:focus {
	outline: none !important;
}

.my-account__avatar_container {
	width: 2.375rem;
	height: 2.375rem;
	border-radius: 2.375rem;
	object-fit: cover;
}

/* CSS tablet */

@media only screen and (min-width: 767px) and (max-width: 1023px) {
	.header__container {
		padding: 0 2rem;
		height: 3.3125rem;
	}
}

@media only screen and (max-width: 1023px) {
	.header__items {
		margin-left: 0.9375rem;
		margin-top: 1rem;
	}

	.header__link {
		font-size: 0.7rem;
	}

	.header__menu-btn {
		width: 8.75rem;
		height: 2.125rem;
	}

	.my-account__avatar_container {
		width: 2.125rem;
		height: 2.125rem;
		border-radius: 2.125rem;
		object-fit: cover;
	}

	.header__menu-btn img {
		width: 2.125rem;
		height: 2.125rem;
	}

	.header__menu-btn:after {
		width: 8px;
		height: 8px;
		position: absolute;
		right: -8px;
		top: 46%;
		-webkit-transform: translateY(-50%) rotate(135deg);
		transform: translateY(-50%) rotate(135deg);
	}

	.header__menu-text {
		margin-left: 0.5rem;
		font-size: 0.7rem;
		line-height: 0.2rem;
	}
}

/* CSS mobile */

@media only screen and (max-width: 766px) {
	.header__container {
		padding: 0 1rem;
		height: 3rem;
	}

	.header__container.is--desk {
		display: none;
	}

	.header__container.is--mobile {
		display: -webkit-box;
		display: flex;
	}

	.header__logo {
		margin-left: 1rem;
		height: 3rem;
	}

	.header__logo img {
		height: 100%
	}

	.header__menu-btn {
		width: 6.875rem;
		height: 2.125rem;
	}
}

.header_menu-btn-mobile{
	display: flex;
	gap: 30px;
}

.header_menu-btn-mobile div.dropdown-menu {
	margin-left: -41px!important;
}