.crm-input {
  display: inline-flex;
  position: relative;
}

.crm-input-label {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
}

.crm-input [type="text"],
.crm-input [type="number"],
.crm-input [type="email"],
.crm-input [type="password"],
.crm-input [type="tel"],
.crm-input [type="url"] {
  /* box-shadow: 0 0 0 1px #b5b5b5; */
  border: 1px solid #b5b5b5;
  padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 0;
  width: 100%;
}

.crm-input input:read-only {
  color: #a6abaa;
  border-color: #cbcbcb;
}

.crm-input [type="text"]:read-only,
.crm-input [type="number"]:read-only,
.crm-input [type="email"]:read-only,
.crm-input [type="password"]:read-only,
.crm-input [type="tel"]:read-only,
.crm-input [type="url"]:read-only,
.crm-input [type="date"]:read-only,
.crm-input [type="datetime-local"]:read-only {
  border: 1px solid #cbcbcb;
  box-shadow: unset;
}

.crm-input [type="text"]:disabled,
.crm-input [type="number"]:disabled,
.crm-input [type="email"]:disabled,
.crm-input [type="password"]:disabled,
.crm-input [type="tel"]:disabled,
.crm-input [type="url"]:disabled,
.crm-input [type="date"]:disabled,
.crm-input [type="datetime-local"]:disabled {
  background-color: unset;
}

.crm-input [type="date"],
.crm-input [type="datetime-local"] {
  box-shadow: 0 0 0 1px #b5b5b5 !important;
  border: initial;
  padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 0;
  width: calc(100% - 1rem);
}
.crm-input [type="textarea"] {
  box-shadow: 0 0 0 1px #b5b5b5 !important;
  border: initial;
  padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 0;
  width: 100%;
}

.crm-input input {
  color: rgba(32, 34, 34, 0.9);
}

.tooltip-shape {
  position: absolute;
  width: 0.9375rem;
  height: 0.9375rem;
  right: 25px;
  top: 42px;
}

.tooltip-shape:before {
  content: "i";
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  font-size: 0.625rem;
  color: #0394db;
  z-index: 15;
  border: 1px solid #0394db;
  border-radius: 50%;
  width: 0.9375rem;
  height: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  opacity: 1 !important;
}

.tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: relative;
  z-index: 5;
  left: 9.2rem;
  top: 4.5rem;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-shape:hover .tooltiptext {
  visibility: visible;
}

.font-order {
  color: #333333 !important;
  font-family: "Segoe UI";
  font-weight: 400;
}

@media only screen and (max-width: 766px) {
  .crm-input {
    width: 100% !important;
  }

  .crm-input [type="text"],
  .crm-input [type="number"],
  .crm-input [type="email"],
  .crm-input [type="password"],
  .crm-input [type="tel"],
  .crm-input [type="url"],
  .crm-input [type="date"],
  .crm-input [type="datetime-local"] {
    width: 100%;
    margin: 1.375rem 0 0 0;
  }
}

@media only screen and (max-width: 425px) {
  .tooltip-shape {
    top: 66px;
  }
}
