.img-modal{
    width: 100%;
}

.media-center-download-image {
    display: flex;
    height: 100%;
    flex-flow: column-reverse;
}

.media-center-share-image {
    display: none;
}

.modal-mobile-only {
    background: transparent;
    display: contents;
}

.media-center-legenda {
    margin-top: 10px;
}

.media-center-download-image .media-center-card-button {
    width: 100%;
    vertical-align: baseline;
}

.media-center-modal-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.media-center-modal-flex .float-end {
    text-align: end;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0 transparent;
}

.media-center-modal-copy-clipboard {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}

.modal-copiar {
    cursor: pointer;
}

@media only screen and (max-width: 766px) {
    .modal-mobile-only {
        background: #efefef;
        padding: 13px 0;
        display: inline;
    }

    .media-center-download-image {
        display: none;
    }

    .media-center-share-image {
        display: block;
        margin: 10px 0!important;
        color: #999;
        font-size: 12px;
    }
}