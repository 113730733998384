.x-downloads__search {
  display: -webkit-box;
  display: flex;
  margin: 1rem 0 3.75rem 0;
}

.x-downloads__search--form {
  width: 100%;
}

.x-downloads__search--container {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.x-downloads__search--field {
  display: -webkit-box;
  display: flex;
  width: 100%;
}

.x-downloads__search--text {
  font-family: 'Open Sans';
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
}

.midia-search-button {
  border-radius: 1.875rem;
  width: 100%;
  height: 3.125rem;
  padding: 0 .9375rem;
  position: relative;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  background: #00518b;
  background: -webkit-gradient(linear, left top, right top, from(#00518b), color-stop(86%, #0066a0), to(#006aa3));
  background: linear-gradient(90deg, #00518b 0%, #0066a0 86%, #006aa3 100%);
  margin: 0 auto;
  margin-top: 1.75rem;
  -webkit-transition: all .155s ease-in;
  transition: all .155s ease-in;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.midia-search-button:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.284)
}

.limparFiltro{
  color:#0066b3
}

@media only screen and (max-width: 766px) {
  .x-downloads__search--container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .x-downloads__search--field {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: center;
  }

  .x-downloads__search--field {
    margin: 0 0 0.625rem 0;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .x-downloads__search--container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .image-upload {
    width: 35%;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 766px) and (min-width: 200px) {
  .image-upload {
    width: 75%;
    margin-top: 10px;
  }
}