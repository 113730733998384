.crm-input {
  display: inline-flex;
  position: relative;
}

.auto-complete-input {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, -7px, 0);
  transform: translate3d(10px, -7px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
}

.auto-complete-tooltip-shape {
  top: 22px !important;
}

.crm-input-label {
  font-size: 0.75rem;
  -webkit-transform: translate3d(10px, 15px, 0);
  transform: translate3d(10px, 15px, 0);
  color: #00518b;
  background: #fff;
  font-family: "Open Sans";
  position: absolute;
  z-index: 2;
  padding: 0 5px;
}

.crm-input textarea {
  box-shadow: 0 0 0 1px #b5b5b5 !important;
  border: initial;
  padding: 0.9375rem 1.3125rem 0.9375rem 0.625rem;
  border-radius: 5px;
  margin: 1.375rem 1rem 0 0;
  position: relative;
  z-index: 0;
  width: 100%;
}

.crm-input textarea:read-only {
  color: #a6abaa;
  border-color: #cbcbcb;
}

.crm-input textarea:read-only {
  border: 1px solid #cbcbcb;
  box-shadow: unset;
}

.crm-input textarea:disabled {
  background-color: unset;
}

.crm-input textarea {
  color: rgba(66, 75, 74, 0.8);
}

.tooltip-shape {
  position: absolute;
  width: 0.9375rem;
  height: 0.9375rem;
  right: 25px;
  top: 42px;
}

.tooltip-shape:before {
  content: "i";
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  font-size: 0.625rem;
  color: #0394db;
  z-index: 15;
  border: 1px solid #0394db;
  border-radius: 50%;
  width: 0.9375rem;
  height: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  opacity: 1 !important;
}

.tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: relative;
  z-index: 5;
  left: 9.2rem;
  top: 4.5rem;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-shape:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 766px) {
  .crm-input {
    width: 100% !important;
  }

  .crm-input textarea {
    width: 100%;
    margin: 1.375rem 0 0 0;
  }
}


