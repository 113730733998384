.card-result .table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: rgba(0, 64, 154, 0.07);
}

.card-result .table {
  width: auto !important;
  border: #e1e6eb 1px solid !important;
}

.card-result .table > thead {
  background-color: #00538c;
}

.card-result .table > thead > tr > th {
  color: #fff !important;
}

.card-result {
  font-size: 12px !important;
}

.strong-codigo-vazio {
  padding-left: 1.069rem;
}
