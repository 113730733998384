.main-lab {
    padding: 0 5.75rem;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 1.75rem;
}

.main-lab .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important; 
    margin-left: 0 !important; 
}

@media only screen and (max-width: 766px){
    .main-lab {
        padding: 0 1.75rem;
    }
}