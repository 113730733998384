.divTable {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  position: relative;
  overflow: hidden;
  min-height: 20vh;
  max-height: 65vh;
}
.divCardTable {
  grid-column: 2 / 15;
  overflow: scroll;
  height: 100%;
}
.div-container-input {
  display: flex;
  margin: 5px;
}

.input-matriz-dioptrias {
  width: 45px !important;
  border-radius: 4px;
  border: 1px solid;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
}
.containerModalMatriz {
  width: 100%;
  height: 100%;
}
.th-container-matriz-dioptrias {
  height: 100%;
  display: flex;
  align-items: center;
}
.th-matriz-dioptrias {
  height: 39px !important;
  width: 70px;
  background-color: white;
  color: #525456;
  position: sticky;
  left: 0;
  z-index: 1;
  padding-left: 6px;
}
.table-header-horizontal {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
table#tablePositive\ tabUmaLinha {
  display: initial;
  width: 41%;
}
table#tableNegative\ tabUmaLinha {
  display: initial;
  width: 41%;
}

.divTable table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

.divContainerTable {
  display: flex;
  justify-content: center;
}

.CilindricoContainer {
  grid-column: 2 / 15;
  display: flex;
  justify-content: space-around;
}

.EsfericoContainer {
  grid-row: 1 / 3;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  display: flex;
  justify-content: center;
  margin: 0px 20px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
#degativoOuPositivo {
  z-index: 6;
}
#tablePositive th#degativoOuPositivo::after {
  content: "( + )";
}
#tableNegative th#degativoOuPositivo::after {
  content: "( - )";
}
.focusInputMatriz {
  border: 2px solid #88c0ff;
  box-shadow: 0 0 9px #afd5ff;
}
.tabSemPsitivosOuNegativos {
  justify-content: center;
  height: 54vh;
  width: 100%;
}
#thEmpty {
  z-index: 2;
}
.dioptriasNotFound {
  font-weight: 700;
  color: #979797;
}

#contained-modal-title-vcenter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.td-matriz-dioptrias {
  vertical-align: inherit !important;
}
@media (max-height: 1024px) {
  .divTable {
    min-height: 20vh;
    max-height: 60vh;
  }
}

.containerDioptriasNotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (max-width: 992px) {
  .modal-dialog {
    max-width: 892px !important;
    margin: 1.75rem auto;
  }
  .modal-content {
    width: 90vw !important;
    margin: auto;
  }
}
@media (max-width: 767px) {
  .tabSemPsitivosOuNegativos {
    width: 100%;
    height: 15vh;
    justify-content: center;
  }
  .divTable {
    overflow: auto;
  }
  #thEmpty {
    z-index: 2;
    width: 185px;
  }
  .divContainerTable {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .mapaDioptriasTR {
    display: table !important;
  }
  .divTable {
    margin-bottom: 41px;
  }
  .table-header-horizontal {
    top: -3px;
  }
  .th-matriz-dioptrias {
    left: -6px;
  }
}

@media (max-width: 500px) {
  .EsfericoContainer {
    margin: 0px 11px 0px 0px;
  }
}
