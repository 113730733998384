.AccordionSummary {
	width: 100%;
}

.MuiAccordionDetails-root {
	display: block !important;
	padding: 8px 16px 16px;
}

.btnRemover {
	top: 29px;
	margin-top: 30px;
	font-size: 16px;
	color: #000;
}

.btnRemover > small {
	font-size: small;
}

.btnRemover:hover {
	text-decoration:none; 
}

.oticaUp .image-upload{
	position: unset;
    width: unset;
}