.lab-card {
	display: inline-grid;
	box-shadow: 0 0 0 2px rgb(181 181 181 / 40%);
	border-radius: 0.1875rem;
	padding: 1rem 1rem 1.25rem 1rem;
	background: #fff;
	width: 100%;
	height: 350px;
}

.lab-card-image {
	margin: 0 auto;
    max-width: 100%;
	max-height: 96.23%;
}

.lab-card-title {
	font-size: 0.75rem;
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	line-height: 2;
	font-weight: bold;
}

.lab-card-paragraph {
	font-size: 0.75rem;
	font-family: "Montserrat", sans-serif;
	color: #424b4a;
	line-height: 2;
	margin-bottom: 0px !important;
	word-break: break-all;
}

.lab-card-link {
	font-size: 0.75rem;
	font-family: "Montserrat", sans-serif;
	color: #0066b3;
	line-height: 1.5;
	font-weight: bold;
	text-decoration: underline;
}

@media only screen and (max-width: 766px) {
    .lab-card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin: 0.7rem 0px;
		width: 105.5%;
		word-wrap: break-word;
    }

    .lab-card-title {
		line-height: 1.5;
    }

    .lab-card-paragraph {
		line-height: 1.5;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
	.lab-card-title {
		font-size: 0.6875rem;
	}

	.lab-card-paragraph {
		font-size: 0.6875rem;
	}

	.lab-card-link {
		font-size: 0.6875rem;
	}
}
