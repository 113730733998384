.x-order-confirmed__container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0 auto;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 3.5625rem 0 0 0;
  font-family: "Montserrat", sans-serif;
}

.x-order-confirmed__container1 {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0 auto;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  /* padding: 3.5625rem 0 0 0; */
  font-family: "Montserrat", sans-serif;
}

.x-order-confirmed__title {
  margin: 1.0625rem 0 1.1875rem 0;
  font-size: 1.625rem;
  font-family: "Montserrat", sans-serif;
  color: #0066b3;
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__title {
    font-size: 1.125rem;
  }

  .button-mobile-only {
    padding-right: 1rem;
  }
}

.x-order-confirmed__paragraph {
  display: -webkit-box;
  display: flex;
  margin: 0 auto;
  text-align: center;
  font-size: 0.875rem;
}

.x-order-confirmed__paragraph:nth-of-type(1) {
  font-weight: bold;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__paragraph {
    max-width: 15.5rem;
    font-size: 0.75rem;
  }
}

.confirmed-opacity {
  opacity: 0.2;
}

.x-order-confirmed__list {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 1.875rem 0 2.5rem 0;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 15.5rem;
    margin: 1.625rem 0 1.375rem 0;
  }
}

.x-order-confirmed__item {
  font-size: 0.875rem;
  background: white;
  padding: 1.1875rem 1.875rem;
}

.x-order-confirmed__item--number {
  color: #0066b3;
  font-weight: bold;
}

.x-order-confirmed__item--lab {
  font-weight: bold;
}

.x-order-confirmed__item:nth-of-type(1) {
  margin: 0 1rem 0 0;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__item {
    font-size: 0.75rem;
    padding: 1.25rem 1.125rem;
    text-align: center;
  }

  .x-order-confirmed__item:nth-of-type(1) {
    margin: 0 0 0.3125rem 0;
    padding: 0.8125rem;
  }
}

.x-order-confirmed__button {
  padding: 14px 0;
  border-radius: 3.125rem;
  margin: 0 0 0.875rem 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  pointer-events: all;
  cursor: pointer;
}

.x-order-confirmed__button:hover {
  cursor: pointer;
}

.x-order-confirmed__button--text {
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0 1.25rem;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__button--text {
    -webkit-box-ordinal-group: 2;
    order: 1;
    margin: 0rem 0 0rem 1.25rem;
  }
}

.x-order-confirmed__button--icon {
  margin: 0 1rem 0 1rem;
  -webkit-box-ordinal-group: 3;
  order: 2;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__button--icon {
    margin: 0 0.625rem 0 1rem;
  }
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__button {
    padding: 0.625rem 0;
  }
}

.x-order-confirmed__link {
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
  text-decoration: underline;
  color: #424b4a;
  margin: 0 0 3.375rem 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (max-width: 766px) {
  .x-order-confirmed__link {
    margin: 0 0 2.5rem 0;
  }
}

/* ********* SUMMARY PDF ********* */

.pdf-pedido__summary {
  width: 100%;
  background: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 0rem 3.75rem 0rem 2.75rem;
  -webkit-transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
  transition: all 0.3s cubic-bezier(1, 1, 0.3, 1);
}

.pdf-pedido__summary--intro {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.pdf-pedido__summary--header {
  margin-top: 1rem;
}

.pdf-pedido__summary--barcode {
  float: right;
}

.pdf-pedido__summary--icon {
  align-self: center;
  margin: 0 0 1.25rem 0;
}

.pdf-pedido__summary--title {
  color: #00518b;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 0.9375rem 0;
}

.pdf-pedido__summary--paragraph {
  color: #424b4a;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
}

.pdf-pedido__summary--body {
  /* margin: 1.6875rem 0 0 0; */
}

.pdf-pedido__summary--item {
  position: relative;
  border-bottom: 1px solid #d8d8da;
}

.pdf-pedido__summary--item:not(:last-child) {
  border-bottom: 1px solid #707070;
}

.pdf-pedido__summary--link {
  color: #00518b !important;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0 1rem 0;
  display: block;
  cursor: default;
  text-decoration: none !important;
}

.pdf-pedido__summary--print {
  color: #0d81d3 !important;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0 1rem 0;
  display: block;
  cursor: pointer;
}

.pdf-pedido__summary--card {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.pdf-pedido__summary--card.left:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.pdf-pedido__summary--card.right {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.pdf-pedido__summary--card.right:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 45%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.pdf-pedido__summary--card.shape {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.pdf-pedido__summary--card.shape:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.pdf-pedido__summary--text {
  margin: 0.625rem 0 0.125rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
}

.pdf-pedido__summary--text-2 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.pdf-pedido__summary--text.no--gap {
  margin-bottom: 0;
}

.pdf-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 10rem;
}

.pdf-pedido__summary--side-left .pdf-pedido__summary--subitem {
  padding: 0.3rem 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.775rem;
  width: 10rem;
}

.pdf-pedido__summary--content {
  display: none;
  margin: 0 0 0 0;
}

.pdf-pedido__summary--content.show {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.pdf-pedido__summary--group {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.pdf-pedido__summary--side-left {
  width: 45%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0 1.875rem 0 0;
}

.pdf-pedido__summary--side-right {
  width: 45%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.pdf-pedido__summary--subitem.right {
  text-align: right;
  width: auto;
}
.pdf-pedido__summary--subitem.left {
  width: auto;
}

/*.x-confirm-order__list__pdf {
  width: 100%;
  margin: 1rem 0 1rem 0;
  background: rgba(0, 102, 179, 0.1);
}*/

.x-confirm-order__dados--list__pdf {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.5;
  height: 3.125rem;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 3.75rem 0 3.75rem;
  width: 100%;
}

.x-confirm-order__dados--item {
  margin: 0 1.25rem 0 0;
}

.x-confirm-order__dados--item.is--large {
  font-weight: bold;
}

.x-confirm-order__dados--item.blue {
  color: #0066b3;
  font-weight: bold;
}

.x-order-confirmed__list__pdf {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  /*margin: 1rem 0 2.5rem 0;*/
}

.x-order-confirmed__item__pdf {
  font-size: 0.875rem;
  background: white;
  padding: 1.1875rem 3.75rem;
  width: 49%;
}

.x-web-ordering-pedido__main .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0;
}

.card-body {
  padding: 0.25rem;
}
